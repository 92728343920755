
"use strict";
let stData = {
	1: [78.4,9.6,4.7,7.3],
	2: [78.9,5.7,7.9,6.8],
	3: [71.1,11.9,9.1,4.8],
	4: [80.9,11.5,2.6,5],
	5: [67.3,15.4,8,5.1],
	6: [72,15.7,9.2,3.1],
	7: [75.8,6.8,6.4,11],
	8: [66.4,7.3,16.1,9.5],
	9: [61.7,21.8,14.3,2.3],
	10: [77.7,8.2,8.2,3.5],
	11: [74.3,11,9.2,5.4],
	12: [60.9,22.3,10.9,4.3],
	13: [70,20,4.7,4.7],
	14: [83.2,6.4,7.2,3.2],
	15: [73.1,12.7,7.3,6.2],
	16: [44.4,26.2,27,2.4],
	17: [68.8,10,9.8,8.3],
	18: [75.7,8.9,6.8,7.9],
	19: [100,0,0,0],
	20: [70.4,13.2,7.9,6.3],
	21: [48.5,37.4,8.7,4.9],
	22: [69.2,13.9,8.7,5.8],
	23: [58.3,19.3,12.4,7.2],
	24: [54.4,21.2,17,4.2],
	25: [64.1,20.5,6,2.6],
	26: [77.6,8.6,5.9,7.9],
	27: [63.6,14.8,9.3,12.3],
	28: [73.5,12.1,6.1,8.3],
	29: [70.8,17.7,5.3,6.2],
	30: [57.3,29.4,6.2,7.1],
	31: [73.3,11.5,5.5,9.7],
	32: [61.9,20.5,8.6,7.4],
	33: [60.3,24.9,9,2.8],
	34: [43.9,21.4,5.1,5.1],
	35: [60.7,32.1,3.6,3.6],
	36: [70.9,13.2,8.7,5.3],
	37: [72.8,9.8,12,3.3],
	38: [77,6.9,5.9,4.6],
	39: [34.8,26.1,17.4,21.7],
	40: [38.3,39.8,16.5,5.3],
	41: [35.1,41.4,10.8,2.7],
	42: [57.5,22,15,5.5],
	43: [66.7,19,11.1,3.2],
	44: [60,21,18.1,1],
	45: [82.3,8.7,5.6,3.4],
	46: [74.2,9.5,8.7,7.5],
	47: [80.9,5.5,7,4.1],
	48: [70.5,16.3,6.3,5.6],
	49: [76.6,9,5.9,7.3],
	50: [71.8,15.6,8.4,1.4],
	51: [61.2,23.9,9.7,5.1],
	52: [78.6,6,10.1,5.2],
	53: [61.7,20.8,8.1,9.5],
	54: [75.5,12.2,2,10.2],
	55: [84.8,3.3,3.3,8.7],
	56: [67,17.5,9.3,6.2],
	57: [67.9,9.7,13.9,8.5],
	58: [77.2,9.5,6.3,7],
	59: [71.6,10.5,6.2,10.5],
	60: [78.8,9.6,3.8,5.8],
	61: [71.6,12.8,11.3,4.3],
	62: [65.7,16.8,11,6.5],
	63: [71.8,12,6.5,9.7],
	64: [71.7,14.6,5.5,7.9],
	65: [72.2,14.5,6.7,5.3],
	66: [62.7,21.9,10.5,3.5],
	67: [67.3,12.5,9.2,10.2],
	68: [63.3,15.3,12.5,6.2],
	69: [58.8,19.3,12.7,6.4],
	70: [73.1,8.6,11.7,5.3],
	71: [61.3,13.5,14.7,8],
	74: [70.6,29.4,0,0],
	75: [16.7,41.7,8.3,8.3],
	76: [64.2,12.3,2.5,8.6],
	77: [58,15.5,12.7,10.6],
	78: [51.5,33.5,9.2,5.8],
	79: [64.6,11,10.2,7.1],
	80: [61.9,16.7,14.3,7.1],
	81: [63.6,9.1,18.2,9.1],
	82: [67.5,10.4,11.7,10.4],
	72: [80,12.5,3.3,4.2],
	83: [0,50,0,0],
	73: [48.5,32,9.3,4.1],
	84: [61.9,12.7,20.6,4.8],
	85: [50,20,10,15]
};
	
export default stData;
