
;"use strict";

var State = {

// Глобальные флаги тестовой модификации

	flNoDist: 0, // Отключение автоматического распределения пациентов в группах
	flTrace: 0, // Флаг включения трассировки
/////////////////////////////////////////

	dbVersion: 81, // версия хранилища в local storage
	lastMetod: 1201, // Id посследнего метода в общем списке всех методов

	super: 1072427180, // пароль суперпользователя

	freePats: {
		'rmz': {
			st1: 0,
			st2: 0,
			st3: 0
		},
		'krr': {
			st1: 0,
			st2: 0,
			st3: 0,
			st4_1_1: 0,
			st4_1_2: 0,
			st4_1_3: 0,
			st4_2_1: 0,
			st4_2_2: 0,
			st4_2_3: 0,
			st4_2_4: 0,
			st4_3_1: 0,
			st4_3_2: 0,
			st4_3_3: 0,
			st4_3_4: 0,
			st4_4_1: 0,
			st4_4_2: 0,
			st4_4_3: 0,
			st4_4_4: 0
		},
		'rl': {
			st1: 0,
			st2: 0,
			st3: 0
		},
		'rya': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_2_6: 0,
			st0_2_9: 0,
			st0_3_7: 0,
			st0_3_10: 0,
			st0_4_8: 0,
			st0_4_11: 0
		},
		'rshm': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_1_4: 0,
			st0_2_5: 0,
			st0_3_6: 0,
			st0_4_7: 0,
			st0_2_8: 0,
			st0_3_9: 0,
			st0_4_10: 0,
			st0_2_11: 0,
			st0_3_12: 0,
			st0_4_13: 0
		},
		'rp': {
			st4_1: 0,
			st4_2: 0,
			st4_3: 0
		},
		'rz': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_1_1: 0,
			st0_1_2: 0,
			st0_2_3: 0,
			st0_2_4: 0,
			st0_3_5: 0,
			st0_3_6: 0
		},
		'rmp': {
			st1: 0,
			st3: 0,
			st4_1: 0,
			st4_2: 0
		},
		'rpsh': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_1_4: 0,
			st0_2_5: 0
		},
		'rpz': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_1_3: 0,
			st0_2_4: 0
		},
		'rtm': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		},
		'gcr': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0
		},
		'rpsz': {
			st2_0_1: 0,
			st3_0_1: 0,
			st4_0_1: 0,
			st4_0_2: 0
		},
		'neo': {
			st0_1_1: 0,
			st0_2_2: 0,
			st0_1_3: 0,
			st0_2_4: 0,
			st0_1_5: 0,
			st0_2_6: 0
		},
		'melanoma': {
			st1: 0,
			st2: 0,
			st3: 0,
			st4_1: 0,
			st4_2: 0
		}
	},

	extraPats: {
		'rmz': {
			st1: 0,
			st2: 0,
			st3: 0
		},
		'krr': {
			st1: 0,
			st2: 0,
			st3: 0,
			st4_1_1: 0,
			st4_1_2: 0,
			st4_1_3: 0,
			st4_2_1: 0,
			st4_2_2: 0,
			st4_2_3: 0,
			st4_2_4: 0,
			st4_3_1: 0,
			st4_3_2: 0,
			st4_3_3: 0,
			st4_3_4: 0,
			st4_4_1: 0,
			st4_4_2: 0,
			st4_4_3: 0,
			st4_4_4: 0
		},
		'rl': {
			st1: 0,
			st2: 0,
			st3: 0
		},
		'rya': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_2_6: 0,
			st0_2_9: 0,
			st0_3_7: 0,
			st0_3_10: 0,
			st0_4_8: 0,
			st0_4_11: 0
		},
		'rshm': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_1_4: 0,
			st0_2_5: 0,
			st0_3_6: 0,
			st0_4_7: 0,
			st0_2_8: 0,
			st0_3_9: 0,
			st0_4_10: 0,
			st0_2_11: 0,
			st0_3_12: 0,
			st0_4_13: 0
		},
		'rp': {
			st4_1: 0,
			st4_2: 0,
			st4_3: 0
		},
		'rz': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_1_1: 0,
			st0_1_2: 0,
			st0_2_3: 0,
			st0_2_4: 0,
			st0_3_5: 0,
			st0_3_6: 0
		},
		'rmp': {
			st1: 0,
			st3: 0,
			st4_1: 0,
			st4_2: 0
		},
		'rpsh': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_1_4: 0,
			st0_2_5: 0
		},
		'rpz': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_1_3: 0,
			st0_2_4: 0
		},
		'rtm': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0,
			st0_0_8: 0
		},
		'gcr': {
			st0_0_1: 0,
			st0_0_2: 0,
			st0_0_3: 0,
			st0_0_4: 0,
			st0_0_5: 0,
			st0_0_6: 0,
			st0_0_7: 0
		},
		'rpsz': {
			st2_0_1: 0,
			st3_0_1: 0,
			st4_0_1: 0,
			st4_0_2: 0
		},
		'neo': {
			st0_1_1: 0,
			st0_2_2: 0,
			st0_1_3: 0,
			st0_2_4: 0,
			st0_1_5: 0,
			st0_2_6: 0
		},
		'melanoma': {
			st1: 0,
			st2: 0,
			st3: 0,
			st4_1: 0,
			st4_2: 0
		}
	},

	stProbs: [ // Распределение вероятности по стадиям (Только для krr)
//		[15,20,40,25] // krr
// 16 - фейковые проценты, позволяющие привести сумму к 100% и использовать 
// унифицированную процедуру распределения до пациентов с химиотерапией
		[15,8,36,25,16] // krr

	],

	stPats: [ // Распределение пациентов по стадиям с учетом корректировки для 2-3 стадий
		[15,20,40,25], // krr
//		[75,99,69,61] // mel
		[1,1,1,1], // mel
		[1,1,1,1], // rmz. 
		[1,1,1,1], // rl 
		[1,1,1,1], // rya 
		[1,1,1,1], // rshm 
		[1,1,1,1], // rp 
		[1,1,1,1], // rz 
		[1,1,1,1], // rmp 
		[1,1,1,1], // rpsh 
		[1,1,1,1], // rpz 
		[1,1,1,1], // rtm 
		[1,1,1,1], // rpsz 
		[1,1,1,1], // gcr 
		[1,1,1,1] // neo 
	],// Используется для размещения исходного числа пациентов в группах перед пересчетом
		// в тех случаях, когда сумма процентов методов в группе не равна 100%

// Распределение КРР 4ст между резектабельными, потенциальными и нерезектабельными 

	resectProb: [
	10, 				// 0 - резектабельн
	15, 				// 1 - потенциально
	75					// 2 - нерезкт
	],			
	resectPats: [0, 0, 0],

	// pathList:   ['РМЖ', 'КРР', 'РЛ', 'Меланома'],
	// pathKeys:   ['rmz', 'krr', 'rl', 'melanoma'],
	// pathTotals: [140,   130,   120,  110       ],

	pathList:   ['КРР','Меланома','РМЖ', 'РЛ', 'РЯ', 'РШМ', 'РП', 'РЖ', 'РМП', 
		'Рак пищевода', 'РПЖ', 'РТМ', 'ГЦР', 'РПСЖ', 'НЭО'],
	pathKeys:   ['krr','melanoma','rmz', 'rl', 'rya', 'rshm', 'rp', 'rz', 'rmp', 
		'rpsh', 'rpz', 'rtm', 'gcr', 'rpsz', 'neo'],
	defaultSt: {
		'krr':'st2',
		'melanoma':'st2',
		'rmz':'st0_0_1',
		'rl':'st1_0_1',
		'rya':'st0_0_1',
		'rshm':'st0_0_1',
		'rp':'st4_1_1',
		'rz':'st0_0_1',
		'rmp':'st1',
		'rpsh':'st0_0_1',
		'rpz':'st0_0_1',
		'rtm':'st0_0_1',
		'rpsz':'st2_0_1',
		'gcr':'st0_0_1',
		'neo':'st0_1_1'
	},
	drugBuf: [], 
	omsBuf: [],
	omsdBuf: [],
	rloBuf: [],
	onlsBuf: [],
	pathTotals: [100,100,100,100,100,100,100,100,100,100,100,100,100,1000000], // Пациенты, вновь диагностированные на входе в модель
									// распределения.
	pathSumm: [100,100,100,100,100,100,100,100,100,100,100,100,100,100],	// Сумма пациентов, проходящих терапию за год.
									// Т.е. сумма по всем группам, после распределения								
//	rlPatsBuf: {}, // Буфер для сохранения и восстановления текущего распределения по группам для РЛ
	rmpPatsBuf: {}, // То же для RMP
	rmzPatsBuf: {}, // То же для RMZ
	rmzMultBuf: {}, // Буфер сохранения множителей расчета общего количества в группе для РМЖ
	rzPatsBuf: {}, // То же для RZ
	rzMultBuf: {}, // Буфер сохранения множителей расчета общего количества в группе для РЖ
	rpszPatsBuf: {}, 
	rpszMultBuf: {}, 
	rlPatsBuf: {}, 
	rlMultBuf: {}, 
	rpshPatsBuf: {}, 
	rpshMultBuf: {}, 
	ryaPatsBuf: {}, 
	ryaMultBuf: {}, 
	rmpFreePatsBuf: {},
	ryaFreePatsBuf: {},
	pathBudgets: {'krr':1, 'melanoma':2, 'rmz':3, 'rl':4, 'rya':5, 'rshm':6, 'rp':7 , 'rz':8, 
				'rmp':9, 'rpsh':10, 'rpz':11, 'rtm':12, 'gcr':13, 'rpsz':14, 'neo':15 },
	chanBudgets: {'oms':0, 'omsd':0, 'rlo':0, 'onls':0 },
	pathFreeList: [],
	selPath: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
	selPathKeys: ['krr','melanoma','rmz', 'rl', 'rya', 'rshm', 'rp', 'rz', 'rmp', 
				'rpsh', 'rpz', 'rtm', 'gcr', 'rpsz', 'neo'], // Ключи, оставшиеся после фильтрации 

	startEvent: 'mousedown',
	currStad: 'st2',
	currStNum: 2,
	currPath: 'krr',
	totFreePats: 0,
	stPanel: null,
	pathPanel: null,
	currPage: 0,
	flSelection: 0,
	flModal: 0,
	flIpad: 0,
	flAnimate: 0,
	flDistChanged: 0,
	regionDataInit: 0, // Индикатор первой инициализации выбранного региона
	currPats: { // Набор исходных данных по текущему региону
		'mel': 0,
		'krr': 0,
		'rmz': 0,
		'rl': 0,
		'rya': 0,
		'rshm': 0,
		'rp': 0,
		'rz': 0,
		'rmp': 0,
		'rpsh': 0,
		'rpz': 0,
		'rtm': 0,
		'rpsz': 0,
		'gcr': 0,
		'neo': 0
	}, //
	customPats: { // Набор исходных данных, расчитанных исходя из нового
		'mel': -1, // значения общего числа пациентов
		'krr': -1,
		'rmz': -1,
		'rl': -1,
		'rya': -1,
		'rshm': -1,
		'rp': -1,
		'rz': -1,
		'rmp': -1,
		'rpsh': -1,
		'rpz': -1,
		'rtm': -1,
		'rpsz': -1,
		'gcr': -1,
		'neo': -1
	}, //
	currLeth: {
		'mel': 0,
		'krr': 0,
		'rmz': 0,
		'rl': 0,
		'rya': 0,
		'rshm': 0,
		'rp': 0,
		'rz': 0,
		'rmp': 0,
		'rpsh':0,
		'rpz':0,
		'rtm':0,
		'rpsz':0,
		'gcr':0,
		'neo':0
	},
	drugOrd: [119,99,100,70,28,29,133,147,71,72,1,27,2,26,120,30,117,3,113,80,31,112,32,114,115,
		34,83,73,35,121,4,145,5,122,123,84,36,85,86,88,37,87,142,143,38,39,6,7,8,9,25,96,124,13,40,10,41,140,11,12,74,134,42,
		127,126,125,43,101,102,44,148,89,46,118,98,47,48,116,97,14,15,144,135,136,16,24,141,90,75,103,91,50,17,18,51,19,20,
		76,52,128,129,108,109,21,53,104,105,138,137,92,54,146,139,106,95,81,55,22,56,57,82,58,59,130,60,131,61,62,77,23,63,64,
		66,93,67,107,68,132,69,65,78,79,111,110],// Порядок листинга препаратов в алфавитных списках

	tabletForm: [
		3, 4, 6, 10, 12, 13, 21, 22, 29, 40, 42, 44, 46, 48, 51, 53,
		54, 55, 63, 67, 68, 70, 72, 73, 74, 75, 77, 78, 89, 90, 92, 95, 97,
		99, 100, 101, 102, 103, 104, 105, 107, 111, 117, 118, 119, 120, 122, 128, 132, 133,
		137,138,139, 140, 141, 144, 146, 147, 148
	], // Индексы препаратов, содержащих много единиц в упаковке, бюджет которых
		// округляется на полный курс лечения	
	perorForm: [
		99, 100, 70, 29, 72, 3, 31, 73, 4, 6, 10, 40, 12, 74, 42, 101, 102, 44,
		89, 46, 48, 90, 75, 51, 21, 53, 104, 105, 54, 92, 55, 22, 77, 63, 67, 107, 68, 78, 111, 118,
		119, 120, 128, 131, 132, 133, 137,138,139, 140, 141, 144, 146, 148  
	], // Индексы препаратов в пероральной форме, для формирования данных по 
		// инъекционным препаратам  для ограничения их использования при распределении
		// каналов финансирования 

	currRegname: '',
	currRegNum: 0,
	currLawDoc: 0,
	flRegPrice: 0,	// Is maximal regional price addon selected
	flTrastMode: 0, // Mode of trastuzumab rendering and calculation 0 - infusion
					//												1 - trancedermal
	currRegAdd: 8, 	// price addon persent for selected region
	totPatSumm: 0,	// total patients in therapy in all selected pathologies 

/////////// DISTRIBUTION MODULE ////////////////

	clinType: 'st', // Current selected type of clinic 
//	clinType: 'ds', // Current selected type of clinic 

	dsIncome: 0, // OMS compensation accumulators for DS and KS
	stIncome: 0,
	distCurrPage: 4,

	clinicData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 22563.91,
		kus: 1.1,
		uk: 1.0,
		kslp: 1.0,
		kd: 1.0
	},

	dsData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 12272.64,
		kus: 1.0,
		uk: 1.0,
		kslp: 1.0,
		kd: 1.0
	},

	stData: {
		omsPlan: 2000000000,
		omsdPlan: 2000000000,
		rloPlan: 5000000,
		onlsPlan: 10000000,
		bs: 22563.91,
		kus: 1.1,
		uk: 1.0,
		kslp: 1.0,
		kd: 1.0
	},

	dsUKData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1], // Individual values for UK for all KSG
	stUKData: [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1], // Individual values for UK for all KSG
	averCostKs: 0, 
	nOfCasesKs: 0,
	averCostDs: 0, 
	nOfCasesDs: 0,

	ksgBuf: {
		'ds19.037': 0,
		'ds19.038': 0,
		'ds19.039': 0,
		'ds19.040': 0,
		'ds19.041': 0,
		'ds19.042': 0,
		'ds19.043': 0,
		'ds19.044': 0,
		'ds19.045': 0,
		'ds19.046': 0,
		'ds19.047': 0,
		'ds19.048': 0,
		'ds19.049': 0,

		'st19.062': 0,
		'st19.063': 0,
		'st19.064': 0,
		'st19.065': 0,
		'st19.066': 0,
		'st19.067': 0,
		'st19.068': 0,
		'st19.069': 0,
		'st19.070': 0,
		'st19.071': 0,
		'st19.072': 0,
		'st19.073': 0,
		'st19.074': 0
	}
}

export default State;