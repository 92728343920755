;"use strict";

const rpshData = {

// ============================>rpsh
// ---------> ХЛТ б-ые I-IVa стадий с и без оперативного лечения
		'st0_0_1':{
			group: 'ХЛТ б-ые I-IVa стадий с и без оперативного лечения',
			meth: [
				{
					id: 973,
					code: 'sh0974',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 30,
					dose: [100,40,6000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [2,2,2],
					pats: 5
				},
				{
					id: 974,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин AUC2',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 50,
					dose: [100,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 7
				},
				{
					id: 975,
					code: 'sh0685',
					name: 'Цисплатин + капецитабин',
					info: '(цисплатин, капецитабин)',
					drugs: [66,93,10],
					rate: 20,
					dose: [100,40,50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [2,2,2],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Пред- периоперационная ХТ б-ые I-IVА ст.
		'st0_0_2':{
			group: 'Пред- периоперационная ХТ б-ые I-IVА ст.',
			meth: [
				{
					id: 976,
					code: 'sh0588',
					name: 'FLOT',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [88,16,13,61],
					rate: 15,
					dose: [120,150,400,5000],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 1
				},
				{
					id: 977,
					code: 'sh0074',
					name: 'DCF',
					info: '(доцетаксел, цисплатин, 5-фторурацил)',
					drugs: [88,87,66,93,61],
					rate: 15,
					dose: [120,20,100,40,7000],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3,3,3],
					pats: 1
				},
				{
					id: 978,
					code: 'sh0974',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 30,
					dose: [100,40,6000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [3,3,3],
					pats: 2
				},
				{
					id: 979,
					code: 'sh0130',
					name: 'FOLFOX6',
					info: '(oксалиплатин, кальция фолинат, 5-ФУ 400 мг/м2, 5-ФУ 2400 мг/м2)',
					drugs: [16,13,61],
					rate: 10,
					dose: [150,400,3000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5,5,5],
					pats: 1
				},
				{
					id: 980,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел, цисплатин)',
					drugs: [17,66],
					rate: 30,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> Паллиативная ХТ б-ые II-IV ст.
		'st0_0_3':{
			group: 'Паллиативная ХТ б-ые II-IV ст.',
			meth: [
				{
					id: 981,
					code: 'sh0974',
					name: 'CF',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 20,
					dose: [100,40,6000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 982,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин AUC5',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 50,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 10
				},
				{
					id: 983,
					code: 'sh0685',
					name: 'XP',
					info: '(цисплатин, капецитабин)',
					drugs: [66,93,10],
					rate: 15,
					dose: [100,40,50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 984,
					code: 'sh0653',
					name: 'Оксалиплатин + капецитабин',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,10],
					rate: 15,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 985,
					code: 'sh0180',
					name: 'T-XP',
					info: '(цисплатин, капецитабин, трастузумаб)',
					drugs: [66,93,10,56,57],
					rate: 2,
					dose: [100,40,50500,440,150],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [4,4,4,4,4],
					pats: 0
				},
				{
					id: 986,
					code: 'sh0544',
					name: 'T-CF',
					info: '(цисплатин, 5-фторурацил, трастузумаб)',
					drugs: [66,93,61,56,57],
					rate: 2,
					dose: [100,40,2000,440,150],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4,4,4],
					pats: 0
				}
			],
			pats: 0
		},

// --------->  Линия 1
		'st0_1_4':{
			meth: [
				{
					id: 987,
					code: 'sh0974',
					name: 'CF',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 20,
					dose: [100,40,6000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 4
				},
				{
					id: 988,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин AUC5',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 50,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 989,
					code: 'sh0685',
					name: 'Цисплатин + капецитабин',
					info: '(цисплатин, капецитабин)',
					drugs: [66,93,10],
					rate: 15,
					dose: [100,40,50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 990,
					code: 'sh0653',
					name: 'Оксалиплатин + капецитабин',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,10],
					rate: 15,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 991,
					code: 'sh0180',
					name: 'T-XP',
					info: '(цисплатин, капецитабин, трастузумаб)',
					drugs: [66,93,10,56,57],
					rate: 2,
					dose: [100,40,50500,440,150],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [4,4,4,4,4],
					pats: 0
				},
				{
					id: 992,
					code: 'sh0544',
					name: 'T-CF',
					info: '(цисплатин, 5-фторурацил, трастузумаб)',
					drugs: [66,93,61,56,57],
					rate: 2,
					dose: [100,40,2000,440,150],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4,4,4],
					pats: 0
				}
			],
			pats: 0
		},

// --------->  Линия 2
		'st0_2_5':{
			meth: [
				{
					id: 993,
					code: 'sh0672',
					name: 'Паклитаксел 175 + карбоплатин AUC5',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 10,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 2
				},
				{
					id: 994,
					code: 'sh0675',
					name: 'Паклитаксел 175 + цисплатин 75',
					info: '(паклитаксел, цисплатин)',
					drugs: [17,66],
					rate: 10,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 2
				},
				{
					id: 995,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 15,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 996,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,17,108,109],
					rate: 10,
					dose: [300,200,1000,200],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [3,3,3,3],
					pats: 2
				},
				{
					id: 997,
					code: 'sh0179',
					name: 'Трастузумаб',
					info: '(трастузумаб)',
					drugs: [56,57],
					rate: 5,
					dose: [440,150],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4,4],
					pats: 1
				},
				{
					id: 998,
					code: 'sh0706',
					name: 'XELIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 10,
					dose: [300,80,40500],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 2
				},
				{
					id: 999,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 5,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 1000,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 10,
					dose: [50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [3],
					pats: 2
				},
				{
					id: 1001,
					code: 'sh0139.1',
					name: 'Паклитаксел',
					info: '(паклитаксел)',
					drugs: [91,17],
					rate: 10,
					dose: [300,200],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3,3],
					pats: 2
				},
				{
					id: 1002,
					code: 'sh0685',
					name: 'Цисплатин + капецитабин',
					info: '(цислпатин, капецитабин)',
					drugs: [66,93,10],
					rate: 5,
					dose: [100,40,50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [3,3,3],
					pats: 1
				},
				{
					id: 1003,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 10,
					dose: [400,80],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [2,2],
					pats: 1
				}
			],
			pats: 0
		}
	}

export default rpshData;	