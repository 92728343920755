var regLeth = {
	1: {'reg':'Белгородская область','mel':30},
	2: {'reg':'Брянская область','mel':23},
	3: {'reg':'Владимирская область','mel':47},
	4: {'reg':'Воронежская область','mel':54},
	5: {'reg':'Ивановская область','mel':24},
	6: {'reg':'Тверская область','mel':37},
	7: {'reg':'Калужская область','mel':34},
	8: {'reg':'Костромская область','mel':20},
	9: {'reg':'Курская область','mel':26},
	10: {'reg':'Липецкая область','mel':22},
	11: {'reg':'г. Москва','mel':433},
	12: {'reg':'Московская область','mel':170},
	13: {'reg':'Орловская область','mel':23},
	14: {'reg':'Рязанская область','mel':39},
	15: {'reg':'Смоленская область','mel':32},
	16: {'reg':'Тамбовская область','mel':20},
	17: {'reg':'Тульская область','mel':51},
	18: {'reg':'Ярославская область','mel':48},
	19: {'reg':'Ненецкий а.о.','mel':1},
	20: {'reg':'Архангельская обл.(б а.о)','mel':27},
	21: {'reg':'Вологодская область','mel':25},
	22: {'reg':'Калининградская область','mel':33},
	23: {'reg':'г. Санкт-Петербург','mel':229},
	24: {'reg':'Ленинградская область','mel':54},
	25: {'reg':'Мурманская область','mel':17},
	26: {'reg':'Новгородская область','mel':21},
	27: {'reg':'Псковская область','mel':19},
	28: {'reg':'Республика Карелия','mel':28},
	29: {'reg':'Республика Коми','mel':18},
	30: {'reg':'Краснодарский край','mel':133},
	31: {'reg':'Астраханская область','mel':21},
	32: {'reg':'Волгоградская область','mel':52},
	33: {'reg':'Ростовская область','mel':106},
	34: {'reg':'Республика Адыгея','mel':11},
	35: {'reg':'Республика Калмыкия','mel':4},
	36: {'reg':'Республика Крым','mel':82},
	37: {'reg':'Город Cевастополь','mel':20},
	38: {'reg':'Ставропольский край','mel':78},
	39: {'reg':'Республика Ингушетия','mel':3},
	40: {'reg':'Республика Дагестан','mel':16},
	41: {'reg':'Респ. Кабардино-Балкария','mel':18},
	42: {'reg':'Респ. Северная Осетия','mel':14},
	43: {'reg':'Респ. Карачаево-Черкесия','mel':9},
	44: {'reg':'Республика Чечня','mel':4},
	45: {'reg':'Нижегородская область','mel':75},
	46: {'reg':'Кировская область','mel':41},
	47: {'reg':'Самарская область','mel':101},
	48: {'reg':'Оренбургская область','mel':43},
	49: {'reg':'Пензенская область','mel':34},
	50: {'reg':'Пермский край','mel':56},
	51: {'reg':'Саратовская область','mel':65},
	52: {'reg':'Ульяновская область','mel':24},
	53: {'reg':'Республика Башкортостан','mel':72},
	54: {'reg':'Республика Марий Эл','mel':18},
	55: {'reg':'Республика Мордовия','mel':19},
	56: {'reg':'Республика Татарстан','mel':73},
	57: {'reg':'Республика Удмуртия','mel':36},
	58: {'reg':'Республика Чувашия','mel':19},
	59: {'reg':'Ханты-Мансийский а.о.','mel':20},
	60: {'reg':'Ямало-Ненецкий а.о.','mel':6},
	61: {'reg':'Курганская область','mel':19},
	62: {'reg':'Свердловская область','mel':126},
	63: {'reg':'Тюменская обл.(б а.о)','mel':23},
	64: {'reg':'Челябинская область','mel':94},
	65: {'reg':'Алтайский край','mel':53},
	66: {'reg':'Красноярский край','mel':55},
	67: {'reg':'Иркутская область','mel':74},
	68: {'reg':'Кемеровская область','mel':73},
	69: {'reg':'Новосибирская область','mel':77},
	70: {'reg':'Омская область','mel':41},
	71: {'reg':'Томская область','mel':18},
	72: {'reg':'Забайкальский край','mel':13},
	73: {'reg':'Республика Бурятия','mel':11},
	74: {'reg':'Республика Алтай','mel':6},
	75: {'reg':'Республика Тыва','mel':0},
	76: {'reg':'Республика Хакасия','mel':6},
	77: {'reg':'Приморский край','mel':58},
	78: {'reg':'Хабаровский край','mel':34},
	79: {'reg':'Амурская область','mel':23},
	80: {'reg':'Камчатский край','mel':7},
	81: {'reg':'Магаданская область','mel':3},
	82: {'reg':'Сахалинская область','mel':12},
	83: {'reg':'Чукотский авт.округ','mel':0},
	84: {'reg':'Республика Саха (Якутия)','mel':7},
	85: {'reg':'Еврейская авт. обл.','mel':2}
}

export default regLeth;