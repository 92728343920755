
"use strict";
let stData = {
	1: [39.7,47.1,6.6,6.6],
	2: [29.3,50,12.9,7.8],
	3: [44.2,42.6,6.2,5.4],
	4: [41.8,46.1,7.8,4.3],
	5: [33.7,44.2,8.1,5.8],
	6: [35,47.9,7.7,9.4],
	7: [32,49,10,7],
	8: [28.9,53.3,8.9,6.7],
	9: [39.4,47.1,8.7,3.8],
	10: [19.3,51.4,5.5,13.8],
	11: [39.4,42.6,9,9],
	12: [31.4,42.7,16.9,7.8],
	13: [34.2,43,15.2,6.3],
	14: [34.3,47.1,12.9,5.7],
	15: [23.8,58.4,9.9,5],
	16: [20.7,62.2,7.3,9.8],
	17: [30.4,42.8,14.5,11.6],
	18: [37.8,38.6,7.1,14.2],
	19: [100,0,0,0],
	20: [48.6,33,8.3,9.2],
	21: [27.8,46.7,16.7,8.9],
	22: [26.1,45.5,12.5,5.7],
	23: [39.8,40.3,10.2,8.1],
	24: [24.7,42.3,17.5,12.4],
	25: [31,43.1,6.9,13.8],
	26: [32.8,42.6,9.8,9.8],
	27: [19.3,56.1,15.8,7],
	28: [39.4,33.3,9.1,18.2],
	29: [39.1,48.4,10.9,1.6],
	30: [33.3,51.4,6.7,8.6],
	31: [32.6,43.5,6.5,15.2],
	32: [33.3,52.2,6.8,7.7],
	33: [32.1,47.6,9.5,7.9],
	34: [21.2,33.3,12.1,6.1],
	35: [9.1,54.5,0,36.4],
	36: [26.1,44.9,17.9,7.7],
	37: [36.7,36.7,13.3,13.3],
	38: [26.2,40.6,13.4,9.1],
	39: [0,37.5,25,37.5],
	40: [11.4,52.3,25,9.1],
	41: [30.8,46.2,7.7,7.7],
	42: [26.7,53.3,6.7,13.3],
	43: [50,16.7,33.3,0],
	44: [44.4,38.9,0,16.7],
	45: [39.5,31.1,20.1,8.4],
	46: [39.8,48.3,6.8,5.1],
	47: [40.5,45.1,6.7,5.3],
	48: [34.2,34.2,18.1,11.4],
	49: [26.3,53.5,8.8,10.5],
	50: [43.2,42.4,6.6,5.7],
	51: [44.9,42.2,5.9,7],
	52: [34.3,44.3,10,11.4],
	53: [20.9,53.4,15.5,10.1],
	54: [45,36.3,11.3,6.3],
	55: [43.6,41,7.7,7.7],
	56: [28.6,40.4,18.8,12.2],
	57: [39.1,42.6,11.3,7],
	58: [12.1,63.8,13.8,10.3],
	59: [39.6,35.8,3.8,17],
	60: [28.6,42.9,14.3,14.3],
	61: [43.1,41.5,9.2,6.2],
	62: [40.9,40.6,11.2,7.3],
	63: [50.5,37.9,4.9,6.8],
	64: [46.6,37.7,7.2,7.6],
	65: [46.3,39.9,7.4,4.9],
	66: [35.6,45,12.8,6.1],
	67: [40.1,37.9,6,15.9],
	68: [28.8,46.8,8.6,12.9],
	69: [34.5,43.1,9.1,12.7],
	70: [35.8,40.4,6.6,7.9],
	71: [35.7,37.8,13.3,8.2],
	74: [35.7,28.6,14.3,21.4],
	75: [0,50,0,50],
	76: [16.1,51.6,12.9,16.1],
	77: [41.3,34,11.3,8.7],
	78: [38.9,34.3,13.9,13],
	79: [33.3,46,7.9,9.5],
	80: [33.3,33.3,11.1,22.2],
	81: [22.2,55.6,22.2,0],
	82: [38.5,48.7,5.1,7.7],
	72: [37.1,45.7,5.7,11.4],
	83: [100,0,0,0],
	73: [41.7,36.1,16.7,5.6],
	84: [33.3,41.7,8.3,16.7],
	85: [10,70,20,0]
};
	
export default stData;