;"use strict";

const trastData1 = {

		'st0_0_2': {
			group: 'Нео/адъювантная химиотерапия, HER-2 позитивный РМЖ',
			meth: [
				{
					code: 'sh0058;sh0069',
					name: 'AC×4→ (D + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,трастузумаб)',
					drugs: [36,64,37,82],
					rate: 20,
					dose: [110,1200,160,600],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,4],
					custKsg: 'ac4_dtr4',//
					pats: 0
				},
				{
					code: 'sh0058;sh0577.1',// Var!
					name: 'AC×4→ (Р + трастузумаб)×12',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,82],
					rate: 14,
					dose: [110,1200,150,600],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					custKsg: '058_577',//
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					code: 'sh0058;sh0150',
					name: 'АС×4→ (Р + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,82],
					rate: 5,
					dose: [110,1200,450,600],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					custKsg: 'ac4_p4t',//
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					code: 'sh0179',
					name: '(DC + трастузумаб)×4',
					info: '(доцетаксел,циклофосфамид,трастузумаб)',
					drugs: [37,64,82],
					rate: 10,
					dose: [160,1200,600],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					code: 'sh0066',
					name: 'DCН×6',
					info: '(доцетаксел,карбоплатин,трастузумаб)',
					drugs: [37,41,82],
					rate: 1,
					dose: [160,450,600],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					code: 'sh0058;sh0070',
					name: 'АС×4→ (D + трастузумаб + пертузумаб)x4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,<br>трастузумаб,пертузумаб)',
					drugs: [36,64,37,82,52],
					rate: 20,
					dose: [110,1200,160,600,2100,0],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,4,1,3],
					custKsg: 'ac4_dtrp4',//
					pats: 0
				},
				{
					code: 'sh0067',
					name: '(DCН + пертузумаб)×6',
					info: '(доцетаксел,карбоплатин,трастузумаб,пертузумаб)',
					drugs: [37,41,82,52],
					rate: 20,
					dose: [160,450,600,2940],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [6,6,6,1],
					pats: 0
				},
				{
					code: 'sh0577.1',//!Var
					name: '(Р + трастузумаб)×12',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,82],
					rate: 10,
					dose: [150,600],
					ds: 'ds19.043',
					st: 'st19.068',
					cycles: [12,4],
					pats: 0
				}
			],
			pats: 0
		},
		'st0_0_3': {
			group: 'Адъювантная таргетная терапия, HER-2 позитивный РМЖ',
			meth: [
				{
					code: 'sh0179',
					name: 'Трастузумаб',
					info: '(трастузумаб)',
					drugs: [82],
					rate: 95,
					dose: [600],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9],
					pats: 0
				},
				{
					code: 'sh0533',
					name: 'Трастузумаб + Пертузумаб',
					info: '(трастузумаб,пертузумаб)',
					drugs: [82,52],
					rate: 5,
					dose: [600,420],
					ds: 'ds19.047',//>>
					st: 'st19.072',//>>
					cycles: [13,13],
					pats: 0
				}
			],
			pats: 0
		},
		'st0_0_7': {
			group: 'Химиотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					code: 'sh0494',//!!Var
					name: 'паклитаксел + карбоплатин AUC2 + трастузумаб',
					info: '(паклитаксел,карбоплатин,трастузумаб)',
					drugs: [17,41,82],
					rate: 1,
					dose: [300.5,150,600],
					ds: 'ds19.043',
					st: 'st19.068',
					cycles: [6,6,8],
					pats: 0
				},
				{
					code: 'sh0069',
					name: 'D + трастузумаб',
					info: '(доцетаксел,трастузумаб)',
					drugs: [37,82],
					rate: 15,
					dose: [160,600],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [10,10],
					pats: 0
				},
				{
					code: 'sh0577.1',//!!Var
					name: 'Р + трастузумаб (еженедельный)',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,82],
					rate: 1,
					dose: [150,600],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [30,10],
					pats: 0
				},
				{
					code: 'sh0577.1',
					name: 'Р + трастузумаб',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,82],
					rate: 19,
					dose: [150,600],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [30,10],
					pats: 0
				},
				{
					code: 'sh0027.1',
					name: 'винорелбин + трастузумаб',
					info: '(винорелбин,трастузумаб)',
					drugs: [32,82],
					rate: 7,
					dose: [100,600],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [10,10],
					pats: 0
				},
				{
					code: 'sh0104',
					name: 'капецитабин + трастузумаб',
					info: '(капецитабин,трастузумаб)',
					drugs: [40,82],
					rate: 7,
//!! Требуется Подгонка !!
					dose: [48000,600],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [8,8],
					pats: 0
				},
				{
					code: 'sh0390',
					name: 'циклофосфамид + метотрексат + трастузумаб',
					info: '(циклофосфамид,метотрексат,трастузумаб)',
					drugs: [63,48,82],
					rate: 0,
					dose: [350,10,600],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [24,24,8],
					pats: 0
				},
				{
					code: 'sh0418.1',
					name: 'эрибулин + трастузумаб',
					info: '(эрибулин,трастузумаб)',
					drugs: [65,82],
					rate: 8,
					dose: [6,600],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [10,10],
					pats: 0
				},
				{
					code: 'sh0317.1',
					name: 'гемцитабин + трастузумаб',
					info: '(гемцитабин,трастузумаб)',
					drugs: [34,82],
					rate: 0,
					dose: [4000,600],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [8,8],
					pats: 0
				},
				{
					code: 'sh0306.1',
					name: 'гемцитабин + цисплатин + трастузумаб',
					info: '(гемцитабин,цисплатин,трастузумаб)',
					drugs: [34,66,82],
					rate: 7,
					dose: [4000,300,600],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,8],
					pats: 0
				},
				{
					code: 'sh0070',
					name: 'D + трастузумаб + пертузумаб',
					info: '(доцетаксел,трастузумаб,пертузумаб)',
					drugs: [37,82,52],
					rate: 15,
					dose: [160,600,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [12,12,13],
					pats: 0
				},
				{
					code: 'sh0576.1',
					name: 'P + трастузумаб + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,82,52],
					rate: 14,
					dose: [150,600,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,12,13],
					pats: 0
				},
				{
					code: 'sh0576.1',
					name: 'P + трастузумаб (еженед) + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,82,52],
					rate: 1,
					dose: [150,600,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,12,13],
					pats: 0
				},
				{
					code: 'sh0181',
					name: 'трастузумаб эмтанзин',
					info: '(трастузумаб эмтанзин)',
					drugs: [58,59],
					rate: 20,
					dose: [100,160],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8,8],
					pats: 0
				},
				-1,
			],
			pats: 0
		},
		'st0_0_8': {
			group: 'Гормонотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					code: 'sh0538',
					name: 'Фулвестрант + трастузумаб',
					info: '(фулвестрант,трастузумаб)',
					drugs: [62,82],
					rate: 20,
					dose: [500,600],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [10,12],
					pats: 0
				},
				{
					code: 'sh0293',
					name: 'Анастрозол + трастузумаб',
					info: '(анастрозол,трастузумаб)',
					drugs: [29,82],
					rate: 15,
					dose: [30,600],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [9,12],
					pats: 0
				},
				{
					code: '-',
					name: 'Летрозол + трастузумаб',
					info: '(летрозол,трастузумаб)',
					drugs: [44,82],
					rate: 15,
					dose: [30,600],
					ds: '',//>>
					st: '',
					cycles: [9,12],
					pats: 0
				},
				{
					code: '-',
					name: 'Эксеместан + трастузумаб',
					info: '(эксеместан,трастузумаб)',
					drugs: [68,82],
					rate: 10,
					dose: [750,600],
					ds: '',//>>
					st: '',
					cycles: [9,12],
					pats: 0
				},
				{
					code: 'sh0372',
					name: 'Тамоксифен + трастузумаб',
					info: '(тамоксифен,трастузумаб)',
					drugs: [54,82],
					rate: 30,
					dose: [600,600],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [7,10],
					pats: 0
				},
				-1,
				-1,
				-1,
				{
					code: 'sh0112',
					name: 'Анастрозол + трастузумаб + лапатиниб',
					info: '(анастрозол,трастузумаб,лапатиниб)',
					drugs: [29,82,42],
					rate: 5,
					dose: [30,600,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,10,7],
					pats: 0
				},
				{
					code: '-',
					name: 'Летрозол + трастузумаб + лапатиниб',
					info: '(летрозол,трастузумаб,лапатиниб)',
					drugs: [44,82,42],
					rate: 5,
					dose: [30,600,42000],
					ds: '',//>>
					st: '',
					cycles: [7,10,7],
					pats: 0
				},
				{
					code: 'sh0112',
					name: 'Эксеместан + трастузумаб + лапатиниб',
					info: '(эксеместан,трастузумаб,лапатиниб)',
					drugs: [68,82,42],
					rate: 1.1,
					dose: [750,600,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,10,7],
					pats: 0
				}
			],
			pats: 0
		},
	};	

export default trastData1;