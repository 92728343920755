;"use strict";

const rpData = {

// ============================>rp
// ---------> Хороший прогноз Линия 1
		'st4_1_1':{
			group: 'Хороший прогноз',
			meth: [
				{
					id: 857,
					code: 'sh0504',
					name: 'Пембролизумаб + акситиниб',
					info: '(пембролизумаб, акситиниб)',
					drugs: [20,99],
					rate: 30,
					dose: [200,210],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [15,15],
					pats: 4
				},
				{
					id: 858,
					code: 'sh0512',
					name: 'Сунитиниб',
					info: '(cунитиниб)',
					drugs: [105],
					rate: 30,
					dose: [1400],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [8],
					pats: 4
				},
				{
					id: 859,
					code: 'sh0135',
					name: 'Пазопаниб',
					info: '(пазопаниб)',
					drugs: [103],
					rate: 30,
					dose: [24000],
					ds: 'ds19.045',//>>
					st: '',
					cycles: [11.1],
					pats: 4
				},
				{
					id: 860,
					code: 'sh0078',
					name: 'Бевацизумаб + ИФН-альфа',
					info: '(бевацизумаб, интерферон альфа)',
					drugs: [26,7],
					rate: 10,
					dose: [1400,12],
					ds: 'ds19.043',//>>
					st: '',
					cycles: [11,11],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Промежуточный прогноз Линия 1
		'st4_1_2':{
			group: 'Промежуточный прогноз',
			meth: [
				{
					id: 861,
					code: 'sh0962;sh0661',
					name: 'Ниволумаб + ипилимумаб',
					info: '(ниволумаб, ипилимумаб)',
					drugs: [14,15,8],
					rate: 40,
					dose: [200,40,100],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [15,15,4],
					custKsg: '962_661',//
					pats: 12
				},
				{
					id: 862,
					code: 'sh0504',
					name: 'Пембролизумаб + акситиниб',
					info: '(пембролизумаб, акситиниб)',
					drugs: [20,99],
					rate: 40,
					dose: [200,210],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [15,15],
					pats: 11
				},
				{
					id: 863,
					code: 'sh0710',
					name: 'Кабозантиниб',
					info: '(кабозантиниб)',
					drugs: [140],
					rate: 20,
					dose: [1800],
					ds: 'ds19.048',//>>
					st: '',//>>
					cycles: [7],
					pats: 6
				}
			],
			pats: 0
		},

// ---------> Плохой прогноз Линия 1
		'st4_1_3':{
			group: 'Плохой прогноз',
			meth: [
				{
					id: 864,
					code: 'sh0962;sh0661',
					name: 'Ниволумаб + ипилимумаб',
					info: '(ниволумаб, ипилимумаб)',
					drugs: [14,15,8],
					rate: 30,
					dose: [200,40,100],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [15,15,4],
					custKsg: '962_661',//
					pats: 7
				},
				{
					id: 865,
					code: 'sh0504',
					name: 'Пембролизумаб + акситиниб',
					info: '(пембролизумаб, акситиниб)',
					drugs: [20,99],
					rate: 50,
					dose: [200,210],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [15,15],
					pats: 11
				},
				{
					id: 866,
					code: 'sh0710',
					name: 'Кабозантиниб',
					info: '(кабозантиниб)',
					drugs: [140],
					rate: 15,
					dose: [1800],
					ds: 'ds19.048',//>>
					st: '',//>>
					cycles: [7],
					pats: 3
				},
				{
					id: 867,
					code: 'sh9001',
					name: 'Темсиролимус',
					info: '(темсиролимус)',
					drugs: [106],
					rate: 5,
					dose: [25],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [17],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Несветлоклеточный рак Линия 1
		'st4_1_4':{
			group: 'Несветлоклеточный рак',
			meth: [
				{
					id: 868,
					code: 'sh0704.1',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,66],
					rate: 25,
					dose: [4000,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 2
				},
				{
					id: 869,
					code: 'sh0634.1',
					name: 'Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 25,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 2
				},
				{
					id: 870,
					code: 'sh0632.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34],
					rate: 25,
					dose: [4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4],
					pats: 2
				},
				{
					id: 871,
					code: 'sh0330',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [36],
					rate: 25,
					dose: [90],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 2
		'st4_2_5':{
			meth: [
				{
					id: 872,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 36,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [11,11],
					pats: 16
				},
				{
					id: 873,
					code: 'sh0710',
					name: 'Кабозантиниб',
					info: '(кабозантиниб)',
					drugs: [140],
					rate: 20,
					dose: [1800],
					ds: 'ds19.048',//>>
					st: '',//>>
					cycles: [7],
					pats: 8
				},
				{
					id: 874,
					code: 'sh0621',
					name: 'Акситиниб',
					info: '(акситиниб)',
					drugs: [99,99,100],
					rate: 14,
					dose: [560,280,56],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [6,1,1],
					pats: 6
				},
				{
					id: 875,
					code: 'sh0135',
					name: 'Пазопаниб',
					info: '(пазопаниб)',
					drugs: [103],
					rate: 5,
					dose: [24000],
					ds: 'ds19.045',//>>
					st: '',
					cycles: [7.4],
					pats: 2
				},
				{
					id: 876,
					code: 'sh0115',
					name: 'Ленватиниб + эверолимус',
					info: '(ленватиниб, эверолимус)',
					drugs: [101,102,107],
					rate: 14,
					dose: [300,240,150],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [7,7,7],
					pats: 6
				},
				{
					id: 877,
					code: 'sh0512',
					name: 'Сунитиниб',
					info: '(cунитиниб)',
					drugs: [105],
					rate: 5,
					dose: [1400],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [5],
					pats: 2
				},
				{
					id: 878,
					code: 'sh0508',
					name: 'Сорафениб',
					info: '(сорафениб)',
					drugs: [104],
					rate: 1,
					dose: [22400],
					ds: 'ds19.043',//>>
					st: '',
					cycles: [5.89285],
					pats: 0
				},
				{
					id: 879,
					code: 'sh0240',
					name: 'Эверолимус',
					info: '(эверолимус)',
					drugs: [67],
					rate: 5,
					dose: [300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [5],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 3
		'st4_3_6':{
			meth: [
				{
					id: 880,
					code: 'sh0135',
					name: 'Пазопаниб',
					info: '(пазопаниб)',
					drugs: [103],
					rate: 5,
					dose: [24000],
					ds: 'ds19.045',//>>
					st: '',
					cycles: [4],
					pats: 1
				},
				{
					id: 881,
					code: 'sh0512',
					name: 'Сунитиниб',
					info: '(cунитиниб)',
					drugs: [105],
					rate: 5,
					dose: [1400],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [3],
					pats: 1
				},
				{
					id: 882,
					code: 'sh0621',
					name: 'Акситиниб',
					info: '(акситиниб)',
					drugs: [99,99,100],
					rate: 7,
					dose: [560,280,56],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [4,1,1],
					pats: 1
				},
				{
					id: 883,
					code: 'sh0115',
					name: 'Ленватиниб + эверолимус',
					info: '(ленватиниб, эверолимус)',
					drugs: [101,102,107],
					rate: 15,
					dose: [300,240,150],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [6,6,6],
					pats: 3
				},
				{
					id: 884,
					code: 'sh0240',
					name: 'Эверолимус',
					info: '(эверолимус)',
					drugs: [67],
					rate: 3,
					dose: [300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [3],
					pats: 1
				},
				{
					id: 885,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 50,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [11,11],
					pats: 7
				},
				{
					id: 886,
					code: 'sh0710',
					name: 'Кабозантиниб',
					info: '(кабозантиниб)',
					drugs: [140],
					rate: 15,
					dose: [1800],
					ds: 'ds19.048',//>>
					st: '',//>>
					cycles: [5],
					pats: 3
				}
			],
			pats: 0
		}
	}

export default rpData;	