;"use strict";

//const jquery = require('./jquery-1.10.1.min');
import jquery from 'jquery';
window.$ = window.jQuery = jquery;

import State from './state';
import BlackList from './blacklist';
import methData from './methdata';
import DrugStore from './drugstore';
import MethPanel from './meth-panel';
import StPanel from './st-panel';
import PathPanel from './path-panel';
import ChanTable from './chantable';
import DistPanel from './dist-panel';
import DrugTable from './drugtable';
import PriceTable from './pricetable';
import PathSelector from './pathselect';
import RegSelector from './regselector';
import RyaModel from './rya-model';
import RshmModel from './rshm-model';
import RpModel from './rp-model';
import RzModel from './rz-model';
import RmpModel from './rmp-model';
import RpshModel from './rpsh-model';
import RpzModel from './rpz-model';
import RtmModel from './rtm-model';
import GcrModel from './gcr-model';
import RpszModel from './rpsz-model';
import NeoModel from './neo-model';
import Calc from './calc';
import ClinData from './clindata';
import Printer from './print';
import ChanPrinter from './chan-print';
import sha256 from 'crypto-js/sha256';

$(document).ready(function(){

	var currSt = 'st4_2',
		currPath = 'melanoma',
		methSet, pathData, str;

	var pathPanel = new PathPanel(1);// By default 'melanoma' will be selected
	var stPanel = new StPanel();
	var methPanel = new MethPanel();
	var chanTable = new ChanTable();
	var distPanel = new DistPanel();
	var drugStore = new DrugStore();
	var drugTable = new DrugTable();
	var priceTable = new PriceTable();
	var pathSelector = new PathSelector();
	var regSelector = new RegSelector();
	var ryaModel = new RyaModel();
	var rshmModel = new RshmModel();
	var rpModel = new RpModel();
	var rzModel = new RzModel();
	var rmpModel = new RmpModel();
	var rpshModel = new RpshModel();
	var rpzModel = new RpzModel();
	var rtmModel = new RtmModel();
	var gcrModel = new GcrModel();
	var rpszModel = new RpszModel();
	var neoModel = new NeoModel();
	var calc = new Calc();
	State.calc = calc;
	var clinData = new ClinData();
	var printer = new Printer();
	var chanPrinter = new ChanPrinter();


	State.pathPanel = pathPanel;
	State.stPanel = stPanel;
	State.methPanel = methPanel;
	State.chanTable = chanTable;
	State.distPanel = distPanel;
	State.drugStore = drugStore;
	State.ryaModel = ryaModel;
	State.rshmModel = rshmModel;
	State.rpModel = rpModel;
	State.rzModel = rzModel;
	State.rmpModel = rmpModel;
	State.rpshModel = rpshModel;
	State.rpzModel = rpzModel;
	State.rtmModel = rtmModel;
	State.gcrModel = gcrModel;
	State.rpszModel = rpszModel;
	State.neoModel = neoModel;
	State.calc = calc;
	State.clinData = clinData;
	State.drugTable = drugTable;
	State.priceTable = priceTable;
	State.regSelector = regSelector;
	State.printer = printer;
	State.chanPrinter = chanPrinter;

	loadSetup();

	methPanel.loadOptions(); // Update methods set acccording trastuzumab setup value
	drugStore.fillDrugData();
	regSelector.initRegData();

	priceTable.render();

//!!D	pathPanel.render(0); // Current selected pathology is defined during instantiation.
//	stPanel.render(State.pathKeys[0]); // Render first item in list (RMZ)
//!!D	stPanel.setCurrentSt('st2'); // 
//!!D	stPanel.render('krr'); // Render default pathology ('Melanoma') with default selected item 
						// (4-th stage in stage list)
//	methPanel.render('st2');
//!!D	methPanel.render(); // Render default stage ('st4_2') or selected before ST

//	drugTable.render('melanoma');

	$('#hmenu').on(State.startEvent, (e)=>switchPage(e));
	$('#setup-close-but').on(State.startEvent, (e)=>closeSetupPanel(e));
	$('.setup-button').on(State.startEvent, (e)=>setupAction(e));
	$('#trast-sw').on(State.startEvent, (e)=>switchTrastMode(e));

//	$('#login-form').on('submit', enterCalc);
	$('#pass-input').on('keypress', (e)=>checkLogin(e));
	$('#gen-input').on('input', (e)=>showGText(e));
	$('#gen-close-but').on(State.startEvent, (e)=>closeGenPanel(e));

//	$('#login-input').on('keypress', checkLogin);
//	setTimeout(()=>regSelector.showRegs(), 2000);

//calc.initDataSet();

//!!D stPanel.render()
//!!D methPanel.render('st2');
//	State.methPanel.displayResult();

	// var nonce = 'gfhksirlcgysbs', data = 'moonlightelephant';
	// var hashDigest = sha256(nonce + data);
	// console.log(hashDigest);

	var nonce = 'gfhksirlcgysbs', data = 'doctor';
	var hashDigest = sha256(nonce + data);

	console.log(hashDigest.words[0]);
	data = 'marketnavadm';
	hashDigest = sha256(nonce + data);
	if(hashDigest.words[0]<0) hashDigest.words[0] = 0-hashDigest.words[0];
	console.log(hashDigest.words[0]);
});


function enterGen() {

	$('#p-gen').show();
}

function closeGenPanel() {

	$('#p-gen').hide();
}

function showGText(e) {

	var key, n, p, h, res, str;
	var no = 'gfhksirlcgysbs';

	n = $('#gen-input').val();
	if(n.length==0) {
		$('#p-value').text('');
		return;
	} 
	h = sha256(no + n);
	if(h.words[0]<0) h.words[0] = 0-h.words[0];
	$('#p-value').text(h.words[0]);
}

function checkLogin(e) {

	var key, n, p, h, res, str;
	var no = 'gfhksirlcgysbs';

	if(e.charCode==13) {
		n = $('#login-input').val();
if(BlackList.includes(n)) {
//console.log('User blocked')
	return;
}
		p = $('#pass-input').val();
		h = sha256(no + n);
		if(h.words[0]<0) h.words[0] = 0-h.words[0];
		if(p == h.words[0] && h.words[0]==State.super) {
			enterGen();
			return;
		}

		str = '' + window.location;
		res = str.indexOf('oncocalculator.ru');
		if(res==-1) enterCalc();
		if(p == h.words[0]) enterCalc();
		else console.log('Wrong!');
	}
}


function enterCalc() {

	$('#section0').css({'opacity':0});
	$('.top').css({'opacity':0});
	$('.top').show();
	$('#section0').show();
	$('#section0').animate({'opacity':1}, 700);
	$('.top').animate({'opacity':1}, 700);
	$('#start-block').animate({'opacity':0}, 700, function(){
		$('#start-block').hide();
	});
	if(State.regionDataInit) {
		$('#title-block').animate({'top':'-680px', 'opacity':0}, 700, function(){
			State.regSelector.showRegs();
		});
	} else State.regSelector.restoreRegData();
}

function showSetupPanel() {

	if(State.flAnimate==1) return;
	State.flAnimate = 1;
	State.flModal = 1;
	$('#tool-menu').show();
	$('#tool-menu').animate({'opacity':1}, 700, function(){

	});

}

function closeSetupPanel() {

	$('#tool-menu').animate({'opacity':0}, 700, function(){
		State.flAnimate = 0;
		State.flModal = 0;
		$('#tool-menu').hide();
	});
}

function setupAction(e) {

	let action = e.target.id;

	closeSetupPanel();
	if(action=='reg-setup-but') State.regSelector.showRegs();
	if(action=='price-reset-but') State.drugStore.resetPrice();
}

function switchTrastMode() {

	if(State.flTrastMode==0) {
		State.flTrastMode = 1;
		$('#trast-sw').addClass('h-sw-on');
		$('#trast-sw').removeClass('h-sw-off');
	} else {
		State.flTrastMode = 0;
		$('#trast-sw').addClass('h-sw-off');
		$('#trast-sw').removeClass('h-sw-on');
	}
	saveSetup();
	State.methPanel.loadOptions();
	State.methPanel.saveMethData();
	State.methPanel.displayResult();
}

function switchPage(e) {

        var target, adrStr, hideSection, str;
        if(State.flAnimate>0) return;
        if(State.flSelection>0) return;
        if(State.flModal==1) return;
        hideSection = '#section' + State.currPage;
        target = e.target.id;
        str = target.slice(0,9);
        if(str=='setup-but') {
        	showSetupPanel();
//        	State.regSelector.showRegs();
        }
        if(str=='print-but') {
        	e.preventDefault();
        	State.printer.dataPrint();
        }
        if(str!='menu-butt') return;
        target = target.slice(-1);
//if(target<1) return;
        if(target==State.currPage) return;
        State.flAnimate = 2;
        $('#menu-butt'+State.currPage).addClass('inactive');
        $('#menu-butt'+State.currPage).removeClass('active');
// if(target==4) { // Change current region
//         $('#menu-butt'+1).addClass('active');
//         $('#menu-butt'+1).removeClass('inactive');
//    hideMainDisplay();
//    renderSubjList();
//    showRegs();
//    State.currPage = 1; // Price page
//    return;
//}        
        $('#menu-butt'+target).addClass('active');
        $('#menu-butt'+target).removeClass('inactive');
        $('#section'+target).css({'opacity':0, 'display':'block'});
        $('#section'+target).animate({'opacity':1}, 700, function(){
            State.flAnimate--;
        });
        $(hideSection).animate({'opacity':0}, 700, function(){
            $(hideSection).css({'display':'none'});
            State.currPage = target;
            State.flAnimate--;
        });
}

function saveSetup() {

	let dataStr, setupData = {}, lsName = 'mn_setup';

	setupData.dbVersion = State.dbVersion;
	setupData.trustMode = State.flTrastMode;
	dataStr = JSON.stringify(setupData);
	localStorage.setItem(lsName, dataStr);
}

function cleanStorage() {

	localStorage.removeItem('mn_path');
	localStorage.removeItem('mn_meth');
	localStorage.removeItem('mn_store');
	localStorage.removeItem('mn_cdata');
	saveSetup();
console.log('Reload');		
	window.location.reload(true);
}

function loadSetup() {

	let dataSet, dataStr, setupData = {}, lsName = 'mn_setup';

	dataSet = localStorage.getItem(lsName);
	if(null == dataSet) {
		cleanStorage();
		return;
	}
	setupData = JSON.parse(dataSet);
	State.flTrastMode = setupData.trustMode;
	if(State.flTrastMode==0) {
		$('#trast-sw').addClass('h-sw-off');
		$('#trast-sw').removeClass('h-sw-on');
	} else {
		$('#trast-sw').addClass('h-sw-on');
		$('#trast-sw').removeClass('h-sw-off');
	}
	if(setupData.dbVersion!=State.dbVersion) {
		cleanStorage();
	}
}