;"use strict";

const ryaData = {

// ============================>rya
// ---------> Стадии I A и I Б в сочетании со светлоклеточным гистологическим типом или высокой степенью злокачественности
		'st0_0_1':{
			group: 'Стадии I A и I Б в сочетании со светлоклеточным гистологическим типом или высокой степенью злокачественности',
			meth: [
				{
					id: 542,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 80,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 543,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [91,66,93],
					rate: 10,
					dose: [600,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 544,
					code: 'sh0673.1',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 80 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 1,
					dose: [1200,750],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 545,
					code: 'sh0644',
					name: 'Доцетаксел + цисплатин',
					info: '(доцетаксел 75 мг/м2, цисплатин 75 мг/м2)',
					drugs: [88,87,66,93],
					rate: 3,
					dose: [120,20,100,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 546,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(Паклитаксел 60 мг/м2, карбоплатин AUC2)',
					drugs: [91,41],
					rate: 1,
					dose: [300,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 547,
					code: 'sh0153',
					name: 'Паклитаксел + цисплатин',
					info: '(Паклитаксел 135 мг/м2, цисплатин 75 мг/м2, паклитаксел 60 мг/м2)',
					drugs: [91,66,93,91],
					rate: 1,
					dose: [300,100,20,300],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 548,
					code: 'sh0707',
					name: 'Карбоплатин',
					info: '(карбоплатин AUC6-7)',
					drugs: [41],
					rate: 3,
					dose: [750],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 549,
					code: 'sh0640',
					name: 'Доксорубицин + карбоплатин',
					info: '(доксорубицин 50 мг/м2, карбоплатин AUC 6-7)',
					drugs: [84,36,41],
					rate: 1,
					dose: [50,20,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 550,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин 50 мг/м2, цисплатин 75 мг/м2)',
					drugs: [84,36,66,93],
					rate: 1,
					dose: [50,20,100,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадия I С
		'st0_0_2':{
			group: 'Стадия I С',
			meth: [
				{
					id: 551,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 80,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 13
				},
				{
					id: 552,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [91,66,93],
					rate: 10,
					dose: [600,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 553,
					code: 'sh0673.1',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 80 мг/м2, карбоплатин AUC 6)',
					drugs: [91,41],
					rate: 1,
					dose: [300,750],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 554,
					code: 'sh0644',
					name: 'Доцетаксел + цисплатин',
					info: '(доцетаксел 75 мг/м2, цисплатин 75 мг/м2)',
					drugs: [88,87,66,93],
					rate: 3,
					dose: [120,20,100,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 555,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 60 мг/м2, карбоплатин AUC 2)',
					drugs: [91,41],
					rate: 1,
					dose: [300,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 556,
					code: 'sh0153',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 135 мг/м2, цисплатин 75 мг/м2, паклитаксел 60 мг/м2)',
					drugs: [91,66,93,91],
					rate: 0,
					dose: [300,100,20,300],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 557,
					code: 'sh0707',
					name: 'Карбоплатин',
					info: '(карбоплатин AUC6-7)',
					drugs: [41],
					rate: 3,
					dose: [750],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 558,
					code: 'sh0640',
					name: 'Доксорубицин + карбоплатин',
					info: '(доксорубицин 50 мг/м2, карбоплатин AUC 6-7)',
					drugs: [84,36,41],
					rate: 1,
					dose: [50,20,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 559,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин 50 мг/м2, цисплатин 75 мг/м2)',
					drugs: [84,36,66,93],
					rate: 1,
					dose: [50,20,100,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадии II A, II Б, II С
		'st0_0_3':{
			group: 'Стадии II A, II Б, II С',
			meth: [
				{
					id: 560,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 80,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 26
				},
				{
					id: 561,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [91,66,93],
					rate: 10,
					dose: [600,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 3
				},
				{
					id: 562,
					code: 'sh0673.1',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 80 мг/м2, карбоплатин AUC 6)',
					drugs: [91,41],
					rate: 1.5,
					dose: [300,750],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 563,
					code: 'sh0644',
					name: 'Доцетаксел + цисплатин',
					info: '(доцетаксел 75 мг/м2, цисплатин 75 мг/м2)',
					drugs: [88,87,66,93],
					rate: 5,
					dose: [120,20,100,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [6,6,6,6],
					pats: 1
				},
				{
					id: 564,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 60 мг/м2, карбоплатин AUC 2)',
					drugs: [91,41],
					rate: 1.5,
					dose: [1200,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 565,
					code: 'sh0153',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 135 мг/м2, цисплатин 75 мг/м2, паклитаксел 60 мг/м2)',
					drugs: [91,66,93,91],
					rate: 0,
					dose: [300,100,20,300],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 566,
					code: 'sh0707',
					name: 'Карбоплатин',
					info: '(карбоплатин AUC6-7)',
					drugs: [41],
					rate: 0.5,
					dose: [750],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 567,
					code: 'sh0640',
					name: 'Доксорубицин + карбоплатин',
					info: '(доксорубицин 50 мг/м2, карбоплатин AUC 6-7)',
					drugs: [84,36,41],
					rate: 1,
					dose: [50,20,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 568,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин 50 мг/м2, цисплатин 75 мг/м2)',
					drugs: [84,36,66,93],
					rate: 1,
					dose: [50,20,100,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадии III A, III Б, III С
		'st0_0_4':{
			group: 'Стадии III A, III Б, III С',
			meth: [
				{
					id: 569,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 30,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 13
				},
				{
					id: 570,
					code: 'sh0497',
					name: 'Паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 6, бевацизумаб 15 мг)',
					drugs: [91,41,2,26],
					rate: 50,
					dose: [600,750,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,17.143,17.143],
					pats: 25
				},
				{
					id: 571,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [91,66,93],
					rate: 5,
					dose: [600,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 572,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг)',
					drugs: [91,66,93,2,26],
					rate: 5,
					dose: [600,100,20,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,17.142857,17.142857],
					pats: 2
				},
				{
					id: 573,
					code: 'sh0673.1',
					name: 'Паклитаксел + карбоплатин',
					info: '(Паклитаксел 80 мг/м2, карбоплатин AUC 6)',
					drugs: [91,41],
					rate: 1,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 574,
					code: 'sh0644',
					name: 'Доцетаксел + цисплатин',
					info: '(доцетаксел 75 мг/м2, цисплатин 75 мг/м2)',
					drugs: [88,87,66,93],
					rate: 5,
					dose: [120,20,100,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [6,6,6,6],
					pats: 2
				},
				{
					id: 575,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(Паклитаксел 60 мг/м2, карбоплатин AUC 2)',
					drugs: [91,41],
					rate: 1,
					dose: [300,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 576,
					code: 'sh0153',
					name: 'Паклитаксел + цисплатин',
					info: '(Паклитаксел 135 мг/м2, цисплатин 75 мг/м2, паклитаксел 60 мг/м2)',
					drugs: [91,66,93,91],
					rate: 2,
					dose: [300,100,20,300],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 1
				},
				{
					id: 577,
					code: 'sh0707',
					name: 'Карбоплатин',
					info: '(карбоплатин AUC6-7)',
					drugs: [41],
					rate: 1,
					dose: [750],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 578,
					code: 'sh0640',
					name: 'Доксорубицин + карбоплатин',
					info: '(доксорубицин 50 мг/м2, карбоплатин AUC 6-7)',
					drugs: [84,36,41],
					rate: 1,
					dose: [50,20,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 579,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин 50 мг/м2, цисплатин 75 мг/м2)',
					drugs: [84,36,66,93],
					rate: 1,
					dose: [50,20,100,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 580,
					code: 'sh0134',
					name: 'Олапариб',
					info: '(олапариб 300 мг)',
					drugs: [141],
					rate: 1,
					dose: [18000],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадии IV A, IV Б, IV С
		'st0_0_5':{
			group: 'Стадии IV A, IV Б, IV С',
			meth: [
				{
					id: 581,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC6)',
					drugs: [91,41],
					rate: 10,
					dose: [600,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 582,
					code: 'sh0497',
					name: 'Паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 6, бевацизумаб 15 мг)',
					drugs: [91,41,2,26],
					rate: 70,
					dose: [600,750,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,17.143,17.143],
					pats: 14
				},
				{
					id: 583,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [91,66,93],
					rate: 1,
					dose: [600,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 584,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг)',
					drugs: [91,66,93,2,26],
					rate: 9,
					dose: [600,100,20,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,17.143,17.143],
					pats: 1
				},
				{
					id: 585,
					code: 'sh0673.1',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 80 мг/м2, карбоплатин AUC 6)',
					drugs: [17,41],
					rate: 0.5,
					dose: [500,750],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 586,
					code: 'sh0371.1',
					name: 'Паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел 80 мг/м2, карбоплатин AUC 6, бевацизумаб 15 мг)',
					drugs: [17,41,2,26],
					rate: 1,
					dose: [500,750,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6,6,17.143,17.143],
					pats: 0
				},
				{
					id: 587,
					code: 'sh0644',
					name: 'Доцетаксел + цисплатин',
					info: '(доцетаксел 75 мг/м2, цисплатин 75 мг/м2)',
					drugs: [88,87,66,93],
					rate: 5,
					dose: [120,20,100,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 588,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 60 мг/м2, карбоплатин AUC 2)',
					drugs: [17,41],
					rate: 0.5,
					dose: [100,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 589,
					code: 'sh0368',
					name: 'Паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел 60 мг/м2, карбоплатин AUC 2, бевацизумаб 15 мг)',
					drugs: [17,41,2,26],
					rate: 1,
					dose: [100,750,800,200],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6,6,17.143,17.143],
					pats: 0
				},
				{
					id: 590,
					code: 'sh0153',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 135 мг/м2, цисплатин 75 мг/м2, паклитаксел 60 мг/м2)',
					drugs: [91,66,93,91],
					rate: 0,
					dose: [300,100,20,300],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 591,
					code: 'sh0707',
					name: 'Карбоплатин',
					info: '(карбоплатин AUC6-7)',
					drugs: [41],
					rate: 0.5,
					dose: [750],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 592,
					code: 'sh0347',
					name: 'Карбоплатин + бевацизумаб',
					info: '(Карбоплатин AUC6-7, бевацизумаб 15 мг)',
					drugs: [41,2,26],
					rate: 1,
					dose: [750,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [6,17.143,17.143],
					pats: 0
				},
				{
					id: 593,
					code: 'sh0640',
					name: 'Доксорубицин + карбоплатин',
					info: '(доксорубицин 50 мг/м2, карбоплатин AUC 6-7)',
					drugs: [84,36,41],
					rate: 1,
					dose: [50,20,750],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 594,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин 50 мг/м2, цисплатин 75 мг/м2)',
					drugs: [84,36,66,93],
					rate: 1,
					dose: [50,20,100,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 595,
					code: 'sh0134',
					name: 'Олапариб',
					info: '(олапариб 300 мг)',
					drugs: [141],
					rate: 1,
					dose: [18000],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Платинорезис-<br>тентный рецидив Линия 2
		'st0_2_6':{
			group: 'Платинорезис-<br>тентный рецидив',
			meth: [
				{
					id: 596,
					code: 'sh0264',
					name: 'Этопозид',
					info: '(этопозид 100 мг)',
					drugs: [111],
					rate: 10,
					dose: [1000],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [5.714286],
					pats: 1
				},
				{
					id: 597,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин 50-60 мг/м2)',
					drugs: [84],
					rate: 10,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5.714286],
					pats: 1
				},
				{
					id: 598,
					code: 'sh0024.1',
					name: 'Винорельбин',
					info: '(винорельбин 25 мг/м2)',
					drugs: [112],
					rate: 10,
					dose: [90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.714286],
					pats: 1
				},
				{
					id: 599,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 1,25 мг/м2)',
					drugs: [95],
					rate: 10,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5.714286],
					pats: 1
				},
				{
					id: 600,
					code: 'sh9001',
					name: 'Топотекан + бевацизумаб',
					info: '(топотекан 1,25 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 6,
					dose: [12,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [10,10,10],
					pats: 0
				},
				{
					id: 601,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 3,7-4 мг/м2)',
					drugs: [95],
					rate: 2,
					dose: [8],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [16],
					pats: 0
				},
				{
					id: 602,
					code: 'sh9001',
					name: 'Топотекан + бевациумаб',
					info: '(топотекан 3,7-4 мг/м2, бевациумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 1,
					dose: [8,400,300],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [28,10,10],
					pats: 0
				},
				{
					id: 603,
					code: 'sh0330',
					name: 'Доксорубицин',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2)',
					drugs: [85,86],
					rate: 1,
					dose: [50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4.285714,4.285714],
					pats: 0
				},
				{
					id: 604,
					code: 'sh0331',
					name: 'Доксорубицин + бевацизумаб',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [85,86,2,26],
					rate: 1,
					dose: [50,20,400,300],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.5,7.5,10,10],
					pats: 0
				},
				{
					id: 605,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 10,
					dose: [3000,2400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4.285714,4.285714],
					pats: 1
				},
				{
					id: 606,
					code: 'sh0700',
					name: 'Паклитаксел',
					info: '(паклитаксел 60 мг/м2)',
					drugs: [17],
					rate: 12,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [16],
					pats: 6
				},
				{
					id: 607,
					code: 'sh0825',
					name: 'Паклитаксел + бевацизумаб',
					info: '(паклитаксел 80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,2,26],
					rate: 10,
					dose: [200,400,300],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [28,10,10],
					pats: 1
				},
				{
					id: 608,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 8,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [5.714286,5.714286],
					pats: 1
				},
				{
					id: 609,
					code: 'sh0221',
					name: 'Метотрексат + циклофосфамид',
					info: '(метотрексат 2,5 мг, циклофосфамид 50 мг)',
					drugs: [48,63],
					rate: 1,
					dose: [40,1500],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [2,2],
					pats: 0
				},
				{
					id: 610,
					code: 'sh9001',
					name: 'Летрозол',
					info: '(летрозол 2,5 мг)',
					drugs: [44],
					rate: 1.5,
					dose: [75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [2],
					pats: 0
				},
				{
					id: 611,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол 1 мг)',
					drugs: [29],
					rate: 1.5,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [2],
					pats: 0
				},
				{
					id: 612,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен 20-40 мг)',
					drugs: [54],
					rate: 1.5,
					dose: [1200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [2],
					pats: 0
				},
				{
					id: 613,
					code: 'sh9001',
					name: 'Мегестрол',
					info: '(мегестрол 160 мг)',
					drugs: [89],
					rate: 1.5,
					dose: [4800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [2],
					pats: 0
				},
				{
					id: 614,
					code: 'sh0161',
					name: 'Пеметрексед',
					info: '(пеметрексед 500 мг)',
					drugs: [76],
					rate: 2,
					dose: [900],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [5.7143],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Платиночувстви-<br>тельный рецидив Линия 2
		'st0_2_9':{
			group: 'Платиночувстви-<br>тельный рецидив',
			meth: [
				{
					id: 615,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2)',
					drugs: [66,93,84,36],
					rate: 3.5,
					dose: [100,40,50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 616,
					code: 'sh0697',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,84,36,2,26],
					rate: 3.5,
					dose: [100,40,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 1
				},
				{
					id: 617,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [66,93,85],
					rate: 0.5,
					dose: [100,40,50],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 618,
					code: 'sh0696',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,85,2,26],
					rate: 0.5,
					dose: [100,40,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 619,
					code: 'sh0675',
					name: 'Цисплатин + паклитаксел',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 мг/м2)',
					drugs: [66,93,17],
					rate: 7.5,
					dose: [100,40,1000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,6],
					pats: 2
				},
				{
					id: 620,
					code: 'sh0499',
					name: 'Цисплатин + паклитаксел + бевацизумаб',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,17,2,26],
					rate: 7.5,
					dose: [100,40,1000,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,6,17.142857,17.142857],
					pats: 2
				},
				{
					id: 621,
					code: 'sh0644',
					name: 'Цисплатин + доцетаксел',
					info: '(цисплатин 75 мг/м2, доцетаксел 75 мг/м2)',
					drugs: [66,93,88,87],
					rate: 5,
					dose: [100,40,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 622,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,93,34,83],
					rate: 3.5,
					dose: [100,40,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 623,
					code: 'sh0638.1',
					name: 'Цисплатин + гемцитабин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,34,83,2,26],
					rate: 3.5,
					dose: [100,40,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 1
				},
				{
					id: 624,
					code: 'sh0028.1',
					name: 'Цисплатин + винорелбин',
					info: '(цисплатин 75 мг/м2, винорелбин 25 мг/м2)',
					drugs: [66,93,112],
					rate: 3,
					dose: [100,40,90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 625,
					code: 'sh0814',
					name: 'Цисплатин + этопозид',
					info: '(цисплатин 75 мг/м2, этопозид 100 мг)',
					drugs: [66,93,111],
					rate: 3,
					dose: [100,40,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 626,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 75 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,93,95],
					rate: 1,
					dose: [100,40,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 627,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2)',
					drugs: [41,84,36],
					rate: 3.5,
					dose: [750,50,20],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,4.285714,4.285714],
					pats: 1
				},
				{
					id: 628,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,84,36,2,26],
					rate: 3.5,
					dose: [750,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,4.285714,4.285714,17.142857,17.142857],
					pats: 1
				},
				{
					id: 629,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [41,85],
					rate: 0.5,
					dose: [750,50],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 630,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,85,2,26],
					rate: 0.5,
					dose: [750,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 631,
					code: 'sh0673.1',
					name: 'Карбоплатин + паклитаксел',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2)',
					drugs: [41,17],
					rate: 7.5,
					dose: [750,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857],
					pats: 2
				},
				{
					id: 632,
					code: 'sh0371.1',
					name: 'Карбоплатин + паклитаксел + бевацизумаб',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,17,2,26],
					rate: 7.5,
					dose: [750,400,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 2
				},
				{
					id: 633,
					code: 'sh0643',
					name: 'Карбоплатин + доцетаксел',
					info: '(карбоплатин AUC 5–6, доцетаксел 75 мг/м2 )',
					drugs: [41,88,87],
					rate: 5,
					dose: [750,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 634,
					code: 'sh0634.1',
					name: 'Карбоплатин + гемцитабин',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2)',
					drugs: [41,34,83],
					rate: 3.5,
					dose: [750,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 635,
					code: 'sh0040.1',
					name: 'Карбоплатин + гемцитабин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,34,83,2,26],
					rate: 3.5,
					dose: [750,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 1
				},
				{
					id: 636,
					code: 'sh9001',
					name: 'Карбоплатин + винорелбин',
					info: '(карбоплатин AUC 5–6, винорелбин 25 мг/м2)',
					drugs: [41,112],
					rate: 5,
					dose: [750,90],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857],
					pats: 1
				},
				{
					id: 637,
					code: 'sh0555',
					name: 'Карбоплатин + этопозид',
					info: '(карбоплатин AUC 5–6, этопозид 100 мг)',
					drugs: [41,111],
					rate: 1,
					dose: [750,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 638,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, доксорубицин 40-50 мг/м2)',
					drugs: [16,24,84,36],
					rate: 5,
					dose: [150,100,50,20],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 639,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, пегилированный лопосомальный доксорубицин 30 мг/м2)',
					drugs: [16,24,85],
					rate: 1,
					dose: [150,100,50],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 640,
					code: 'sh0635',
					name: 'Оксалиплатин + гемцитабин',
					info: '(оксалиплатин 100-130 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [16,24,34,83],
					rate: 5,
					dose: [150,100,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 1
				},
				{
					id: 641,
					code: 'sh0653',
					name: 'Оксалиплатин + капецитабин',
					info: '(оксалиплатин 100-130 мг/м2, капецитабин 2000 мг/м2)',
					drugs: [16,24,10],
					rate: 1,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 642,
					code: 'sh0090.1',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 60 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 1,
					dose: [150,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.357143,5.357143,5.357143],
					pats: 0
				},
				{
					id: 643,
					code: 'sh0663',
					name: 'Оксалиплатин + фолинат кальция + фторурацил',
					info: '(оксалиплатин 85 мг/м2, фолинат кальция 400 мг/м2, фторурацил 400 мг/м2 болюс, фторурацил 2400 мг/м2)',
					drugs: [16,13,61],
					rate: 1,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.714286,7.142857,10.714286],
					pats: 0
				},
				{
					id: 644,
					code: 'sh0134',
					name: 'Олапариб',
					info: '(олапариб 400 мг)',
					drugs: [90],
					rate: 40,
					dose: [24000],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [12],
					pats: 9
				}
			],
			pats: 0
		},

// ---------> Платинорезис-<br>тентный рецидив Линия 3
		'st0_3_7':{
			group: 'Платинорезис-<br>тентный рецидив',
			meth: [
				{
					id: 645,
					code: 'sh0264',
					name: 'Этопозид',
					info: '(этопозид 100 мг)',
					drugs: [111],
					rate: 10,
					dose: [1000],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 646,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин 50-60 мг/м2)',
					drugs: [84],
					rate: 10,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 647,
					code: 'sh0024.1',
					name: 'Винорельбин',
					info: '(винорельбин 25 мг/м2)',
					drugs: [112],
					rate: 10,
					dose: [90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 648,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 1,25 мг/м2)',
					drugs: [95],
					rate: 10,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 649,
					code: 'sh9001',
					name: 'Топотекан + бевацизумаб',
					info: '(топотекан 1,25 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 6,
					dose: [12,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [9.28571,9.28571,9.28571],
					pats: 0
				},
				{
					id: 650,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 3,7-4 мг/м2)',
					drugs: [95],
					rate: 2,
					dose: [8],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [16],
					pats: 0
				},
				{
					id: 651,
					code: 'sh9001',
					name: 'Топотекан + бевациумаб',
					info: '(топотекан 3,7-4 мг/м2, бевациумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 1,
					dose: [8,400,300],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [26,9.28571,9.28571],
					pats: 0
				},
				{
					id: 652,
					code: 'sh0330',
					name: 'Доксорубицин',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2)',
					drugs: [85,86],
					rate: 1,
					dose: [50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [3.75,3.75],
					pats: 0
				},
				{
					id: 653,
					code: 'sh0331',
					name: 'Доксорубицин + бевацизумаб',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [85,86,2,26],
					rate: 1,
					dose: [50,20,400,300],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.07143,7.07143,9.28571,9.28571],
					pats: 0
				},
				{
					id: 654,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 10,
					dose: [3000,2400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3.75,3.75],
					pats: 0
				},
				{
					id: 655,
					code: 'sh0700',
					name: 'Паклитаксел',
					info: '(паклитаксел 60 мг/м2)',
					drugs: [17],
					rate: 12,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [14],
					pats: 3
				},
				{
					id: 656,
					code: 'sh0825',
					name: 'Паклитаксел + бевацизумаб',
					info: '(паклитаксел 80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,2,26],
					rate: 10,
					dose: [200,400,300],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [26,9.28571,9.28571],
					pats: 0
				},
				{
					id: 657,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 8,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [5,5],
					pats: 0
				},
				{
					id: 658,
					code: 'sh0221',
					name: 'Метотрексат + циклофосфамид',
					info: '(метотрексат 2,5 мг, циклофосфамид 50 мг)',
					drugs: [48,63],
					rate: 1,
					dose: [40,1500],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5,1.5],
					pats: 0
				},
				{
					id: 659,
					code: 'sh9001',
					name: 'Летрозол',
					info: '(летрозол 2,5 мг)',
					drugs: [44],
					rate: 1.5,
					dose: [75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1.5],
					pats: 0
				},
				{
					id: 660,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол 1 мг)',
					drugs: [29],
					rate: 1.5,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5],
					pats: 0
				},
				{
					id: 661,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен 20-40 мг)',
					drugs: [54],
					rate: 1.5,
					dose: [1200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5],
					pats: 0
				},
				{
					id: 662,
					code: 'sh9001',
					name: 'Мегестрол',
					info: '(мегестрол 160 мг)',
					drugs: [89],
					rate: 1.5,
					dose: [4800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1.5],
					pats: 0
				},
				{
					id: 663,
					code: 'sh0161',
					name: 'Пеметрексед',
					info: '(пеметрексед 500 мг)',
					drugs: [76],
					rate: 1,
					dose: [900],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [5],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Платиночувстви-<br>тельный рецидив Линия 3
		'st0_3_10':{
			group: 'Платиночувстви-<br>тельный рецидив',
			meth: [
				{
					id: 664,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2)',
					drugs: [66,93,84,36],
					rate: 3.5,
					dose: [100,40,50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 665,
					code: 'sh0697',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,84,36,2,26],
					rate: 3.5,
					dose: [100,40,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 666,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [66,93,85],
					rate: 0.5,
					dose: [100,40,50],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 667,
					code: 'sh0696',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,85,2,26],
					rate: 0.5,
					dose: [100,40,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 668,
					code: 'sh0675',
					name: 'Цисплатин + паклитаксел',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 мг/м2)',
					drugs: [66,93,17],
					rate: 7.5,
					dose: [100,40,1000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,6],
					pats: 1
				},
				{
					id: 669,
					code: 'sh0499',
					name: 'Цисплатин + паклитаксел + бевацизумаб',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,17,2,26],
					rate: 7.5,
					dose: [100,40,1000,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,6,17.142857,17.142857],
					pats: 1
				},
				{
					id: 670,
					code: 'sh0644',
					name: 'Цисплатин + доцетаксел',
					info: '(цисплатин 75 мг/м2, доцетаксел 75 мг/м2)',
					drugs: [66,93,88,87],
					rate: 5,
					dose: [100,40,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 671,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,93,34,83],
					rate: 3.5,
					dose: [100,40,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 672,
					code: 'sh0638.1',
					name: 'Цисплатин + гемцитабин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,34,83,2,26],
					rate: 3.5,
					dose: [100,40,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 673,
					code: 'sh0028.1',
					name: 'Цисплатин + винорелбин',
					info: '(цисплатин 75 мг/м2, винорелбин 25 мг/м2)',
					drugs: [66,93,112],
					rate: 3,
					dose: [100,40,90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 674,
					code: 'sh0814',
					name: 'Цисплатин + этопозид',
					info: '(цисплатин 75 мг/м2, этопозид 100 мг)',
					drugs: [66,93,111],
					rate: 3,
					dose: [100,40,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 675,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 75 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,93,95],
					rate: 1,
					dose: [100,40,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 676,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2)',
					drugs: [41,84,36],
					rate: 3.5,
					dose: [750,50,20],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,4.285714,4.285714],
					pats: 0
				},
				{
					id: 677,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,84,36,2,26],
					rate: 3.5,
					dose: [750,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,4.285714,4.285714,17.142857,17.142857],
					pats: 0
				},
				{
					id: 678,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [41,85],
					rate: 0.5,
					dose: [750,50],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 679,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,85,2,26],
					rate: 0.5,
					dose: [750,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 680,
					code: 'sh0673.1',
					name: 'Карбоплатин + паклитаксел',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2)',
					drugs: [41,17],
					rate: 7.5,
					dose: [750,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857],
					pats: 1
				},
				{
					id: 681,
					code: 'sh0371.1',
					name: 'Карбоплатин + паклитаксел + бевацизумаб',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,17,2,26],
					rate: 7.5,
					dose: [750,400,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 10
				},
				{
					id: 682,
					code: 'sh0643',
					name: 'Карбоплатин + доцетаксел',
					info: '(карбоплатин AUC 5–6, доцетаксел 75 мг/м2 )',
					drugs: [41,88,87],
					rate: 5,
					dose: [750,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 683,
					code: 'sh0634.1',
					name: 'Карбоплатин + гемцитабин',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2)',
					drugs: [41,34,83],
					rate: 3.5,
					dose: [750,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 684,
					code: 'sh0040.1',
					name: 'Карбоплатин + гемцитабин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,34,83,2,26],
					rate: 3.5,
					dose: [750,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 685,
					code: 'sh9001',
					name: 'Карбоплатин + винорелбин',
					info: '(карбоплатин AUC 5–6, винорелбин 25 мг/м2)',
					drugs: [41,112],
					rate: 5,
					dose: [750,90],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 686,
					code: 'sh0555',
					name: 'Карбоплатин + этопозид',
					info: '(карбоплатин AUC 5–6, этопозид 100 мг)',
					drugs: [41,111],
					rate: 1,
					dose: [750,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 687,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, доксорубицин 40-50 мг/м2)',
					drugs: [16,24,84,36],
					rate: 5,
					dose: [150,100,50,20],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 688,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, пегилированный лопосомальный доксорубицин 30 мг/м2)',
					drugs: [16,24,85],
					rate: 1,
					dose: [150,100,50],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 689,
					code: 'sh0635',
					name: 'Оксалиплатин + гемцитабин',
					info: '(оксалиплатин 100-130 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [16,24,34,83],
					rate: 5,
					dose: [150,100,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 690,
					code: 'sh0653',
					name: 'Оксалиплатин + капецитабин',
					info: '(оксалиплатин 100-130 мг/м2, капецитабин 2000 мг/м2)',
					drugs: [16,24,10],
					rate: 1,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 691,
					code: 'sh0090.1',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 60 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 1,
					dose: [150,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.357143,5.357143,5.357143],
					pats: 0
				},
				{
					id: 692,
					code: 'sh0663',
					name: 'Оксалиплатин + фолинат кальция + фторурацил',
					info: '(оксалиплатин 85 мг/м2, фолинат кальция 400 мг/м2, фторурацил 400 мг/м2 болюс, фторурацил 2400 мг/м2)',
					drugs: [16,13,61],
					rate: 1,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.714286,10.714286,10.714286],
					pats: 0
				},
				{
					id: 693,
					code: 'sh0134',
					name: 'Олапариб',
					info: '(олапариб 400 мг)',
					drugs: [90],
					rate: 0,
					dose: [24000],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Платинорезис-<br>тентный рецидив Линия 4
		'st0_4_8':{
			group: 'Платинорезис-<br>тентный рецидив',
			meth: [
				{
					id: 694,
					code: 'sh0264',
					name: 'Этопозид',
					info: '(этопозид 100 мг)',
					drugs: [111],
					rate: 10,
					dose: [1000],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 695,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин 50-60 мг/м2)',
					drugs: [84],
					rate: 10,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 696,
					code: 'sh0024.1',
					name: 'Винорельбин',
					info: '(винорельбин 25 мг/м2)',
					drugs: [112],
					rate: 10,
					dose: [90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 697,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 1,25 мг/м2)',
					drugs: [95],
					rate: 10,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5],
					pats: 0
				},
				{
					id: 698,
					code: 'sh9001',
					name: 'Топотекан + бевацизумаб',
					info: '(топотекан 1,25 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 6,
					dose: [12,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [9.2857,9.2857,9.28571],
					pats: 0
				},
				{
					id: 699,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан 3,7-4 мг/м2)',
					drugs: [95],
					rate: 2,
					dose: [8],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [16],
					pats: 0
				},
				{
					id: 700,
					code: 'sh9001',
					name: 'Топотекан + бевациумаб',
					info: '(топотекан 3,7-4 мг/м2, бевациумаб 15 мг/кг)',
					drugs: [95,2,26],
					rate: 1,
					dose: [8,400,300],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [26,9.2857,9.28571],
					pats: 0
				},
				{
					id: 701,
					code: 'sh0330',
					name: 'Доксорубицин',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2)',
					drugs: [85,86],
					rate: 1,
					dose: [50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [3.75,3.75],
					pats: 0
				},
				{
					id: 702,
					code: 'sh0331',
					name: 'Доксорубицин + бевацизумаб',
					info: '(пегилированный липосомальный доксорубицин 40-50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [85,86,2,26],
					rate: 1,
					dose: [50,20,400,300],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.0714,7.0714,9.2857,9.28571],
					pats: 0
				},
				{
					id: 703,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 10,
					dose: [3000,2400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3.75,3.75],
					pats: 0
				},
				{
					id: 704,
					code: 'sh0700',
					name: 'Паклитаксел',
					info: '(паклитаксел 60 мг/м2)',
					drugs: [17],
					rate: 12,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [14],
					pats: 0
				},
				{
					id: 705,
					code: 'sh0825',
					name: 'Паклитаксел + бевацизумаб',
					info: '(паклитаксел 80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,2,26],
					rate: 10,
					dose: [200,400,300],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [26,9.2857,9.28571],
					pats: 0
				},
				{
					id: 706,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 8,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [5,5],
					pats: 0
				},
				{
					id: 707,
					code: 'sh0221',
					name: 'Метотрексат + циклофосфамид',
					info: '(метотрексат 2,5 мг, циклофосфамид 50 мг)',
					drugs: [48,63],
					rate: 1,
					dose: [40,1500],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5,1.5],
					pats: 0
				},
				{
					id: 708,
					code: 'sh9001',
					name: 'Летрозол',
					info: '(летрозол 2,5 мг)',
					drugs: [44],
					rate: 1.5,
					dose: [75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1.5],
					pats: 0
				},
				{
					id: 709,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол 1 мг)',
					drugs: [29],
					rate: 1.5,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5],
					pats: 0
				},
				{
					id: 710,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен 20-40 мг)',
					drugs: [54],
					rate: 1.5,
					dose: [1200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [1.5],
					pats: 0
				},
				{
					id: 711,
					code: 'sh9001',
					name: 'Мегестрол',
					info: '(мегестрол 160 мг)',
					drugs: [89],
					rate: 1.5,
					dose: [4800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1.5],
					pats: 0
				},
				{
					id: 712,
					code: 'sh0161',
					name: 'Пеметрексед',
					info: '(пеметрексед 500 мг)',
					drugs: [76],
					rate: 1,
					dose: [900],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [5],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Платиночувстви-<br>тельный рецидив Линия 4
		'st0_4_11':{
			group: 'Платиночувстви-<br>тельный рецидив',
			meth: [
				{
					id: 713,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2)',
					drugs: [66,93,84,36],
					rate: 3.5,
					dose: [100,40,50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 714,
					code: 'sh0697',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,84,36,2,26],
					rate: 3.5,
					dose: [100,40,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 715,
					code: 'sh0641',
					name: 'Цисплатин + доксорубицин',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [66,93,85],
					rate: 0.5,
					dose: [100,40,50],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 716,
					code: 'sh0696',
					name: 'Цисплатин + доксорубицин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,85,2,26],
					rate: 0.5,
					dose: [100,40,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 717,
					code: 'sh0675',
					name: 'Цисплатин + паклитаксел',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 мг/м2)',
					drugs: [66,93,17],
					rate: 7.5,
					dose: [100,40,1000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,6],
					pats: 0
				},
				{
					id: 718,
					code: 'sh0499',
					name: 'Цисплатин + паклитаксел + бевацизумаб',
					info: '(цисплатин 75 мг/м2, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,17,2,26],
					rate: 7.5,
					dose: [100,40,1000,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,6,17.142857,17.142857],
					pats: 0
				},
				{
					id: 719,
					code: 'sh0644',
					name: 'Цисплатин + доцетаксел',
					info: '(цисплатин 75 мг/м2, доцетаксел 75 мг/м2)',
					drugs: [66,93,88,87],
					rate: 5,
					dose: [100,40,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 720,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,93,34,83],
					rate: 3.5,
					dose: [100,40,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 721,
					code: 'sh0638.1',
					name: 'Цисплатин + гемцитабин + бевацизумаб',
					info: '(цисплатин 75 мг/м2, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [66,93,34,83,2,26],
					rate: 3.5,
					dose: [100,40,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 722,
					code: 'sh0028.1',
					name: 'Цисплатин + винорелбин',
					info: '(цисплатин 75 мг/м2, винорелбин 25 мг/м2)',
					drugs: [66,93,112],
					rate: 3,
					dose: [100,40,90],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 723,
					code: 'sh0814',
					name: 'Цисплатин + этопозид',
					info: '(цисплатин 75 мг/м2, этопозид 100 мг)',
					drugs: [66,93,111],
					rate: 3,
					dose: [100,40,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 724,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 75 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,93,95],
					rate: 1,
					dose: [100,40,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 725,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2)',
					drugs: [41,84,36],
					rate: 3.5,
					dose: [750,50,20],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,4.285714,4.285714],
					pats: 0
				},
				{
					id: 726,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, доксорубицин 40–50 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,84,36,2,26],
					rate: 3.5,
					dose: [750,50,20,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,4.285714,4.285714,17.142857,17.142857],
					pats: 0
				},
				{
					id: 727,
					code: 'sh0640',
					name: 'Карбоплатин + доксорубицин',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2)',
					drugs: [41,85],
					rate: 0.5,
					dose: [750,50],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 728,
					code: 'sh0696',
					name: 'Карбоплатин + доксорубицин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, пегилированный липосомальный доксорубицин 30 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,85,2,26],
					rate: 0.5,
					dose: [750,50,800,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 729,
					code: 'sh0673.1',
					name: 'Карбоплатин + паклитаксел',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2)',
					drugs: [41,17],
					rate: 7.5,
					dose: [750,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 730,
					code: 'sh0371.1',
					name: 'Карбоплатин + паклитаксел + бевацизумаб',
					info: '(карбоплатин AUC 5–6, паклитаксел 175 или 60–80 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,17,2,26],
					rate: 7.5,
					dose: [750,400,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,17.142857,17.142857],
					pats: 2
				},
				{
					id: 731,
					code: 'sh0643',
					name: 'Карбоплатин + доцетаксел',
					info: '(карбоплатин AUC 5–6, доцетаксел 75 мг/м2 )',
					drugs: [41,88,87],
					rate: 5,
					dose: [750,120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 732,
					code: 'sh0634.1',
					name: 'Карбоплатин + гемцитабин',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2)',
					drugs: [41,34,83],
					rate: 3.5,
					dose: [750,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 733,
					code: 'sh0040.1',
					name: 'Карбоплатин + гемцитабин + бевацизумаб',
					info: '(карбоплатин AUC 5–6, гемцитабин 1000 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [41,34,83,2,26],
					rate: 3.5,
					dose: [750,3000,600,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7.142857,7.142857,7.142857,17.142857,17.142857],
					pats: 0
				},
				{
					id: 734,
					code: 'sh9001',
					name: 'Карбоплатин + винорелбин',
					info: '(карбоплатин AUC 5–6, винорелбин 25 мг/м2)',
					drugs: [41,112],
					rate: 5,
					dose: [750,90],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 735,
					code: 'sh0555',
					name: 'Карбоплатин + этопозид',
					info: '(карбоплатин AUC 5–6, этопозид 100 мг)',
					drugs: [41,111],
					rate: 1,
					dose: [750,700],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [7.142857,7.142857],
					pats: 0
				},
				{
					id: 736,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, доксорубицин 40-50 мг/м2)',
					drugs: [16,24,84,36],
					rate: 5,
					dose: [150,100,50,20],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 737,
					code: 'sh0816',
					name: 'Оксалиплатин + доксорубицин',
					info: '(оксалиплатин 100-130 мг/м2, пегилированный лопосомальный доксорубицин 30 мг/м2)',
					drugs: [16,24,85],
					rate: 1,
					dose: [150,100,50],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 738,
					code: 'sh0635',
					name: 'Оксалиплатин + гемцитабин',
					info: '(оксалиплатин 100-130 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [16,24,34,83],
					rate: 5,
					dose: [150,100,3000,600],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.142857,7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 739,
					code: 'sh0653',
					name: 'Оксалиплатин + капецитабин',
					info: '(оксалиплатин 100-130 мг/м2, капецитабин 2000 мг/м2)',
					drugs: [16,24,10],
					rate: 1,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [7.142857,7.142857,7.142857],
					pats: 0
				},
				{
					id: 740,
					code: 'sh0090.1',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 60 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 1,
					dose: [150,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.357143,5.357143,5.357143],
					pats: 0
				},
				{
					id: 741,
					code: 'sh0663',
					name: 'Оксалиплатин + фолинат кальция + фторурацил',
					info: '(оксалиплатин 85 мг/м2, фолинат кальция 400 мг/м2, фторурацил 400 мг/м2 болюс, фторурацил 2400 мг/м2)',
					drugs: [16,13,61],
					rate: 1,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.714286,10.714286,10.714286],
					pats: 0
				},
				{
					id: 742,
					code: 'sh0134',
					name: 'Олапариб',
					info: '(олапариб 400 мг)',
					drugs: [90],
					rate: 0,
					dose: [24000],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		}
	};	

export default ryaData;