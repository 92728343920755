"use strict";

import methData from './methdata';
import rmzData from './rmz-meth';
import rlData from './rl-meth';
import ryaData from './rya-meth';
import rshmData from './rshm-meth';
import rpData from './rp-meth';
import rzData from './rz-meth';
import rmpData from './rmp-meth';
import rpshData from './rpsh-meth';
import rpzData from './rpz-meth';
import rtmData from './rtm-meth';
import gcrData from './gcr-meth';
import rpszData from './rpsz-meth';
import neoData from './neo-meth';
import drugData from './drugdata';
import State from './state';

class DistPanel {

	constructor() {

		$('#d-menu').on(State.startEvent, (e)=>this.switchPage(e));
		$('.scr4-sw-line').on(State.startEvent, (e)=>this.switchType(e));
		$('#exit-butt').on(State.startEvent, this.hide);
		this.currSt = State.currStad;
		this.flEdit = 0;
	}

///////////////////////////////////

	show() {

		$('#distcont').show();		
		$('#section4').show();
		$('#main-cont').hide();		

	}

///////////////////////////////////

	hide() {

		if(State.flModal==1) return;
		if(this.flEdit==1) this.processInput(this.currElem);
		$('#main-cont').show();		
		$('#distcont').hide();		
		$('#section4').hide();		
	}

///////////////////////////////////

	renderSwitch() {

		if(State.clinType=='ds') {
			$('#sw-pict-day').addClass('bull-active');			
			$('#sw-pict-day').removeClass('bull-inactive');			
			$('#sw-pict-full').addClass('bull-inactive');			
			$('#sw-pict-full').removeClass('bull-active');			
			$('#sw-capt-day').addClass('mid-orange-txt');			
			$('#sw-capt-day').removeClass('mid-grey-txt');			
			$('#sw-capt-full').addClass('mid-grey-txt');			
			$('#sw-capt-full').removeClass('mid-orange-txt');			
		} else {
			$('#sw-pict-full').addClass('bull-active');			
			$('#sw-pict-full').removeClass('bull-inactive');			
			$('#sw-pict-day').addClass('bull-inactive');			
			$('#sw-pict-day').removeClass('bull-active');			
			$('#sw-capt-full').addClass('mid-orange-txt');			
			$('#sw-capt-full').removeClass('mid-grey-txt');			
			$('#sw-capt-day').addClass('mid-grey-txt');			
			$('#sw-capt-day').removeClass('mid-orange-txt');			
		}
	}

///////////////////////////////////

	switchType(e) {

		let type, newType;

		if(e.currentTarget.id=='sw-day') {
			if(State.clinType=='ds') return;
			newType = 'ds';
			State.clinicData = State.dsData;
		} else {
			if(State.clinType=='st') return;
			newType = 'st';			
			State.clinicData = State.stData;
		}
		if(this.flEdit==1) this.processInput(this.currElem);
		State.methPanel.saveMethData();
		State.clinType = newType;
		State.methPanel.loadMethData();
		this.renderSwitch();
		this.render();
		State.clinData.saveClinData();	
	}

///////////////////////////////////


	getPathData(pathReq) {

		let pathData, path;

		if(pathReq) path = pathReq;
		else path = State.currPath;

		if(path=='rmz') pathData = rmzData;
		else if(path=='rl') pathData = rlData;
		else if(path=='rya') pathData = ryaData;
		else if(path=='rshm') pathData = rshmData;
		else if(path=='rp') pathData = rpData;
		else if(path=='rz') pathData = rzData;
		else if(path=='rmp') pathData = rmpData;
		else if(path=='rpsh') pathData = rpshData;
		else if(path=='rpz') pathData = rpzData;
		else if(path=='rtm') pathData = rtmData;
		else if(path=='gcr') pathData = gcrData;
		else if(path=='rpsz') pathData = rpszData;
		else if(path=='neo') pathData = neoData;
		else pathData = methData[path];
		return pathData;
	}

///////////////////////////////////

	render(st) {

		const path = State.currPath;
		let pathData, colClass, tableLine, tableCode, methSet, drugs,
			flOnls, alert, meth, size, cnt, visible, currDrug, omsVis, omsdVis,
			omsPrice, omsdPrice, methPrice, OmsDrugBudget, OmsdDrugBudget,
			omsMethodBudget, omsdMethodBudget, nOfOmsPatients, nOfOmsdPatients,
			omsDelta, omsdDelta, omsPriceStr, omsdPriceStr, remPats, omsDeltaStr, 
			omsdDeltaStr;

		if(st) this.currSt = st;
		pathData = this.getPathData();
		methSet = pathData[this.currSt].meth;

		if(path=='rmp') this.allPats = State.rmpFreePatsBuf[this.currSt];
		else if(path=='rya') this.allPats = State.ryaFreePatsBuf[this.currSt];
		else {
			if(pathData[this.currSt].result != -1) this.allPats = pathData[this.currSt].result;
			else this.allPats = pathData[this.currSt].pats;	
		}
		const methTable = $('#scr4-insert');

		const len = methSet.length;
		let color = 0 ; 
		tableCode = '';
		let tarifOmsSumm = 0, deltaOmsSumm = 0;
		let tarifOmsdSumm = 0, deltaOmsdSumm = 0;
		for(let num=0 ; num<len ; num++) {
			meth = methSet[num];
			if(meth.oms===undefined) {
				meth.oms = 0;
				meth.omsd = 0;
				meth.rlo = 0;
				meth.onls = 0;
			}
			drugs = meth.drugs;
			size = drugs.length;
			flOnls = 1;
// 			for(cnt=0 ; cnt<size ; cnt++) { // Все ли препараты схемы входят в список ОНЛС?
// 				currDrug = drugData[drugs[cnt]];
// //				if(currDrug.on == 0 || currDrug.inj == 1) { 
// 				if(currDrug.on == 0) { // Текущий препарат не входит?
// 					flOnls = 0; 
// 					break; 
// 				}
// 			}
			
			for(cnt=0 ; cnt<size ; cnt++) { // Все ли препараты схемы входят в список ОНЛС или ЖНВЛП?
				currDrug = drugData[drugs[cnt]];
				if(currDrug.on == 0 && currDrug.list == 0) { // Текущий препарат не входит ни в один список?
					flOnls = 0; 
					break; 
				}
			}

			if(meth.custKsg) {
				let custSet = State.calc.getCustomKSGSet(meth.custKsg, 'st');
				let custCnt, custLen = custSet.length;
				omsPrice = 0;
				for(custCnt=0 ; custCnt<custLen ; custCnt++) {
					omsPrice += this.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc);
				}	
				custSet = State.calc.getCustomKSGSet(meth.custKsg, 'ds');
				custLen = custSet.length;
				omsdPrice = 0;
				for(custCnt=0 ; custCnt<custLen ; custCnt++) {
					omsdPrice += this.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc);
				}	
			} else {
				omsPrice = this.calcMethodIncome(meth.st, meth.cycles[0]);
				omsdPrice = this.calcMethodIncome(meth.ds, meth.cycles[0]);
			}	

			omsPrice = Math.round(omsPrice*meth.oms);
			omsPriceStr = State.calc.padDigits(omsPrice);
			omsdPrice = Math.round(omsdPrice*meth.omsd);
			omsdPriceStr = State.calc.padDigits(omsdPrice);

			tarifOmsSumm += omsPrice;
			tarifOmsdSumm += omsdPrice;

			let drugSet = drugs;
			let doseSet = meth.dose;
			let nOfDrugs = drugSet.length;
			let nOfCycles = meth.cycles; // N of infusions for year 
										// for current method of therapy
			nOfOmsPatients = meth.oms;
			nOfOmsdPatients = meth.omsd;

			omsMethodBudget = 0;							
			omsdMethodBudget = 0;

			let drugIndex, cycleDose, currDrug, currPrice, drugDose, drugPack, 
			nOfPacks, nOfOmsPacks, nOfOmsdPacks;

			for(let currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

				if(currDrug.tab) { // Таблетированная форма
					nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
					nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
					nOfOmsPacks = nOfPacks*nOfOmsPatients // Пачек на курс на всех
					nOfOmsdPacks = nOfPacks*nOfOmsdPatients // Пачек на курс на всех
				}
				else {
					nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
					nOfOmsPacks = nOfOmsPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
					nOfOmsdPacks = nOfOmsdPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
				}
				nOfOmsPacks = Math.ceil(nOfOmsPacks);
				nOfOmsdPacks = Math.ceil(nOfOmsdPacks);
				OmsDrugBudget = nOfOmsPacks*currPrice;
				OmsdDrugBudget = nOfOmsdPacks*currPrice;
				omsMethodBudget += OmsDrugBudget;
				omsdMethodBudget += OmsdDrugBudget;
			}

			omsDelta = Math.round(omsPrice - omsMethodBudget);	
			omsDeltaStr = State.calc.padDigits(omsDelta);	
			omsdDelta = Math.round(omsdPrice - omsdMethodBudget);	
			omsdDeltaStr = State.calc.padDigits(omsdDelta);	
			deltaOmsSumm += omsDelta;
			deltaOmsdSumm += omsdDelta;

			remPats = meth.pats - meth.oms - meth.omsd - meth.rlo - meth.onls;

			if(flOnls) visible = ''; else visible = 'style="display:none"';	
			alert = '';
			if(meth.st==undefined || meth.st=='') omsVis = 'style="display:none"'; else omsVis = '';	
			if(meth.ds==undefined || meth.ds=='') omsdVis = 'style="display:none"'; else omsdVis = '';	

			if(color==0) colClass = 'grey'; else colClass = '';
			tableLine = `
            <div class="scr4-line" id="scr4-line${num}">
                <div class="scr4-col d-col1 ${colClass}" id="col1-line${num}">
    				${meth.name}<br>
    				<span class="scr4-info">${meth.info}</span>
                </div>
                <div class="scr4-data-col d-col2 ${colClass}" id="col2-line${num}"><span class="p-capt">П-тов:</span> ${remPats}</div>
                <div class="scr4-data-col d-col3 ${colClass}" id="col3-line${num}">
        	        <input ${omsVis} class="oms-input" id="oms-input${num}" type="text" value="${meth.oms}">	
                </div>
                <div class="scr4-data-col d-col4 ${colClass}" id="col4-line${num}">
        		    <input ${omsdVis} class="omsd-input" id="omsd-input${num}" type="text" value="${meth.omsd}">	
                </div>
                <div class="scr4-data-col d-col5 ${colClass}" id="col5-line${num}">
        		    <input class="rlo-input" id="rlo-input${num}" type="text" value="${meth.rlo}">	
                </div>
                <div class="scr4-data-col d-col6 ${colClass}" id="col6-line${num}">
        		    <input ${visible} class="onls-input" id="onls-input${num}" type="text" value="${meth.onls}">	
                </div>
            </div>
            <div class="scr4-bot-line" id="scr4-bot-line${num}">
                <div class="scr4-tcap db-col1 ${colClass}">
					Тариф КСГ/Тариф КСГ-ЛП
                </div>
                <div class="scr4-data-col db-col2 ${colClass}"></div>
                <div class="db-col3 ${colClass}" id="col3-bot-line${num}">
                	<span id="scr4-oms-tarif${num}">${omsPriceStr}</span>/<br>
                	<span id="scr4-oms-delta${num}">${omsDeltaStr}</span>&nbsp;
                </div>
                <div class="db-col4 ${colClass}" id="col4-bot-line${num}">
                	<span id="scr4-omsd-tarif${num}">${omsdPriceStr}</span>/<br>
                	<span id="scr4-omsd-delta${num}">${omsdDeltaStr}</span>&nbsp;
                </div>
                <div class="scr4-data-col db-col5 ${colClass}">
                </div>
                <div class="scr4-data-col db-col6 ${colClass}">
                </div>
            </div>
			`;
			tableCode += tableLine;
			color++;
			if(color>1) color = 0;
		}
		methTable.empty();
		methTable.append(tableCode);
		$('.oms-input').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.omsd-input').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.rlo-input').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.onls-input').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.oms-input').on('keypress', (e) => this.editNumber(e));
		$('.omsd-input').on('keypress', (e) => this.editNumber(e));
		$('.rlo-input').on('keypress', (e) => this.editNumber(e));
		$('.onls-input').on('keypress', (e) => this.editNumber(e));
		$('.oms-input').on('focusout', (e) => this.focusOut(e));
		$('.omsd-input').on('focusout', (e) => this.focusOut(e));
		$('.rlo-input').on('focusout', (e) => this.focusOut(e));
		$('.onls-input').on('focusout', (e) => this.focusOut(e));

		tarifOmsSumm = State.calc.padDigits(Math.round(tarifOmsSumm));
		tarifOmsdSumm = State.calc.padDigits(Math.round(tarifOmsdSumm));
		$('#omsTarSumm').text(tarifOmsSumm);
		$('#omsdTarSumm').text(tarifOmsdSumm);

		deltaOmsSumm = State.calc.padDigits(Math.round(deltaOmsSumm));
		deltaOmsdSumm = State.calc.padDigits(Math.round(deltaOmsdSumm));
		$('#omsDeltaSumm').text(deltaOmsSumm);
		$('#omsdDeltaSumm').text(deltaOmsdSumm);
		this.renderTotals();
		this.renderSwitch();
	}

///////////////////////////////////

	renderTotals(reqPath, reqSt) {

		let res, path, st, rem;

		if(State.clinType=='st') State.clinicData = State.stData;
		if(State.clinType=='ds') State.clinicData = State.dsData;
		if(reqPath) path = reqPath; else path = State.currPath;
		if(reqSt) st = reqSt; else st = State.currStad;

		res = Math.round(State.calc.calcOneGroup(path, st, 'oms')); 
		$('#scr4-hcol2-line3').text(State.calc.padDigits(res));

		res = Math.round(State.calc.calcFullBudget('oms')); 
		rem = State.clinicData.omsPlan - res;
		$('#scr4-hcol2-line2').text(State.calc.padDigits(rem));
		if(rem<0) {
			$('#scr4-hcol2-line2').addClass('alert');
			$('#scr4-hcol2-line2').removeClass('white');
		}
		else {
			$('#scr4-hcol2-line2').removeClass('alert');
			$('#scr4-hcol2-line2').addClass('white');
		}


		res = Math.round(State.calc.calcOneGroup(path, st, 'omsd')); 
		$('#scr4-hcol3-line3').text(State.calc.padDigits(res));

		res = Math.round(State.calc.calcFullBudget('omsd')); 
		rem = State.clinicData.omsdPlan - res;
		$('#scr4-hcol3-line2').text(State.calc.padDigits(rem));
		if(rem<0) {
			$('#scr4-hcol3-line2').addClass('alert');
			$('#scr4-hcol3-line2').removeClass('white');
		}
		else {
			$('#scr4-hcol3-line2').removeClass('alert');
			$('#scr4-hcol3-line2').addClass('white');
		}

		res = Math.round(State.calc.calcOneGroup(path, st, 'rlo')); 
		$('#scr4-hcol4-line3').text(State.calc.padDigits(res));

		res = Math.round(State.calc.calcFullBudget('rlo')); 
		rem = State.clinicData.rloPlan - res;
		$('#scr4-hcol4-line2').text(State.calc.padDigits(rem));
		if(rem<0) {
			$('#scr4-hcol4-line2').addClass('alert');
			$('#scr4-hcol4-line2').removeClass('white');
		}
		else {
			$('#scr4-hcol4-line2').removeClass('alert');
			$('#scr4-hcol4-line2').addClass('white');
		}

		res = Math.round(State.calc.calcOneGroup(path, st, 'onls')); 
		$('#scr4-hcol5-line3').text(State.calc.padDigits(res));

		res = Math.round(State.calc.calcFullBudget('onls')); 
		rem = State.clinicData.onlsPlan - res;
		$('#scr4-hcol5-line2').text(State.calc.padDigits(rem));
		if(rem<0) {
			$('#scr4-hcol5-line2').addClass('alert');
			$('#scr4-hcol5-line2').removeClass('white');
		}
		else {
			$('#scr4-hcol5-line2').removeClass('alert');
			$('#scr4-hcol5-line2').addClass('white');
		}

// Clinic plan
		$('#scr4-hcol2-line1').text(State.calc.padDigits(State.clinicData.omsPlan));
		$('#scr4-hcol3-line1').text(State.calc.padDigits(State.clinicData.omsdPlan));
		$('#scr4-hcol4-line1').text(State.calc.padDigits(State.clinicData.rloPlan));
		$('#scr4-hcol5-line1').text(State.calc.padDigits(State.clinicData.onlsPlan));

// OMS income		
		$('#total-oms-income').text(State.calc.padDigits(Math.round(State.stIncome + State.dsIncome)));
		if(State.nOfCasesKs!=0)	State.averCostKs = State.stIncome/State.nOfCasesKs;
		else State.averCostKs = 0;
		if(State.nOfCasesDs!=0)	State.averCostDs = State.dsIncome/State.nOfCasesDs;
		else State.averCostDs = 0;

// Page 6 chanel table
		$('#scr6-insert').html('');
		State.chanTable.render('');
		State.chanTable.render('oms');
		State.chanTable.render('omsd');
		State.chanTable.render('rlo');
		State.chanTable.render('onls');

	}

///////////////////////////////////

	resetGroupChanels(methSet) {

		let meth, clinTypeBackup = State.clinType;

		const len = methSet.length;
		for(let num=0 ; num<len ; num++) {
			meth = methSet[num];
				meth.oms = 0;
				meth.omsd = 0;
				meth.rlo = 0;
				meth.onls = 0;
		}
//!!TODO >>>>>>> Нужно сделать процедуру сохранения одной группы !!
		State.clinType = 'st'; State.methPanel.saveMethData();
		State.clinType = 'ds'; State.methPanel.saveMethData();
		State.clinType = clinTypeBackup;
	}

///////////////////////////////////

	resetChanels(reqSt) {

		let pathData, methSet, meth;

//console.log('resetGroupChanels');
		const path = State.currPath;
		let st; 

		if(reqSt) st = reqSt;
		else st = State.currStad;

		this.currSt = st;
		pathData = this.getPathData();
		methSet = pathData[st].meth;
		this.resetGroupChanels(methSet);
		this.render(st);
	}

///////////////////////////////////

	resetPathChanels(path) {

//console.log('resetPathChanels: '+ path);
		let pathData, st, methSet;

		pathData = this.getPathData(path);
		for (st in pathData) {
			methSet = pathData[st].meth;
			this.resetGroupChanels(methSet);
		}
	}

///////////////////////////////////

	resetAllChanels() {

		let path, len;
//console.log('resetAllChanels');
		for(let i=0, len=State.pathKeys.length ; i<len ; i++)
			this.resetPathChanels(State.pathKeys[i]);
	}

///////////////////////////////////

	selectEdit(e) {

		let el = e.target.id;

		this.flEdit = 1;	
		this.currElem = el;
		setTimeout(() => {
			$('#'+el).focus();
			let len = $('#'+el).val().length;
			let inp = document.getElementById(el);
			inp.setSelectionRange(0,len);
		},100);
	}

///////////////////////////////////

	processInput(el) {

		let lineNum, pathData, chan, oldValue, 
			methData, delta, newVal, groupData, patsVal, 
			val = $('#'+el).val();

		this.flEdit = 0;	
		if(-1 < el.indexOf('oms-input')) {
			chan = 'oms';
			lineNum = el.slice(9);
		}
		if(-1 < el.indexOf('omsd-input')) {
			chan = 'omsd';
			lineNum = el.slice(10);
		}
		if(-1 < el.indexOf('rlo-input')) {
			chan = 'rlo';
			lineNum = el.slice(9);
		}
		if(-1 < el.indexOf('onls-input')) {
			chan = 'onls';
			lineNum = el.slice(10);
		}
		pathData = this.getPathData();
		groupData = pathData[this.currSt];
		methData = groupData.meth[lineNum];
		if(chan=='oms') oldValue = methData.oms;
		if(chan=='omsd') oldValue = methData.omsd;
		if(chan=='rlo') oldValue = methData.rlo;
		if(chan=='onls') oldValue = methData.onls;
		delta = val - oldValue;
//		patsVal = methData.pats;
		patsVal = methData.pats - methData.oms - methData.omsd - methData.rlo - methData.onls;
		if((patsVal-delta)<0) {
			newVal = oldValue + patsVal; 
			patsVal = 0;
		} else {
			newVal = oldValue + delta;
			patsVal -= delta;
		}
		if(chan=='oms') methData.oms = newVal;
		if(chan=='omsd') methData.omsd = newVal;
		if(chan=='rlo') methData.rlo = newVal;
		if(chan=='onls') methData.onls = newVal;
//		methData.pats = patsVal;
		$('#'+el).val(newVal);
		$('#col2-line'+lineNum).text(patsVal);
		State.calc.calcFullBudget();
//TODO сделать целевой рефреш в текущей строке
this.render();
//		this.renderTotals();
		State.methPanel.saveMethData();
	}

///////////////////////////////////

	focusOut(e) {

		let el = e.target.id, lineNum;

		this.processInput(el);
	}


///////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.processInput(e.target.id);
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
		// if(e.type=='focusout') {
		// 		this.completeEdit('out');
		// }	
	}


///////////////////////////////////

	calcMethodIncome(code, cycles) {

		let type, kz, dzp, res, clinData, ksgNum, customUK;
		const stInd = {
		'st19.062': 0,
		'st19.063': 1,
		'st19.064': 2,
		'st19.065': 3,
		'st19.066': 4,
		'st19.067': 5,
		'st19.068': 6,
		'st19.069': 7,
		'st19.070': 8,
		'st19.071': 9,
		'st19.072': 10,
		'st19.073': 11,
		'st19.074': 12
		};
		const dsInd = {
		'ds19.037': 0,
		'ds19.038': 1,
		'ds19.039': 2,
		'ds19.040': 3,
		'ds19.041': 4,
		'ds19.042': 5,
		'ds19.043': 6,
		'ds19.044': 7,
		'ds19.045': 8,
		'ds19.046': 9,
		'ds19.047': 10,
		'ds19.048': 11,
		'ds19.049': 12
		};

		if(code=='') return 0;
		if(code==undefined) return 0;
		kz = this.getKsgValue(code);
		dzp = this.getDzpValue(code);
		ksgNum = code.slice(6);
		ksgNum = parseInt(ksgNum);		
		if(-1 != code.indexOf('ds')) {
			ksgNum = dsInd[code]; 
			customUK = State.dsUKData[ksgNum];
			clinData = State.dsData;
		}
		else if(-1 != code.indexOf('st')) {
			ksgNum = stInd[code];; 
			customUK = State.stUKData[ksgNum];
			clinData = State.stData;
		}
		else return 0;
//		if(customUK==-1) {
//			res = clinData.bs*kz*clinData.kus*clinData.uk*clinData.kslp*clinData.kd;
//		} else {
//			res = clinData.bs*kz*clinData.kus*customUK*clinData.kslp*clinData.kd;
//		}
		res = clinData.bs*kz*((1.0 - dzp) + dzp*clinData.kus*clinData.kd);
		if(cycles) {
			cycles = Math.ceil(cycles);
			res = res*cycles;
		}
		res = Math.round(res);
		return res;

		// let type, kz, res, clinData, ksgNum, customUK;

		// if(code=='') return 0;
		// if(code==undefined) return 0;
		// kz = this.getKsgValue(code);
		// ksgNum = code.slice(6);
		// ksgNum = parseInt(ksgNum);		
		// if(-1 != code.indexOf('ds')) {
		// 	if(ksgNum==9) ksgNum = 10; // Исключения для радия
		// 	else ksgNum -= 18; 
		// 	customUK = State.dsUKData[ksgNum];
		// 	clinData = State.dsData;
		// }
		// else if(-1 != code.indexOf('st')) {
		// 	if(ksgNum==45) ksgNum = 10; // Исключения для радия
		// 	else ksgNum -= 27; 
		// 	customUK = State.stUKData[ksgNum];
		// 	clinData = State.stData;
		// }
		// else return 0;

		// if(customUK==-1) {
		// 	res = clinData.bs*kz*clinData.kus*clinData.uk*clinData.kslp*clinData.kd;
		// } else {
		// 	res = clinData.bs*kz*clinData.kus*customUK*clinData.kslp*clinData.kd;
		// }
		// if(cycles) {
		// 	cycles = Math.ceil(cycles);
		// 	res = res*cycles;
		// }
		// res = Math.round(res);
		// return res;
	}


///////////////////////////////////

	getKsgValue(ksg) {

		let val = 0;

		if(ksg=='ds19.037') val = 0.48;
		if(ksg=='ds19.038') val = 0.83;
		if(ksg=='ds19.039') val = 1.58;
		if(ksg=='ds19.040') val = 2.45;
		if(ksg=='ds19.041') val = 3.46;
		if(ksg=='ds19.042') val = 4.55;
		if(ksg=='ds19.043') val = 5.97;
		if(ksg=='ds19.044') val = 7.73;
		if(ksg=='ds19.045') val = 9.19;
		if(ksg=='ds19.046') val = 11.25;
		if(ksg=='ds19.047') val = 15.26;
		if(ksg=='ds19.048') val = 23.85;
		if(ksg=='ds19.049') val = 35.24;

		if(ksg=='st19.062') val = 0.51;
		if(ksg=='st19.063') val = 0.71;
		if(ksg=='st19.064') val = 1.39;
		if(ksg=='st19.065') val = 1.86;
		if(ksg=='st19.066') val = 2.43;
		if(ksg=='st19.067') val = 3.32;
		if(ksg=='st19.068') val = 4.23;
		if(ksg=='st19.069') val = 5.14;
		if(ksg=='st19.070') val = 7.18;
		if(ksg=='st19.071') val = 8.49;
		if(ksg=='st19.072') val = 13.38;
		if(ksg=='st19.073') val = 17.89;
		if(ksg=='st19.074') val = 34.58;

		return val;
	}

///////////////////////////////////

	getDzpValue(ksg) {

		const dzpData = {
			'ds19.037': 11.93,
			'ds19.038': 5.99,
			'ds19.039': 29.39,
			'ds19.040': 3.23,
			'ds19.041': 4.14,
			'ds19.042': 3.97,
			'ds19.043': 3.96,
			'ds19.044': 3.76,
			'ds19.045': 5.39,
			'ds19.046': 6.78,
			'ds19.047': 0.43,
			'ds19.048': 0.32,
			'ds19.049': 0.67,
			// 'ds19.063': 0,
			// 'ds19.064': 0,
			// 'ds19.065': 0,
			// 'ds19.066': 0,
			// 'ds19.067': 36.19,
			// 'ds19.068': 36.19,
			// 'ds19.069': 36.19,
			// 'ds19.070': 36.19,
			// 'ds19.071': 3.37,
			// 'ds19.072': 9.56,
			// 'ds19.073': 16.32,
			// 'ds19.074': 20.69,
			// 'ds19.075': 0.82,
			// 'ds19.076': 2.73,
			// 'ds19.077': 5.68,
			// 'ds19.078': 8.41,

			'st19.062': 50.51,
			'st19.063': 39.68,
			'st19.064': 23.68,
			'st19.065': 16.19,
			'st19.066': 22.8,
			'st19.067': 21.32,
			'st19.068': 17.54,
			'st19.069': 20.55,
			'st19.070': 31.4,
			'st19.071': 7.19,
			'st19.072': 1.89,
			'st19.073': 2.44,
			'st19.074': 0.73
			// 'st19.090': 0,
			// 'st19.091': 0,
			// 'st19.092': 0,
			// 'st19.093': 0,
			// 'st19.094': 71.77,
			// 'st19.095': 71.77,
			// 'st19.096': 71.77,
			// 'st19.097': 34.55,
			// 'st19.098': 46.64,
			// 'st19.099': 53.9,
			// 'st19.100': 13.18,
			// 'st19.101': 22.12,
			// 'st19.102': 28.24
		}

		if(dzpData[ksg]) return dzpData[ksg]/100.0;
		else return 0;
	}

//////////////////////////////////////////////////////////////////////

	switchPage(e) {

	        var target, adrStr, hideSection, str;
	        if(State.flAnimate>0) return;
	        if(State.flSelection>0) return;
	        if(State.flModal==1) return;
	        hideSection = '#section' + State.distCurrPage;
	        target = e.target.id;
	        str = target.slice(0,9);
	        if(str=='print-but') {
	        	e.preventDefault();
	        	State.printer.dataPrint();
	        }
	        if(str!='menu-butt') return;
	        target = target.slice(-1);

	        if(target==State.distCurrPage) return;
	        State.flAnimate = 2;
	        $('#menu-butt'+State.distCurrPage).addClass('inactive');
	        $('#menu-butt'+State.distCurrPage).removeClass('active');
	        $('#menu-butt'+target).addClass('active');
	        $('#menu-butt'+target).removeClass('inactive');
	        $('#section'+target).css({'opacity':0, 'display':'block'});
	        $('#section'+target).animate({'opacity':1}, 700, function(){
	            State.flAnimate--;
	        });
	        $(hideSection).animate({'opacity':0}, 700, function(){
	            $(hideSection).css({'display':'none'});
	            State.distCurrPage = target;
	            State.flAnimate--;
	        });
	}

///////////////////////////////////


}

export default DistPanel;