;"use strict";

const rmpData = {

// ============================>rmp
// ---------> Стадия 1 
		'st1':{
			meth: [
				{
					id: 954,
					code: 'sh0123',
					name: 'Митомицин внутрипузырно',
					info: '(митомицин)',
					drugs: [116],
					rate: 25,
					dose: [40],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6],
					pats: 14
				},
				{
					id: 955,
					code: 'sh0638',
					name: 'Доксорубицин внутрипузырно',
					info: '(доксорубицин)',
					drugs: [36],
					rate: 17,
					dose: [50],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 10
				},
				{
					id: 956,
					code: 'sh0253',
					name: 'Эпирубицин внутрипузырно',
					info: '(эпирубицин)',
					drugs: [69],
					rate: 4,
					dose: [50],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 957,
					code: '-',
					name: 'Гемцитабин внутрипузырно',
					info: '(гемцитабин)',
					drugs: [34],
					rate: 4,
					dose: [1000],
					ds: '',//>>
					st: '',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 958,
					code: 'sh0711',
					name: 'Вакцина БЦЖ внутрипузырно',
					info: '(вакцина БЦЖ)',
					drugs: [117],
					rate: 50,
					dose: [150],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [9],
					pats: 28
				}
			],
			pats: 0
		},

// ---------> Стадия 3 
		'st3':{
			meth: [
				{
					id: 959,
					code: 'sh0121.1',
					name: 'MVAC',
					info: '(метотрексат, винбластин, доксорубицин, цисплатин)',
					drugs: [47,113,36,66],
					rate: 0,
					dose: [180,15,60,150],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 960,
					code: 'sh9001',
					name: 'CMV',
					info: '(цисплатин, метотрексат, винбластин)',
					drugs: [66,47,113],
					rate: 0,
					dose: [200,120,10],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 961,
					code: 'sh0712.1',
					name: 'GC',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,66],
					rate: 4,
					dose: [4000,200],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 962,
					code: 'sh0634.1',
					name: 'Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 4,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 1
		'st4_1':{
			meth: [
				{
					id: 963,
					code: 'sh0121.1',
					name: 'MVAC',
					info: '(метотрексат, винбластин, доксорубицин, цисплатин)',
					drugs: [47,113,36,66],
					rate: 1,
					dose: [180,15,60,150],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					id: 964,
					code: 'sh0712.1',
					name: 'GC',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,66],
					rate: 38,
					dose: [4000,200],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [11,11],
					pats: 17
				},
				{
					id: 965,
					code: 'sh0632.1',
					name: 'Монотерапия гемцитабином',
					info: '(гемцитабин)',
					drugs: [34],
					rate: 5.5,
					dose: [4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7],
					pats: 3
				},
				{
					id: 966,
					code: 'sh0634.1',
					name: 'Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 44.5,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8,8],
					pats: 20
				},
				{
					id: 967,
					code: 'sh0450',
					name: 'Атезолизумаб',
					info: '(атезолизумаб)',
					drugs: [71],
					rate: 5.5,
					dose: [1200],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [8],
					pats: 3
				},
				{
					id: 968,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 5.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 2
		'st4_2':{
			meth: [
				{
					id: 969,
					code: 'sh0450',
					name: 'Атезолизумаб',
					info: '(атезолизумаб)',
					drugs: [71],
					rate: 31,
					dose: [1200],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [6],
					pats: 9
				},
				{
					id: 970,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 31,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [6],
					pats: 9
				},
				{
					id: 971,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 23,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [9,9],
					pats: 7
				},
				{
					id: 972,
					code: 'sh9001',
					name: 'Винфлунин',
					info: '(винфлунин)',
					drugs: [114,115],
					rate: 15,
					dose: [500,100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 5
				}
			],
			pats: 0
		}
	}

export default rmpData;	