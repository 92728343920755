;"use strict";

const rpszData = {

// ============================>rpsz
// ---------> Стадия 2 
		'st2_0_1':{
			meth: [
				{
					id: 1089,
					code: 'sh0047',
					name: 'Гозерелин 3,6 мг (1 раз в 28 дней)',
					info: '(гозерелин)',
					drugs: [35],
					rate: 11.5,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 4
				},
				{
					id: 1090,
					code: 'sh0272',
					name: 'Гозерелин 10,8 мг (1 раз в 3 мес)',
					info: '(гозерелин)',
					drugs: [121],
					rate: 11.5,
					dose: [10.8],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [2],
					pats: 4
				},
				{
					id: 1091,
					code: 'sh0113',
					name: 'Лейпрорелин 3,75 мг (1 раз в 28 дней̆)',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 5.75,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 1092,
					code: 'sh0350',
					name: 'Лейпрорелин 7,5 мг (1 раз в 28 дней)',
					info: '(лейпрорелин)',
					drugs: [125],
					rate: 5.75,
					dose: [7.5],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 1093,
					code: 'sh0348',
					name: 'Лейпрорелин 22,5 мг (1 раз в 3 мес)',
					info: '(лейпрорелин)',
					drugs: [126],
					rate: 5.75,
					dose: [22.5],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [2],
					pats: 2
				},
				{
					id: 1094,
					code: 'sh0349',
					name: 'Лейпрорелин 45 мг (1 раз в 6 мес)',
					info: '(лейпрорелин)',
					drugs: [127],
					rate: 5.75,
					dose: [45],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [1],
					pats: 2
				},
				{
					id: 1095,
					code: 'sh0182',
					name: 'Трипторелин 3,75 мг (1 раз в 28 дней)',
					info: '(трипторелин)',
					drugs: [60],
					rate: 11.5,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6],
					pats: 4
				},
				{
					id: 1096,
					code: 'sh0534',
					name: 'Трипторелин 11,25 мг (1 раз в 3 мес)',
					info: '(трипторелин)',
					drugs: [130],
					rate: 11.5,
					dose: [11.25],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 4
				},
				{
					id: 1097,
					code: 'sh0019',
					name: 'Бусерелин (3,75 мг 1 раз в 28 дней)',
					info: '(бусерелин)',
					drugs: [30],
					rate: 23,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 8
				},
				{
					id: 1098,
					code: 'sh0012',
					name: 'Бикалутамид 150 мг',
					info: '(бикалутамид)',
					drugs: [120],
					rate: 4,
					dose: [4200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [6],
					pats: 1
				},
				{
					id: 1099,
					code: 'sh9001',
					name: 'Флутамид 250 мг',
					info: '(флутамид)',
					drugs: [131],
					rate: 4,
					dose: [21000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Стадия 3 
		'st3_0_1':{
			meth: [
				{
					id: 1100,
					code: 'sh0047',
					name: 'Гозерелин 3,6 мг (1 раз в 28 дней)',
					info: '(гозерелин)',
					drugs: [35],
					rate: 11.875,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 13
				},
				{
					id: 1101,
					code: 'sh0272',
					name: 'Гозерелин 10,8 мг (1 раз в 3 мес)',
					info: '(гозерелин)',
					drugs: [121],
					rate: 11.875,
					dose: [10.8],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 13
				},
				{
					id: 1102,
					code: 'sh0113',
					name: 'Лейпрорелин 3,75 мг (1 раз в 28 дней̆)',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 5.9375,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 7
				},
				{
					id: 1103,
					code: 'sh0350',
					name: 'Лейпрорелин 7,5 мг (1 раз в 28 дней)',
					info: '(лейпрорелин)',
					drugs: [125],
					rate: 5.9375,
					dose: [7.5],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 7
				},
				{
					id: 1104,
					code: 'sh0348',
					name: 'Лейпрорелин 22,5 мг (1 раз в 3 мес)',
					info: '(лейпрорелин)',
					drugs: [126],
					rate: 5.9375,
					dose: [22.5],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 7
				},
				{
					id: 1105,
					code: 'sh0349',
					name: 'Лейпрорелин 45 мг (1 раз в 6 мес)',
					info: '(лейпрорелин)',
					drugs: [127],
					rate: 5.9375,
					dose: [45],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 7
				},
				{
					id: 1106,
					code: 'sh0182',
					name: 'Трипторелин 3,75 мг (1 раз в 28 дней)',
					info: '(трипторелин)',
					drugs: [60],
					rate: 11.875,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [13],
					pats: 13
				},
				{
					id: 1107,
					code: 'sh0534',
					name: 'Трипторелин 11,25 мг (1 раз в 3 мес)',
					info: '(трипторелин)',
					drugs: [130],
					rate: 11.875,
					dose: [11.25],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 13
				},
				{
					id: 1108,
					code: 'sh0019',
					name: 'Бусерелин (3,75 мг 1 раз в 28 дней)',
					info: '(бусерелин)',
					drugs: [30],
					rate: 23.75,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 27
				},
				{
					id: 1109,
					code: 'sh0012',
					name: 'Бикалутамид 150',
					info: '(бикалутамид)',
					drugs: [120],
					rate: 5,
					dose: [4200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [13],
					pats: 6
				},
				{
					id: 1110,
					code: 'sh9001',
					name: 'Флутамид 250 мг',
					info: '(флутамид)',
					drugs: [131],
					rate: 0,
					dose: [21000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Гормоно-<br>чувствительный
		'st4_0_1':{
			group: 'Гормоно-<br>чувствительный',
			meth: [
				{
					id: 1111,
					code: 'sh0047',
					name: 'Гозерелин 3,6 мг (1 раз в 28 дней)',
					info: '(гозерелин)',
					drugs: [35],
					rate: 10.625,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 24
				},
				{
					id: 1112,
					code: 'sh0272',
					name: 'Гозерелин 10,8 мг (1 раз в 3 мес)',
					info: '(гозерелин)',
					drugs: [121],
					rate: 10.625,
					dose: [10.8],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 24
				},
				{
					id: 1113,
					code: 'sh0113',
					name: 'Лейпрорелин 3,75 мг (1 раз в 28 дней̆)',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 5.3125,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 1114,
					code: 'sh0350',
					name: 'Лейпрорелин 7,5 мг (1 раз в 28 дней)',
					info: '(лейпрорелин)',
					drugs: [125],
					rate: 5.3125,
					dose: [7.5],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 1115,
					code: 'sh0348',
					name: 'Лейпрорелин 22,5 мг (1 раз в 3 мес)',
					info: '(лейпрорелин)',
					drugs: [126],
					rate: 5.3125,
					dose: [22.5],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 12
				},
				{
					id: 1116,
					code: 'sh0349',
					name: 'Лейпрорелин 45 мг (1 раз в 6 мес)',
					info: '(лейпрорелин)',
					drugs: [127],
					rate: 5.3125,
					dose: [45],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 12
				},
				{
					id: 1117,
					code: 'sh0182',
					name: 'Трипторелин 3,75 мг (1 раз в 28 дней)',
					info: '(трипторелин)',
					drugs: [60],
					rate: 10.625,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [13],
					pats: 24
				},
				{
					id: 1118,
					code: 'sh0534',
					name: 'Трипторелин 11,25 мг (1 раз в 3 мес)',
					info: '(трипторелин)',
					drugs: [130],
					rate: 10.625,
					dose: [11.25],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 24
				},
				{
					id: 1119,
					code: 'sh0052',
					name: 'Дегареликс',
					info: '(дегареликс)',
					drugs: [123,122],
					rate: 15,
					dose: [80,240],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [11,1],
					pats: 34
				},
				{
					id: 1120,
					code: 'sh0019',
					name: 'Бусерелин (3,75 мг 1 раз в 28 дней)',
					info: '(бусерелин)',
					drugs: [30],
					rate: 21.25,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 49
				},
				{
					id: 1121,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел, преднизолон)',
					drugs: [87,128],
					rate: 16.25,
					dose: [140,210],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [7,7],
					pats: 37
				},
				{
					id: 1122,
					code: 'sh0001',
					name: 'Абиратерон + преднизолон',
					info: '(абиратерон, преднизолон)',
					drugs: [119,128],
					rate: 16.25,
					dose: [30000,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [12,12],
					pats: 37
				},
				{
					id: 1123,
					code: 'sh0246',
					name: 'Энзалутамид',
					info: '(энзалутамид)',
					drugs: [132],
					rate: 16.25,
					dose: [4480],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13],
					pats: 37
				},
				{
					id: 1124,
					code: 'sh0762',
					name: 'Апалутамид',
					info: '(апалутамид)',
					drugs: [133],
					rate: 16.25,
					dose: [7200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [12],
					pats: 37
				}
			],
			pats: 0
		},

// ---------> нмКРРПЖ
		'st4_0_2':{
			group: 'нмКРРПЖ',
			meth: [
				{
					id: 1125,
					code: 'sh0246',
					name: 'Энзалутамид',
					info: '(энзалутамид)',
					drugs: [132],
					rate: 50,
					dose: [4480],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13],
					pats: 2
				},
				{
					id: 1126,
					code: 'sh0762',
					name: 'Апалутамид',
					info: '(апалутамид)',
					drugs: [133],
					rate: 50,
					dose: [7200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [12],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> мКРРПЖ Линия 1
		'st4_1_3':{
			group: 'мКРРПЖ',
			meth: [
				{
					id: 1127,
					code: 'sh0001',
					name: 'Абиратерон + преднизолон',
					info: '(абиратерон, преднизолон)',
					drugs: [119,128],
					rate: 30,
					dose: [30000,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [9,9],
					pats: 13
				},
				{
					id: 1128,
					code: 'sh0246',
					name: 'Энзалутамид',
					info: '(энзалутамид)',
					drugs: [132],
					rate: 30,
					dose: [4480],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [9],
					pats: 13
				},
				{
					id: 1129,
					code: 'sh0705',
					name: 'Доцетаксел + преднизолон',
					info: '(доцетаксел, преднизолон)',
					drugs: [87,128],
					rate: 40,
					dose: [140,210],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [14,14],
					pats: 16
				}
			],
			pats: 0
		},

// ---------> мКРРПЖ Линия 2
		'st4_2_4':{
			group: 'мКРРПЖ',
			meth: [
				{
					id: 1130,
					code: 'sh0096',
					name: 'Кабазитаксел + преднизолон',
					info: '(кабазитаксел, преднизолон)',
					drugs: [124,128],
					rate: 30,
					dose: [60,210],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [5,5],
					pats: 11
				},
				{
					id: 1131,
					code: '-',
					name: 'Радий-223',
					info: '(радий-223)',
					drugs: [129],
					rate: 30,
					dose: [6600],
					ds: '',
					st: '',
					cycles: [6],
					pats: 11
				},
				{
					id: 1132,
					code: 'sh0001',
					name: 'Абиратерон + преднизолон',
					info: '(абиратерон, преднизолон)',
					drugs: [119,128],
					rate: 20,
					dose: [30000,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [6,6],
					pats: 8
				},
				{
					id: 1133,
					code: 'sh0246',
					name: 'Энзалутамид',
					info: '(энзалутамид)',
					drugs: [132],
					rate: 20,
					dose: [4480],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [6],
					pats: 8
				}
			],
			pats: 0
		},

// ---------> КРРПЖ гормонотерапия
		'st0_0_5':{
			group: 'КРРПЖ гормонотерапия',
			meth: [
				{
					id: 1134,
					code: 'sh0047',
					name: 'Гозерелин 3,6 мг (1 раз в 28 дней)',
					info: '(гозерелин)',
					drugs: [35],
					rate: 10.625,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 9
				},
				{
					id: 1135,
					code: 'sh0272',
					name: 'Гозерелин 10,8 мг (1 раз в 3 мес)',
					info: '(гозерелин)',
					drugs: [121],
					rate: 10.625,
					dose: [10.8],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 9
				},
				{
					id: 1136,
					code: 'sh0113',
					name: 'Лейпрорелин 3,75 мг (1 раз в 28 дней̆)',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 5.3125,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 4
				},
				{
					id: 1137,
					code: 'sh0350',
					name: 'Лейпрорелин 7,5 мг (1 раз в 28 дней̆)',
					info: '(лейпрорелин)',
					drugs: [125],
					rate: 5.3125,
					dose: [7.5],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 4
				},
				{
					id: 1138,
					code: 'sh0348',
					name: 'Лейпрорелин 22,5 мг (1 раз в 3 мес)',
					info: '(лейпрорелин)',
					drugs: [126],
					rate: 5.3125,
					dose: [22.5],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 4
				},
				{
					id: 1139,
					code: 'sh0349',
					name: 'Лейпрорелин  45 мг (1 раз в 6 мес)',
					info: '(лейпрорелин)',
					drugs: [127],
					rate: 5.3125,
					dose: [45],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 4
				},
				{
					id: 1140,
					code: 'sh0182',
					name: 'Трипторелин 3,75 мг (1 раз в 28 дней)',
					info: '(трипторелин)',
					drugs: [60],
					rate: 10.625,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [13],
					pats: 9
				},
				{
					id: 1141,
					code: 'sh0534',
					name: 'Трипторелин 11,25 мг (1 раз в 3 мес)',
					info: '(трипторелин)',
					drugs: [130],
					rate: 10.625,
					dose: [11.25],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 9
				},
				{
					id: 1142,
					code: 'sh0052',
					name: 'Дегареликс',
					info: '(дегареликс)',
					drugs: [123,122],
					rate: 15,
					dose: [80,240],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [11,1],
					pats: 12
				},
				{
					id: 1143,
					code: 'sh0019',
					name: 'Бусерелин (3,75 мг 1 раз в 28 дней)',
					info: '(бусерелин)',
					drugs: [30],
					rate: 21.25,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 19
				}
			],
			pats: 0
		}
	}

export default rpszData;	