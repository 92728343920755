
"use strict";
let stData = {
	1: [46.6,30.5,10.8,10.3],
	2: [37.3,30.7,10.8,18.1],
	3: [50.9,18,13.8,16.2],
	4: [58.1,23.9,12,6],
	5: [64.5,9,5.4,6.6],
	6: [59.7,20.1,6.3,12.5],
	7: [62,20.3,9.5,7.6],
	8: [49.2,13.1,16.4,19.7],
	9: [55.5,30.7,8.8,4.4],
	10: [62.1,11,14.3,12.1],
	11: [71.6,14.8,8.7,5],
	12: [46.7,28.6,13.2,8.5],
	13: [51.3,24.8,13.3,9.7],
	14: [53.7,25.7,13.1,7.4],
	15: [33,41.2,9.3,14.4],
	16: [32.1,42.7,17.6,7.6],
	17: [37.9,25.6,17.9,18.5],
	18: [67.9,11.4,7.1,12.5],
	19: [25,25,25,25],
	20: [34.3,33.6,14.7,14],
	21: [31,28.9,23.2,7],
	22: [40.5,23,13.5,11.1],
	23: [57,23.2,11.1,6.7],
	24: [51.5,31.4,6.5,6.5],
	25: [65.8,20.3,2.5,7.6],
	26: [63.9,16.7,11.1,5.6],
	27: [41.1,24.7,20.5,13.7],
	28: [54.1,26.2,9.8,9.8],
	29: [49.1,17.9,17.9,15.1],
	30: [49.4,32.6,9,8.9],
	31: [37,22.2,3.7,35.2],
	32: [48.2,31.4,10.6,8],
	33: [48.2,25.8,17.3,7.5],
	34: [44.9,27.5,4.3,17.4],
	35: [23.5,41.2,29.4,5.9],
	36: [36.5,33.2,21.4,7.4],
	37: [28.4,53.1,7.4,6.2],
	38: [51.6,16,17.8,7.7],
	39: [38.5,38.5,11.5,11.5],
	40: [47.4,30.7,14.6,7.3],
	41: [45.8,23.6,16.7,11.1],
	42: [55.2,28.1,8.3,8.3],
	43: [51.5,18.2,15.2,12.1],
	44: [62.2,24.3,10.8,2.7],
	45: [53.6,23.5,16.3,6.6],
	46: [57.4,23,6,12.6],
	47: [68.9,18.9,5.2,5.9],
	48: [44.4,31.2,16.9,7.1],
	49: [60.6,17.6,14.7,6.5],
	50: [56.5,23.8,8.9,7.7],
	51: [36.8,30.6,21.8,10.8],
	52: [55.8,20.4,8.2,14.3],
	53: [52.3,26.4,10.6,10.6],
	54: [37.9,39.4,12.1,6.1],
	55: [58.5,22.6,10.4,8.5],
	56: [49.6,27.5,10.9,12],
	57: [56,15.2,12,16.8],
	58: [47.1,32.2,12.6,8],
	59: [45.9,31.2,11,9.2],
	60: [61.9,14.3,4.8,14.3],
	61: [30.3,32.8,27.7,8.4],
	62: [51.9,20.6,11.7,15.7],
	63: [62,18.1,9.6,10.2],
	64: [49.4,22.8,9.9,13.7],
	65: [23.4,48.8,18.1,8.8],
	66: [59.7,21.4,12,5.4],
	67: [64.4,12.7,9.6,13.4],
	68: [37.6,30.7,20.3,8.1],
	69: [41.6,29.3,15,12.5],
	70: [57.4,18.3,8.8,9.2],
	71: [42.6,31.8,12.8,11.5],
	74: [7.7,30.8,53.8,0],
	75: [23.5,35.3,17.6,17.6],
	76: [34,26.4,18.9,11.3],
	77: [31,32.5,18.2,15.8],
	78: [41.2,39.4,10.9,8.5],
	79: [55.6,14.8,12.3,8.6],
	80: [53.3,20,3.3,13.3],
	81: [38.9,27.8,11.1,22.2],
	82: [37.3,33.3,10.7,18.7],
	72: [60.2,16.8,13.3,9.7],
	83: [40,40,20,0],
	73: [28.4,37,28.4,4.9],
	84: [37.9,27.6,20.7,13.8],
	85: [53.6,21.4,10.7,7.1]
};
	
export default stData;