"use strict";

import State from './state'; 

class ClinData {

	constructor() {
		$('.scr7-edit').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.scr7-edit').on('keypress', (e) => this.editNumber(e));
		$('.scr7-edit').on('focusout', (e) => this.focusOut(e));
		$('.scr5-edit').on(State.startEvent, (e)=>this.selectEdit(e));
		$('.scr5-edit').on('keypress', (e) => this.editNumber(e));
		$('.scr5-edit').on('focusout', (e) => this.focusOut(e));
		$('#uk-open-button').on(State.startEvent, (e)=>this.openUKEdit(e));
		$('#uk-close-button').on(State.startEvent, (e)=>this.closeUKEdit(e));
		this.render();
	}


///////////////////////////////////

	render() {

		const ind = ['omsPlan', 'rloPlan', 'onlsPlan', 'bs', 'kus', 'uk', 'kslp', 'kd'];
		let stVal, dsVal, stCnt, dsCnt, dsData, stData, ksgCnt;

		for(stCnt=0 ; stCnt<8 ; stCnt++) {
			dsCnt = stCnt + 8;
			stData = State.stData[ind[stCnt]];
			dsData = State.dsData[ind[stCnt]];
			if(stCnt<3) {
				stVal = State.calc.padDigits(stData);
				dsVal = State.calc.padDigits(dsData);
			} else if (stCnt==3) {
				stVal = State.calc.padFloat(stData);
				dsVal = State.calc.padFloat(dsData);
			} else {
				stVal = stData;
				dsVal = dsData;
			}
			$('#clin-data'+stCnt).val(stVal);
			$('#clin-data'+dsCnt).val(dsVal);
		}
		for(ksgCnt=0 ; ksgCnt<15 ; ksgCnt++) {
			stVal = State.stUKData[ksgCnt];
			if(stVal==-1) stVal = State.stData['uk'];
			$('#st-data'+ksgCnt).val(stVal);
			stVal = State.dsUKData[ksgCnt];
			if(stVal==-1) stVal = State.dsData['uk'];
			$('#ds-data'+ksgCnt).val(stVal);
		}
	}

///////////////////////////////////

	openUKEdit() {

		State.flModal = 1;
		$('#section7').show();
		$('#section5').hide();
	}

	closeUKEdit() {

		State.flModal = 0;
		$('#section7').hide();
		$('#section5').show();
	}

///////////////////////////////////

	selectEdit(e) {

		let el = e.target.id;

		this.currElem = el;
		setTimeout(() => {
			$('#'+el).focus();
			let len = $('#'+el).val().length;
			let inp = document.getElementById(el);
			inp.setSelectionRange(0,len);
		},100);
	}

///////////////////////////////////

	editNumber(e) {

		let val;

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.processInput(e.target.id);
				return;
			}
			if(e.key==' ') return;
			if(e.key=='.' || e.key==',') {
				val = e.target.value;
				if(val.indexOf('.')==-1 && val.indexOf(',')==-1) return;
				e.preventDefault();
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
	}

///////////////////////////////////

	focusOut(e) {

		let el = e.target.id;

		this.processInput(el);
	}


///////////////////////////////////

	processInput(el) {

		const ind = ['omsPlan', 'rloPlan', 'onlsPlan', 'bs', 'kus', 'uk', 'kslp', 'kd'];
		let val = $('#'+el).val(), param, num, res, prefix;

		prefix = el.slice(0,7);
		if(prefix=='st-data' || prefix=='ds-data') this.processUKInput(el);
		val = val.replace(',','.');
		val = val.replace(/ /g,'');
		num = el.slice(9);
		if(num>7) {
			num -= 8;
			param = ind[num];
			if(num<3) res = parseInt(val);
			else res = parseFloat(val);
			State.dsData[param] = res;
		} else {
			param = ind[num];
			if(num<3) res = parseInt(val);
			else res = parseFloat(val);
			State.stData[param] = res;
		}
		if(num<3) val = State.calc.padDigits(val);
		$('#'+el).val(val);
		this.saveClinData();
		this.render();
		State.distPanel.render();
	}

///////////////////////////////////

	processUKInput(el) {

		let val = $('#'+el).val(), num, res, prefix;

		prefix = el.slice(0,7);
		val = val.replace(',','.');
		val = val.replace(/ /g,'');
		num = el.slice(7);
		res = parseFloat(val);
		if(isNaN(res)) res = -1;
		if(prefix=='st-data') State.stUKData[num] = res;
		if(prefix=='ds-data') State.dsUKData[num] = res;
		this.saveClinData();
		setTimeout(()=>this.render(),250);
		State.distPanel.render();
	}

///////////////////////////////////

	saveClinData() {

		let dataStr, key = 'mn_cdata', dataPack = {};

		dataPack.stData = State.stData;
		dataPack.dsData = State.dsData;
		dataPack.stUKData = State.stUKData;
		dataPack.dsUKData = State.dsUKData;
		dataPack.cType = State.clinType;
		dataStr = JSON.stringify(dataPack);
		localStorage.setItem(key,dataStr);
	}

///////////////////////////////////

	loadClinData() {

		let dataStr, key = 'mn_cdata', dataPack;

		dataStr = localStorage.getItem(key);	
		if(dataStr==null) return;
		dataPack = JSON.parse(dataStr);
		State.stData = dataPack.stData;
		State.dsData = dataPack.dsData;
		State.stUKData = dataPack.stUKData;
		State.dsUKData = dataPack.dsUKData;
		State.clinType = dataPack.cType;
		if(State.clinType=='st') State.clinicData = State.stData;
		if(State.clinType=='ds') State.clinicData = State.dsData;
		this.render();
	}

///////////////////////////////////

}

export default ClinData;