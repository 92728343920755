;"use strict";

const neoData = {

// ============================>neo
// ---------> G1 Линия 1
		'st0_1_1':{
			group: 'G1',
			meth: [
				{
					id: 1144,
					code: 'sh0489',
					name: 'Октреотид 30 мг',
					info: '(октреотид)',
					drugs: [135],
					rate: 50,
					dose: [30],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [13],
					pats: 3
				},
				{
					id: 1145,
					code: 'sh0488',
					name: 'Октреотид 20 мг',
					info: '(октреотид)',
					drugs: [136],
					rate: 0,
					dose: [20],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [13],
					pats: 0
				},
				{
					id: 1146,
					code: 'sh0474',
					name: 'Ланреотид',
					info: '(ланреотид)',
					drugs: [134],
					rate: 50,
					dose: [120],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [13],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> G1 Линия 2
		'st0_2_2':{
			group: 'G1',
			meth: [
				{
					id: 1147,
					code: 'sh0546',
					name: 'Эверолимус + октреотид 30 мг',
					info: '(эверолимус, октреотид)',
					drugs: [135,67],
					rate: 45,
					dose: [30,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 3
				},
				{
					id: 1148,
					code: 'sh0545',
					name: 'Эверолимус + октреотид 20 мг',
					info: '(эверолимус, октреотид)',
					drugs: [136,67],
					rate: 0,
					dose: [20,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 0
				},
				{
					id: 1149,
					code: 'sh0476',
					name: 'Эверолимус + ланреотид',
					info: '(эверолимус, ланреотид)',
					drugs: [134,67],
					rate: 45,
					dose: [120,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 3
				},
				{
					id: 1150,
					code: 'sh0510',
					name: 'Сунитиниб + октреотид 30 мг',
					info: '(сунитиниб, октреотид)',
					drugs: [137,138,135],
					rate: 5,
					dose: [350,700,30],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,13,13],
					pats: 0
				},
				{
					id: 1151,
					code: 'sh0475',
					name: 'Сунитиниб + ланреотид',
					info: '(сунитиниб, ланреотид)',
					drugs: [137,138,134],
					rate: 5,
					dose: [350,700,120],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,13,13],
					pats: 0
				},
				{
					id: 1152,
					code: 'sh9001',
					name: 'Октреотид 30 мг + интерферон',
					info: '(октреотид, интерферон)',
					drugs: [135,7],
					rate: 0,
					dose: [30,12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1153,
					code: 'sh0489',
					name: 'Октреотид 20 мг + интерферон',
					info: '(октреотид, интерферон)',
					drugs: [136,7],
					rate: 0,
					dose: [20,12],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1154,
					code: 'sh0469',
					name: 'Ланреотид + интерферон',
					info: '(ланреотид, интерферон)',
					drugs: [134,7],
					rate: 0,
					dose: [120,12],
					ds: 'ds19.043',//>>
					st: '',
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1155,
					code: 'sh9001',
					name: 'Интерферон',
					info: '(интерферон)',
					drugs: [7],
					rate: 0,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> G2/G3НЭО Линия 1
		'st0_1_3':{
			group: 'G2/G3НЭО',
			meth: [
				{
					id: 1156,
					code: 'sh0546',
					name: 'Эверолимус + октреотид 30 мг',
					info: '(эверолимус, октреотид)',
					drugs: [135,67],
					rate: 45,
					dose: [30,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 14
				},
				{
					id: 1157,
					code: 'sh0545',
					name: 'Эверолимус + октреотид 20 мг',
					info: '(эверолимус, октреотид)',
					drugs: [136,67],
					rate: 0,
					dose: [20,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 0
				},
				{
					id: 1158,
					code: 'sh0476',
					name: 'Эверолимус + ланреотид',
					info: '(эверолимус, ланреотид)',
					drugs: [134,67],
					rate: 45,
					dose: [120,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 12
				},
				{
					id: 1159,
					code: 'sh0510',
					name: 'Сунитиниб + октреотид 30 мг',
					info: '(сунитиниб, октреотид)',
					drugs: [137,138,135],
					rate: 5,
					dose: [350,700,30],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,13,13],
					pats: 2
				},
				{
					id: 1160,
					code: 'sh0475',
					name: 'Сунитиниб + ланреотид',
					info: '(сунитиниб, ланреотид)',
					drugs: [137,138,134],
					rate: 5,
					dose: [350,700,120],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,13,13],
					pats: 2
				},
				{
					id: 1161,
					code: 'sh9001',
					name: 'Октреотид 30 мг + интерферон',
					info: '(октреотид, интерферон)',
					drugs: [135,7],
					rate: 0,
					dose: [30,12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1162,
					code: 'sh0489',
					name: 'Октреотид 20 мг + интерферон',
					info: '(октреотид, интерферон)',
					drugs: [136,7],
					rate: 0,
					dose: [20,12],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1163,
					code: 'sh0469',
					name: 'Ланреотид + интерферон',
					info: '(ланреотид, интерферон)',
					drugs: [134,7],
					rate: 0,
					dose: [120,12],
					ds: 'ds19.043',//>>
					st: '',
					cycles: [13,13],
					pats: 0
				},
				{
					id: 1164,
					code: 'sh9001',
					name: 'Интерферон',
					info: '(интерферон)',
					drugs: [7],
					rate: 0,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> G2/G3НЭО Линия 2
		'st0_2_4':{
			group: 'G2/G3НЭО',
			meth: [
				{
					id: 1165,
					code: 'sh0546',
					name: 'Эверолимус + октреотид 30 мг',
					info: '(эверолимус, октреотид)',
					drugs: [135,67],
					rate: 5,
					dose: [30,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 1
				},
				{
					id: 1166,
					code: 'sh0545',
					name: 'Эверолимус + октреотид 20 мг',
					info: '(эверолимус, октреотид)',
					drugs: [136,67],
					rate: 0,
					dose: [20,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 0
				},
				{
					id: 1167,
					code: 'sh0476',
					name: 'Эверолимус + ланреотид',
					info: '(эверолимус, ланреотид)',
					drugs: [134,67],
					rate: 5,
					dose: [120,300],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [13,12],
					pats: 1
				},
				{
					id: 1168,
					code: 'sh0517',
					name: 'TK + октреотид 30 мг',
					info: '(темозоломид, капецитабин, октреотид)',
					drugs: [139,40,135],
					rate: 45,
					dose: [1400,50500,30],
					ds: 'ds19.046',//>>
					st: 'st19.071',//>>
					cycles: [8,8,8],
					pats: 12
				},
				{
					id: 1169,
					code: 'sh0516',
					name: 'TK + октреотид 20 мг',
					info: '(темозоломид, капецитабин, октреотид)',
					drugs: [139,40,136],
					rate: 0,
					dose: [1400,50500,20],
					ds: 'ds19.046',//>>
					st: 'st19.071',//>>
					cycles: [8,8,8],
					pats: 0
				},
				{
					id: 1170,
					code: 'sh0515',
					name: 'TK + ланреотид',
					info: '(темозоломид, капецитабин, ланреотид)',
					drugs: [139,40,134],
					rate: 45,
					dose: [1400,50500,120],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [8,8,8],
					pats: 13
				},
				{
					id: 1171,
					code: 'sh0657',
					name: 'XELOX + октреотид 30 мг',
					info: '(капецитабин, оксалиплатин, октреотид)',
					drugs: [40,24,135],
					rate: 0,
					dose: [50500,200,30],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [8,8,6],
					pats: 0
				},
				{
					id: 1172,
					code: 'sh0656',
					name: 'XELOX + октреотид 20 мг',
					info: '(капецитабин, оксалиплатин, октреотид)',
					drugs: [40,24,136],
					rate: 0,
					dose: [50500,200,20],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [8,8,6],
					pats: 0
				},
				{
					id: 1173,
					code: 'sh0655',
					name: 'XELOX + ланреотид',
					info: '(капецитабин, оксалиплатин, ланреотид)',
					drugs: [40,24,134],
					rate: 0,
					dose: [50500,200,120],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [8,8,6],
					pats: 0
				},
				{
					id: 1174,
					code: 'sh0676',
					name: 'TI',
					info: '(темозоломид, иринотекан)',
					drugs: [139,9],
					rate: 0,
					dose: [1400,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8],
					pats: 0
				},
				{
					id: 1175,
					code: 'sh9001',
					name: 'FOLFOX + октреотид 30 мг',
					info: '(оксалиплатин, кальция фолинат, фторурацил, октреотид)',
					drugs: [16,13,61,135],
					rate: 0,
					dose: [150,750,5000,30],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					id: 1176,
					code: 'sh0489',
					name: 'FOLFOX + октреотид 20 мг',
					info: '(оксалиплатин, кальция фолинат, фторурацил, октреотид)',
					drugs: [16,13,61,136],
					rate: 0,
					dose: [150,750,5000,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					id: 1177,
					code: 'sh0665',
					name: 'FOLFOX + ланреотид',
					info: '(оксалиплатин, кальция фолинат, фторурацил, ланреотид)',
					drugs: [16,13,61,134],
					rate: 0,
					dose: [150,750,5000,120],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [8,8,8,8],
					pats: 0
				},
				{
					id: 1178,
					code: 'sh0525',
					name: 'Темозоломид + октреотид 30 мг',
					info: '(темозоломид, октреотид)',
					drugs: [139,135],
					rate: 0,
					dose: [1400,30],
					ds: 'ds19.046',//>>
					st: 'st19.071',//>>
					cycles: [8,8],
					pats: 0
				},
				{
					id: 1179,
					code: 'sh0524',
					name: 'Темозоломид + октреотид 20 мг',
					info: '(темозоломид, октреотид)',
					drugs: [139,136],
					rate: 0,
					dose: [1400,20],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8,8],
					pats: 0
				},
				{
					id: 1180,
					code: 'sh0523',
					name: 'Темозоломид + ланреотид',
					info: '(темозоломид, ланреотид)',
					drugs: [139,134],
					rate: 0,
					dose: [1400,120],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [8,8],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> G3НЭР Линия 1
		'st0_1_5':{
			group: 'G3НЭР',
			meth: [
				{
					id: 1181,
					code: 'sh0556',
					name: 'EP',
					info: '(этопозид, цисплатин)',
					drugs: [110,66],
					rate: 70,
					dose: [650,150],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 4
				},
				{
					id: 1182,
					code: 'sh0555',
					name: 'EС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 30,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 1183,
					code: 'sh0489',
					name: 'Октреотид 30 мг',
					info: '(октреотид)',
					drugs: [135],
					rate: 0,
					dose: [30],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [13],
					pats: 0
				},
				{
					id: 1184,
					code: 'sh0488',
					name: 'Октреотид 20 мг',
					info: '(октреотид)',
					drugs: [136],
					rate: 0,
					dose: [20],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [13],
					pats: 0
				},
				{
					id: 1185,
					code: 'sh0474',
					name: 'Ланреотид',
					info: '(ланреотид)',
					drugs: [134],
					rate: 0,
					dose: [120],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [13],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> G3НЭР Линия 2
		'st0_2_6':{
			group: 'G3НЭР',
			meth: [
				{
					id: 1186,
					code: 'sh0677',
					name: 'CAV',
					info: '(циклофосфамид, доксорубицин, винкристин)',
					drugs: [64,36,80],
					rate: 100,
					dose: [1800,90,3],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 6
				},
				{
					id: 1187,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [40,24],
					rate: 0,
					dose: [50500,200],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 1188,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [9,25,13,61],
					rate: 0,
					dose: [300,40,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [9,9,9,9],
					pats: 0
				},
				{
					id: 1189,
					code: 'sh0663',
					name: 'FOLFOX',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 0,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [9,9,9],
					pats: 0
				}
			],
			pats: 0
		}
	}

export default neoData;	