"use strict";
let stData = {
	1: [8.7,38.6,18.9,33.5],
	2: [3.8,20.5,46,25.5],
	3: [6.1,40.6,24.6,28.1],
	4: [17.7,42.9,13.6,25.8],
	5: [29.4,33.4,23.3,13.1],
	6: [6.6,38.9,31.1,22.4],
	7: [19.3,37.2,20.1,23.4],
	8: [3.8,47.8,18.1,29.7],
	9: [9,44.6,20.2,26.3],
	10: [20.8,39.7,13.3,25.6],
	11: [22.9,48,16,13.1],
	12: [17.2,45.6,20.8,14.3],
	13: [3,35,27.9,33.5],
	14: [19.1,42.1,23,14.5],
	15: [7.7,31.7,26,33.7],
	16: [7.1,63,18.8,10.4],
	17: [7.8,46.8,17.5,27.8],
	18: [14,44.4,14.8,26.8],
	19: [5.9,70.6,0,17.6],
	20: [19.9,41.1,15.3,21.1],
	21: [9.6,23,39.6,21.3],
	22: [10.3,32.4,32.4,22.5],
	23: [15.9,45.7,21.3,16.5],
	24: [5.6,52.2,23.9,14.6],
	25: [42.1,33.3,7.1,15.5],
	26: [9.6,42.7,20.4,26.8],
	27: [2,41.3,28,28.7],
	28: [11.2,49.4,14,25.3],
	29: [7.7,41.4,18.2,32.6],
	30: [9,44.9,27.9,18.2],
	31: [20.8,36.1,7.6,35.4],
	32: [3.9,51.9,23,20.9],
	33: [9.5,54.6,20.4,12.9],
	34: [4,41.1,19.9,20.5],
	35: [15.4,26.9,11.5,46.2],
	36: [7.3,63.6,8.5,18],
	37: [15.1,50.4,15.1,18.7],
	38: [13.8,35.3,20.7,25.1],
	39: [9.4,40.6,21.9,28.1],
	40: [8,45.7,24.6,21.8],
	41: [10.3,22.1,34.5,29.7],
	42: [3.5,41.7,21.7,33],
	43: [6.8,58.3,18.4,15.5],
	44: [13.8,29.2,9.2,47.7],
	45: [13.3,35.1,30.5,21.1],
	46: [7.3,52.2,13.1,26.9],
	47: [19.7,48.5,17.3,14.5],
	48: [8.7,26.6,46.3,18.2],
	49: [6.3,42.2,34.1,16.2],
	50: [1.7,19.5,54.8,14.2],
	51: [17.2,44.4,20.2,18.2],
	52: [11.4,41.8,17.3,29.5],
	53: [6,38.9,33.7,21.4],
	54: [14.4,41.1,15.1,26.7],
	55: [13.6,48.6,9.5,28.2],
	56: [16.5,45.2,22.2,16.2],
	57: [1.9,55,18.5,24.6],
	58: [0.5,44.3,19.2,36.1],
	59: [22.5,45,9,22.1],
	60: [16.7,41.7,16.7,25],
	61: [10.3,44.3,22.2,21.7],
	62: [20.5,36.9,24.3,18.3],
	63: [13.2,51.8,20.4,14.7],
	64: [14.8,48.9,13.8,20.7],
	65: [6.1,55,19,19.3],
	66: [3.1,48.1,38.3,9.8],
	67: [6.2,51.8,19.1,22.9],
	68: [17.8,41.7,25.3,13.4],
	69: [11.4,52.9,19.4,15.9],
	70: [3.6,62.1,12,21.1],
	71: [3.7,68.1,7.2,19.4],
	74: [7.4,51.9,11.1,29.6],
	75: [6.5,77.4,6.5,9.7],
	76: [2.9,50.3,15,22],
	77: [12.8,42.1,22.6,21.4],
	78: [5.5,63.9,15.7,14.9],
	79: [12.6,43.9,22.4,19.7],
	80: [17.2,36.2,24.1,22.4],
	81: [11.4,31.4,11.4,45.7],
	82: [2.8,35,44.1,18.2],
	72: [58.4,17.6,8.8,14.9],
	83: [0,80,20,0],
	73: [15,51.5,14.4,13.2],
	84: [13.3,38.8,13.3,34.7],
	85: [20.7,34.5,13.8,31]
};
	
export default stData;