;"use strict";

const rpzData = {

// ============================>rpz
// ---------> Предоперационная ХТ
		'st0_0_1':{
			group: 'Предоперационная ХТ',
			meth: [
				{
					id: 1004,
					code: 'sh0628',
					name: 'FOLFIRINOX',
					info: '(оксалиплатин, иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [16,9,25,13,61],
					rate: 30,
					dose: [150,300,40,750,5000],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6,6,4,4,3],
					pats: 3
				},
				{
					id: 1005,
					code: 'sh9001',
					name: 'Nab-паклитаксел + гемцитабин',
					info: '(Nab-паклитаксел, гемцитабин)',
					drugs: [28,34,83],
					rate: 20,
					dose: [300,5000,400],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 1006,
					code: 'sh0629',
					name: 'GEMCAP',
					info: '(гемцитабин, капецитабин)',
					drugs: [34,83,10],
					rate: 20,
					dose: [5000,400,42000],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 1007,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 15,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3,3],
					pats: 2
				},
				{
					id: 1008,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 5,
					dose: [54000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [3],
					pats: 1
				},
				{
					id: 1009,
					code: 'sh9001',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16,24],
					rate: 10,
					dose: [5000,400,150,100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,4],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Адъювантная ХТ
		'st0_0_2':{
			group: 'Адъювантная ХТ',
			meth: [
				{
					id: 1010,
					code: 'sh0628',
					name: 'mFOLFIRINOX',
					info: '(оксалиплатин, иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [16,9,25,13,61],
					rate: 10,
					dose: [150,200,80,750,5000],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [9,9,9,9,9],
					pats: 1
				},
				{
					id: 1011,
					code: 'sh0629',
					name: 'GEMCAP',
					info: '(гемцитабин, капецитабин)',
					drugs: [34,83,10],
					rate: 40,
					dose: [5000,400,42000],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4,4,4],
					pats: 4
				},
				{
					id: 1012,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 30,
					dose: [1000,800],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [11,11],
					pats: 2
				},
				{
					id: 1013,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 15,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 1014,
					code: 'sh0695',
					name: '5-фторурацил, кальция фолинат',
					info: '(5-фторурацил, кальция фолинат)',
					drugs: [61,13],
					rate: 5,
					dose: [4000,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [5,5],
					pats: 0
				}
			],
			pats: 0
		},

// --------->  Линия 1
		'st0_1_3':{
			meth: [
				{
					id: 1015,
					code: 'sh0628',
					name: 'FOLFIRINOX',
					info: '(оксалиплатин, иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [16,9,25,13,61],
					rate: 20,
					dose: [150,300,40,750,5000],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [8,8,8,8,8],
					pats: 3
				},
				{
					id: 1016,
					code: 'sh9001',
					name: 'Nab-паклитаксел + гемцитабин',
					info: '(Nab-паклитаксел, гемцитабин)',
					drugs: [28,34,83],
					rate: 20,
					dose: [300,5000,400],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 1017,
					code: 'sh0704',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66,93],
					rate: 5,
					dose: [3000,600,100,40],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [5,5,5,5],
					pats: 1
				},
				{
					id: 1018,
					code: 'sh9001',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16,24],
					rate: 10,
					dose: [3000,600,150,100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [5,5,5,5],
					pats: 1
				},
				{
					id: 1019,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 25,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4,4],
					pats: 2
				},
				{
					id: 1020,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,10],
					rate: 20,
					dose: [150,100,63000],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 3
				}
			],
			pats: 0
		},

// --------->  Линия 2
		'st0_2_4':{
			meth: [
				{
					id: 1021,
					code: 'sh9001',
					name: 'Nab-паклитаксел + гемцитабин',
					info: '(Nab-паклитаксел, гемцитабин)',
					drugs: [28,34,83],
					rate: 15,
					dose: [300,5000,400],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [3,3,3],
					pats: 1
				},
				{
					id: 1022,
					code: 'sh0704',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66,93],
					rate: 5,
					dose: [3000,600,100,40],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 1023,
					code: 'sh9001',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16,24],
					rate: 12,
					dose: [3000,600,150,100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 1024,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 20,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3,3],
					pats: 3
				},
				{
					id: 1025,
					code: 'sh0628',
					name: 'mFOLFIRINOX',
					info: '(оксалиплатин, иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [16,9,25,13,61],
					rate: 10,
					dose: [150,200,80,750,5000],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [7,7,7,7,7],
					pats: 0
				},
				{
					id: 1026,
					code: 'sh0663',
					name: 'mFOLFOX6',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 5,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 1027,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,10],
					rate: 10,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 0
				},
				{
					id: 1028,
					code: 'sh0646',
					name: 'FOLFIRI.3',
					info: '(иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [9,25,13,61],
					rate: 3,
					dose: [300,40,750,4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6,6],
					pats: 0
				},
				{
					id: 1029,
					code: 'sh0706',
					name: 'CAPIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 2,
					dose: [300,80,40500],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 0
				},
				{
					id: 1030,
					code: 'sh0663',
					name: 'OFF',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 2,
					dose: [150,1100,11000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [2,2,2],
					pats: 0
				},
				{
					id: 1031,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 1,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1032,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 12,
					dose: [50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [2],
					pats: 0
				},
				{
					id: 1033,
					code: 'sh0695',
					name: '5-фторурацил + кальция фолинат',
					info: '(5-фторурацил, кальция фолинат)',
					drugs: [61,13],
					rate: 3,
					dose: [4000,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [2,2],
					pats: 0
				}
			],
			pats: 0
		}
	}

export default rpzData;	