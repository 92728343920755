"use strict";

import methData from './methdata';
import State from './state';

class PathPanel {

	constructor(path) {

		if(typeof path === 'undefined') this.currPath =  3;
		else this.currPath = path;
	
		this.mode = 'count';
		this.pathBuf = State.pathList;;
//		this.pathTotals = State.pathTotals;
		this.newPathSet = [];
		this.lastChanged = 'krr';
		$('#path-title').on(State.startEvent, (e)=>this.openEditor(e));

//		this.freeList = [];
	}

///////////////////////////////////

	updatePathTotals() {

		let cnt, len = State.selPath.length;
		this.pathTotals = [];
		for(cnt=0 ; cnt<len ; cnt++) {
			if(State.selPath[cnt]==0) {
				continue;
			}
			this.pathTotals.push(State.pathTotals[cnt]);
		}
	}	

///////////////////////////////////

	render(currPath) { // currPath:number

		const pathPanel = $('#path-panel');
		let path, len, cnt, defaultSt,
		flChangeCurrPath = 0;

		if(typeof currPath === 'undefined') ;
		else this.currPath = currPath;
	
		len = State.pathList.length;
		this.pathBuf = [];
		this.pathTotals = [];
		this.pathSumm = [];
		this.newPathSet = [];
		for(cnt=0 ; cnt<len ; cnt++) {
			if(State.selPath[cnt]==0) {
				continue;
			}
			this.newPathSet.push(cnt);
			this.pathBuf.push(State.pathList[cnt]);
			this.pathTotals.push(State.pathTotals[cnt]);
			this.pathSumm.push(State.pathSumm[cnt]);
		}
		this.currPath = 0;
		State.currPath = State.pathKeys[this.newPathSet[0]];
		len = this.pathBuf.length;
		const view = `${ this.pathBuf.map( (path, num)=>`
                <div class="path-line" id="path-line${num}">
                    <div class="panel-pic">
                        <div class="line-pic mid-line" id="pathline-pic${num}">
                            <div class="dot-pic" id="path-dot-pic${num}"></div>
                        </div>
                    </div>
                    <div class="path-line-text" id="pathline-text${num}">
                        <div class="stline-title normal-blue" id="pathline-title${num}">${path}</div>
                        <div class="stline-line normal-grey" id="pathline-line${num}"></div>
                        <div class="stline-info normal-grey" id="pathline-info${num}">
                            <div class="man-pict" id="path-man-pict${num}"></div>
                            <div class="path-num normal-grey" id="path-num${num}">${this.pathTotals[num]}<b> / </b>${this.pathSumm[num]}</div>
                        </div>
                    </div>
                    <div class="line-alert" id="path-line-alert${num}">
                    </div>
	                <div class="st-line-counter">
	                    <div class="path-minus-but" id="path-minus${num}"></div>
	                    <div class="path-pats-count" id="path-pcount${num}">
	                    	<div class="pats-edit" id="path-pats-edit${num}">
	                    		<input class="path-count-edit" id="path-count-edit${num}" type="text" value="${this.pathTotals[num]}">
	                    	</div>
	                    	<div class="pats-text" id="path-pats-text${num}">${this.pathTotals[num]}</div>
	                    </div>
	                    <div class="path-plus-but" id="path-plus${num}"></div>
	                </div>
                </div>
			`).join('')}	
		`;	

		pathPanel.empty();
		pathPanel.append(view);
		path = this.currPath;
		if(len==1) {
			$('#pathline-pic0').removeClass('mid-line');
			$('#pathline-pic0').removeClass('bot-line');
			$('#pathline-pic0').removeClass('top-line');

		} else {
			$('#pathline-pic0').addClass('top-line');
			$('#pathline-pic0').removeClass('mid-line');
			$('#pathline-pic'+(len-1)).addClass('bot-line');
			$('#pathline-pic'+(len-1)).removeClass('mid-line');
		}
		$('#pathline-title'+path).addClass('selected');
		$('#pathline-line'+path).addClass('selected');
		$('#pathline-info'+path).addClass('selected');
		$('#path-man-pict'+path).addClass('man-pict-sel');
		$('#path-num'+path).addClass('selected');
		$('#path-dot-pic'+path).addClass('dot-pic-sel');
		$('#pathline-title'+path).removeClass('normal-blue');
		$('#pathline-line'+path).removeClass('normal-grey');
		$('#pathline-info'+path).removeClass('normal-grey');
		$('#path-man-pict'+path).removeClass('man-pict');
		$('#path-num'+path).removeClass('normal-grey');
		$('#path-dot-pic'+path).removeClass('dot-pic');

		this.markFreePats();

		$('.path-line-text').on(State.startEvent, (e) => { this.selectPath(e)});
		$('.path-minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.path-plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.path-pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.path-count-edit').on('keypress focusout', (e) => this.editNumber(e));
		for(cnt=0 ; cnt<len ; cnt++) {
			if(State.pathList[cnt].length>6) $('#path-line-alert'+cnt).css({'margin-right':0});
		}

		State.currPath = State.pathKeys[this.newPathSet[this.currPath]];

		// if(this.newPathSet[this.currPath]==2) {// Выбран рмж - там нет st2
		// 	State.stPanel.setCurrentSt('st0_0_1');
		// 	State.methPanel.setCurrentSt('st0_0_1');
		// } else	State.stPanel.setCurrentSt('st2');

		defaultSt = State.calc.getDefaultSt();
		State.stPanel.setCurrentSt(defaultSt);
		State.methPanel.setCurrentSt(defaultSt);

		State.stPanel.setCurrentPath(State.currPath);
		State.stPanel.render(State.currPath);
		State.methPanel.render(defaultSt);

	}


///////////////////////////////////

	countFreePats() {

		let freeCnt = 0, cnt, currPath, methSet, stKey, len, 
			numberOfPath, allPats = 0;
				 
		numberOfPath = this.pathBuf.length;
		for(currPath=0 ; currPath<numberOfPath ; currPath++) {
			State.stPanel.countFreePats(State.pathKeys[this.newPathSet[currPath]]);
			allPats += State.pathFreeList[this.newPathSet[currPath]];
		}
		State.totFreePats = allPats;
//console.log('All pats:'+allPats);		
	}


///////////////////////////////////

	getFilteredIndex(ind) {

		let i, len = this.newPathSet.length;

		for(i=0 ; i<len ; i++) {
			if(this.newPathSet[i]==ind) {
				return i;
			}
		}
		return -1;
	}


///////////////////////////////////

	markFreePats() {

		let len, cnt;

		this.countFreePats();
		len = State.pathFreeList.length;
		for(cnt=0 ; cnt<len ; cnt++) {

			if(State.pathFreeList[cnt]>0) {
				$('#path-line-alert'+ this.getFilteredIndex(cnt)).show();
			}
			else {
				$('#path-line-alert'+this.getFilteredIndex(cnt)).hide();
			}
		}
		if(State.totFreePats>0)	$('#alert-banner').show();
		else $('#alert-banner').hide();
	}


///////////////////////////////////

	openEditor(e) {

		let len, cnt;

		if(State.flAnimate==1) return;
		State.flAnimate = 1;
		this.flEditMode = 1;

		$('#methods-wrap').animate({'left':'620px','opacity':0},600,()=>{
			$('.st-line-counter').css({'display':'flex'});
			State.flAnimate = 0;
			$('#methods-wrap').hide();
		});
		$('#st-wrap').animate({'left':'620px','opacity':0},600,()=>{
			State.flAnimate = 0;
			$('#st-wrap').hide();
		});
		len = State.pathFreeList.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			$('#path-line-alert'+cnt).hide();

			$('#pathline-title'+cnt).removeClass('selected');
			$('#pathline-line'+cnt).removeClass('selected');
			$('#pathline-info'+cnt).removeClass('selected');
			$('#path-man-pict'+cnt).removeClass('man-pict-sel');
			$('#path-num'+cnt).removeClass('selected');
			$('#path-dot-pic'+cnt).removeClass('dot-pic-sel');
			$('#pathline-title'+cnt).addClass('normal-blue');
			$('#pathline-line'+cnt).addClass('normal-grey');
			$('#pathline-info'+cnt).addClass('normal-grey');
			$('#path-man-pict'+cnt).addClass('man-pict');
			$('#path-num'+cnt).addClass('normal-grey');
			$('#path-dot-pic'+cnt).addClass('dot-pic');
		}
	}

///////////////////////////////////

	closeEditor() {

		if(State.flAnimate==1) return;
		State.flAnimate = 2;
		this.flEditMode = 0;

		$('.st-line-counter').css({'display':'none'});
		$('#methods-wrap').show();
		$('#methods-wrap').animate({'left':'364px','opacity':1},600,()=>{
			State.flAnimate--;
		});
		$('#st-wrap').show();
		$('#st-wrap').animate({'left':'161px','opacity':1},600,()=>{
			State.flAnimate--;
		});
		this.markFreePats();
	}

///////////////////////////////////

	selectPath(e) {

		let num = e.currentTarget.id, st, path;

		num = num.slice(13);
		if(this.currPath==num && this.editMode==0) return;
		if(this.flEditMode==1) this.closeEditor();

		path = this.currPath;
		$('#pathline-title'+path).removeClass('selected');
		$('#pathline-line'+path).removeClass('selected');
		$('#pathline-info'+path).removeClass('selected');
		$('#path-man-pict'+path).removeClass('man-pict-sel');
		$('#path-num'+path).removeClass('selected');
		$('#path-dot-pic'+path).removeClass('dot-pic-sel');

		$('#pathline-title'+path).addClass('normal-blue');
		$('#pathline-line'+path).addClass('normal-grey');
		$('#pathline-info'+path).addClass('normal-grey');
		$('#path-man-pict'+path).addClass('man-pict');
		$('#path-num'+path).addClass('normal-grey');
		$('#path-dot-pic'+path).addClass('dot-pic');


		path = num;
		$('#pathline-title'+path).addClass('selected');
		$('#pathline-line'+path).addClass('selected');
		$('#pathline-info'+path).addClass('selected');
		$('#path-man-pict'+path).addClass('man-pict-sel');
		$('#path-num'+path).addClass('selected');
		$('#path-dot-pic'+path).addClass('dot-pic-sel');

		$('#pathline-title'+path).removeClass('normal-blue');
		$('#pathline-line'+path).removeClass('normal-grey');
		$('#pathline-info'+path).removeClass('normal-grey');
		$('#path-man-pict'+path).removeClass('man-pict');
		$('#path-num'+path).removeClass('normal-grey');
		$('#path-dot-pic'+path).removeClass('dot-pic');

		this.currPath = num;
		State.currPath = State.pathKeys[this.newPathSet[num]];
		if(this.newPathSet[num]==2) {// Выбран рмж - там нет st2
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==3) {// Выбран рл - там нет st2
			State.stPanel.setCurrentSt('st1_0_1');
			State.methPanel.setCurrentSt('st1_0_1');
		} else if(this.newPathSet[num]==4) {// Выбран ря - там нет st2
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==5) {// Выбран ршм - там нет st2
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==6) {// Выбран рп - там нет st2
			State.stPanel.setCurrentSt('st4_1_1');
			State.methPanel.setCurrentSt('st4_1');
		} else if(this.newPathSet[num]==7) {// Выбран рж - там нет st2
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==8) {// Выбран рмп - там нужен st1
			State.stPanel.setCurrentSt('st1');
			State.methPanel.setCurrentSt('st1');
		} else if(this.newPathSet[num]==9) {// Выбран рпищ - там нужен st0_0_1
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==10) {// Выбран рпж - там нужен st0_0_1
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==11) {// Выбран ртм - там нужен st0_0_1
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==12) {// Выбран гцр - там нужен st0_0_1
			State.stPanel.setCurrentSt('st0_0_1');
			State.methPanel.setCurrentSt('st0_0_1');
		} else if(this.newPathSet[num]==13) {// Выбран рпсж - там нужен st2_0_1
			State.stPanel.setCurrentSt('st2_0_1');
			State.methPanel.setCurrentSt('st2_0_1');
		} else if(this.newPathSet[num]==14) {// Выбран нео - там нужен st0_1_1
			State.stPanel.setCurrentSt('st0_1_1');
			State.methPanel.setCurrentSt('st0_1_1');
		} else	State.stPanel.setCurrentSt('st2');
		State.stPanel.render(State.pathKeys[this.newPathSet[num]]);
		State.methPanel.render();
	}

///////////////////////////////////

	getPathNumber(pathName) {

		let i, len = State.pathKeys.length;

		for(i=0 ; i<len ; i++) if(State.pathKeys[i]==pathName) return i;
		return -1;
	}

///////////////////////////////////

	completeEdit() {

		const num = this.currLineNum;
		let	pats = $('#path-count-edit'+num).val();
		let pathName = State.selPathKeys[num], pathInd, str;

		pats = parseInt(pats);
		this.editMode = 'count';
		$('#path-count-edit'+num).val(pats);
		$('#path-pats-edit'+num).hide();
		$('#path-pats-text'+num).show();
		$('#path-pats-text'+num).text(pats);
		// this.pathTotals[num] = pats;
		// State.pathTotals[num] = this.pathTotals[num];
		pathInd = this.getPathNumber(pathName);
		this.pathTotals[pathInd] = pats;
		State.pathTotals[pathInd] = pats;
		if(pathName=='melanoma') pathName = 'mel';
		State.customPats[pathName] = pats;
		this.lastChanged = State.selPathKeys[num];
		this.updateState();
		str = '' + pats + '<b> / </b>' + State.pathSumm[pathInd];
		$('#path-num'+num).html(str);
	}

///////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}


///////////////////////////////////

	showEdit(e) {

		let num = e.target.id,
			str = num.slice(0,14);
		if(str!='path-pats-text') return;
		num = num.slice(14);
		if(this.editMode=='edit') {
			if(this.currLineNum==num) return;
			this.completeEdit();
		}

		setTimeout(() => {
			this.editMode = 'edit';
			this.currLineNum = num;
			$('#path-pats-text'+num).hide();
			$('#path-pats-edit'+num).show();
			$('#path-count-edit'+num).val(this.pathTotals[num]);
		},50);
		setTimeout(() => {
			$('#path-count-edit'+num).focus();
			let len = $('#path-count-edit'+num).val().length;
			let inp = document.getElementById('path-count-edit'+num);
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

	updateState() {

//State.flDistChanged = 1;

//		State.calc.distributePath(State.currPath);
		State.calc.distributePath(this.lastChanged);
console.log(this.lastChanged);		
State.distPanel.resetPathChanels(this.lastChanged);
		State.stPanel.render(State.currPath);
		State.calc.updateTotalPatientViews();
		State.methPanel.displayResult();
		State.methPanel.saveMethData();
	}	

///////////////////////////////////

	changeCounter(e) {

		let str = e.target.id, num, pathName, pathInd,
			methSet = this.methSet;

		if(this.editMode=='edit') {
			this.completeEdit();
		}	
		num = str.slice(0,9);

		setTimeout(() => {
			if(num=='path-minu') {
				num = str.slice(10);
				pathName = State.selPathKeys[num];
				pathInd = this.getPathNumber(pathName);
				if(this.pathTotals[num] < 1) return;
				this.pathTotals[num]--;
				this.currFreePats++;
				$('#path-pats-text'+num).text(''+this.pathTotals[num]);
				$('#path-num'+num).text(''+this.pathTotals[num]);
			}
			else if(num=='path-plus') {
				num = str.slice(9);
				pathName = State.selPathKeys[num];
				pathInd = this.getPathNumber(pathName);
				this.pathTotals[num]++;
				this.currFreePats--;
				$('#path-pats-text'+num).text(''+this.pathTotals[num]);
				$('#path-num'+num).text(''+this.pathTotals[num]);
			}
			State.pathTotals[pathInd] = this.pathTotals[num];
			if(pathName=='melanoma') pathName = 'mel';
			State.customPats[pathName] = this.pathTotals[num];
			this.lastChanged = State.selPathKeys[num];
			this.updateState();	
			str = '' + State.pathTotals[pathInd] + '<b> / </b>' + State.pathSumm[pathInd];
			$('#path-num'+num).html(str);
		}, 100);

	}

///////////////////////////////////
// Сохраняем текущее распределение пациентов по патологиям

	savePathData() {

		const key = 'mn_path';
		let dataStr, dataObj = {};

		dataObj.customPats = State.customPats;
		dataObj.totalPats = State.pathSumm;
		dataStr = JSON.stringify(dataObj);
		localStorage.setItem(key,dataStr);
	}

///////////////////////////////////
// Загружаем текущее распределение пациентов по патологиям

	loadPathData() {

		let dataStr, key = 'mn_path', dataObj = {};

		dataStr = localStorage.getItem(key);
		if(null==dataStr) {
			State.calc.resetData();
			return;
		}
		dataObj = JSON.parse(dataStr);
		State.customPats = dataObj.customPats;
		State.pathSumm = dataObj.totalPats;
	}

}

export default PathPanel;