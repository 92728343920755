;"use strict";

const trastData0 = {

		'st0_0_2': {
			group: 'Нео/адъювантная химиотерапия, HER-2 позитивный РМЖ',
			meth: [
				{
					id: 368,
					code: 'sh0058;sh0069',
					name: 'AC×4→ (D + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,трастузумаб)',
					drugs: [36,64,37,57,56],
					rate: 20,
					dose: [110,1200,160,150,440],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4],
					custKsg: 'ac4_dtr4',//
					pats: 26
				},
				{
					id: 369,
					code: 'sh0058;sh0069',
					name: 'AC×4→ (Р + трастузумаб)×12',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,57,57],
					rate: 14,
					dose: [110,1200,150,150,150],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,12,1,12],
					custKsg: 'ac4_ptr12',//
					pats: 18
				},
				{
					id: 370,
					code: 'sh0058;sh0150',
					name: 'АС×4→ (Р + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,57,56],
					rate: 5,
					dose: [110,1200,450,150,440],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4],
					custKsg: 'ac4_ptr4',//
					pats: 7
				},
				{
					id: 371,
					code: 'sh0179',
					name: '(DC + трастузумаб)×4',
					info: '(доцетаксел,циклофосфамид,трастузумаб)',
					drugs: [37,64,57,56],
					rate: 10,
					dose: [160,1200,150,440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4,4,1,4],
					pats: 13
				},
				{
					id: 372,
					code: 'sh0066',
					name: 'DCН×6',
					info: '(доцетаксел,карбоплатин,трастузумаб)',
					drugs: [37,41,57,56],
					rate: 1,
					dose: [160,450,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6,6,1,6],
					pats: 1
				},
				{
					id: 373,
					code: 'sh0058;sh0070',
					name: 'АС×4→ (D + трастузумаб + пертузумаб)x4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,<br>трастузумаб,пертузумаб)',
					drugs: [36,64,37,57,56,52],
					rate: 20,
					dose: [110,1200,160,150,440,2100,0],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4,1,3],
					custKsg: 'ac4_dtrp4',//
					pats: 26
				},
				{
					id: 374,
					code: 'sh0067',
					name: '(DCН + пертузумаб)×6',
					info: '(доцетаксел,карбоплатин,трастузумаб,пертузумаб)',
					drugs: [37,41,57,56,52],
					rate: 20,
					dose: [160,450,150,440,2940],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [6,6,1,6,1],
					pats: 27
				},
				{
					id: 375,
					code: 'sh0149',
					name: '(Р + трастузумаб)×12',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57],
					rate: 10,
					dose: [150,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [12,13],
					pats: 13
				}
			],
			pats: 0
		},
		'st0_0_3': {
			group: 'Адъювантная таргетная терапия, HER-2 позитивный РМЖ',
			meth: [
				{
					id: 376,
					code: 'sh0179',
					name: 'Трастузумаб',
					info: '(трастузумаб)',
					drugs: [56],
					rate: 95,
					dose: [440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9],
					pats: 124
				},
				{
					id: 377,
					code: 'sh0533',
					name: 'Трастузумаб + Пертузумаб',
					info: '(трастузумаб,пертузумаб)',
					drugs: [56,52],
					rate: 5,
					dose: [440,420],
					ds: 'ds19.047',//>>
					st: 'st19.072',//>>
					cycles: [13,13],
					pats: 7
				}
			],
			pats: 0
		},
		'st0_0_7': {
			group: 'Химиотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					id: 402,
					code: 'sh0145',
					name: 'паклитаксел + карбоплатин AUC2 + трастузумаб',
					info: '(паклитаксел,карбоплатин,трастузумаб)',
					drugs: [17,41,57,57],
					rate: 1,
					dose: [300.5,150,300,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6,1,24],
					pats: 1
				},
				{
					id: 403,
					code: 'sh0069',
					name: 'D + трастузумаб',
					info: '(доцетаксел,трастузумаб)',
					drugs: [37,57,56],
					rate: 15,
					dose: [160,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [10,1,10],
					pats: 20
				},
				{
					id: 404,
					code: 'sh0149',
					name: 'Р + трастузумаб (еженедельный)',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57,57],
					rate: 1,
					dose: [150,300,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [30,1,29],
					pats: 1
				},
				{
					id: 405,
					code: 'sh0577.1',
					name: 'Р + трастузумаб',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57,56],
					rate: 19,
					dose: [150,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [30,1,10],
					pats: 25
				},
				{
					id: 406,
					code: 'sh0027.1',
					name: 'винорелбин + трастузумаб',
					info: '(винорелбин,трастузумаб)',
					drugs: [32,57,56],
					rate: 7,
					dose: [100,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [10,1,10],
					pats: 9
				},
				{
					id: 407,
					code: 'sh0104',
					name: 'капецитабин + трастузумаб',
					info: '(капецитабин,трастузумаб)',
					drugs: [40,57,56],
					rate: 7,
					dose: [48000,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [8,1,8],
					pats: 9
				},
				{
					id: 408,
					code: 'sh0390',
					name: 'циклофосфамид + метотрексат + трастузумаб',
					info: '(циклофосфамид,метотрексат,трастузумаб)',
					drugs: [63,48,57,56],
					rate: 0,
					dose: [350,10,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [24,24,1,8],
					pats: 0
				},
				{
					id: 409,
					code: 'sh0418.1',
					name: 'эрибулин + трастузумаб',
					info: '(эрибулин,трастузумаб)',
					drugs: [65,57,56],
					rate: 8,
					dose: [6,150,440],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [10,1,10],
					pats: 10
				},
				{
					id: 410,
					code: 'sh0317.1',
					name: 'гемцитабин + трастузумаб',
					info: '(гемцитабин,трастузумаб)',
					drugs: [34,57,56],
					rate: 0,
					dose: [4000,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [8,1,8],
					pats: 0
				},
				{
					id: 411,
					code: 'sh0306.1',
					name: 'гемцитабин + цисплатин + трастузумаб',
					info: '(гемцитабин,цисплатин,трастузумаб)',
					drugs: [34,66,57,56],
					rate: 7,
					dose: [4000,300,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,1,8],
					pats: 9
				},
				{
					id: 412,
					code: 'sh0070',
					name: 'D + трастузумаб + пертузумаб',
					info: '(доцетаксел,трастузумаб,пертузумаб)',
					drugs: [37,57,56,52],
					rate: 15,
					dose: [160,150,440,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [12,1,12,13],
					pats: 20
				},
				{
					id: 413,
					code: 'sh0576.1',
					name: 'P + трастузумаб + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,57,56,52],
					rate: 14,
					dose: [150,150,440,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,1,12,13],
					pats: 18
				},
				{
					id: 414,
					code: 'sh0576.1',
					name: 'P + трастузумаб (еженед) + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,57,57,52],
					rate: 1,
					dose: [150,300,150,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,1,36,13],
					pats: 1
				},
				{
					id: 415,
					code: 'sh0181',
					name: 'трастузумаб эмтанзин',
					info: '(трастузумаб,эмтанзин)',
					drugs: [58,59],
					rate: 20,
					dose: [100,160],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8,8],
					pats: 27
				},
				-1,
			],
			pats: 0
		},
		'st0_0_8': {
			group: 'Гормонотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					id: 417,
					code: 'sh0538',
					name: 'Фулвестрант + трастузумаб',
					info: '(фулвестрант,трастузумаб)',
					drugs: [62,57,56],
					rate: 20,
					dose: [500,150,440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [10,1,12],
					pats: 8
				},
				{
					id: 418,
					code: 'sh0293',
					name: 'Анастрозол + трастузумаб',
					info: '(анастрозол,трастузумаб)',
					drugs: [29,57,56],
					rate: 15,
					dose: [30,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [9,1,12],
					pats: 6
				},
				{
					id: 419,
					code: '-',
					name: 'Летрозол + трастузумаб',
					info: '(летрозол,трастузумаб)',
					drugs: [44,57,56],
					rate: 15,
					dose: [30,150,440],
					ds: '',//>>
					st: '',
					cycles: [9,1,12],
					pats: 6
				},
				{
					id: 420,
					code: '-',
					name: 'Эксеместан + трастузумаб',
					info: '(эксеместан,трастузумаб)',
					drugs: [68,57,56],
					rate: 10,
					dose: [750,150,440],
					ds: '',//>>
					st: '',
					cycles: [9,1,12],
					pats: 4
				},
				{
					id: 421,
					code: 'sh0372',
					name: 'Тамоксифен + трастузумаб',
					info: '(тамоксифен,трастузумаб)',
					drugs: [54,57,56],
					rate: 30,
					dose: [600,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [7,1,10],
					pats: 12
				},				
				-1,
				-1,
				-1,
				{
					id: 425,
					code: 'sh0112',
					name: 'Анастрозол + трастузумаб + лапатиниб',
					info: '(анастрозол,трастузумаб,лапатиниб)',
					drugs: [29,57,56,42],
					rate: 5,
					dose: [30,150,440,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 2
				},
				{
					id: 426,
					code: '-',
					name: 'Летрозол + трастузумаб + лапатиниб',
					info: '(летрозол,трастузумаб,лапатиниб)',
					drugs: [44,57,56,42],
					rate: 5,
					dose: [30,150,440,42000],
					ds: '',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 2
				},
				{
					id: 427,
					code: 'sh0112',
					name: 'Эксеместан + трастузумаб + лапатиниб',
					info: '(эксеместан,трастузумаб,лапатиниб)',
					drugs: [68,57,56,42],
					rate: 1.1,
					dose: [750,150,440,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 0
				}
			],
			pats: 0
		},
	};	

export default trastData0;