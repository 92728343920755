; "use strict";

import State from './state';
import drugData from './drugdata';
import rpData from './rp-meth';
import stData from './rp-st';

class RpModel {

	constructor() {

	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = rpData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				if(methSet[cnt].custKsg) {
					let custSet = State.calc.getCustomKSGSet(methSet[cnt].custKsg, 'st');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.stIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc; // Округление не делаем, т.к. в этой модели нет неценых циклов
						State.nOfCasesKs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.stIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesKs += nOfPatients*cyclesRound;
					if(methSet[cnt].st && methSet[cnt].st!='') {
						State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
					}
				}
			}
			if(chan=='omsd') {		
				if(methSet[cnt].custKsg) {
					let custSet = State.calc.getCustomKSGSet(methSet[cnt].custKsg, 'ds');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.dsIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc;
						State.nOfCasesDs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.dsIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesDs += nOfPatients*cyclesRound;
					if(methSet[cnt].ds && methSet[cnt].ds!='') {
						State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
					}
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// порядке
// Отдаем приоритет позициям с большей вероятностью!!
		rest = input ; // Нераспределенный остаток

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);

			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData;

		if(State.flNoDist) return; // Отладочный режим

		pathData = rpData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
//		State.calc.disributePats(total, probBuf, patBuf);
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

// Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
		let pathData = rpData;
		let currGroup, currSumm,i,customVal;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in pathData) {
// 			currSumm = this.summMelGroupPats(currGroup);
// 			currSumm = Math.ceil(currSumm);
// // Заносим сумму пациентов в данные группы
// //!!D
// 			if(!State.flNoDist)	pathData[currGroup].pats = currSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
		}
		for(currGroup in pathData) {
			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
			else pathSumm += pathData[currGroup].result;
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[6] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = rpData, currGroup;
		let stPats = [], total = State.pathTotals[6];
		let stProbs = [47.9, 16, 15.5, 19.1];

		stProbs = stData[State.currRegNum];
		const recidProbs = [5, 8, 25, 0];
		let recidSumm = 0, st4Line1, st4Line2, st4Line3,
			dead = State.currLeth['rshm'], i;
			for(i=0 ; i<4 ; i++) {
				stPats[i] = total*stProbs[i]/100.0;
				recidSumm += stPats[i]*recidProbs[i]/100.0;
			}

		st4Line1 = stPats[3] + recidSumm;
		st4Line1 *= 0.95;
		st4Line2 = st4Line1*0.6;	
		st4Line3 = st4Line2*0.4;
		let st4Line1a, st4Line1b, st4Line1c, st4Line1d;	

		st4Line1a = st4Line1*0.18;
		st4Line1b = st4Line1*0.405;
		st4Line1c = st4Line1*0.315;
		st4Line1d = st4Line1*0.1;

// IV стадия, линия 1 Хороший прогноз
		Data['st4_1_1'].pats = Math.round(st4Line1a);

// IV стадия, линия 1 Промежуточный прогноз
		Data['st4_1_2'].pats = Math.round(st4Line1b);

// IV стадия, линия 1 Плохой прогноз
		Data['st4_1_3'].pats = Math.round(st4Line1c);

// IV стадия, линия 1 Несветлоклеточный рак
		Data['st4_1_4'].pats = Math.round(st4Line1d);

// IV стадия, линия 2
		Data['st4_2_5'].pats = Math.round(st4Line2);

// IV стадия, линия 3
		Data['st4_3_6'].pats = Math.round(st4Line3);

		for(currGroup in Data) Data[currGroup].result = -1;
		this.distributeAllMeth();
	}
		

}

export default RpModel;