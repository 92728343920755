
"use strict";
let stData = {
	1: [47.5,17.6,10.8,24.1],
	2: [45.7,15.1,9.7,29],
	3: [54.6,12,14.4,18.5],
	4: [50.8,12.9,18.2,18.2],
	5: [58.8,13.4,13.9,12.9],
	6: [45.2,15.5,14.6,23.3],
	7: [60.1,14.5,5.1,20.3],
	8: [47.3,9.9,11,31.9],
	9: [50.4,19,14,16.5],
	10: [47.6,14.4,11.8,25.7],
	11: [56.1,11.1,16.6,16.2],
	12: [42.9,19.2,16.8,18.4],
	13: [40.2,12.1,17.4,29.5],
	14: [47.3,18.1,16,17],
	15: [31.5,20.6,21.2,26.7],
	16: [35.8,25.8,22.6,15.1],
	17: [44.4,12.8,14.8,28],
	18: [54.8,8.8,16.4,20],
	19: [33.3,16.7,33.3,16.7],
	20: [49.1,7.7,24.1,16.4],
	21: [30.9,28.7,19.3,16.6],
	22: [56,12.1,10.6,16.3],
	23: [51.7,19.4,12.6,14.8],
	24: [40.4,21.8,17.6,15.5],
	25: [65.5,8.2,11.1,14],
	26: [44.4,5.6,23.1,25],
	27: [42.9,25.5,9.2,22.4],
	28: [69,5.2,7.8,18.1],
	29: [49.4,18.9,6.7,25],
	30: [49.3,10.7,15.9,23.9],
	31: [55.1,15.3,2.5,26.3],
	32: [46.6,20.9,11.5,19.6],
	33: [41.4,17.9,15.6,24.3],
	34: [41.4,18.6,11.4,27.1],
	35: [31.4,22.9,25.7,20],
	36: [37.5,22.3,18.9,21.3],
	37: [29.9,17.9,11.9,29.9],
	38: [50.3,19.9,11,15],
	39: [28.9,34.2,26.3,10.5],
	40: [36.2,20.6,29.1,14.2],
	41: [35.2,19.8,22,19.8],
	42: [42.6,11.8,26.5,19.1],
	43: [54.8,11.9,16.7,16.7],
	44: [35.1,33.8,13.5,17.6],
	45: [51.9,12.9,18.7,16.5],
	46: [48.7,17.6,7,23.8],
	47: [54.7,10.9,12.9,21.4],
	48: [41.6,14,29.5,14.5],
	49: [56.4,9.1,12,21.6],
	50: [45,18.3,16.8,15.6],
	51: [44.6,15.4,20.3,19.7],
	52: [45.3,7.1,18.8,28.2],
	53: [50.6,13.5,15,20.9],
	54: [45.7,20.2,4.3,24.5],
	55: [64.7,12.5,9.2,13.6],
	56: [44.9,21.6,10.8,22.6],
	57: [54.4,14,11,20.6],
	58: [63.9,8.3,3,24.9],
	59: [56.4,12.3,10.2,20.3],
	60: [63.5,6.8,10.8,16.2],
	61: [48.5,20.5,12.3,18.1],
	62: [51.1,15.9,10.1,22.9],
	63: [62.7,6.5,12.3,18.5],
	64: [54.9,12.6,8.2,23.6],
	65: [51.6,14.8,11.7,21.1],
	66: [43.6,14.5,26.6,13.1],
	67: [62,7.9,9.8,20.1],
	68: [41.7,16.8,20.9,18.6],
	69: [46.4,17.6,13.8,22.2],
	70: [50,7,21.6,19.4],
	71: [39.8,11.7,15.2,33.3],
	74: [34.5,27.6,10.3,17.2],
	75: [13.5,21.6,29.7,29.7],
	76: [46.2,5.7,26.4,20.8],
	77: [48.8,14,11.4,24.1],
	78: [51.5,14.1,12.2,22.1],
	79: [53.8,12.6,11.9,19.6],
	80: [61,8.5,13.6,15.3],
	81: [55.6,7.4,11.1,25.9],
	82: [54.7,8.6,13.3,23.4],
	72: [55.2,9.2,17.2,18.4],
	83: [50,20,10,20],
	73: [43.9,28.8,11.5,13.7],
	84: [51.7,15.4,8.7,24.2],
	85: [75,5,10,10]
};
	
export default stData;