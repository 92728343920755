;"use strict";

const rlData = {

// ============================>rl
// ---------> МРЛ
		'st1_0_1':{
			group: 'МРЛ',
			meth: [
				{
					id: 447,
					code: 'sh0556',
					name: 'EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 50,
					dose: [600,150],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 6
				},
				{
					id: 448,
					code: 'sh0555',
					name: 'ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 50,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> МРЛ
		'st2_0_1':{
			group: 'МРЛ',
			meth: [
				{
					id: 449,
					code: 'sh0556',
					name: 'EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 100,
					dose: [600,150],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 12
				},
				{
					id: 450,
					code: 'sh0555',
					name: 'ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 0,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> НМРЛ
		'st2_0_2':{
			group: 'НМРЛ',
			meth: [
				{
					id: 451,
					code: 'sh0871',
					name: 'Этопозид + цисплатин',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 5,
					dose: [600,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 3
				},
				{
					id: 452,
					code: 'sh0028.1',
					name: 'Винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 20,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 11
				},
				{
					id: 453,
					code: 'sh0634.1',
					name: 'Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 12.5,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 7
				},
				{
					id: 454,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 50,
					dose: [400,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 27
				},
				{
					id: 455,
					code: 'sh0162',
					name: 'Пеметрексед + цисплатин',
					info: '(пеметрексед, цисплатин)',
					drugs: [76,66],
					rate: 12.5,
					dose: [900,150],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 7
				}
			],
			pats: 0
		},

// ---------> МРЛ
		'st3_0_1':{
			group: 'МРЛ',
			meth: [
				{
					id: 456,
					code: 'sh0556',
					name: 'EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 100,
					dose: [600,150],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 18
				},
				{
					id: 457,
					code: 'sh0555',
					name: 'ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 0,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> НМРЛ неоадъювантная терапия
		'st3_0_2':{
			group: 'НМРЛ неоадъювантная терапия',
			meth: [
				{
					id: 458,
					code: 'sh0704.1',
					name: '[НМРЛ неоадъювантная] Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [83,66],
					rate: 20,
					dose: [4800,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 3
				},
				{
					id: 459,
					code: 'sh0028.1',
					name: '[НМРЛ неоадъювантная] Винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 20,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 3
				},
				{
					id: 460,
					code: 'sh0672',
					name: '[НМРЛ неоадъювантная] Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [50,41],
					rate: 50,
					dose: [300,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 6
				},
				{
					id: 461,
					code: 'sh0643',
					name: '[НМРЛ неоадъювантная] Доцетаксел + карбоплатин',
					info: '(доцетаксел, карбоплатин)',
					drugs: [87,41],
					rate: 10,
					dose: [140,450],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 462,
					code: 'sh0644',
					name: '[НМРЛ неоадъювантная] Доцетаксел + цисплатин',
					info: '(доцетаксел, цисплатин)',
					drugs: [87,66],
					rate: 0,
					dose: [140,150],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> НМРЛ адъювантная терапия
		'st3_0_3':{
			group: 'НМРЛ адъювантная терапия',
			meth: [
				{
					id: 463,
					code: 'sh0871',
					name: '[НМРЛ адъювантная] Этопозид + цисплатин',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 5,
					dose: [600,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 1
				},
				{
					id: 464,
					code: 'sh0028.1',
					name: '[НМРЛ адъювантная] Винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 20,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 4
				},
				{
					id: 465,
					code: 'sh0634.1',
					name: '[НМРЛ адъювантная] Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 12.5,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 3
				},
				{
					id: 466,
					code: 'sh0672',
					name: '[НМРЛ адъювантная] Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 50,
					dose: [400,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 9
				},
				{
					id: 467,
					code: 'sh0162',
					name: '[НМРЛ адъювантная] Пеметрексед + цисплатин',
					info: '(пеметрексед, цисплатин)',
					drugs: [76,66],
					rate: 12.5,
					dose: [900,150],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> НМРЛ ХЛТ
		'st3_0_4':{
			group: 'НМРЛ ХЛТ',
			meth: [
				{
					id: 468,
					code: '-',
					name: '[НМРЛ ХЛТ] Паклитаксел + карбоплатин + ЛТ',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 5,
					dose: [400,450],
					ds: '',
					st: '',
					cycles: [1,1],
					pats: 3
				},
				{
					id: 469,
					code: '-',
					name: '[НМРЛ ХЛТ] Этопозид + цисплатин + ЛТ',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 5,
					dose: [500,200],
					ds: '',
					st: '',
					cycles: [1,1],
					pats: 3
				},
				{
					id: 470,
					code: 'sh0556',
					name: '[НМРЛ ХЛТ] EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 9,
					dose: [600,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 471,
					code: 'sh0555',
					name: '[НМРЛ ХЛТ] ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 9,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 472,
					code: 'sh0028.1',
					name: '[НМРЛ ХЛТ] Винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 9,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 473,
					code: 'sh0672',
					name: '[НМРЛ ХЛТ] Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 27,
					dose: [400,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 14
				},
				{
					id: 474,
					code: 'sh0704.1',
					name: '[НМРЛ ХЛТ] Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [83,66],
					rate: 9,
					dose: [4800,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 475,
					code: 'sh0634.1',
					name: '[НМРЛ ХЛТ] Гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 9,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 476,
					code: 'sh0162',
					name: '[НМРЛ ХЛТ] Пеметрексед + цисплатин',
					info: '(пеметрексед, цисплатин)',
					drugs: [76,66],
					rate: 9,
					dose: [900,150],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 477,
					code: 'sh0701',
					name: '[НМРЛ ХЛТ] Пеметрексед + карбоплатин',
					info: '(пеметрексед, карбоплатин)',
					drugs: [76,41],
					rate: 9,
					dose: [900,450],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 478,
					code: 'sh0872',
					name: '[НМРЛ ХЛТ] Дурвалумаб',
					info: '(дурвалумаб)',
					drugs: [142,143],
					rate: 60,
					dose: [500,240],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [26,26],
					pats: 25
				}
			],
			pats: 0
		},

// ---------> МРЛ Линия 1
		'st4_1_1':{
			group: 'МРЛ',
			meth: [
				{
					id: 479,
					code: 'sh0450',
					name: 'ЕС + атезолизумаб',
					info: '(этопозид, карбоплатин, атезолизумаб)',
					drugs: [79,41,71],
					rate: 50,
					dose: [600,450,1200],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [4,4,8],
					pats: 21
				},
				{
					id: 480,
					code: 'sh0556',
					name: 'EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 20,
					dose: [600,150],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 8
				},
				{
					id: 481,
					code: 'sh0555',
					name: 'ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 20,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 8
				},
				{
					id: 482,
					code: 'sh0702.1',
					name: 'IP',
					info: '(иринотекан, цисплатин)',
					drugs: [25,66],
					rate: 10,
					dose: [240,150],
					ds: 'ds19.038',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 4
				}
			],
			pats: 0
		},

// ---------> НМРЛ EGFRmut Линия 1
		'st4_1_2':{
			group: 'НМРЛ EGFRmut',
			meth: [
				{
					id: 483,
					code: 'sh0491',
					name: '[EGFRmut] осимертиниб',
					info: '(осимертиниб)',
					drugs: [75],
					rate: 50,
					dose: [2400],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [12],
					pats: 9
				},
				{
					id: 484,
					code: 'sh0046',
					name: '[EGFRmut] гефитиниб',
					info: '(гефитиниб)',
					drugs: [73],
					rate: 30,
					dose: [7500],
					ds: 'ds19.044',//>>
					st: '',
					cycles: [8],
					pats: 6
				},
				{
					id: 485,
					code: 'sh0256',
					name: '[EGFRmut] эрлотиниб',
					info: '(эрлотиниб)',
					drugs: [78],
					rate: 10,
					dose: [4500],
					ds: 'ds19.044',//>>
					st: '',
					cycles: [10.4],
					pats: 2
				},
				{
					id: 486,
					code: 'sh0010',
					name: '[EGFRmut] афатиниб',
					info: '(афатиниб)',
					drugs: [72],
					rate: 10,
					dose: [1200],
					ds: 'ds19.045',//>>
					st: '',
					cycles: [11.1],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> НМРЛ ALKmut Линия 1
		'st4_1_3':{
			group: 'НМРЛ ALKmut',
			meth: [
				{
					id: 487,
					code: 'sh0592',
					name: '[ALKmut] алектиниб',
					info: '(алектиниб)',
					drugs: [70],
					rate: 90,
					dose: [33600],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [13],
					pats: 6
				},
				{
					id: 488,
					code: 'sh0109',
					name: '[ALKmut] кризотиниб',
					info: '(кризотиниб)',
					drugs: [74],
					rate: 5,
					dose: [15000],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [10.9],
					pats: 0
				},
				{
					id: 489,
					code: 'sh0539',
					name: '[ALKmut] церитиниб',
					info: '(церитиниб)',
					drugs: [77],
					rate: 5,
					dose: [13500],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> НМРЛ ROSmut Линия 1
		'st4_1_4':{
			group: 'НМРЛ ROSmut',
			meth: [
				{
					id: 490,
					code: 'sh0109',
					name: '[ROS mut] кризотиниб',
					info: '(кризотиниб)',
					drugs: [74],
					rate: 100,
					dose: [15000],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [10.9],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> BRAFmut Линия 1
		'st4_1_5':{
			group: 'BRAFmut',
			meth: [
				{
					id: 491,
					code: 'sh0049',
					name: '[BRAF mut] дабрафениб + траметиниб',
					info: '(дабрафениб, траметиниб)',
					drugs: [4,22],
					rate: 100,
					dose: [9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [9,9],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> НМРЛ PDL-1 >50% Линия 1
		'st4_1_6':{
			group: 'НМРЛ PDL-1 >50%',
			meth: [
				{
					id: 492,
					code: 'sh0504',
					name: '[ИТ (при PDL-1 >50%)] пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 100,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [17],
					pats: 23
				}
			],
			pats: 0
		},

// ---------> НМРЛ Линия 1
		'st4_1_7':{
			group: 'НМРЛ',
			meth: [
				{
					id: 493,
					code: 'sh0593;sh0594',
					name: '[IT+ХТ] атезолизумаб + паклитаксел + карбоплатин AUC  + бевацизумаб',
					info: '(атезолизумаб, паклитаксел, карбоплатин AUC, бевацизумаб)',
					drugs: [71,17,41,2],
					rate: 8.26,
					dose: [1200,400,450,1200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [12,4,4,12],
					custKsg: '593_594',//
					pats: 18
				},
				{
					id: 494,
					code: 'sh0595;sh0597',
					name: '[IT+ХТ] пембролизумаб + пеметрексед  + цисплатин',
					info: '(пембролизумаб, пеметрексед, цисплатин)',
					drugs: [20,76,66],
					rate: 8.26,
					dose: [200,900,150],
					ds: '',
					st: '',
					cycles: [10,10,4],
					custKsg: '595_597',//
					pats: 18
				},
				{
					id: 495,
					code: 'sh0596;sh0597',
					name: '[IT+ХТ] пембролизумаб + пеметрексед + карбоплатин',
					info: '(пембролизумаб, пеметрексед, карбоплатин)',
					drugs: [20,76,41],
					rate: 8.26,
					dose: [200,900,450],
					ds: 'ds19.049',
					st: 'st19.073',
					cycles: [10,10,4],
					custKsg: '596_597',//
					pats: 18
				},
				{
					id: 496,
					code: 'sh0954;sh0504',
					name: '[IT+ХТ] пембролизумаб + паклитаксел + карбоплатин',
					info: '(пембролизумаб, паклитаксел, карбоплатин)',
					drugs: [20,17,41],
					rate: 16.53,
					dose: [200,400,450],
					ds: 'ds19.048',
					st: 'st19.073',
					cycles: [10,4,4],
					custKsg: '954_504',//
					pats: 36
				},
				{
					id: 497,
					code: 'sh0556',
					name: '[ХТ] EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 5.87,
					dose: [600,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 498,
					code: 'sh0555',
					name: '[ХТ] ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 5.87,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 499,
					code: 'sh0028.1',
					name: '[ХТ] винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 5.87,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 500,
					code: 'sh0672',
					name: '[ХТ] паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 14.08,
					dose: [400,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 31
				},
				{
					id: 501,
					code: 'sh0497;sh011',
					name: '[ХТ] паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел, карбоплатин, бевацизумаб)',
					drugs: [17,41,2],
					rate: 3.52,
					dose: [400,450,1200],
					ds: 'ds19.068',//>>
					st: 'st19.067',//>>
					cycles: [4,4,5],
					custKsg: '497_011',//
					pats: 8
				},
				{
					id: 502,
					code: 'sh0704.1',
					name: '[ХТ] гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [83,66],
					rate: 5.87,
					dose: [4800,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 503,
					code: 'sh0634.1',
					name: '[ХТ] гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 5.87,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 13
				},
				{
					id: 504,
					code: 'sh0162',
					name: '[ХТ] пеметрексед  + цисплатин',
					info: '(пеметрексед, цисплатин)',
					drugs: [76,66],
					rate: 4.69,
					dose: [900,150],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 10
				},
				{
					id: 505,
					code: 'sh0701',
					name: '[ХТ] пеметрексед + карбоплатин',
					info: '(пеметрексед, карбоплатин)',
					drugs: [76,41],
					rate: 4.69,
					dose: [900,450],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 10
				},
				{
					id: 506,
					code: 'sh0163',
					name: '[ХТ] пеметрексед + цисплатин + бевацизумаб',
					info: '(пеметрексед, цисплатин, бевацизумаб)',
					drugs: [76,66,2,26],
					rate: 1.17,
					dose: [900,150,400,200],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [10,4,10,10],
					pats: 3
				},
				{
					id: 507,
					code: 'sh0601',
					name: '[ХТ] пеметрексед + карбоплатин + бевацизумаб',
					info: '(пеметрексед, карбоплатин, бевацизумаб)',
					drugs: [76,41,2,26],
					rate: 1.17,
					dose: [900,450,400,200],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [10,4,10,10],
					pats: 3
				},
				{
					id: 508,
					code: 'sh0025.1',
					name: '[ХТ] винорелбин',
					info: '(винорелбин)',
					drugs: [32],
					rate: 0,
					dose: [150],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 509,
					code: 'sh0161',
					name: '[ХТ] пеметрексед',
					info: '(пеметрексед)',
					drugs: [76],
					rate: 0,
					dose: [900],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 510,
					code: 'sh0632.1',
					name: '[ХТ] гемцитабин',
					info: '(гемцитабин)',
					drugs: [83],
					rate: 0,
					dose: [4800],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 511,
					code: 'sh0705',
					name: '[ХТ] доцетаксел',
					info: '(доцетаксел)',
					drugs: [87],
					rate: 0,
					dose: [140],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 512,
					code: 'sh9001',
					name: '[ХТ] этопозид',
					info: '(этопозид)',
					drugs: [79],
					rate: 0,
					dose: [600],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> МРЛ Линия 2
		'st4_2_1':{
			group: 'МРЛ',
			meth: [
				{
					id: 513,
					code: 'sh0677',
					name: 'CAV',
					info: '(циклофосфамид, доксорубицин, винкристин)',
					drugs: [64,36,80],
					rate: 50,
					dose: [1800,90,3],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					pats: 8
				},
				{
					id: 514,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан)',
					drugs: [81],
					rate: 50,
					dose: [15],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 8
				}
			],
			pats: 0
		},

// ---------> НМРЛ c драйверными мутациями Линия 2
		'st4_2_2':{
			group: 'НМРЛ c драйверными мутациями',
			meth: [
				{
					id: 515,
					code: 'sh0046',
					name: '[EGFRmut] гефитиниб',
					info: '(гефитиниб)',
					drugs: [73],
					rate: 0,
					dose: [7500],
					ds: 'ds19.044',//>>
					st: '',
					cycles: [2.2],
					pats: 0
				},
				{
					id: 516,
					code: 'sh0256',
					name: '[EGFRmut] эрлотиниб',
					info: '(эрлотиниб)',
					drugs: [78],
					rate: 0,
					dose: [4500],
					ds: 'ds19.044',//>>
					st: '',
					cycles: [2.3],
					pats: 0
				},
				{
					id: 517,
					code: 'sh0010',
					name: '[EGFRmut] афатиниб',
					info: '(афатиниб)',
					drugs: [72],
					rate: 0,
					dose: [1200],
					ds: 'ds19.045',//>>
					st: '',
					cycles: [2.4],
					pats: 0
				},
				{
					id: 518,
					code: 'sh0491',
					name: '[EGFRmut] осимертиниб',
					info: '(осимертиниб)',
					drugs: [75],
					rate: 86.44,
					dose: [2400],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [10.1],
					pats: 6
				},
				{
					id: 519,
					code: 'sh0592',
					name: '[ALKmut] алектиниб',
					info: '(алектиниб)',
					drugs: [70],
					rate: 0,
					dose: [33600],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [9.96],
					pats: 0
				},
				{
					id: 520,
					code: 'sh0539',
					name: '[ALKmut] церитиниб',
					info: '(церитиниб)',
					drugs: [77],
					rate: 13.56,
					dose: [13500],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [6.9],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> НМРЛ Линия 2
		'st4_2_3':{
			group: 'НМРЛ',
			meth: [
				{
					id: 521,
					code: 'sh0593;sh0594',
					name: '[ИТ+ХТ] атезолизумаб + паклитаксел + карбоплатин AUC  + бевацизумаб',
					info: '(атезолизумаб, паклитаксел, карбоплатин AUC, бевацизумаб)',
					drugs: [71,17,41,2],
					rate: 1.86,
					dose: [1200,400,450,1200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [12,4,4,12],
					custKsg: '593_594',//
					pats: 2
				},
				{
					id: 522,
					code: 'sh0504',
					name: '[ИТ] пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 10.84,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [5],
					pats: 13
				},
				{
					id: 523,
					code: 'sh0450',
					name: '[ИТ] атезолизумаб',
					info: '(атезолизумаб)',
					drugs: [71],
					rate: 21.68,
					dose: [1200],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [5],
					pats: 26
				},
				{
					id: 524,
					code: 'sh0661',
					name: '[ИТ] ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 21.68,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [7,7],
					pats: 24
				},
				{
					id: 525,
					code: 'sh0556',
					name: '[ХТ] EP',
					info: '(этопозид, цисплатин)',
					drugs: [79,66],
					rate: 3.95,
					dose: [600,200],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 526,
					code: 'sh0555',
					name: '[ХТ] ЕС',
					info: '(этопозид, карбоплатин)',
					drugs: [79,41],
					rate: 3.95,
					dose: [600,450],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 527,
					code: 'sh0028.1',
					name: '[ХТ] винорелбин + цисплатин',
					info: '(винорелбин, цисплатин)',
					drugs: [32,66],
					rate: 3.95,
					dose: [100,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 528,
					code: 'sh0672',
					name: '[ХТ] паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 9.23,
					dose: [400,450],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 11
				},
				{
					id: 529,
					code: 'sh0497;sh0011',
					name: '[ХТ] паклитаксел + карбоплатин + бевацизумаб',
					info: '(паклитаксел, карбоплатин, бевацизумаб)',
					drugs: [17,41,2],
					rate: 2.64,
					dose: [400,450,1200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [4,4,4],
					custKsg: '497_011',//
					pats: 3
				},
				{
					id: 530,
					code: 'sh0704.1',
					name: '[ХТ] гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [83,66],
					rate: 3.95,
					dose: [4800,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 531,
					code: 'sh0634.1',
					name: '[ХТ] гемцитабин + карбоплатин',
					info: '(гемцитабин, карбоплатин)',
					drugs: [34,41],
					rate: 3.95,
					dose: [4000,450],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 532,
					code: 'sh0162',
					name: '[ХТ] пеметрексед  + цисплатин',
					info: '(пеметрексед, цисплатин)',
					drugs: [76,66],
					rate: 3.08,
					dose: [900,150],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 4
				},
				{
					id: 533,
					code: 'sh0701',
					name: '[ХТ] пеметрексед + карбоплатин',
					info: '(пеметрексед, карбоплатин)',
					drugs: [76,41],
					rate: 3.08,
					dose: [900,450],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [4,4],
					pats: 4
				},
				{
					id: 534,
					code: 'sh0163',
					name: '[ХТ] пеметрексед + цисплатин + бевацизумаб',
					info: '(пеметрексед, цисплатин, бевацизумаб)',
					drugs: [76,66,2,26],
					rate: 0.88,
					dose: [900,150,400,200],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4,4,4,4],
					pats: 1
				},
				{
					id: 535,
					code: 'sh0601',
					name: '[ХТ] пеметрексед + карбоплатин + бевацизумаб',
					info: '(пеметрексед, карбоплатин, бевацизумаб)',
					drugs: [76,41,2,26],
					rate: 0.88,
					dose: [900,450,400,200],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4,4,4,4],
					pats: 1
				},
				{
					id: 536,
					code: 'sh0465',
					name: '[ХТ] Доцетаксел + нинтеданиб',
					info: '(доцетаксел, нинтеданиб)',
					drugs: [87,144],
					rate: 4.39,
					dose: [140,8000],
					ds: 'ds19.046',//>>
					st: 'st19.071',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 537,
					code: 'sh0025.1',
					name: '[ХТ] винорелбин',
					info: '(винорелбин)',
					drugs: [32],
					rate: 0,
					dose: [150],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 538,
					code: 'sh0161',
					name: '[ХТ] пеметрексед',
					info: '(пеметрексед)',
					drugs: [76],
					rate: 0,
					dose: [900],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 539,
					code: 'sh0632.1',
					name: '[ХТ] гемцитабин',
					info: '(гемцитабин)',
					drugs: [83],
					rate: 0,
					dose: [4800],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 540,
					code: 'sh0705',
					name: '[ХТ] доцетаксел',
					info: '(доцетаксел)',
					drugs: [87],
					rate: 0,
					dose: [140],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 541,
					code: 'sh9001',
					name: '[ХТ] этопозид',
					info: '(этопозид)',
					drugs: [79],
					rate: 0,
					dose: [600],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				}
			],
			pats: 0
		}
	};	

export default rlData;
