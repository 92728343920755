
; "use strict";

var drugTable = [
	{ name: '5-фторурацил',					price: 295.69, 	list: 1, dose: 1000, set: 1 }, // 0
	{ name: 'Афлиберцепт',					price: 1, 	list: 1, dose: 0, set: 0 }, // 1
	{ name: 'Бевацизумаб',					price: 1, 	list: 1, dose: 0, set: 0 }, // 2
	{ name: 'Вемурафениб 240 мг, 56 таб.',	price: 45000, 	list: 1, dose: 240, set: 56 }, // 3
	{ name: 'Дабрафениб 75 мг, 120 капс.',	price: 230000, 	list: 1, dose: 75, set: 120 }, // 4
	{ name: 'Дакарбазин, 1000 мг',			price: 2000, 	list: 1, dose: 1000, set: 1 }, // 5
	{ name: 'Иматиниб 100 мг (120 капсул)',	price: 69000, 	list: 1, dose: 100, set: 120 }, // 6
	{ name: 'Интерферон, 3 млн',			price: 1011, 	list: 1, dose: 1, set: 5 }, // 7
	{ name: 'Ипилимумаб, 50 мг (10 мл)',	price: 160000, 	list: 1, dose: 50, set: 1 }, // 8
	{ name: 'Иринотекан',					price: 1, 	list: 1, dose: 0, set: 0 }, // 9
	{ name: 'Капецитабин',					price: 9448, 	list: 1, dose: 500, set: 120 }, // 10
	{ name: 'Карбоплатин 450 мг, 45 мл',	price: 2600, 	list: 1, dose: 450, set: 1 }, // 11
	{ name: 'Кобиметиниб 20 мг, 63 капс.',	price: 209000, 	list: 0, dose: 20, set: 63 }, // 12
	{ name: 'Лейковорин',					price: 677,		list: 1, dose: 25, set: 5 }, // 13
	{ name: 'Ниволумаб, 100 мг (10 мл)',	price: 104770, 	list: 1, dose: 100, set: 1 }, // 14
	{ name: 'Ниволумаб, 40 мг (4 мл)',		price: 37968, 	list: 1, dose: 40, set: 1 }, // 15
	{ name: 'Оксалиплатин',					price: 5366,	list: 1, dose: 150, set: 1 }, // 16
	{ name: 'Паклитаксел 100 мг, 16,7 мл',	price: 3000, 	list: 1, dose: 100, set: 1 }, // 17
	{ name: 'Паклитаксел 30 мг, 6 мл',		price: 1500, 	list: 1, dose: 30, set: 1 }, // 18
	{ name: 'Панитумумаб',					price: 1, 	list: 1, dose: 0, set: 0 }, // 19
	{ name: 'Пембролизумаб, 100 мг (4 мл)',	price: 170475, 	list: 1, dose: 100, set: 1 }, // 20
	{ name: 'Регорафениб',					price: 1, 	list: 1, dose: 0, set: 0 }, // 21
	{ name: 'Траметиниб 2мг, 32 таб.',		price: 87000, 	list: 1, dose: 2, set: 32 }, // 22
	{ name: 'Цетуксимаб',					price: 1, 	list: 1, dose: 0, set: 0 } 	// 23
];

export default drugTable;