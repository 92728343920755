; "use strict";

import State from './state';
import drugData from './drugdata';
import methData from './rya-meth';
import stData from './rya-st';

class RyaModel {

	constructor() {

		let group;

		for(group in methData) State.ryaPatsBuf[group] = -1;

	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice,  cyclesRound,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = methData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// 			!! Для дополнительных пациентов вероятность приходить в отрицательном значении,
//				чтобы расположить их первыми в алгоритме ранжирования
// patByf - ссылка на массив распределенных пациентов
// extra - дополнительные фиктивные пациенты, необходимые для расчета медикаментов,
//		добавляемых сверх реального распределения, например - амброксол. Отдельной 
//		группы пациентов с амброксолом нет, но 20% пациентов его получает, независимо
//		от того, в какую группу они попадают. 

// Версия с обратным проходом от маленьких вероятностей к большим
// из модуля РП

	disributePats(input, probBuf, patBuf, extra) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];
		let extraRest = extra;					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в возрастающем
// порядке
// Отдаем приоритет позициям с меньшей вероятностью!!
		rest = input; // Нераспределенный остаток
		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			if(data<0) { 
				data = 0 - data;
				pats = Math.round(data);
				if(pats>extraRest) pats = extraRest;
				patBuf[probList[i]] = pats;
				extraRest -= pats;
				if(extraRest<0) extraRest = 0;
			} else {
//				pats = Math.round(data);
pats = Math.round(data-0.4); // Ручная подстройка под олапариб!!!!
				if(pats>rest) pats = rest;
				patBuf[probList[i]] = pats;
				rest -= pats;
				if(rest<0) rest = 0;
			}	
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
		if(extraRest>0) patBuf[probList[0]] += extraRest;
	}
 
/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам с недостатком

	distributeCutMeth(group, ratio) {

		let pathData;

		if(State.flNoDist)	return;

		pathData = methData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, extraPats, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		total = pathData[group].pats;
		total = Math.round(total);
		len = methSet.length;
		extraPats = 0;
		for(i=0 ; i<len ; i++) {
			if(methSet[i].rate<0) extraPats += Math.abs(methSet[i].rate);
			probBuf.push(methSet[i].rate*ratio);
			patBuf.push(0);
		}
		extraPats = Math.floor(total*extraPats*ratio/100.0);
		State.extraPats['rya'][group] = extraPats;		
		this.disributePats(total, probBuf, patBuf, extraPats);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам для группы с избытком

	distributeExtraMeth(group) {

		let pathData, extraPats;

		if(State.flNoDist) return; // Отладочный режим

		pathData = methData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		extraPats = 0;
		for(i=0 ; i<len ; i++) {
			if(methSet[i].rate<0) extraPats += Math.abs(methSet[i].rate);
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
		extraPats = Math.floor(total*extraPats/100.0);
		State.extraPats['rya'][group] = extraPats;		
		this.disributePats(total, probBuf, patBuf, extraPats);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем пациентов по методам для группы с суммой процентов меньше
// 100%
//

	distributeOneCutGroup(currGroup) {

		let i, pats;
		let stPats = State.stPats[4];
// Для группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам не равна 100%
// Суммируем пациентов внутри группы
			let currGrData = methData[currGroup].meth;
			let grSumm = 0, len = currGrData.length, num, ratio;
			if(methData[currGroup].result != -1) pats = methData[currGroup].result;
			else pats = methData[currGroup].pats;
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num;
				if(num>=0) grSumm += num;
			}
			grSumm = Math.floor(grSumm);			
			if(grSumm==0) ratio = 1;
			else ratio = pats/grSumm;			
// Перезаписываем новое значение в группу
			grSumm = Math.ceil(grSumm);
			methData[currGroup].pats = grSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeCutMeth(currGroup, ratio);
// Сохраняем реальное кол-во пациентов для расчета нераспределенных 
			State.ryaFreePatsBuf[currGroup] = methData[currGroup].pats;
// Восстанавливаем затертое начальное распределение 
			if(State.ryaPatsBuf[currGroup]&&State.ryaPatsBuf[currGroup]!=-1)	{
				methData[currGroup].pats = State.ryaPatsBuf[currGroup];
			}		
			return methData[currGroup].pats;
	}

/////////////////////////////
// Распределяем пациентов по методам для группы с суммой процентов больше
// или равно 100%

	distributeOneExtraGroup(currGroup) {

		let i, pats;
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры

			if(State.ryaPatsBuf[currGroup]!=-1) {
				methData[currGroup].pats = State.ryaPatsBuf[currGroup]; 
			}

			let currGrData = methData[currGroup].meth;
			let ryaGrSumm = 0, percentSumm = 0, len = currGrData.length, num;
			
// Вводилось ли число пациентов в группе вручную и делался ли раньше перерасчет на превышение 100%
// Ручной ввод хранится в result!
			if(methData[currGroup].result!=-1 && State.ryaMultBuf[currGroup]) {
				pats = Math.round(methData[currGroup].result/State.ryaMultBuf[currGroup]);
			} else if(methData[currGroup].result!=-1 && isNaN(State.ryaMultBuf[currGroup])) {
				pats = Math.round(methData[currGroup].result);
			}
			else {
				pats = methData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = Math.abs(currGrData[i].rate*pats/100.0);
				if(!State.flNoDist)	currGrData[i].pats = num; 
				if(currGrData[i].rate>0) ryaGrSumm += num;
			}
			methData[currGroup].pats = pats;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeExtraMeth(currGroup);
// Сохраняем реальное кол-во пациентов для расчета нераспределенных 
			State.ryaFreePatsBuf[currGroup] = methData[currGroup].pats;
// Сохраняем множитель распределения в буфере
			State.ryaMultBuf[currGroup] = ryaGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.ryaPatsBuf[currGroup] = pats;			
			return pats;
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

		let percentSumm, currGroup, len, meth;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 

		for(currGroup in methData) {
			meth = methData[currGroup].meth;
			len = meth.length;
			percentSumm = 0;
			for(let cnt=0 ; cnt<len ; cnt++) {
				if(meth[cnt].rate>=0) percentSumm += meth[cnt].rate;
			}
			if(percentSumm>100) {
				pathSumm += this.distributeOneExtraGroup(currGroup);
			}
			else {
				pathSumm += this.distributeOneCutGroup(currGroup);
			}
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[4] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РЯ

	distributeSt() {

		let stProbs = [27, 13, 39, 19];
		let Data = methData, currGroup;
		let stPats = State.stPats[4], total = State.pathTotals[4],
			dead = State.currLeth['rya'], i;

			stProbs = stData[State.currRegNum];
			for(i=0 ; i<4 ; i++) {
				stPats[i] = Math.ceil(total*stProbs[i]/100.0);
			}
		const chemioRate = [90,90,75,70]; // Проценты, нуждающихся в ХТ по стадиям	
		const platResistRecidRate = [3,5,30,35];	// Проценты платино-резистентных рецидивов по стадиям	
		const platResistNeedRate2 = [60,60,60,60];	// Проценты платино-резистентных рецидивов получающие ХТ 2-линии	
		const platResistNeedRate3 = [30,30,30,30];	// Проценты платино-резистентных рецидивов получающие ХТ 3-линии	
		const platResistNeedRate4 = [15,15,15,15];	// Проценты платино-резистентных рецидивов получающие ХТ 4-линии	
		let platResistNeedPats2 = [0,0,0,0];	// Пациенты платино-резистентных рецидивов получающие ХТ 2-линии	
		let platResistNeedPats3 = [0,0,0,0];	// Пациенты платино-резистентных рецидивов получающие ХТ 3-линии	
		let platResistNeedPats4 = [0,0,0,0];	// Пациенты платино-резистентных рецидивов получающие ХТ 4-линии	

		const platSenseRecidRate = [12,15,60,60];	// Проценты платино-чувствительных рецидивов по стадиям	
		const platSenseNeedRate2 = [80,80,70,70];	// Проценты платино-чувствительных рецидивов получающие ХТ 2-линии	
		const platSenseNeedRate3 = [50,50,40,40];	// Проценты платино-чувствительных рецидивов получающие ХТ 3-линии	
		const platSenseNeedRate4 = [25,25,20,20];	// Проценты платино-чувствительных рецидивов получающие ХТ 4-линии	
		let platSenseNeedPats2 = [0,0,0,0];	// Пациенты платино-чувствительных рецидивов получающие ХТ 2-линии	
		let platSenseNeedPats3 = [0,0,0,0];	// Пациенты платино-чувствительных рецидивов получающие ХТ 3-линии	
		let platSenseNeedPats4 = [0,0,0,0];	// Пациенты платино-чувствительных рецидивов получающие ХТ 4-линии	

//'Стадии I A и I Б в сочетании со светлоклеточным гистологическим типом или высокой степенью злокачественности'
		Data['st0_0_1'].pats = Math.round(stPats[0]*0.56*0.11*0.9);

// 'Стадия I С'		
		Data['st0_0_2'].pats = Math.round(stPats[0]*0.44*0.9);

//  'Стадии II A, II Б, II С'
		Data['st0_0_3'].pats = Math.round(stPats[1]*0.9);

// 'Стадии III A, III Б, III С'
		Data['st0_0_4'].pats = Math.round(stPats[2]*0.75);

// 'Стадии IV A, IV Б, IV С'
		Data['st0_0_5'].pats = Math.round(stPats[3]*0.70);

// 'Платинорезистентный рецидив 2-я линия'
		let line2ResistSumm = 0;
		let line3ResistSumm = 0;
		let line4ResistSumm = 0;
		for(let i=0; i<4 ; i++) {
			platResistNeedPats2[i] = stPats[i]*chemioRate[i]/100.0*platResistRecidRate[i]/100.0*platResistNeedRate2[i]/100.0;
			line2ResistSumm += platResistNeedPats2[i];
			platResistNeedPats3[i] = platResistNeedPats2[i]*platResistNeedRate3[i]/100.0;
			line3ResistSumm += platResistNeedPats3[i];
			platResistNeedPats4[i] = platResistNeedPats3[i]*platResistNeedRate4[i]/100.0;
			line4ResistSumm += platResistNeedPats4[i];
		}
		Data['st0_2_6'].pats = Math.round(line2ResistSumm);

// 'Платинорезистентный рецидив 3-я линия'
		Data['st0_3_7'].pats = Math.round(line3ResistSumm);
 
 //'Платинорезистентный рецидив 4-я линия'
		Data['st0_4_8'].pats = Math.round(line4ResistSumm);
 
// 'Платиночувствительный рецидив 2-я линия'
		let line2SenseSumm = 0;
		let line3SenseSumm = 0;
		let line4SenseSumm = 0;
		for(let i=0; i<4 ; i++) {
			platSenseNeedPats2[i] = stPats[i]*chemioRate[i]/100.0*platSenseRecidRate[i]/100.0*platSenseNeedRate2[i]/100.0;
			line2SenseSumm += platSenseNeedPats2[i];
			platSenseNeedPats3[i] = platSenseNeedPats2[i]*platSenseNeedRate3[i]/100.0;
			line3SenseSumm += platSenseNeedPats3[i];
			platSenseNeedPats4[i] = platSenseNeedPats3[i]*platSenseNeedRate4[i]/100.0;
			line4SenseSumm += platSenseNeedPats4[i];
		}


		Data['st0_2_9'].pats = Math.round(line2SenseSumm);

// 'Платиночувствительный рецидив 3-я линия'
		Data['st0_3_10'].pats = Math.round(line3SenseSumm);

// 'Платиночувствительный рецидив 4-я линия'
		Data['st0_4_11'].pats = Math.round(line4SenseSumm);

		for(currGroup in Data) Data[currGroup].result = -1;
		this.distributeAllMeth();
	}
		

}

export default RyaModel;