;"use strict";

var BlackList = [
  'sapatsinskayay',
  'yapparova',
  'rzaevr',
  'astrazeneca1',
  'astrazeneca2',
  'astrazeneca3',
  'astrazeneca4',
  'astrazeneca5',
  'astrazeneca6',
  'astrazeneca7',
  'astrazeneca8',
  'astrazeneca9',
  'terekhov_astra',
  'rpharm1',
  'kuzicheva',
  'sangadzhievv',
  'tyukavinf',
  'pakanna',
  'atrasheuskayaa',
  'oleynichenkoe',
  'roche1',
  'roche2',
  'roche3',
  'roche4',
  'roche5',
  'roche6',
  'tolkachevad',
  'dodonovv',
  'bulbav',
  'vasilevan',
  'gasparianv',
  'dolotovae',
  'eliseevv',
  'efimenkos',
  'zhukovy',
  'kindsfatere',
  'kozlovd',
  'kopylova',
  'kunavinaa',
  'likhinf',
  'makarovai',
  'menivl',
  'mikhirevv',
  'mordovinao',
  'remezovay',
  'rukavitsynay',
  'sebiakinav',
  'tarasovam',
  'titovam',
  'trampinskiil',
  'tukhvatullina',
  'khomenkom',
  'chistiakovae',
  'chulkinaa',
  'shabrinae',
  'yaroshenkos',
  'astafevae',
  'p.gorin',
  'a.vergeychik',
  'd.galimardanova',
  'i.golubinskaya',
  't.gorina',
  'n.nazarenko',
  's.petrovets',
  'm.posedko ',
  'a.stavitskiy',
  'a.sukhova',
  's.taranina',
  'a.tarasova',
  't.topolyan',
  't.yadrenova',
  'l.veselova ',
  'i.mikhaylova',
  'a.baranovskaya',
  'a.drozdov',
  'm.gorovets',
  'a.nazarova',
  'a.tereshenkov',
  'v. ragozina',
  'd.shurov',
  'm.malyshev',
  'lillypharma1',
  'lillypharma2',
  'lillypharma3',
  'lillypharma4',
  'lillypharma5',
  'lillypharma6',
  'lillypharma7',
  'lillypharma8',
  'lillypharma9',
  'lillypharma10',
  'lillypharma11',
  'lillypharma12',
  'lillypharma13',
  'lillypharma14',
  'lillypharma15',
  'lillypharma16',
  'lillypharma17',
  'lillypharma18',
  'lillypharma19',
  'lillypharma20',
  'lillypharma21',
  'lillypharma22',
  'lillypharma23',
  'lillypharma24',
  'lillypharma25',
  'abyzina',
  'bms1',
  'bms2',
  'Janssen-1',
  'Janssen-2',
  'Janssen-3',
  'Janssen-4',
  'Janssen-5',
  'o.egorova',
  'korolevam',
  'burchakovam',
  'latskikhe',
  'adame',
  'ukhnali',
  'syrovnenkoo',
  'polovnikovad',
  'svechkoval',
  'safiullint',
  'chernyshovas',
  'chernetsovv',
  'kizilovai',
  'khayretdinovar',
  'samolinas',
  'vutanoo',
  'ivanovak',
  'novozhilovay',
  'potemkins',
  'rubtsovan',
  'usenkok',
  'baskakovaz',
  'fomenkovaz',
  'khritankovaa',
  'averinat',
  'galaktionovai',
  'shilyaevaa',
  'podmolodinao',
  'blinova',
  'erilovao',
  'ergunovan',
  'grishchenyan',
  'patsn',
  'luzhetskiyi',
  'pyndovskiyd',
  'ivanovaju',
  'sapatsinskayaya',
  'mironenkor',
  'shchedrenkok',
  'muraschkinat',
  'enikeevm',
  'shulginae',
  'milyukovan',
  'kolosovao',
  'petukhovao',
  'yushkovae',
  'Boehringer1',
  'Boehringer2',
  'Boehringer3',
  'Boehringer4',
  'Boehringer5',
  'Boehringer6',
  'Boehringer7',
  'Boehringer8',
  'Boehringer9',
  'Boehringer10',
  'Boehringer11',
  'Boehringer12',
  'Boehringer13',
  'Boehringer14',
  'Boehringer15',
  'Boehringer16',
  'Boehringer17',
  'Boehringer18',
  'Boehringer19',
  'Boehringer20',
  'Boehringer21',
  'Boehringer22',
  'Boehringer23',
  'Boehringer24',
  'Boehringer25',
  'Boehringer26',
  'Boehringer27',
  'Boehringer28',
  'Boehringer29',
  'Boehringer30',
  'Boehringer31',
  'Boehringer32',
  'Boehringer33',
  'Boehringer34',
  'Boehringer35'
];

export default BlackList;
