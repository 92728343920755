; "use strict";

import State from './state';
import drugData from './drugdata';
import methData from './neo-meth';

class NeoModel {

	constructor() {

	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = methData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
// if(st=='st0_2_4' && drugIndex==40) {
// 	console.log(currDrug.name);
// 	console.log(currPrice);
// 	console.log(drugBudget);
// }
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// порядке
// Отдаем приоритет позициям с большей вероятностью!!
		rest = input ; // Нераспределенный остаток

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);

			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData;

		if(State.flNoDist) return; // Отладочный режим

		pathData = methData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
//		State.calc.disributePats(total, probBuf, patBuf);
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

// Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
		let pathData = methData;
		let currGroup, currSumm,i,customVal;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in pathData) {
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
		}
		for(currGroup in pathData) {
			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
			else pathSumm += pathData[currGroup].result;
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[14] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = methData, currGroup, i, stProbs = [];
		let initPats = [], stPats = [], total, metastat, delta, maxQuant = 0, maxInd = 0;
		let initProbs = [45, 20, 30, 5];
		let patSumm = 0;

		total = State.pathTotals[14];
		metastat = total*0.5;
//		initProbs = stData[State.currRegNum];
		for(i=0 ; i<4 ; i++) initPats[i] = total*initProbs[i]/100.0;
		stPats[0] = Math.round(metastat*0.15);	
		stPats[1] = Math.round(metastat*0.15*0.95);	
		stPats[2] = Math.round(metastat*0.7);	
		stPats[3] = Math.round(metastat*0.7*0.95);	
		stPats[4] = Math.round(metastat*0.15);	
		stPats[5] = Math.round(metastat*0.15*0.95);	
		for(i=0 ; i<6 ; i++) {
			patSumm += stPats[i];
			if(stPats[i]>maxQuant) {
				maxQuant = stPats[i];
				maxInd = i;
			}
		}
//console.log(total+'->'+patSumm);
		delta = total - patSumm;
		stPats[maxInd] += delta;
// G1 1-я линия
		Data['st0_1_1'].pats = Math.round(stPats[0]);

// G1 2-я линия
		Data['st0_2_2'].pats = Math.round(stPats[1]);

// G1 1-я линия
		Data['st0_1_3'].pats = Math.round(stPats[2]);

// G1 2-я линия
		Data['st0_2_4'].pats = Math.round(stPats[3]);

// G1 1-я линия
		Data['st0_1_5'].pats = Math.round(stPats[4]);

// G1 2-я линия
		Data['st0_2_6'].pats = Math.round(stPats[5]);

		for(currGroup in Data) Data[currGroup].result = -1;
		this.distributeAllMeth();
	}
		

}

export default NeoModel;