
;"use strict";

import State from './state';
import drugData from './drugdata';
import methData from './methData';

class ChanPrinter {

	constructor() {

		this.clickCounter = 0;
        $('#chan-mess-but').on(State.startEvent, (e)=>this.hideMessage(e));
        $('#d-print-butt').on(State.startEvent, (e)=>this.dataPrint(e));

	}

//******************************************

	render(chan) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, typeStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind,
			chanName = '', doseMg, doseStr, subTotVal, averStr, averCost, ksgTable;	
		const len = State.drugOrd.length;

		State.calc.resetDataBufs();
		State.calc.calcFullBudget(chan);

		if(chan=='') { // Строим статистику по КСГ
			ksgTable = 
			`
			<div class="print-subtitle">
				Статистика случаев по значению КСГ
			</div>
			<table id="prt-ksg-table">
                <tr id="prt-ksg-line0">
                    <td style="width:70px">st19.062:</td>
                    <td style="width:120px">${State.ksgBuf['st19.062']}</td>
                    <td style="width:70px">ds19.037:</td>
                    <td style="width:120px">${State.ksgBuf['ds19.037']}</td>
                </tr>
                <tr id="prt-ksg-line1">
                    <td>st19.063:</td>
                    <td>${State.ksgBuf['st19.063']}</td>
                    <td>ds19.038:</td>
                    <td>${State.ksgBuf['ds19.038']}</td>
                </tr>
                <tr id="prt-ksg-line2">
                    <td>st19.064:</td>
                    <td>${State.ksgBuf['st19.064']}</td>
                    <td>ds19.039:</td>
                    <td>${State.ksgBuf['ds19.039']}</td>
                </tr>
                <tr id="prt-ksg-line3">
                    <td>st19.065:</td>
                    <td>${State.ksgBuf['st19.065']}</td>
                    <td>ds19.040:</td>
                    <td>${State.ksgBuf['ds19.040']}</td>
                </tr>
                <tr id="prt-ksg-line4">
                    <td>st19.066:</td>
                    <td>${State.ksgBuf['st19.066']}</td>
                    <td>ds19.041:</td>
                    <td>${State.ksgBuf['ds19.041']}</td>
                </tr>
                <tr id="prt-ksg-line5">
                    <td>st19.067:</td>
                    <td>${State.ksgBuf['st19.067']}</td>
                    <td>ds19.042:</td>
                    <td>${State.ksgBuf['ds19.042']}</td>
                </tr>
                <tr id="prt-ksg-line6">
                    <td>st19.068:</td>
                    <td>${State.ksgBuf['st19.068']}</td>
                    <td>ds19.043:</td>
                    <td>${State.ksgBuf['ds19.043']}</td>
                </tr>
                <tr id="prt-ksg-line7">
                    <td>st19.069:</td>
                    <td>${State.ksgBuf['st19.069']}</td>
                    <td>ds19.044:</td>
                    <td>${State.ksgBuf['ds19.044']}</td>
                </tr>
                <tr id="prt-ksg-line8">
                    <td>st19.070:</td>
                    <td>${State.ksgBuf['st19.070']}</td>
                    <td>ds19.045:</td>
                    <td>${State.ksgBuf['ds19.045']}</td>
                </tr>
                <tr id="prt-ksg-line9">
                    <td>st19.071:</td>
                    <td>${State.ksgBuf['st19.071']}</td>
                    <td>ds19.046:</td>
                    <td>${State.ksgBuf['ds19.046']}</td>
                </tr>
                <tr id="prt-ksg-line10">
                    <td>st19.072:</td>
                    <td>${State.ksgBuf['st19.072']}</td>
                    <td>ds19.047:</td>
                    <td>${State.ksgBuf['ds19.047']}</td>
                </tr>
                <tr id="prt-ksg-line11">
                    <td>st19.073:</td>
                    <td>${State.ksgBuf['st19.073']}</td>
                    <td>ds19.048:</td>
                    <td>${State.ksgBuf['ds19.048']}</td>
                </tr>
                <tr id="prt-ksg-line12">
                    <td>st19.074:</td>
                    <td>${State.ksgBuf['st19.074']}</td>
                    <td>ds19.049:</td>
                    <td>${State.ksgBuf['ds19.049']}</td>
                </tr>
            </table>`;
			tableCode = ksgTable;		
			tableCode += '<br>'
			$('#chan-table-tot').html(tableCode);
			return;
		}
		averStr = '';
        if(chan=='oms') { 
        	chanName = 'ОМС КС'; 
        	drugBuf = State.omsBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostKs));
        	averStr = `<div class="print-subtotal">
       		Средняя стоимость случая: ${averCost} руб.
       		</div>`;
        }
        if(chan=='omsd') { 
        	chanName = 'ОМС ДС'; 
        	drugBuf = State.omsdBuf; 
        	averCost = State.calc.padDigits(Math.round(State.averCostDs));
        	averStr = `<div class="print-subtotal">
       		Средняя стоимость случая: ${averCost} руб.
       		</div>`;
        }
        if(chan=='rlo') { chanName = 'РЛО'; drugBuf = State.rloBuf; }
        if(chan=='onls') { chanName = 'ОНЛС'; drugBuf = State.onlsBuf; }
        subTotVal = State.calc.padDigits(Math.floor(State.chanBudgets[chan]));
       	subTotal = `<div class="print-subtotal" style="margin-bottom:0">
       		Суммарные расходы для канала ${chanName}: ${subTotVal} руб.
       	</div>`;

       	let tabFooter = `</tbody>
            </table>
			`;

        let insert = 
                	`<div class="print-title" style="margin-bottom:6px">
						${chanName}
					</div>
		            <table class="prt-drug-table" align="center">
		                <tbody>
		                	<tr class="table-header">
		                        <td width="250px">Препарат</td>
		                        <td>Кол-во уп.</td>
		                        <td>Кол-во мг.</td>
		                        <td>Затраты, руб.</td>
		                    </tr>`;                
		tableCode = $('#chan-table-tot').html();
		tableCode += insert;
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			nOfPacks = State.calc.padDigits(drugBuf[ind].packs);	
			if(nOfPacks==0) continue;
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
			doseMg = drugBuf[ind].mg;
			doseStr = State.calc.padDigits(Math.floor(doseMg));
 			result = Math.floor(result);
 			result = State.calc.padDigits(result);
			priceStr = State.calc.padFloat(currDrug.price);
			if(currDrug.type=='t') typeStr = '[Таргет.]'; 
			if(currDrug.type=='c') typeStr = '[Цитост.]'; 
			if(currDrug.type=='h') typeStr = '[Гормон.]';
			if(currDrug.type=='i') typeStr = '[Иммуно.]';
			if(currDrug.type=='o') typeStr = '[Другое]';
			lineTemplate = 
                	`<tr>
                        <td>
                        	${currDrug.name}
                        	<span class="prt-drug-type">${typeStr}</span>
                        </td>
                        <td>${nOfPacks}</td>
                        <td>${doseStr}</td>
                        <td>${result}</td>
                    </tr>`;
            tableCode += lineTemplate;    
		}
		tableCode += tabFooter;
		tableCode += subTotal;
		tableCode += averStr;		
		tableCode += '<br>'
		$('#chan-table-tot').html(tableCode);
	}

//******************************************

	renderTables(){

		this.render('');
		this.render('oms');
		this.render('omsd');
		this.render('rlo');
		this.render('onls');
	}

//******************************************

	hideMessage(e){

	    var type;

	    e.preventDefault();
        type = e.type;
        $('#chan-print-message').css('display','none');
        State.flModal = 0;
    }

//******************************************

	    dataPrint(e){

	        var type, messTxt;

	        $('#chan-print-cont').css('display','block');
	        this.renderTables();
	        $('.maincont').css('display','none');
	        $('#chan-print-but').css('display','none');
	        $('body').css('overflow','visible');
	            State.flModal = 1;

	        setTimeout(function(){
	            window.focus();
	//            alert('Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.');
	            window.print();

	            if(State.flIpad) {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.';
	                messTxt += '<br>Если ваш планшет не нашел доступный принтер для печати, воспользуйтесь пиктограммой в виде прямоугольника в верхней строке браузера<br><br><img src="img/pict.png"><br><br>для отправки результатов по почте или преобразования в другой удобный формат';
	            } else {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной клик на экране с таблицей.';
	            }
	            $('#chan-prt-mess-text').html(messTxt);
	            setTimeout(function(){
	                    $('#chan-print-message').css('display','block');
	                }, 1000);
	        },1000);
	        this.clickCounter = 0;
	        $('body').bind(State.startEvent, (e)=>this.closePrintWindow(e));

	        return true;
	    }

//******************************************

	closePrintWindow(e){

	        var type;

	        if(State.flModal) return;
	        if(e.target.id=='chan-mess-but') return;
	        type = e.type;
	        this.clickCounter++;
	        setTimeout(()=>{
	            this.clickCounter = 0;
	        },400);
	        if(this.clickCounter<2) return;
	        
	        $('body').unbind();
//	        $('body').css('overflow','hidden');
//	        $('.maincont').css('display','block');
	        $('#distcont').css('display','block');
	        $('#chan-print-cont').css('display','none');
	        e.preventDefault();
	    }
	}


//******************************************

export default ChanPrinter;