; "use strict";

import State from './state';
import drugData from './drugdata';
import rmpData from './rmp-meth';
import stData from './rmp-st';

class RmpModel {

	constructor() {

	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = rmpData[st].meth;
//console.log(st);
//if(st!='st0_2_6') return 0;
//console.log('------------>');
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==2) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}


/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

//	disributePats(input, probBuf, patBuf, flSt3) {

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// порядке
// Отдаем приоритет позициям с большей вероятностью!!
		rest = input ; // Нераспределенный остаток

		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);

			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 

// 		let len = probBuf.length, i, maxInd, rest, pats, data;
// 		let probList = []; // Массив индексов элементов из probBuf, отсортированных
// 							// по убыванию
// console.log('>>>>>>>>>>'+flSt3);

// // Временный патч для 3-й стадии
// 		if(flSt3) {
// console.log('!!!! St3');
// 			patBuf[2] = Math.ceil(input/2);
// 			patBuf[3] = input - patBuf[2];
// 			patBuf[0] = 0;
// 			patBuf[1] = 0;
// console.log(patBuf);
// 			return;
// 		}

// 		let buf = [];					
// 		for(i=0 ; i<len ; i++) {
// 			buf.push(probBuf[i]);
// 		}
// 		for(i=0 ; i<len ; i++) {
// 			maxInd = State.calc.findMax(buf);
// 			probList.push(maxInd);
// 			buf[maxInd] = -1;
// 		}

// // В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// // порядке
// // Отдаем приоритет позициям с большей вероятностью!!
// 		rest = input ; // Нераспределенный остаток
// //console.log('==============');
// //!! EXPERIMENTAL calculation of bias constant
// 		let bias = 0, delta, bias2 = 0;
// 		for(i=0 ; i<len ; i++) {
// 			data = input*probBuf[probList[i]]/100;
// 			pats = Math.round(data);
// 			delta = (pats - data);
// 			if(delta > 0) delta = 0.5 - delta;
// 			else delta = -0.5 + delta;
// 			bias += delta;
// 		}

// 		bias = bias/len;


// 		for(i=0 ; i<len ; i++) {
// //	V1		pats = Math.ceil(input*probBuf[probList[i]]/100);
// //	V2		pats = Math.round(input*probBuf[probList[i]]/100);
// //!! EXPERIMENTAL distribution (simple mode with bias constant of 0.1):
// //			data = input*probBuf[probList[i]]/100 - 0.1;
// 			data = input*probBuf[probList[i]]/100 - bias;
// 			pats = Math.round(data);

// 			if(pats>rest) pats = rest;
// 			patBuf[probList[i]] = pats;
// 			rest -= pats;
// 			if(rest<0) rest = 0;
// 		}
// 		if(rest>0) patBuf[probList[0]] += rest;
// 	}


/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group, ratio) {

		let flSt3;
		let pathData, prob;

		if(State.flNoDist)	return;

		pathData = rmpData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		total = pathData[group].pats;
		total = Math.round(total);
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			prob = methSet[i].rate;
			probBuf.push(prob*ratio);
			patBuf.push(0);
		}
		if(group=='st3') flSt3 = 1; else flSt3 = 0;
//		this.disributePats(total, probBuf, patBuf, flSt3);
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

		let i, currGroup, pats;
		let stPats = State.stPats[8];
		for(currGroup in rmpData) {
// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам не равна 100%
// Суммируем пациентов внутри каждой группы
			let currGrData = rmpData[currGroup].meth;
			let grSumm = 0, len = currGrData.length, num, ratio;
			if(rmpData[currGroup].result != -1) pats = rmpData[currGroup].result;
			else pats = rmpData[currGroup].pats;
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num;
				grSumm += num;
			}
			grSumm = Math.floor(grSumm);			
			if(grSumm==0) ratio = 1;
			else ratio = pats/grSumm;			
// Перезаписываем новое значение в группу
			grSumm = Math.ceil(grSumm);
//!!D			
			rmpData[currGroup].pats = grSumm;

// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup, ratio);
// Сохраняем реальное кол-во пациентов для расчета нераспределенных 
			State.rmpFreePatsBuf[currGroup] = rmpData[currGroup].pats;
// Восстанавливаем затертое начальное распределение 
if(State.rmpPatsBuf[currGroup])			
			rmpData[currGroup].pats = State.rmpPatsBuf[currGroup];
		}
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = rmpData, currGroup;
		let stPats = [], total = State.pathTotals[8];
		let totalPatsBuf = State.stPats[8];
		let stProbs = [49.3, 25.7, 12.9, 9.9];
		const recidProbs = [5, 20, 60, 0];
		let recidPats = [], i, recidPatsSumm = 0;

		stProbs = stData[State.currRegNum];
		for(i=0 ; i<4 ; i++) {
			stPats[i] = total*stProbs[i]/100.0;
			recidPats[i] = stPats[i]*recidProbs[i]/100.0;
			recidPatsSumm += recidPats[i];
		}
		let st1Pats = 0.6*stPats[0],
			st3Pats = stPats[2],
			st4Pats = stPats[3] + recidPatsSumm,
			st4Line1Pats = 0.91*st4Pats,
			st4Line2Pats = 0.65*st4Line1Pats;
// console.log(stPats);
// console.log(stPats);
// Первая стадия
		Data['st1'].pats = Math.round(st1Pats);
		totalPatsBuf['st1'] = Math.round(st1Pats);

// Третья стадия
		Data['st3'].pats = Math.round(st3Pats);
		totalPatsBuf['st3'] = Math.round(st3Pats);
// console.log(Data['st3'].pats);

// Четвертая стадия
// Линия 1
		Data['st4_1'].pats = Math.round(st4Line1Pats);
		totalPatsBuf['st4_1'] = Math.round(st4Line1Pats);

// Четвертая стадия
// Линия 2
		Data['st4_2'].pats = Math.round(st4Line2Pats);
		totalPatsBuf['st4_2'] = Math.round(st4Line2Pats);

		let pathSumm = 0;
		for(currGroup in rmpData) {
// Для каждой группы нужно расчитать и сохранить в State.rlPatsBuf общее количество пациентов,
// т.к. после ручного ввода и перераспределения, исходные значения буду перезаписаны

			State.rmpPatsBuf[currGroup] = rmpData[currGroup].pats;
			rmpData[currGroup].result = -1;
			pathSumm += rmpData[currGroup].pats;
		}
		State.pathSumm[8] = pathSumm;
		for(currGroup in Data) Data[currGroup].result = -1;
		this.distributeAllMeth();
	}
		

}

export default RmpModel;