; "use strict";

import State from './state';
import drugData from './drugdata';
import methData from './rpsh-meth';
import stData from './rpsh-st';

class RpshModel {

	constructor() {

		let group;

		for(group in methData) State.rpshPatsBuf[group] = -1;
	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = methData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по убыванию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// порядке
// Отдаем приоритет позициям с меньшей вероятностью!!
		rest = input ; // Нераспределенный остаток
		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);
			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData, prob;

		if(State.flNoDist) return; // Отладочный режим

		pathData = methData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			prob = methSet[i].pats/total*100;
			probBuf.push(prob);
			patBuf.push(0);
		}
//		State.calc.disributePats(total, probBuf, patBuf);
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

		let i, currGroup, pats;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in methData) {
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры
			if(State.rpshPatsBuf[currGroup]!=-1) {
				methData[currGroup].pats = State.rpshPatsBuf[currGroup]; 
			}

// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам больше 100%
// Суммируем пациентов внутри каждой группы

			let currGrData = methData[currGroup].meth;
			let rpshGrSumm = 0, len = currGrData.length, num;
			if(methData[currGroup].result!=-1 && State.rpshMultBuf[currGroup]) {
				pats = Math.round(methData[currGroup].result/State.rpshMultBuf[currGroup]);
			}
			else {
				pats = methData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num; 
				rpshGrSumm += num;
			}
			rpshGrSumm = Math.ceil(rpshGrSumm);
// Перезаписываем новое значение в группу
			methData[currGroup].pats = rpshGrSumm;
			pathSumm += rpshGrSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
// Сохраняем множитель распределения в буфере
			State.rpshMultBuf[currGroup] = rpshGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.rpshPatsBuf[currGroup] = pats;			
		}

		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[9] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = methData, currGroup, i;
		let stPats = [], total = State.pathTotals[9];
		let initProbs = [6.8, 26.1, 34.5, 32.6], stProbs;

		initProbs = stData[State.currRegNum];
		for(i=0 ; i<4 ; i++) stPats[i] = total*initProbs[i]/100.0;
		// Распределяем 4-ю стадию по двум подстадиям	
		stPats[4] = stPats[3]*50.9/100.0;	
		stPats[3] = stPats[3]*49.1/100.0;	
		let hlt1Probs = [10,10,10,20,0];
		let hlt2Probs = [5,10,10,10,0];
		let gr2Probs = [10,10,10,10,0];
		let gr3Probs = [0,5,15,15,70];
		let progProbs = [15,40,75,90,100];
		let line1Probs = [50,50,60,35,0];
		let line2Probs = [40,40,50,60,40];
		let gr1Summ = 0, gr2Summ = 0, gr3Summ = 0, line1Pats,
			line1Summ = 0, line2Summ = 0;

		for(i=0 ; i<5 ; i++) {
			gr1Summ += stPats[i]*hlt1Probs[i];
			gr1Summ += stPats[i]*hlt2Probs[i];
			gr2Summ += stPats[i]*gr2Probs[i];
			gr3Summ += stPats[i]*gr3Probs[i];
			line1Pats = stPats[i]*progProbs[i]*line1Probs[i];
			line1Summ += line1Pats;
			line2Summ += line1Pats*line2Probs[i];
		}
		line2Summ = line2Summ/100.0/100.0/100.0;
		line2Summ += line2Probs[4]*stPats[4]/100.0;
		gr1Summ = gr1Summ/100.0;
		gr2Summ = gr2Summ/100.0;
		gr3Summ = gr3Summ/100.0;
		line1Summ = line1Summ/100.0/100.0;

// ХЛТ б-ые I-IVa стадий с и без оперативного лечения
		Data['st0_0_1'].pats = Math.round(gr1Summ);

// Пред- периоперационная ХТ б-ые I-IVА ст.
		Data['st0_0_2'].pats = Math.round(gr2Summ);

// Паллиативная ХТ б-ые II-IV ст.
		Data['st0_0_3'].pats = Math.round(gr3Summ);

// Первая линия терапии
		Data['st0_1_4'].pats = Math.round(line1Summ);

// Вторая линия терапии
		Data['st0_2_5'].pats = Math.round(line2Summ);

		for(currGroup in Data) {
			State.rpshPatsBuf[currGroup] = -1;
			Data[currGroup].result = -1;
		}
		this.distributeAllMeth();
	}
		

}

export default RpshModel;