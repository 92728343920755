
"use strict";
let stData = {
	1: [23.5,21.8,39.4,14.7],
	2: [18.8,10.9,28.9,37.5],
	3: [21.3,20.6,33.3,22],
	4: [43.8,4.6,38.7,12.9],
	5: [27,7.1,40.5,19.8],
	6: [34.9,8.5,43.4,12.4],
	7: [39,8.9,33.3,17.9],
	8: [35.8,6,38.8,17.9],
	9: [16,20,44.8,17.6],
	10: [23.8,16.2,37.1,17.1],
	11: [29.5,10.6,39,20.8],
	12: [23.9,20,36.8,15.2],
	13: [25.9,10.3,50,13.8],
	14: [27.4,12.8,42.7,17.1],
	15: [29.2,12.4,29.2,27.4],
	16: [18.6,14.3,47.1,20],
	17: [18.1,10.6,30,41.3],
	18: [19.6,7,49,24.5],
	19: [16.7,0,66.7,16.7],
	20: [36.9,14.2,31.9,12.8],
	21: [36.6,13.4,33.9,14.3],
	22: [24,14.4,28.8,27.9],
	23: [21.7,17.3,37.1,17.9],
	24: [22.2,17,37,20],
	25: [35.7,16.7,16.7,31],
	26: [30.1,4.1,37,26],
	27: [18.8,7.2,39.1,34.8],
	28: [25.8,14.5,40.3,19.4],
	29: [42.3,11.5,24.4,21.8],
	30: [24.5,10.2,46.2,18.5],
	31: [35.2,4.4,35.2,25.3],
	32: [23.4,12.5,36.7,25.8],
	33: [24.4,13,45.3,16.8],
	34: [40.5,10.8,13.5,21.6],
	35: [20,5,60,15],
	36: [19.6,26.1,38.2,13.1],
	37: [15,5,37.5,27.5],
	38: [35.1,8.1,38.2,15.8],
	39: [42.9,23.8,9.5,23.8],
	40: [15.4,19.5,45,20.1],
	41: [21.1,12.3,31.6,31.6],
	42: [16.9,13.6,42.4,23.7],
	43: [36.6,22,26.8,14.6],
	44: [27.3,25.5,36.4,10.9],
	45: [26.9,8.2,42.9,22],
	46: [36.8,5.9,28.3,28.3],
	47: [39.5,11.2,38.1,10.9],
	48: [30.7,15.8,37.1,14.9],
	49: [30.5,9.8,34.8,23.8],
	50: [43.1,13.4,28.9,8.1],
	51: [25.5,14.4,41.8,18.3],
	52: [12.5,6.7,51.7,29.2],
	53: [22.2,13.4,48.1,16.2],
	54: [24.6,15.9,39.1,17.4],
	55: [34.3,8.1,26.3,31.3],
	56: [31.1,11.7,36.5,20.7],
	57: [47.9,6,23.9,22.2],
	58: [26.6,7.4,41.5,24.5],
	59: [26.2,13.9,41,18.9],
	60: [34.4,12.5,34.4,18.8],
	61: [26.5,4.4,51.3,16.8],
	62: [19.8,10.8,42.2,25.2],
	63: [36.7,9.2,30,24.2],
	64: [31.1,8.9,34.6,24.9],
	65: [27.2,11.2,40,17.6],
	66: [36.3,11.1,33,18],
	67: [33.6,14,26.9,25.5],
	68: [22.5,17.4,37.1,19.7],
	69: [24.8,13.6,45.3,15.9],
	70: [33.5,7.6,41.6,14.7],
	71: [18.3,20.2,30.8,28.8],
	74: [20,10,30,30],
	75: [12.1,27.3,24.2,36.4],
	76: [42,0,24,32],
	77: [20.8,12.7,30.1,34.7],
	78: [18.4,16.7,42.1,22.8],
	79: [50.9,10.9,21.8,14.5],
	80: [16,4,48,24],
	81: [33.3,0,55.6,11.1],
	82: [10.9,16.4,50.9,21.8],
	72: [50,4.5,30.7,14.8],
	83: [0,100,0,0],
	73: [17.6,8.8,56,12.1],
	84: [40,2,36,22],
	85: [26.7,6.7,40,26.7]
};
	
export default stData;