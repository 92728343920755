
"use strict";
let stData = {
	1: [11.7,22.3,36.2,28.7],
	2: [1.1,13.2,38.5,44],
	3: [6.1,21.9,34.2,37.7],
	4: [3.7,63.4,7.3,25.6],
	5: [4.3,42,24.6,27.5],
	6: [6.1,26.5,41.8,21.4],
	7: [8.5,18.6,32.2,40.7],
	8: [0,27.3,29.5,43.2],
	9: [7.3,19.5,51.2,20.7],
	10: [6.3,70.8,8.3,14.6],
	11: [13.3,22.8,38.1,25.8],
	12: [6.4,26.7,37.7,26.7],
	13: [2,12,36,48],
	14: [8.7,21.7,23.2,43.5],
	15: [7.4,29.6,33.3,27.8],
	16: [1.7,29.3,48.3,20.7],
	17: [6.7,21.1,27.8,44.4],
	18: [7.1,20.2,33.3,39.4],
	19: [0,0,60,40],
	20: [12.9,17.4,37.1,28.8],
	21: [3.9,22.1,48.1,26],
	22: [2.3,22.7,47.7,22.7],
	23: [7.4,18,39.7,30.9],
	24: [3.8,28.6,36.2,24.8],
	25: [23.9,19.6,23.9,23.9],
	26: [3.7,14.8,33.3,42.6],
	27: [5.1,17.9,38.5,38.5],
	28: [8.7,17.4,47.8,26.1],
	29: [11.8,29.4,27.1,31.8],
	30: [10.9,40.8,12,36.4],
	31: [6.3,12.5,28.1,50],
	32: [6.6,24.8,28.1,33.9],
	33: [3.8,22,46.2,18.2],
	34: [7.1,28.6,28.6,28.6],
	35: [0,0,77.8,22.2],
	36: [2.7,21.6,45.9,25.7],
	37: [9.1,18.2,9.1,54.5],
	38: [2.3,38.6,25,22.7],
	39: [16.7,25,25,33.3],
	40: [1.1,33,49.5,14.3],
	41: [0,23.8,66.7,9.5],
	42: [0,30,40,30],
	43: [0,37.5,25,25],
	44: [17.3,28.8,42.3,11.5],
	45: [10,40.2,24.9,24.9],
	46: [7.8,20.3,32.8,39.1],
	47: [11.1,26.8,24.2,33.3],
	48: [4.3,15.2,47.1,32.6],
	49: [4.2,23.6,33.3,38.9],
	50: [7,10.9,27.3,35.2],
	51: [5.2,14.8,36.1,43.9],
	52: [1,28.1,41.7,28.1],
	53: [5.8,29.3,37.1,27.9],
	54: [3.8,13.5,23.1,50],
	55: [5,15,55,25],
	56: [3,39.1,25.8,32.1],
	57: [3.8,31.1,20.8,44.3],
	58: [3.1,21.5,33.8,41.5],
	59: [16.7,25.9,18.5,33.3],
	60: [19.2,26.9,15.4,26.9],
	61: [2.1,27.7,36.2,34],
	62: [4.2,27.4,27,39.1],
	63: [9.5,25.7,33.8,31.1],
	64: [8.9,29.7,19.3,38],
	65: [5.4,32.3,33.1,26.2],
	66: [7.3,23.4,42.3,25.5],
	67: [9.4,20.6,28.2,41.8],
	68: [3.6,19.1,42.7,31.8],
	69: [5.9,28.9,34.8,26.7],
	70: [7.4,17.9,49.5,20],
	71: [22.4,31,15.5,29.3],
	74: [0,22.2,44.4,33.3],
	75: [5.3,31.6,42.1,21.1],
	76: [5.9,11.8,23.5,35.3],
	77: [9.6,20.8,33.6,32],
	78: [9.6,36.2,27.7,26.6],
	79: [4.3,17,21.3,25.5],
	80: [0,22.2,40.7,37],
	81: [9.1,27.3,9.1,54.5],
	82: [9.8,5.9,45.1,39.2],
	72: [20,35.4,20,24.6],
	83: [0,0,50,50],
	73: [4.9,21.6,53.9,16.7],
	84: [10.3,26.5,44.1,19.1],
	85: [9.1,9.1,9.1,54.5]
};
	
export default stData;