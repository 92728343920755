var regStat = {
	1: {'reg':'Белгородская область','mel':103,'krr':612,'rmz':703,'rl':574,	'rya':150,	'rshm':184,	'rp':243,'rz':364,'rmp':201,'rpsh':84,'rpz':139,'rtm':326,'gcr':32, 'rpsz':357, 'neo':1547532},
	2: {'reg':'Брянская область','mel':95,'krr':526,'rmz':577,'rl':577,			'rya':132,	'rshm':143,	'rp':169,'rz':369,'rmp':144,'rpsh':80,'rpz':160,'rtm':256,'gcr':66, 'rpsz':235, 'neo':1192570},
	3: {'reg':'Владимирская область','mel':118,'krr':585,'rmz':606,'rl':474,	'rya':145,	'rshm':178,	'rp':189,'rz':393,'rmp':155,'rpsh':91,'rpz':147,'rtm':321,'gcr':37, 'rpsz':309, 'neo':1358538},
	4: {'reg':'Воронежская область','mel':214,'krr':910,'rmz':1065,'rl':981,	'rya':200,	'rshm':221,	'rp':277,'rz':480,'rmp':293,'rpsh':73,'rpz':246,'rtm':557,'gcr':50, 'rpsz':591, 'neo':2323657},
	5: {'reg':'Ивановская область','mel':63,'krr':440,'rmz':557,'rl':361,		'rya':130,	'rshm':161,	'rp':176,'rz':244,'rmp':135,'rpsh':62,'rpz':106,'rtm':292,'gcr':32, 'rpsz':307, 'neo':997196},
	6: {'reg':'Тверская область','mel':112,'krr':602,'rmz':621,'rl':589,		'rya':118,	'rshm':191,	'rp':195,'rz':391,'rmp':133,'rpsh':87,'rpz':180,'rtm':266,'gcr':51, 'rpsz':382, 'neo':1260345},
	7: {'reg':'Калужская область','mel':77,'krr':446,'rmz':491,'rl':364,		'rya':117,	'rshm':116,	'rp':119,'rz':291,'rmp':149,'rpsh':52,'rpz':115,'rtm':209,'gcr':55, 'rpsz':349, 'neo':1000070},
	8: {'reg':'Костромская область','mel':45,'krr':306,'rmz':314,'rl':260,		'rya':73,	'rshm':94,	'rp':86,'rz':165,'rmp':53,'rpsh':40,'rpz':62,'rtm':129,'gcr':14, 'rpsz':169, 'neo':633392},
	9: {'reg':'Курская область','mel':71,'krr':476,'rmz':560,'rl':517,			'rya':135,	'rshm':141,	'rp':236,'rz':356,'rmp':124,'rpsh':74,'rpz':133,'rtm':251,'gcr':27, 'rpsz':303, 'neo':1103059},
	10: {'reg':'Липецкая область','mel':88,'krr':459,'rmz':487,'rl':461,		'rya':91,	'rshm':122,	'rp':171,'rz':258,'rmp':166,'rpsh':42,'rpz':117,'rtm':266,'gcr':29, 'rpsz':334, 'neo':1139492},
	11: {'reg':'г. Москва','mel':883,'krr':4908,'rmz':5902,'rl':2660,			'rya':923,	'rshm':811,	'rp':1371,'rz':2173,'rmp':1157,'rpsh':348,'rpz':1204,'rtm':1922,'gcr':349, 'rpsz':4486, 'neo':12692466},
	12: {'reg':'Московская область','mel':577,'krr':3017,'rmz':3499,'rl':1964,	'rya':624,	'rshm':775,	'rp':873,'rz':1544,'rmp':659,'rpsh':330,'rpz':722,'rtm':1349,'gcr':224, 'rpsz':1875, 'neo':7687647},
	13: {'reg':'Орловская область','mel':99,'krr':390,'rmz':389,'rl':387,		'rya':55,	'rshm':104,	'rp':126,'rz':227,'rmp':110,'rpsh':49,'rpz':77,'rtm':185,'gcr':19, 'rpsz':190, 'neo':733682},
	14: {'reg':'Рязанская область','mel':100,'krr':579,'rmz':541,'rl':461,		'rya':125,	'rshm':125,	'rp':166,'rz':354,'rmp':163,'rpsh':63,'rpz':125,'rtm':344,'gcr':29, 'rpsz':361, 'neo':1108924},
	15: {'reg':'Смоленская область','mel':68,'krr':406,'rmz':476,'rl':358,		'rya':104,	'rshm':125,	'rp':155,'rz':285,'rmp':93,'rpsh':53,'rpz':111,'rtm':253,'gcr':31, 'rpsz':201, 'neo':934747},
	16: {'reg':'Тамбовская область','mel':72,'krr':434,'rmz':461,'rl':485,		'rya':76,	'rshm':120,	'rp':147,'rz':266,'rmp':121,'rpsh':53,'rpz':106,'rtm':240,'gcr':30, 'rpsz':142, 'neo':1006962},
	17: {'reg':'Тульская область','mel':122,'krr':727,'rmz':729,'rl':612,		'rya':157,	'rshm':201,	'rp':217,'rz':400,'rmp':181,'rpsh':83,'rpz':198,'rtm':426,'gcr':86, 'rpsz':446, 'neo':1466025},
	18: {'reg':'Ярославская область','mel':112,'krr':737,'rmz':680,'rl':472,	'rya':149,	'rshm':141,	'rp':227,'rz':414,'rmp':154,'rpsh':95,'rpz':188,'rtm':268,'gcr':71, 'rpsz':350, 'neo':1253189},
	19: {'reg':'Ненецкий а.о.','mel':1,'krr':13,'rmz':14,'rl':12,				'rya':1,	'rshm':11,	'rp':6,'rz':9,'rmp':4,'rpsh':5,'rpz':5,'rtm':2,'gcr':1, 'rpsz':17, 'neo':44110},
	20: {'reg':'Архангельская обл.(б а.о)','mel':92,'krr':622,'rmz':431,'rl':498,'rya':146,	'rshm':183,	'rp':194,'rz':394,'rmp':134,'rpsh':120,'rpz':107,'rtm':176,'gcr':43, 'rpsz':388, 'neo':1092277},
	21: {'reg':'Вологодская область','mel':74,'krr':471,'rmz':486,'rl':430,		'rya':101,	'rshm':166,	'rp':169,'rz':309,'rmp':134,'rpsh':72,'rpz':97,'rtm':185,'gcr':86, 'rpsz':227, 'neo':1160721},
	22: {'reg':'Калининградская область','mel':71,'krr':376,'rmz':491,'rl':277,	'rya':100,	'rshm':144,	'rp':124,'rz':173,'rmp':113,'rpsh':43,'rpz':107,'rtm':192,'gcr':28, 'rpsz':222, 'neo':1012253},
	23: {'reg':'г. Санкт-Петербург','mel':516,'krr':2860,'rmz':2866,'rl':1750,	'rya':575,	'rshm':507,	'rp':797,'rz':1221,'rmp':633,'rpsh':247,'rpz':680,'rtm':906,'gcr':278, 'rpsz':1382, 'neo':5392992},
	24: {'reg':'Ленинградская область','mel':107,'krr':632,'rmz':661,'rl':530,	'rya':140,	'rshm':178,	'rp':186,'rz':346,'rmp':164,'rpsh':99,'rpz':157,'rtm':274,'gcr':42, 'rpsz':292, 'neo':1876392},
	25: {'reg':'Мурманская область','mel':57,'krr':337,'rmz':379,'rl':250,		'rya':63,	'rshm':111,	'rp':156,'rz':187,'rmp':69,'rpsh':40,'rpz':77,'rtm':107,'gcr':23, 'rpsz':230, 'neo':741511},
	26: {'reg':'Новгородская область','mel':41,'krr':294,'rmz':302,'rl':296,	'rya':79,	'rshm':63,	'rp':95,'rz':199,'rmp':68,'rpsh':51,'rpz':87,'rtm':135,'gcr':12, 'rpsz':142, 'neo':596173},
	27: {'reg':'Псковская область','mel':41,'krr':305,'rmz':315,'rl':264,		'rya':73,	'rshm':81,	'rp':86,'rz':190,'rmp':69,'rpsh':38,'rpz':68,'rtm':152,'gcr':24, 'rpsz':137, 'neo':626046},
	28: {'reg':'Республика Карелия','mel':56,'krr':287,'rmz':335,'rl':230,		'rya':58,	'rshm':86,	'rp':101,'rz':210,'rmp':56,'rpsh':44,'rpz':46,'rtm':123,'gcr':22, 'rpsz':159, 'neo':614628},
	29: {'reg':'Республика Коми','mel':72,'krr':362,'rmz':385,'rl':371,			'rya':73,	'rshm':97,	'rp':173,'rz':184,'rmp':103,'rpsh':83,'rpz':88,'rtm':109,'gcr':39, 'rpsz':179, 'neo':820171},
	30: {'reg':'Краснодарский край','mel':511,'krr':2661,'rmz':2531,'rl':2245,	'rya':503,	'rshm':691,	'rp':783,'rz':914,'rmp':661,'rpsh':169,'rpz':642,'rtm':941,'gcr':298, 'rpsz':1646, 'neo':5677786},
	31: {'reg':'Астраханская область','mel':65,'krr':368,'rmz':407,'rl':290,	'rya':87,	'rshm':121,	'rp':113,'rz':212,'rmp':51,'rpsh':60,'rpz':106,'rtm':150,'gcr':67, 'rpsz':136, 'neo':1005967},
	32: {'reg':'Волгоградская область','mel':185,'krr':1148,'rmz':1094,'rl':1047,'rya':225,	'rshm':405,	'rp':355,'rz':527,'rmp':249,'rpsh':115,'rpz':287,'rtm':408,'gcr':134, 'rpsz':775, 'neo':2491751},
	33: {'reg':'Ростовская область','mel':293,'krr':1719,'rmz':1929,'rl':1436,	'rya':376,	'rshm':622,	'rp':567,'rz':709,'rmp':462,'rpsh':132,'rpz':421,'rtm':721,'gcr':135, 'rpsz':1182, 'neo':4195327},
	34: {'reg':'Республика Адыгея','mel':26,'krr':140,'rmz':157,'rl':135,		'rya':27,	'rshm':54,	'rp':64,'rz':67,'rmp':58,'rpsh':14,'rpz':44,'rtm':97,'gcr':26, 'rpsz':135, 'neo':463453},
	35: {'reg':'Республика Калмыкия','mel':10,'krr':85,'rmz':76,'rl':136,		'rya':19,	'rshm':51,	'rp':32,'rz':42,'rmp':17,'rpsh':18,'rpz':23,'rtm':27,'gcr':14, 'rpsz':26, 'neo':271035},
	36: {'reg':'Республика Крым','mel':162,'krr':832,'rmz':963,'rl':611,		'rya':188,	'rshm':235,	'rp':268,'rz':377,'rmp':237,'rpsh':66,'rpz':208,'rtm':332,'gcr':104, 'rpsz':469, 'neo':1912025},
	37: {'reg':'Город Cевастополь','mel':34,'krr':247,'rmz':233,'rl':117,		'rya':44,	'rshm':54,	'rp':67,'rz':79,'rmp':75,'rpsh':9,'rpz':64,'rtm':92,'gcr':19, 'rpsz':136, 'neo':448829},
	38: {'reg':'Ставропольский край','mel':172,'krr':1180,'rmz':1072,'rl':921,	'rya':265,	'rshm':301,	'rp':288,'rz':453,'rmp':250,'rpsh':79,'rpz':201,'rtm':410,'gcr':90, 'rpsz':591, 'neo':2803021},
	39: {'reg':'Республика Ингушетия','mel':6,'krr':80,'rmz':125,'rl':74,		'rya':25,	'rshm':20,	'rp':38,'rz':30,'rmp':26,'rpsh':10,'rpz':6,'rtm':23,'gcr':7, 'rpsz':32, 'neo':506688},
	40: {'reg':'Республика Дагестан','mel':56,'krr':452,'rmz':595,'rl':523,		'rya':125,	'rshm':176,	'rp':136,'rz':341,'rmp':128,'rpsh':90,'rpz':109,'rtm':126,'gcr':80, 'rpsz':281, 'neo':3111353},
	41: {'reg':'Респ. Кабардино-Балкария','mel':41,'krr':215,'rmz':296,'rl':270,'rya':53,	'rshm':78,	'rp':86,'rz':98,'rmp':67,'rpsh':20,'rpz':65,'rtm':101,'gcr':60, 'rpsz':136, 'neo':868174},
	42: {'reg':'Респ. Северная Осетия','mel':36,'krr':206,'rmz':328,'rl':141,	'rya':67,	'rshm':50,	'rp':64,'rz':83,'rmp':88,'rpsh':9,'rpz':48,'rtm':116,'gcr':45, 'rpsz':102, 'neo':697064},
	43: {'reg':'Респ. Карачаево-Черкесия','mel':17,'krr':119,'rmz':142,'rl':124,'rya':38,	'rshm':38,	'rp':40,'rz':46,'rmp':33,'rpsh':8,'rpz':34,'rtm':59,'gcr':14, 'rpsz':100, 'neo':465669},
	44: {'reg':'Республика Чечня','mel':15,'krr':236,'rmz':269,'rl':317,		'rya':61,	'rshm':75,	'rp':69,'rz':144,'rmp':37,'rpsh':51,'rpz':63,'rtm':102,'gcr':42, 'rpsz':64, 'neo':1476752},
	45: {'reg':'Нижегородская область','mel':237,'krr':1814,'rmz':1883,'rl':1370,'rya':324,	'rshm':439,	'rp':558,'rz':896,'rmp':380,'rpsh':191,'rpz':346,'rtm':776,'gcr':87, 'rpsz':900, 'neo':3203818},
	46: {'reg':'Кировская область','mel':130,'krr':750,'rmz':164,'rl':681,		'rya':110,	'rshm':145,	'rp':240,'rz':337,'rmp':161,'rpsh':57,'rpz':182,'rtm':231,'gcr':88, 'rpsz':387, 'neo':1262549},
	47: {'reg':'Самарская область','mel':259,'krr':1531,'rmz':1633,'rl':1169,	'rya':306,	'rshm':357,	'rp':520,'rz':672,'rmp':375,'rpsh':142,'rpz':368,'rtm':654,'gcr':110, 'rpsz':1165, 'neo':3179026},
	48: {'reg':'Оренбургская область','mel':139,'krr':1003,'rmz':1016,'rl':934,	'rya':184,	'rshm':238,	'rp':352,'rz':499,'rmp':241,'rpsh':124,'rpz':221,'rtm':377,'gcr':115, 'rpsz':473, 'neo':1956256},
	49: {'reg':'Пензенская область','mel':114,'krr':630,'rmz':572,'rl':633,		'rya':157,	'rshm':126,	'rp':205,'rz':379,'rmp':145,'rpsh':68,'rpz':181,'rtm':336,'gcr':56, 'rpsz':436, 'neo':1304825},
	50: {'reg':'Пермский край','mel':195,'krr':1050,'rmz':1012,'rl':837,		'rya':266,	'rshm':230,	'rp':373,'rz':485,'rmp':236,'rpsh':126,'rpz':214,'rtm':408,'gcr':73, 'rpsz':585, 'neo':2599301},
	51: {'reg':'Саратовская область','mel':207,'krr':1212,'rmz':1248,'rl':992,	'rya':227,	'rshm':295,	'rp':365,'rz':540,'rmp':354,'rpsh':143,'rpz':258,'rtm':516,'gcr':90, 'rpsz':711, 'neo':2421785},
	52: {'reg':'Ульяновская область','mel':84,'krr':603,'rmz':549,'rl':514,		'rya':107,	'rshm':172,	'rp':153,'rz':294,'rmp':124,'rpsh':91,'rpz':94,'rtm':230,'gcr':31, 'rpsz':219, 'neo':1229687},
	53: {'reg':'Республика Башкортостан','mel':141,'krr':1492,'rmz':1523,'rl':1358,'rya':321,'rshm':334,'rp':549,'rz':828,'rmp':297,'rpsh':278,'rpz':349,'rtm':445,'gcr':129, 'rpsz':864, 'neo':4037811},
	54: {'reg':'Республика Марий Эл','mel':59,'krr':237,'rmz':230,'rl':153,		'rya':69,	'rshm':82,	'rp':88,'rz':148,'rmp':63,'rpsh':50,'rpz':37,'rtm':93,'gcr':21, 'rpsz':139, 'neo':679094},
	55: {'reg':'Республика Мордовия','mel':65,'krr':344,'rmz':336,'rl':352,		'rya':62,	'rshm':80,	'rp':161,'rz':208,'rmp':94,'rpsh':53,'rpz':89,'rtm':166,'gcr':47, 'rpsz':201, 'neo':790829},
	56: {'reg':'Республика Татарстан','mel':206,'krr':1745,'rmz':1625,'rl':1381,'rya':298,	'rshm':491,	'rp':543,'rz':1006,'rmp':349,'rpsh':278,'rpz':440,'rtm':498,'gcr':139, 'rpsz':922, 'neo':3902642},
	57: {'reg':'Республика Удмуртия','mel':100,'krr':670,'rmz':537,'rl':552,	'rya':121,	'rshm':144,	'rp':240,'rz':300,'rmp':113,'rpsh':97,'rpz':104,'rtm':146,'gcr':53, 'rpsz':373, 'neo':1501005},
	58: {'reg':'Республика Чувашия','mel':64,'krr':487,'rmz':408,'rl':408,		'rya':85,	'rshm':80,	'rp':152,'rz':238,'rmp':78,'rpsh':54,'rpz':88,'rtm':146,'gcr':54, 'rpsz':199, 'neo':1217820},
	59: {'reg':'Ханты-Мансийский а.о.','mel':58,'krr':548,'rmz':557,'rl':460,	'rya':84,	'rshm':162,	'rp':212,'rz':231,'rmp':100,'rpsh':49,'rpz':132,'rtm':157,'gcr':151, 'rpsz':267, 'neo':1674086},
	60: {'reg':'Ямало-Ненецкий а.о.','mel':23,'krr':135,'rmz':174,'rl':96,		'rya':23,	'rshm':43,	'rp':71,'rz':52,'rmp':37,'rpsh':26,'rpz':27,'rtm':48,'gcr':11, 'rpsz':55, 'neo':544008},
	61: {'reg':'Курганская область','mel':47,'krr':365,'rmz':424,'rl':471,		'rya':121,	'rshm':148,	'rp':160,'rz':200,'rmp':103,'rpsh':47,'rpz':104,'rtm':130,'gcr':54, 'rpsz':187, 'neo':826941},
	62: {'reg':'Свердловская область','mel':322,'krr':1899,'rmz':1780,'rl':1594,'rya':325,	'rshm':464,	'rp':625,'rz':912,'rmp':400,'rpsh':206,'rpz':464,'rtm':616,'gcr':191, 'rpsz':1032, 'neo':4310861},
	63: {'reg':'Тюменская обл.(б а.о)','mel':95,'krr':604,'rmz':664,'rl':636,	'rya':135,	'rshm':210,	'rp':322,'rz':308,'rmp':156,'rpsh':74,'rpz':161,'rtm':207,'gcr':94, 'rpsz':651, 'neo':1537684},
	64: {'reg':'Челябинская область','mel':222,'krr':1625,'rmz':1556,'rl':1220,'rya':365,	'rshm':461,	'rp':504,'rz':786,'rmp':343,'rpsh':171,'rpz':290,'rtm':653,'gcr':115, 'rpsz':1131, 'neo':3466960},
	65: {'reg':'Алтайский край','mel':186,'krr':1097,'rmz':1045,'rl':1241,		'rya':241,	'rshm':258,	'rp':450,'rz':520,'rmp':281,'rpsh':116,'rpz':289,'rtm':404,'gcr':106, 'rpsz':741, 'neo':2317052},
	66: {'reg':'Красноярский край','mel':155,'krr':1187,'rmz':1351,'rl':1091,	'rya':246,	'rshm':452,	'rp':436,'rz':674,'rmp':355,'rpsh':123,'rpz':280,'rtm':474,'gcr':129, 'rpsz':931, 'neo':2867875},
	67: {'reg':'Иркутская область','mel':163,'krr':1061,'rmz':1108,'rl':1109,	'rya':259,	'rshm':414,	'rp':450,'rz':651,'rmp':245,'rpsh':147,'rpz':305,'rtm':346,'gcr':117, 'rpsz':732, 'neo':2390827},
	68: {'reg':'Кемеровская область','mel':179,'krr':1112,'rmz':1198,'rl':1144,	'rya':206,	'rshm':314,	'rp':420,'rz':509,'rmp':315,'rpsh':108,'rpz':268,'rtm':461,'gcr':118, 'rpsz':713, 'neo':2657785},
	69: {'reg':'Новосибирская область','mel':145,'krr':1321,'rmz':1356,'rl':1276,'rya':280,	'rshm':272,	'rp':506,'rz':603,'rmp':382,'rpsh':117,'rpz':393,'rtm':464,'gcr':146, 'rpsz':804, 'neo':2798251},
	70: {'reg':'Омская область','mel':132,'krr':906,'rmz':953,'rl':952,			'rya':188,	'rshm':231,	'rp':307,'rz':416,'rmp':221,'rpsh':84,'rpz':234,'rtm':327,'gcr':50, 'rpsz':546, 'neo':1926562},
	71: {'reg':'Томская область','mel':59,'krr':520,'rmz':502,'rl':411,			'rya':81,	'rshm':136,	'rp':157,'rz':267,'rmp':123,'rpsh':52,'rpz':128,'rtm':153,'gcr':98, 'rpsz':339, 'neo':1079051},
	74: {'reg':'Республика Алтай','mel':9,'krr':70,'rmz':59,'rl':75,			'rya':14,	'rshm':27,	'rp':29,'rz':32,'rmp':13,'rpsh':9,'rpz':21,'rtm':16,'gcr':18, 'rpsz':27, 'neo':220140},
	75: {'reg':'Республика Тыва','mel':4,'krr':50,'rmz':96,'rl':96,				'rya':36,	'rshm':95,	'rp':37,'rz':82,'rmp':17,'rpsh':19,'rpz':25,'rtm':12,'gcr':43, 'rpsz':31, 'neo':327388},
	76: {'reg':'Республика Хакасия','mel':29,'krr':194,'rmz':218,'rl':238,		'rya':46,	'rshm':77,	'rp':97,'rz':120,'rmp':47,'rpsh':32,'rpz':54,'rtm':74,'gcr':16, 'rpsz':159, 'neo':534186},
	77: {'reg':'Приморский край','mel':142,'krr':705,'rmz':765,'rl':815,		'rya':170,	'rshm':230,	'rp':272,'rz':379,'rmp':184,'rpsh':119,'rpz':229,'rtm':263,'gcr':73, 'rpsz':423, 'neo':1895305},
	78: {'reg':'Хабаровский край','mel':95,'krr':541,'rmz':551,'rl':655,		'rya':106,	'rshm':177,	'rp':233,'rz':308,'rmp':142,'rpsh':82,'rpz':152,'rtm':191,'gcr':58, 'rpsz':328, 'neo':1315310},
	79: {'reg':'Амурская область','mel':53,'krr':298,'rmz':336,'rl':337,		'rya':52,	'rshm':119,	'rp':134,'rz':163,'rmp':71,'rpsh':43,'rpz':89,'rtm':122,'gcr':34, 'rpsz':205, 'neo':790676},
	80: {'reg':'Камчатский край','mel':25,'krr':136,'rmz':121,'rl':131,			'rya':20,	'rshm':53,	'rp':53,'rz':73,'rmp':27,'rpsh':27,'rpz':45,'rtm':41,'gcr':15, 'rpsz':54, 'neo':312438},
	81: {'reg':'Магаданская область','mel':8,'krr':56,'rmz':76,'rl':63,			'rya':10,	'rshm':45,	'rp':26,'rz':27,'rmp':16,'rpsh':11,'rpz':15,'rtm':21,'gcr':2, 'rpsz':33, 'neo':140199},
	82: {'reg':'Сахалинская область','mel':31,'krr':197,'rmz':222,'rl':263,		'rya':48,	'rshm':89,	'rp':116,'rz':177,'rmp':66,'rpsh':50,'rpz':83,'rtm':69,'gcr':50, 'rpsz':126, 'neo':488453},
	72: {'reg':'Забайкальский край','mel':32,'krr':310,'rmz':390,'rl':436,		'rya':102,	'rshm':268,	'rp':154,'rz':239,'rmp':96,'rpsh':61,'rpz':93,'rtm':108,'gcr':30, 'rpsz':298, 'neo':1059657},
	83: {'reg':'Чукотский авт.округ','mel':1,'krr':16,'rmz':19,'rl':32,			'rya':4,	'rshm':3,	'rp':10,'rz':12,'rmp':5,'rpsh':8,'rpz':5,'rtm':2,'gcr':5, 'rpsz':5, 'neo':50726},
	73: {'reg':'Республика Бурятия','mel':34,'krr':322,'rmz':333,'rl':407,		'rya':89,	'rshm':225,	'rp':127,'rz':231,'rmp':72,'rpsh':95,'rpz':102,'rtm':89,'gcr':80, 'rpsz':161, 'neo':986109},
	84: {'reg':'Республика Саха (Якутия)','mel':13,'krr':211,'rmz':242,'rl':323,'rya':53,	'rshm':131,	'rp':136,'rz':180,'rmp':49,'rpsh':64,'rpz':80,'rtm':59,'gcr':145, 'rpsz':90, 'neo':970105},
	85: {'reg':'Еврейская авт. обл.','mel':5,'krr':60,'rmz':54,'rl':75,			'rya':19,	'rshm':24,	'rp':15,'rz':39,'rmp':27,'rpsh':10,'rpz':13,'rtm':19,'gcr':5, 'rpsz':29, 'neo':158381}
}

export default regStat;