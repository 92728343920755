; "use strict";

import State from './state';
import drugData from './drugdata';
import rzData from './rz-meth';
import stData from './rz-st';

class RzModel {

	constructor() {

		let group;

		for(group in rzData) State.rzPatsBuf[group] = -1;
	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = rzData[st].meth;
//console.log(st);
//if(st!='st0_2_6') return 0;
//console.log('------------>');
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==2) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}


/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData;

		if(State.flNoDist) return; // Отладочный режим

		pathData = rzData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
		State.calc.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}

/////////////////////////////
// Суммируем для текущией патологии всех пациентов для данной группы
// Необходима в случае, если сумма процентов для всех методов в группе больше 100%

	summGroupPats(group) {

		let totalPatsBuf = State.stPats[7];
		let total = totalPatsBuf[group];
		let methSet = rzData[group].meth;
		let summ = 0, len, i, pats;

		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			pats = total*methSet[i].rate/100.0;
			summ += pats;
		}
		return summ;
	}

/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

// // Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
// 		let pathData = rzData;
// 		let currGroup, currSumm,i,customVal;
// 		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
// 							// терапию в течение года 
// 		for(currGroup in pathData) {
//  			currSumm = this.summGroupPats(currGroup);
//  			currSumm = Math.ceil(currSumm);
// // Заносим сумму пациентов в данные группы
//  			if(!State.flNoDist)	pathData[currGroup].pats = currSumm;
// // Для каждой группы перераспределяем пациентов по методам
// 			this.distributeMeth(currGroup);
// 		}
// 		for(currGroup in pathData) {
// 			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
// 			else pathSumm += pathData[currGroup].result;
// 		}
// 		// Сохраняем полное фактическое кол-во пациентов в хранилище
// 		State.pathSumm[7] = pathSumm;

// <================


		let i, currGroup, pats;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in rzData) {
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры
			if(State.rzPatsBuf[currGroup]!=-1) {
				rzData[currGroup].pats = State.rzPatsBuf[currGroup]; 
			}

// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам больше 100%
// Суммируем пациентов внутри каждой группы

			let currGrData = rzData[currGroup].meth;
			let rzGrSumm = 0, len = currGrData.length, num;
			if(rzData[currGroup].result!=-1 && State.rzMultBuf[currGroup]) {
				pats = Math.round(rzData[currGroup].result/State.rzMultBuf[currGroup]);
			}
			else {
				pats = rzData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num; 
				rzGrSumm += num;
			}
			rzGrSumm = Math.ceil(rzGrSumm);
// Перезаписываем новое значение в группу
			rzData[currGroup].pats = rzGrSumm;
			pathSumm += rzGrSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
// Сохраняем множитель распределения в буфере
			State.rzMultBuf[currGroup] = rzGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.rzPatsBuf[currGroup] = pats;			
		}

		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[7] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РП

	distributeSt() {

		let Data = rzData, currGroup;
		let stPats = [], total = State.pathTotals[7];
		let totalPatsBuf = State.stPats[7];
		let stProbs = [5, 6, 8, 14, 12, 13, 42];

		let regProbs = stData[State.currRegNum];
		stProbs[0] = regProbs[0]*0.455;
		stProbs[1] = regProbs[0]*0.545;
		stProbs[2] = regProbs[1]*0.364;
		stProbs[3] = regProbs[1]*0.636;
		stProbs[4] = regProbs[2]*0.48;
		stProbs[5] = regProbs[2]*0.52;
		stProbs[6] = regProbs[3];
		const periProbs = [0, 5, 20, 30, 40, 40, 0];
		const aduvProbs = [0, 0, 50, 50, 40, 40, 0];
		const nonoperProbs = [0, 0, 20, 20, 20, 20, 0];
		const recid1Probs = [10, 20, 35, 50, 60, 80, 0];
		const ht1lineProbs = [80, 80, 70, 70, 70, 70, 80];
		const nonoperHt1Probs = [0, 0, 50, 50, 50, 50, 0];
		let periPats = [], aduvPats = [], nonoperPats = [], 
		recid1Pats = [], ht1linePats = []; 
		let periSumm = 0, aduvSumm = 0, ht1lineSumm = 0,
			nonoperHt1Summ = 0,
			dead = State.currLeth['rz'], i;
			for(i=0 ; i<7 ; i++) {
				stPats[i] = total*stProbs[i]/100.0;
				periPats[i] = stPats[i]*periProbs[i]/100.0;
				periSumm += periPats[i];
				aduvPats[i] = stPats[i]*aduvProbs[i]/100.0;
				aduvSumm += aduvPats[i];
				nonoperPats[i] = stPats[i]*nonoperProbs[i]/100.0;
				recid1Pats[i] = (periPats[i]+aduvPats[i]+nonoperPats[i])*recid1Probs[i]/100.0;
				if(i<2) recid1Pats[i] = stPats[i]*recid1Probs[i]/100.0;
				ht1linePats[i] = recid1Pats[i]*ht1lineProbs[i]/100.0;
				if(i==6) ht1linePats[i] = stPats[i]*ht1lineProbs[i]/100.0;
				ht1lineSumm += ht1linePats[i];
				nonoperHt1Summ += nonoperPats[i]*nonoperHt1Probs[i]/100.0;
			}

// Периоперационная ХТ
		Data['st0_0_1'].pats = Math.round(periSumm);
		totalPatsBuf['st0_0_1'] = Math.round(periSumm);
// Адъювантная ХТ
		Data['st0_0_2'].pats = Math.round(aduvSumm);
		totalPatsBuf['st0_0_2'] = Math.round(aduvSumm);

// Местнораспрострнанённая и диссеминированная карцинома после оперативного лечения
// Линия 1
		Data['st0_1_1'].pats = Math.round(ht1lineSumm);
		totalPatsBuf['st0_1_1'] = Math.round(ht1lineSumm);

// IIA - IIIB без оперативного лечения
// Линия 1
		Data['st0_1_2'].pats = Math.round(nonoperHt1Summ);
		totalPatsBuf['st0_1_2'] = Math.round(nonoperHt1Summ);

// Местнораспрострнанённая и диссеминированная карцинома после оперативного лечения
// Линия 2
		Data['st0_2_3'].pats = Math.round(ht1lineSumm/2.0);
		totalPatsBuf['st0_2_3'] = Math.round(ht1lineSumm/2.0);

// IIA - IIIB без оперативного лечения
// Линия 2
		Data['st0_2_4'].pats = Math.round(nonoperHt1Summ/2.0);
		totalPatsBuf['st0_2_4'] = Math.round(nonoperHt1Summ/2.0);

// Местнораспрострнанённая и диссеминированная карцинома после оперативного лечения
// Линия 3
		Data['st0_3_5'].pats = Math.round(ht1lineSumm/10.0);
		totalPatsBuf['st0_3_5'] = Math.round(ht1lineSumm/10.0);

// IIA - IIIB без оперативного лечения
// Линия 3
		Data['st0_3_6'].pats = Math.round(nonoperHt1Summ/10.0);
		totalPatsBuf['st0_3_6'] = Math.round(nonoperHt1Summ/10.0);

		for(currGroup in Data) {
			State.rzPatsBuf[currGroup] = -1;
			Data[currGroup].result = -1;
		}

		this.distributeAllMeth();
	}
		

}

export default RzModel;