;"use strict";

import drugData from './drugdata';
import State from './state';
// Цены указаны без НДС
// Drug Types:
// t - target
// c - cytostat
// h - hormon
// i - immuno
// o - other
let initTable = [
	{ name: '5-фторурацил 1000 мг, №1',		price: 182.44, 	list: 1, dose: 1000, 	set: 1, on: 0, type: 'c'}, // 0 // DELETED => 61
	{ name: 'Афлиберцепт 200 мг №1',		price: 41350, 	list: 1, dose: 200,		set: 1, on: 1, type: 't'}, // 1
	{ name: 'Бевацизумаб 400 мг №1',		price: 34508, 	list: 1, dose: 400,		set: 1, on: 1, type: 't'}, // 2
	{ name: 'Вемурафениб 240 мг №56',		price: 43185.94,list: 1, dose: 240, 	set: 56, on: 0, type: 't'}, // 3
	{ name: 'Дабрафениб 75 мг №120',		price: 226039.65,list: 1, dose: 75, 		set: 120, on: 0, type: 't'}, // 4
	{ name: 'Дакарбазин 200 мг №1',			price: 277.40, 	list: 1, dose: 200, 	set: 1, on: 1, type: 'c'}, // 5
	{ name: 'Иматиниб 400 мг №30',			price: 6110.00,	list: 1, dose: 400, 	set: 30, on: 1, type: 't'}, // 6
	{ name: 'Интерферон альфа 2b 3 млн Ед',	price: 459.65, 	list: 1, dose: 1, 		set: 1, on: 1, type: 'o'}, // 7
	{ name: 'Ипилимумаб 50 мг №1',			price: 186134.59,list: 1, dose: 50, 		set: 1, on: 0, type: 'i'}, // 8
	{ name: 'Иринотекан 100 мг №1',			price: 927.90,	list: 1, dose: 100,		set: 1, on: 0, type: 'c'}, // 9
	{ name: 'Капецитабин 500 мг №120',		price: 3737.29,	list: 1, dose: 500, 	set: 120, on: 1, type: 'c'}, // 10
	{ name: 'Карбоплатин 450 мг №1',		price: 1659.71, list: 1, dose: 450, 	set: 1, on: 0, type: 'c'}, // 11
	{ name: 'Кобиметиниб 20 мг №63',		price: 141335.82, 	list: 1, dose: 20, 		set: 63, on: 0, type: 't'}, // 12
	{ name: 'Кальция фолинат 50 мг №5',		price: 469.19,	list: 1, dose: 50, 		set: 5, on: 1, type: 'o'}, // 13
	{ name: 'Ниволумаб 100 мг №1',			price: 77691.35,list: 1, dose: 100, 	set: 1, on: 1, type: 'i'}, // 14
	{ name: 'Ниволумаб 40 мг №1',			price: 31076.23,list: 1, dose: 40, 		set: 1, on: 1, type: 'i'}, // 15
	{ name: 'Оксалиплатин 150 мг №1',		price: 6484.52,	list: 1, dose: 150, 	set: 1, on: 0, type: 'c'}, // 16
	{ name: 'Паклитаксел 100 мг №1',		price: 2267.36, list: 1, dose: 100, 	set: 1, on: 1, type: 'c'}, // 17
	{ name: 'Паклитаксел 30 мг №1',			price: 863.01, list: 1, dose: 30, 		set: 1, on: 1, type: 'c'}, // 18
	{ name: 'Панитумумаб 100 мг №1',		price: 22017.11,list: 1, dose: 100,		set: 1, on: 1, type: 't'}, // 19
	{ name: 'Пембролизумаб 100 мг №1',		price: 154977.5,list: 1, dose: 100, 	set: 1, on: 1, type: 'i'}, // 20
	{ name: 'Регорафениб 40 мг №84',		price: 158110.94, 	list: 1, dose: 40, 		set: 84, on: 0, type: 't'}, // 21
	{ name: 'Траметиниб 2 мг № 30',			price: 88040.38,list: 1, dose: 2, 		set: 30, on: 0, type: 't'}, // 22
	{ name: 'Цетуксимаб 100 мг №1',			price: 11272.06,list: 1, dose: 100,		set: 1, on: 1, type: 't'}, 	// 23
	{ name: 'Оксалиплатин 100 мг №1',		price: 4322.57,	list: 1, dose: 100, 	set: 1, on: 0, type: 'c'}, // 24
	{ name: 'Иринотекан 40 мг №1',			price: 572.51, 	list: 1, dose: 40, 		set: 1, on: 0, type: 'c'}, // 25
	{ name: 'Бевацизумаб 100 мг №1',		price: 8627, 	list: 1, dose: 100,		set: 1, on: 1, type: 't'}, // 26
	{ name: 'Афлиберцепт 100 мг №1',		price: 20675, 	list: 1, dose: 100,		set: 1, on: 1, type: 't'}, // 27
	{ name: 'Альбумин-связанный паклитаксел 100 мг',	price:  48883.55, 	list: 0, dose: 100,	set: 1, on: 0, type: 'c'}, // 28
	{ name: 'Анастрозол 1 мг №30',			price: 969.00, 	list: 1, dose: 1,		set: 30, on: 1, type: 'h'}, // 29
	{ name: 'Бусерелин 3.75 №1',			price: 3059.44, list: 1, dose: 3.75,	set: 1, on: 1, type: 'h'}, // 30
	{ name: 'Винорелбин 20 мг №1',			price: 2817.02, list: 1, dose: 20,		set: 1, on: 1, type: 'c'}, // 31
	{ name: 'Винорелбин 50 мг №1',			price: 2127.25, list: 1, dose: 50,		set: 1, on: 1, type: 'c'}, // 32
	{ name: 'Винорелбин пероральный, 20 мг №1',			price: 4235.82, 	list: 1, dose: 20,	set: 1, on: 1, type: 'c'}, // 33 DELETED => 31
	{ name: 'Гемцитабин 1000 мг №1',		price: 1261.1, list: 1, dose: 1000,	set: 1, on: 0, type: 'c'}, // 34
	{ name: 'Гозерелин 3.6 мг №1',			price: 2464.73, list: 1, dose: 3.6,		set: 1, on: 1, type: 'h'}, // 35
	{ name: 'Доксорубицин 10 мг №1',		price: 146.24, 	list: 1, dose: 10,		set: 1, on: 0, type: 'c'}, // 36
	{ name: 'Доцетаксел 80 мг №1',			price: 8276,list: 1, dose: 80,		set: 1, on: 1, type: 'c'}, // 37
	{ name: 'Иксабепилон 15 мг №1',			price: 18499.51,list: 1, dose: 15,		set: 1, on: 0, type: 'c'}, // 38
	{ name: 'Иксабепилон 45 мг №1',			price: 55120.22,list: 1, dose: 45,		set: 1, on: 0, type: 'c'}, // 39
	{ name: 'Капецитабин 500 мг №60',		price: 3277.68, list: 1, dose: 500,		set: 60, on: 1, type: 'c'}, // 40
	{ name: 'Карбоплатин 150 мг №1',		price: 712.72, 	list: 1, dose: 150,		set: 1, on: 0, type: 'c'}, // 41
	{ name: 'Лапатиниб 250 мг №140',		price: 107090, 	list: 1, dose: 250,	set: 140, on: 0, type: 't'}, // 42
	{ name: 'Лейпрорелин 3.75 №1',			price: 4512.49, list: 1, dose: 3.75,	set: 1, on: 1, type: 'h'}, // 43
	{ name: 'Летрозол 2.5 мг №30',			price: 363.60, list: 0, dose: 2.5,		set: 30, on: 0, type: 'h'}, // 44
	{ name: 'Липосомальный доксорубицин 20 мг №1',		price: 33981.60, 	list: 1, dose: 20,	set: 1, on: 0, type: 'c'}, // 45 DELETED ==> 86
	{ name: 'Медроксипрогестерон 500 мг №30',			price: 2200.00, 	list: 1, dose: 500,	set: 30, on: 1, type: 'h'}, // 46
	{ name: 'Метотрексат 10 мг №1',			price: 51.70, 	list: 1, dose: 10,		set: 1, on: 1, type: 'c'}, // 47
	{ name: 'Метотрексат 2.5 мг №50',		price: 130.28, 	list: 1, dose: 2.5,		set: 50, on: 1, type: 'c'}, // 48
	{ name: 'Паклитаксел, 6 мг №16.7',		price: 2267.36, list: 1, dose: 6,		set: 16.7, on: 1, type: 'c'}, // 49 DELETED => 17
	{ name: 'Паклитаксел 150 мг №1',		price: 3394.25, list: 1, dose: 150,		set: 1, on: 1, type: 'c'}, // 50
	{ name: 'Палбоциклиб 125 мг №21',		price: 66211.92, 	list: 1, dose: 125,	set: 21, on: 0, type: 't'}, // 51
	{ name: 'Пертузумаб 420 мг №1',			price: 147831.58, 	list: 1, dose: 420,	set: 1, on: 1, type: 't'}, // 52
	{ name: 'Рибоциклиб 200 мг №63',		price: 105000.00, 	list: 1, dose: 200,	set: 63, on: 0, type: 't'}, // 53
	{ name: 'Тамоксифен 20 мг №30',			price: 100.22, 	list: 1, dose: 20,		set: 30, on: 1, type: 'h'}, // 54
	{ name: 'Торемифен 60 мг №60',			price: 6869.47, list: 0, dose: 60,		set: 60, on: 0, type: 'h'}, // 55
	{ name: 'Трастузумаб 440 мг №1',		price: 51141.20,list: 1, dose: 440,		set: 1, on: 1, type: 't'}, // 56
	{ name: 'Трастузумаб 150 мг №1',		price: 17434.50,list: 1, dose: 150,		set: 1, on: 1, type: 't'}, // 57
	{ name: 'Трастузумаб эмтанзин 100 мг №1',		price: 105725.63, 	list: 1, dose: 100,	set: 1, on: 0, type: 't'}, // 58
	{ name: 'Трастузумаб эмтанзин 160 мг №1',		price: 169173.01, 	list: 1, dose: 160,	set: 1, on: 0, type: 't'}, // 59
	{ name: 'Трипторелин 3.75 №1',			price: 3956.32,	list: 1, dose: 3.75,	set: 1, on: 1, type: 'h'}, // 60
	{ name: 'Фторурацил 1000 мг №1',		price: 182.44, 	list: 1, dose: 1000,	set: 1, on: 0, type: 'c'}, // 61
	{ name: 'Фулвестрант 250 мг №2',		price: 21216.86,list: 1, dose: 250,		set: 2, on: 1, type: 'h'}, // 62
	{ name: 'Циклофосфамид 50 мг №50',		price: 668.05, 	list: 1, dose: 50,		set: 50, on: 1, type: 'c'}, // 63
	{ name: 'Циклофосфамид 200 мг №1',		price: 82.69, 	list: 1, dose: 200,		set: 1, on: 1, type: 'c'}, // 64
	{ name: 'Эрибулин 1 мг №1',				price: 23362, 	list: 1, dose: 1,		set: 1, on: 0, type: 'c'}, // 70 -> 65
	{ name: 'Цисплатин 50 мг №1',			price: 135.75, 	list: 1, dose: 50,		set: 1, on: 0, type: 'c'}, // 66
	{ name: 'Эверолимус 10 мг №30',			price: 81897, 	list: 1, dose: 10,	set: 30, on: 1, type: 't'}, // 67
	{ name: 'Эксеместан 25 мг №30',			price: 1376.41, list: 0, dose: 25,		set: 30, on: 0, type: 'h'}, // 68
	{ name: 'Эпирубицин 10 мг №1',			price: 310.34, 	list: 1, dose: 10,		set: 1, on: 0, type: 'c'}, // 69
	{ name: 'Алектиниб 150 мг №224',		price: 213696.76, 	list: 1, dose: 150,	set: 224, on: 0, type: 't'}, // 70
	{ name: 'Атезолизумаб 1200 мг №1',		price: 215930.09, 	list: 1, dose: 1200,set: 1, on: 0, type: 'i'}, 	// 71
	{ name: 'Афатиниб 40 мг №30',			price: 87959.54,list: 1, dose: 40,		set: 30, on: 1, type: 't'}, 	// 72
	{ name: 'Гефитиниб 250 мг №30',			price:  40708.38, list: 1, dose: 250,		set: 30, on: 1, type: 't'}, 	// 73
	{ name: 'Кризотиниб 250 мг №60',		price: 229190, 	list: 1, dose: 250,		set: 60, on: 0, type: 't'}, 	// 74
	{ name: 'Осимертиниб 80 мг №30',		price: 250000, 	list: 1, dose: 80,		set: 30, on: 0, type: 't'}, 	// 75
	{ name: 'Пеметрексед 100 мг №1',		price: 4959.2, list: 1, dose: 100,		set: 1, on: 0, type: 'c'}, 	// 76
	{ name: 'Церитиниб 150 мг №90',			price: 103135.5, 	list: 1, dose: 150,		set: 90, on: 0, type: 't'}, // 77
	{ name: 'Эрлотиниб 150 мг №30',			price: 38303.24,	list: 1, dose: 150,		set: 30, on: 1, type: 't'}, 	// 78
	{ name: 'Этопозид 100 мг №1',			price: 231.00, 	list: 1, dose: 100,		set: 1, on: 1, type: 'c'}, 	// 79
	{ name: 'Винкристин 1 мг №1',			price: 125.7, 	list: 1, dose: 1,		set: 1, on: 0, type: 'c'}, 	// 80
	{ name: 'Топотекан 1 мг №1',			price: 1739.30,	list: 0, dose: 1,		set: 1, on: 0, type: 'c'}, 	// 81
	{ name: 'Трастузумаб подкожная форма 600 мг №1', price: 69589.96, list: 1, dose: 600, set: 1, on: 1, type: 't'}, 	// 82
	{ name: 'Гемцитабин 200 мг №1', price: 344.0, list: 1, dose: 200, set: 1, on: 0, type: 'c'}, 	// 83
	{ name: 'Доксорубицин 50 мг №1', price:  577.22, list: 1, dose: 50, set: 1, on: 0, type: 'c'}, 	// 84
	{ name: 'Доксорубицин пегилированный липосомальный 50 мг №1', price: 84954.0, list: 1, dose: 50, set: 1, on: 0, type: 'c'}, // 85
	{ name: 'Доксорубицин пегилированный липосомальный 20 мг №1', price: 33981.60, list: 1, dose: 20, set: 1, on: 0, type: 'c'}, // 86
	{ name: 'Доцетаксел 20 мг №1', price: 2034.77, list: 1, dose: 20, set: 1, on: 1, type: 'c'}, 	// 87
	{ name: 'Доцетаксел 120 мг №1', price: 12414, list: 1, dose: 120, set: 1, on: 1, type: 'c'}, 	// 88
	{ name: 'Мегестрол 160 мг №30', price: 108.22, list: 0, dose: 160, set: 30, on: 0, type: 'h'}, 	// 89
	{ name: 'Олапариб 50 мг №448', price: 423798.30, list: 0, dose: 50, set: 448, on: 0, type: 't'}, 	// 90
	{ name: 'Паклитаксел 300 мг №1', price: 6788.50, list: 1, dose: 300, set: 1, on: 1, type: 'c'}, 	// 91
	{ name: 'Тамоксифен 40 мг №30', price: 232.5, list: 1, dose: 40, set: 30, on: 1, type: 'h'}, 	// 92
	{ name: 'Цисплатин 20 мг №1', price: 126.37, list: 1, dose: 20, set: 1, on: 0, type: 'c'}, 	// 93
	{ name: 'Этопозид 20 мг №10', price: 3235.12, list: 1, dose: 20, set: 10, on: 1, type: 'c'}, 	// 94
	{ name: 'Топотекан 4 мг №5', price: 5268.71, list: 0, dose: 4, set: 5, on: 0, type: 'c'}, 	// 95
	{ name: 'Ифосфамид 2 000 мг №1', price: 1230.00, list: 1, dose: 2000, set: 1, on: 0, type: 'c'},	// 96
	{ name: 'Митомицин 10 мг №1', price:  3977.95, list: 1, dose: 10, set: 1, on: 0, type: 'o'}, 	// 97
	{ name: 'Месна 400 мг №1', price:  72.05, list: 1, dose: 400, set: 1, on: 0, type: 'o'},	 	// 98
	{ name: 'Акситиниб 5 мг №56', price: 113013, list: 1, dose: 5, set: 56, on: 1, type: 't'},	 	// 99
	{ name: 'Акситиниб 1 мг №56', price: 22602.3, list: 1, dose: 1, set: 56, on: 1, type: 't'},	 	// 100
	{ name: 'Ленватиниб 10 мг №30', price: 95000, list: 1, dose: 10, set: 30, on: 1, type: 't'},	 	// 101
	{ name: 'Ленватиниб 4 мг №30', price: 39600, list: 1, dose: 4, set: 30, on: 1, type: 't'},	 	// 102
	{ name: 'Пазопаниб 400 мг №60', price: 49050.16, list: 1, dose: 400, set: 60, on: 0, type: 't'},	 	// 103
	{ name: 'Сорафениб 200 мг №112', price: 79740, list: 1, dose: 200, set: 112, on: 1, type: 't'},	 	// 104
	{ name: 'Сунитиниб 50 мг №28', price: 126958.07, list: 1, dose: 50, set: 28, on: 0, type: 't'},	 	// 105
	{ name: 'Темсиролимус 25 мг №1', price: 56172.87, list: 0, dose: 25, set: 1, on: 0, type: 't'},	 	// 106
	{ name: 'Эверолимус 5 мг №30', price: 40948.50, list: 1, dose: 5, set: 30, on: 1, type: 't'},	 	// 107
	{ name: 'Рамуцирумаб 500 мг №1', price: 137977, list: 1, dose: 500, set: 1, on: 0, type: 't'},	 	// 108
	{ name: 'Рамуцирумаб 100 мг №1', price: 27595.4, list: 1, dose: 100, set: 1, on: 0, type: 't'},	 	// 109
	{ name: 'Этопозид 50 мг №1', price: 115.50, list: 1, dose: 50, set: 1, on: 1, type: 'c'},	 	// 110
	{ name: 'Этопозид 100 мг №10', price: 3235.12, list: 1, dose: 100, set: 10, on: 1, type: 'c'}, 	// 111
	{ name: 'Винорелбин 10 мг №1',		price: 425.45, list: 1, dose: 10,	set: 1, on: 1, type: 'c'}, // 112

	{ name: 'Винбластин 5 мг №1',		price: 147.53, list: 1, dose: 5,	set: 1, on: 0, type: 'o'}, // 113
	{ name: 'Винфлунин 250 мг №1',		price:  77623.96, list: 0, dose: 250,	set: 1, on: 0, type: 'o'}, // 114
	{ name: 'Винфлунин 50 мг №1',		price:  9641.45, list: 0, dose: 50,	set: 1, on: 0, type: 'o'}, // 115
	{ name: 'Митомицин 20 мг №1',		price:  1591.18, list: 1, dose: 20,	set: 1, on: 0, type: 'o'}, // 116
//	{ name: 'Вакцина БЦЖ 50 мг №2',		price: 998.20, list: 1, dose: 50,	set: 2, on: 0} // 117
	{ name: 'Вакцина БЦЖ 50 мг №2',		price: 998.20, list: 1, dose: 50,	set: 1, on: 0, type: 'o'}, // 117
	{ name: 'Медроксипрогестерона ацетат 100 мг №100', price: 177.65, list: 1, dose: 100,	set: 100, on: 1, type: 'h'}, // 118

// ==========> RPSZ

	{ name: 'Абиратерон 250 мг № 120', price: 78631.05, list: 1, dose: 250,	set: 120, on: 0, type: 'h'}, // 119
	{ name: 'Бикалутамид 50 мг №28', price: 900, list: 1, dose: 50,	set: 28, on: 1, type: 'h'}, // 120
	{ name: 'Гозерелин 10,8 мг №1 ', price: 11219.52, list: 1, dose: 10.8,	set: 1, on: 1, type: 'h'}, // 121
	{ name: 'Дегареликс 120 мг №2', price: 14036.56, list: 1, dose: 120,	set: 2, on: 0, type: 'h'}, // 122
	{ name: 'Дегареликс 80 мг №1', price: 7710.37, list: 1, dose: 80,	set: 1, on: 0, type: 'h'}, // 123
	{ name: 'Кабазитаксел 60 мг №1', price: 227185, list: 1, dose: 60,	set: 1, on: 0, type: 'c'}, // 124
	{ name: 'Лейпрорелин 7,5 мг №1', price: 6817.78, list: 1, dose: 7.5,	set: 1, on: 1, type: 'h'}, // 125
	{ name: 'Лейпрорелин 22,5 мг №1', price: 17045.32, list: 1, dose: 22.5,	set: 1, on: 1, type: 'h'}, // 126
	{ name: 'Лейпрорелин 45 мг №1', price: 26102.19, list: 1, dose: 45,	set: 1, on: 1, type: 'h'}, // 127
	{ name: 'Преднизолон 5 мг №100', price: 20.93, list: 1, dose: 5,	set: 100, on: 1, type: 'h'}, // 128
	{ name: 'Радий-223 6600 кБк №1 ', price: 304308, list: 1, dose: 6600,	set: 1, on: 0, type: 'o'}, // 129
	{ name: 'Трипторелин 11,25 мг №1', price: 9468.38, list: 1, dose: 11.25,	set: 1, on: 1, type: 'h'}, // 130
	{ name: 'Флутамид 250 мг №84', price: 1315.69, list: 1, dose: 250,	set: 84, on: 1, type: 'h'}, // 131
	{ name: 'Энзалутамид 40 мг №112', price: 155740, list: 1, dose: 40,	set: 112, on: 1, type: 'h'}, // 132
	{ name: 'Апалутамид 60 мг №120', price: 131822, list: 1, dose: 60,	set: 120, on: 0, type: 'h'}, // 133

// ==========> NEO

	{ name: 'Ланреотид 120 мг №1', price: 61661.7, list: 1, dose: 120,	set: 1, on: 0, type: 'c'}, // 134
	{ name: 'Октреотид 30 мг №1', price: 37645.03, list: 0, dose: 30,	set: 1, on: 0, type: 'c'}, // 135
	{ name: 'Октреотид 20 мг №1', price: 25451.84, list: 0, dose: 20,	set: 1, on: 0, type: 'c'}, // 136
	{ name: 'Сунитиниб 12,5 мг №28', price: 31746.72, list: 1, dose: 12.5,	set: 28, on: 0, type: 'c'}, // 137
	{ name: 'Сунитиниб 25 мг №28', price: 63486.17, list: 1, dose: 25,	set: 28, on: 0, type: 'c'}, // 138
	{ name: 'Темозоломид 140 мг №5', price: 7322.14, list: 1, dose: 140,	set: 5, on: 0, type: 'c'}, // 139

// ==========> RP Update

	{ name: 'Кабозантиниб 60 мг №30', price:  327180, list: 1, dose: 60,	set: 30, on: 0, type: 'o'}, // 140

// ==========> RYA Update

	{ name: 'Олапариб таб 150 мг №56', price:  142200, list: 1, dose: 150,	set: 56, on: 0, type: 't'}, // 141

// ==========> RL20 Update

	{ name: 'Дурвалумаб 500 мг №1', price:  141250, list: 1, dose: 500,	set: 1, on: 0, type: 't'}, // 142
	{ name: 'Дурвалумаб 120 мг №1', price:  33900, list: 1, dose: 120,	set: 1, on: 0, type: 't'}, // 143
	{ name: 'Нинтеданиб 100 мг №60', price: 70712.29, list: 1, dose: 100,	set: 60, on: 0, type: 't'}, // 144

// ==========> MEL20 Update

	{ name: 'Дакарбазин 500 мг №1',	price: 701.44, 	list: 1, dose: 500, 	set: 1, on: 1, type: 'c'}, // 145
	{ name: 'Темозоломид 180 мг №5', price: 9512.29, list: 1, dose: 180,	set: 5, on: 0, type: 'c'}, // 146
	{ name: 'Арабинопиранозилметил нитрозомочевина 0,5 г №6', price: 101398.57, list: 0, dose: 500,	set: 6, on: 0, type: 'о'}, // 147
	{ name: 'Ломустин 40 мг №20', price:  2910.31, list: 1, dose: 40,	set: 20, on: 0, type: 'o'} // 148
];

let dataSet ;

class DrugStore {

	constructor() {

		const lsName = 'mn_store';
		let currDrug, cnt, len, dataStr;

		dataSet = localStorage.getItem(lsName);
		if(null == dataSet) {
//!!D
//		if(true) {
			len = initTable.length;
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = initTable[cnt];
				currDrug.changed = 0;
			}
			dataStr = JSON.stringify(initTable);
			localStorage.setItem(lsName, dataStr);
console.log('Price Storage init');

		}

		dataStr = localStorage.getItem(lsName);	
		dataSet = JSON.parse(dataStr);
	}

////////////////////////////////////////

	resetPrice() {

		const lsName = 'mn_store';
		let currDrug, cnt, len, dataStr;

console.log('Reset Price');

		len = initTable.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			currDrug = initTable[cnt];
			currDrug.changed = 0;
		}
		dataStr = JSON.stringify(initTable);
		localStorage.setItem(lsName, dataStr);

		dataStr = localStorage.getItem(lsName);	
		dataSet = JSON.parse(dataStr);
		this.fillDrugData();
		State.priceTable.render();
		State.methPanel.displayResult();
	}

////////////////////////////////////////

	resetStorage() {

		const lsName = 'mn_store';

		localStorage.removeItem(lsName);

	}

////////////////////////////////////////

	objCopy(src, dest) {
		let key;

		for(key in src) {
			if(src.hasOwnProperty(key)) dest[key] = src[key];
		}
	}


////////////////////////////////////////

	fillDrugData() {

		const len = dataSet.length;
		let cnt, currDrug, price, regAdd, i, tabLen, 
			tabletList = State.tabletForm;

		// Устанавливаем признак округления для таблеток
		tabLen = tabletList.length;
		for(i=0 ; i<tabLen ; i++) {
			dataSet[tabletList[i]].tab = 1;
		}	
		// Устанавливаем признак инъекционного препарата
		for(i=0 ; i<len ; i++) dataSet[i].inj = 1;

		tabLen = State.perorForm.length;
		for(i=0 ; i<tabLen ; i++) {
			dataSet[State.perorForm[i]].inj = 0;
		}	
		drugData.length = 0;
		if(State.flRegPrice) { 
			regAdd = State.currRegAdd;
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = {};
				this.objCopy(dataSet[cnt], currDrug); 
				price = currDrug.price;
				if(currDrug.changed==0) {
					if(currDrug.list==1) price += price/100.0*regAdd; 
					price = Math.floor(price*1.1*100); // +10% VAT
					currDrug.price = price/100
				}
				drugData.push(currDrug);
			}
		} else { // Don't multiply by regional koefficient
			for(cnt=0 ; cnt<len ; cnt++) {
				currDrug = {};
				this.objCopy(dataSet[cnt], currDrug); 
				price = currDrug.price;
				if(currDrug.changed==0) {
					price = Math.floor(price*1.1*100); // +10% VAT
					currDrug.price = price/100
				}
				drugData.push(currDrug);
			}

		}
	}

/////////////////////////////////

	saveData(num) {

		let newData, diff, price, currDrug, dataStr;
		const regAdd = State.regAdd,
				lsName = 'mn_store';

		newData = Math.floor(drugData[num].price*100);
		price = dataSet[num].price;
		if(State.flRegPrice) {
			price = dataSet[num].price;
			if(dataSet[num].changed==0) {
				if(dataSet[num].list==1) price += price/100.0*regAdd; 
				price = Math.floor(price*1.1*100); // +10% VAT
			} else price = Math.floor(price*100);
		} else {
			if(dataSet[num].changed==0) {
				price = Math.floor(price*1.1*100); // +10% VAT
			} else price = Math.floor(price*100);
		}

		diff = newData - price ;
//!!TODO Установить разумный порог детектирования изменений
		if(diff!=0) {
			dataSet[num].price = drugData[num].price;
			drugData[num].changed = 1;
			dataSet[num].changed = 1;
			dataStr = JSON.stringify(dataSet);
			localStorage.setItem(lsName,dataStr);
		}

	}


}

export default DrugStore;