;"use strict";

const rshmData = {

// ============================>rshm
// ---------> Ib + IIa + IIb стадии, адъювантная ХЛТ в группе высокого риска прогрессирования
		'st0_0_1':{
			group: 'Ib + IIa + IIb стадии, адъювантная ХЛТ в группе высокого риска прогрессирования',
			meth: [
				{
					id: 743,
					code: '?',
					name: 'Цисплатин',
					info: '(цисплатин 40 мг/м2 на фоне ЛТ)',
					drugs: [66,93],
					rate: 100,
					dose: [50,20],
					ds: '',
					st: '',
					cycles: [24,24],
					pats: 41
				}
			],
			pats: 0
		},

// ---------> Ib + IIa + Iib + IIIa + IIIb + IVa стадии, радикальная ХЛТ
		'st0_0_2':{
			group: 'Ib + IIa + Iib + IIIa + IIIb + IVa стадии, радикальная ХЛТ',
			meth: [
				{
					id: 744,
					code: '?',
					name: 'Цисплатин',
					info: '(цисплатин 40 мг/м2 на фоне ЛТ)',
					drugs: [66,93],
					rate: 100,
					dose: [50,20],
					ds: '',
					st: '',
					cycles: [24,24],
					pats: 92
				}
			],
			pats: 0
		},

// ---------> Ib + IIa стадии, неоадъювантная ХТ
		'st0_0_3':{
			group: 'Ib + IIa стадии, неоадъювантная ХТ',
			meth: [
				{
					id: 745,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 70,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 18
				},
				{
					id: 746,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2 + карбоплатин AUC 6)',
					drugs: [17,41],
					rate: 30,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [3,3],
					pats: 8
				}
			],
			pats: 0
		},

// ---------> IVb стадия Линия 1
		'st0_1_4':{
			group: 'IVb стадия',
			meth: [
				{
					id: 747,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 748,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 1,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 749,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м)',
					drugs: [17,66],
					rate: 20,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 750,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 20,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 2
				},
				{
					id: 751,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 20,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 752,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 5,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 753,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 5,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 754,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 10,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 755,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 10,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 756,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 5,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 757,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 2,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась  Линия 2
		'st0_2_5':{
			group: 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась ',
			meth: [
				{
					id: 758,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 5,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 759,
					code: 'sh0800',
					name: 'Иринотекан 125 мг/м2',
					info: '(иринотекан 125 мг/м2)',
					drugs: [9,25],
					rate: 30,
					dose: [600,80],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 760,
					code: 'sh0035.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 30,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 761,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 10,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 762,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 25,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение Линия 2
		'st0_2_8':{
			group: 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение',
			meth: [
				{
					id: 763,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 764,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 765,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 25,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 25
				},
				{
					id: 766,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 15,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 15
				},
				{
					id: 767,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2 + карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 20,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 20
				},
				{
					id: 768,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 5,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 769,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 770,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 10,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 10
				},
				{
					id: 771,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 10,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 10
				},
				{
					id: 772,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 5,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 773,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 0,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 774,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 0,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 775,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 0,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 776,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 5,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> П-ты местного рецидива с распространением на соседние органы Линия 2
		'st0_2_11':{
			group: 'П-ты местного рецидива с распространением на соседние органы',
			meth: [
				{
					id: 777,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 778,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 779,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 25,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 12
				},
				{
					id: 780,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 15,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 7
				},
				{
					id: 781,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 20,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 9
				},
				{
					id: 782,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 5,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 783,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 784,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 10,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 5
				},
				{
					id: 785,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 10,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 5
				},
				{
					id: 786,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 5,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 787,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 0,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 0
				},
				{
					id: 788,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 0,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 789,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 0,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 790,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 5,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась  Линия 3
		'st0_3_6':{
			group: 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась ',
			meth: [
				{
					id: 791,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 5,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 792,
					code: 'sh0800',
					name: 'Иринотекан 125 мг/м2',
					info: '(иринотекан 125 мг/м2)',
					drugs: [9,25],
					rate: 15,
					dose: [600,80],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 793,
					code: 'sh0035.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 40,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 7
				},
				{
					id: 794,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 20,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 795,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 20,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение Линия 3
		'st0_3_9':{
			group: 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение',
			meth: [
				{
					id: 796,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 797,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 798,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 5,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 799,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 10,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 10
				},
				{
					id: 800,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2 + карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 5,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 801,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 5,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 5
				},
				{
					id: 802,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 803,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 10,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 10
				},
				{
					id: 804,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 10,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 10
				},
				{
					id: 805,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 20,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 16
				},
				{
					id: 806,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 7,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 7
				},
				{
					id: 807,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 5,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 5
				},
				{
					id: 808,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 1,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 809,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 17,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 16
				}
			],
			pats: 0
		},

// ---------> П-ты местного рецидива с распространением на соседние органы Линия 3
		'st0_3_12':{
			group: 'П-ты местного рецидива с распространением на соседние органы',
			meth: [
				{
					id: 810,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 811,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 812,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 5,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 813,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 10,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 4
				},
				{
					id: 814,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 5,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 815,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 5,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 816,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 817,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 10,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 4
				},
				{
					id: 818,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 10,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 4
				},
				{
					id: 819,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 20,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 12
				},
				{
					id: 820,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 7,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 3
				},
				{
					id: 821,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 5,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 822,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 1,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 823,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 17,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 7
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась  Линия 4
		'st0_4_7':{
			group: 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась ',
			meth: [
				{
					id: 824,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 20,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 3
				},
				{
					id: 825,
					code: 'sh0800',
					name: 'Иринотекан 125 мг/м2',
					info: '(иринотекан 125 мг/м2)',
					drugs: [9,25],
					rate: 20,
					dose: [600,80],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 826,
					code: 'sh0035.1',
					name: 'Гемцитабин',
					info: '(гемцитабин 1000 мг/м2)',
					drugs: [34,83],
					rate: 10,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 827,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел 75 мг/м2)',
					drugs: [88,87],
					rate: 20,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 828,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 30,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение Линия 4
		'st0_4_10':{
			group: 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение',
			meth: [
				{
					id: 829,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 2,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 830,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 831,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 2,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 832,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 2,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 2
				},
				{
					id: 833,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2 + карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 2,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 834,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 2,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 835,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 836,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 2,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 837,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 5,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 5
				},
				{
					id: 838,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 20,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 18
				},
				{
					id: 839,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 15,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 14
				},
				{
					id: 840,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 11,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 10
				},
				{
					id: 841,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 10,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 9
				},
				{
					id: 842,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 23,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 19
				}
			],
			pats: 0
		},

// ---------> П-ты местного рецидива с распространением на соседние органы Линия 4
		'st0_4_13':{
			group: 'П-ты местного рецидива с распространением на соседние органы',
			meth: [
				{
					id: 843,
					code: 'sh0224',
					name: 'Цисплатин',
					info: '(цисплатин 50 мг/м2)',
					drugs: [66],
					rate: 2,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				},
				{
					id: 844,
					code: 'sh9001',
					name: 'Цисплатин + 5-фторурацил',
					info: '(цисплатин 50 мг/м2, 5-фторурацил 500 мг/м2)',
					drugs: [66,61],
					rate: 2,
					dose: [100,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 845,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2)',
					drugs: [17,66],
					rate: 2,
					dose: [400,150],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 846,
					code: 'sh0499',
					name: 'Паклитаксел + цисплатин + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, цисплатин 75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,66,2,26],
					rate: 2,
					dose: [400,150,800,200],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6,6,6,6],
					pats: 1
				},
				{
					id: 847,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел 175 мг/м2, карбоплатин AUC 5–6)',
					drugs: [17,41],
					rate: 2,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 848,
					code: 'sh9001',
					name: 'Цисплатин + топотекан',
					info: '(цисплатин 50 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [66,95],
					rate: 2,
					dose: [100,4],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 849,
					code: 'sh9001',
					name: 'Цисплатин + ифосфамид + месна',
					info: '(цисплатин 50 мг/м2, ифосфамид 5000 мг/м2, месна 400 мг/м2)',
					drugs: [66,96,98],
					rate: 2,
					dose: [100,10000,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 850,
					code: 'sh0704',
					name: 'Цисплатин + гемцитабин',
					info: '(цисплатин 50 мг/м2, гемцитабин 1000 мг/м2)',
					drugs: [66,34,83],
					rate: 2,
					dose: [100,3000,600],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6,6],
					pats: 1
				},
				{
					id: 851,
					code: 'sh0702',
					name: 'Цисплатин + иринотекан',
					info: '(цисплатин 40 мг/м2, иринотекан 60 мг/м2)',
					drugs: [66,9,25],
					rate: 5,
					dose: [150,200,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 2
				},
				{
					id: 852,
					code: 'sh0671',
					name: 'Паклитаксел + топотекан',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2)',
					drugs: [17,95],
					rate: 20,
					dose: [400,4],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 8
				},
				{
					id: 853,
					code: 'sh0830',
					name: 'Циклофосфамид + бевацизумаб',
					info: '(циклофосфамид 50 мг/сут, бевацизумаб 15 мг/кг)',
					drugs: [64,2,26],
					rate: 15,
					dose: [200,800,200],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [6,6,6],
					pats: 6
				},
				{
					id: 854,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин 2500 мг/м2)',
					drugs: [10],
					rate: 11,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 5
				},
				{
					id: 855,
					code: 'sh0124',
					name: 'Митомицин',
					info: '(митомицин 7,5 мг/м2)',
					drugs: [97],
					rate: 10,
					dose: [20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 4
				},
				{
					id: 856,
					code: 'sh9001',
					name: 'Паклитаксел + топотекан + бевацизумаб',
					info: '(паклитаксел 175 мг/м2, топотекан 0,75 мг/м2, бевацизумаб 15 мг/кг)',
					drugs: [17,95,2,26],
					rate: 23,
					dose: [400,4,800,200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 8
				}
			],
			pats: 0
		}
	}

export default rshmData;	