; "use strict";

import State from './state';
import drugData from './drugdata';
import rshmData from './rshm-meth';
import stData from './rshm-st';

class RshmModel {

	constructor() {

	}

/////////////////////////////

	calcOneGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

//!!TODO Возможно просто вызвать calcOneGroup из модуля Calc

		const methSet = rshmData[st].meth;
//console.log(st);
//if(st!='st0_2_6') return 0;
//console.log('------------>');
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==2) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				State.stIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesKs += nOfPatients*cyclesRound;
				if(methSet[cnt].st && methSet[cnt].st!='') {
					State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
				}
			}
			if(chan=='omsd') {		
				State.dsIncome += nOfPatients*
				State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
				cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
				State.nOfCasesDs += nOfPatients*cyclesRound;
				if(methSet[cnt].ds && methSet[cnt].ds!='') {
					State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
				}
			}
		}
	return groupBudget;
	}


/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(group) {

		let pathData;

		if(State.flNoDist) return; // Отладочный режим

		pathData = rshmData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}
		State.calc.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов по методам для всех групп
//

	distributeAllMeth() {

// Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
		let pathData = rshmData;
		let currGroup, currSumm,i,customVal;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in pathData) {
// 			currSumm = this.summMelGroupPats(currGroup);
// 			currSumm = Math.ceil(currSumm);
// // Заносим сумму пациентов в данные группы
// //!!D
// 			if(!State.flNoDist)	pathData[currGroup].pats = currSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth(currGroup);
		}
		for(currGroup in pathData) {
			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
			else pathSumm += pathData[currGroup].result;
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[5] = pathSumm;
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// и группам для РШМ

	distributeSt() {

		let stProbs = [37.2, 28.9, 22.8, 9.8]; // Вероятности по стадиям
		const highRisk = [25, 15, 60]; // Вероятности высокого риска для стадий Ib, IIa, IIb
		const radical = [12,20,20]; // Вероятность радикальной терапии для стадий Ib, IIa, IIb
		const stages = [10.0, 27.2, 12.9, 16.0, 12.8, 10.0, 5.0, 4.8]; // Вероятности распределения по стадиям от Ia до IVb
		const group1 = [40,10,10,5,5,5,5,0]; // Вероятности попадания в группу с изолированным рецидивом в малом тазу (без отдаленных метастатзов),
							//которым ранее не проводилась ЛТ
		const group2 = [17,65,68,74,80,43,58,100]; // Вероятности попадания в группу с изолированными отдаленными метастазами, 
							// которые получали ХЛТ как первичное лечение
		const group3 = [43,25,22,21,15,52,37,0]; // Вероятности попадания в группу с местным рецидивом 
							// с одновременным распространением на соседние органы
		let hrPats = []; // Пациенты высокого риска
		let radPats = []; // Пациенты с радикальной терапией
		let neoPats = []; // Пациенты с неоадьювантной терапией
		let Data = rshmData, currGroup;
		let stPats = State.stPats[5], total = State.pathTotals[5],
			dead = State.currLeth['rshm'], i;
			stProbs = stData[State.currRegNum];
			for(i=0 ; i<4 ; i++) {
				stPats[i] = Math.ceil(total*stProbs[i]/100.0);
			}
		let IbNeo = total*27.2/100.0*0.35; // Неоадьювантная терапия для поциентов Ib
		let IIaNeo = total*12.9/100.0*0.35; // Неоадьювантная терапия для поциентов IIa
		let radPatsSumm = 0;

		for(i=0 ; i<3 ; i++) {
			hrPats[i] = total*highRisk[i]/100.0;
			radPats[i] = hrPats[i]*radical[i]/100.0;
		}
		radPats[0] = radPats[0] + IbNeo*0.3;
		radPats[1] = radPats[1] + IIaNeo*0.3;
		radPats[3] = total*12.8/100.0;
		radPats[4] = total*10.0/100.0;
		radPats[5] = total*5.0/100.0;
		for(i=0 ; i<3 ; i++) radPatsSumm += radPats[i];

//'Ib + IIa + IIb стадии, адъювантная ХЛТ в группе высокого риска прогрессирования'
		Data['st0_0_1'].pats = Math.round(radPatsSumm);

// 'Ib + IIa + Iib + IIIa + IIIb + IVa стадии, радикальная ХЛТ'
		for(i=3 ; i<6 ; i++) radPatsSumm += radPats[i];
		Data['st0_0_2'].pats = Math.round(radPatsSumm);

//  'Ib + IIa стадии, неоадъювантная ХТ'
		Data['st0_0_3'].pats = Math.round(IbNeo + IIaNeo);

// 'IVb стадия'
		Data['st0_1_4'].pats = Math.round(total*4.8/100.0);

// 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась'
		let line2Summ = 0;
		for(i=0 ; i<8 ; i++) {
			line2Summ += total*stages[i]/100.0*group1[i]/100.0*0.95;
		}
		Data['st0_2_5'].pats = Math.round(line2Summ);

// 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась'
		let line3Summ = line2Summ*0.95;
		Data['st0_3_6'].pats = Math.round(line3Summ);

// 'П-ты с изолированным рецидивом в малом тазу (без отдаленных метастатзов), ЛТ не проводилась'
		let line4Summ = line3Summ*0.95;
		Data['st0_4_7'].pats = Math.round(line4Summ);
 
// 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение'
		line2Summ = 0;
		for(i=0 ; i<7 ; i++) {
			line2Summ += total*stages[i]/100.0*group2[i]/100.0*0.95;
		}
		Data['st0_2_8'].pats = Math.round(line2Summ);

// 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение'
		line3Summ = line2Summ*0.95;
		Data['st0_3_9'].pats = Math.round(line3Summ);

// 'П-ты с изолированными отдаленными метастазами, получали ХЛТ как первичное лечение'
		line4Summ = line3Summ*0.95;
		Data['st0_4_10'].pats = Math.round(line4Summ);

// 'П-ты местного рецидива с распространением на соседние органы'
		line2Summ = 0;
		for(i=0 ; i<8 ; i++) {
			line2Summ += total*stages[i]/100.0*group3[i]/100.0*0.95;
		}
		Data['st0_2_11'].pats = Math.round(line2Summ);

// 'П-ты местного рецидива с распространением на соседние органы'
		line3Summ = line2Summ*0.95;
		Data['st0_3_12'].pats = Math.round(line3Summ);

// 'П-ты местного рецидива с распространением на соседние органы'
		line4Summ = line3Summ*0.95;
		Data['st0_4_13'].pats = Math.round(line4Summ);
		for(currGroup in Data) Data[currGroup].result = -1;
		this.distributeAllMeth();
	}
		

}

export default RshmModel;