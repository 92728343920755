;"use strict";

const gcrData = {

// ============================>gcr
// ---------> Локальный ГЦР, будут получать ХТ в качестве bridge - терапии
		'st0_0_1':{
			group: 'Локальный ГЦР, будут получать ХТ в качестве bridge - терапии',
			meth: [
				{
					id: 1057,
					code: 'sh0165',
					name: 'Сорафениб',
					info: '(сорафениб)',
					drugs: [104],
					rate: 88,
					dose: [24000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [4],
					pats: 0
				},
				{
					id: 1058,
					code: 'sh0636.1',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66],
					rate: 3,
					dose: [3000,600,100],
					ds: 'ds19.039',//>>
					st: 'st19.065',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1059,
					code: 'sh0042.1',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16],
					rate: 3,
					dose: [3000,600,300],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1060,
					code: 'sh0130',
					name: 'FOLFOX4',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 3,
					dose: [150,750,4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 1061,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,40],
					rate: 3,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Локальный ГЦР, будут получать химиоэмболиацию в качестве down stage
		'st0_0_2':{
			group: 'Локальный ГЦР, будут получать химиоэмболиацию в качестве down stage',
			meth: [
				{
					id: 1062,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84],
					rate: 100,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [2],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Локальный ГЦР, внутрипеченочное распространение, будут получать ТАХЭ
		'st0_0_3':{
			group: 'Локальный ГЦР, внутрипеченочное распространение, будут получать ТАХЭ',
			meth: [
				{
					id: 1063,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84],
					rate: 100,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [2],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> Локальный ГЦР, внутрипеченочное прогрессирование, будут получать ТАХЭ
		'st0_0_4':{
			group: 'Локальный ГЦР, внутрипеченочное прогрессирование, будут получать ТАХЭ',
			meth: [
				{
					id: 1064,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84],
					rate: 100,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [2],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Распространённый ГЦР или прогрессирование после локальных методов лечения<br>I линия
		'st0_0_5':{
			group: 'Распространённый ГЦР или прогрессирование после локальных методов лечения<br>I линия',
			meth: [
				{
					id: 1065,
					code: 'sh0165',
					name: 'Сорафениб',
					info: '(сорафениб)',
					drugs: [104],
					rate: 80,
					dose: [24000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [5],
					pats: 8
				},
				{
					id: 1066,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84,36],
					rate: 2,
					dose: [100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 1067,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 5,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4,4],
					pats: 1
				},
				{
					id: 1068,
					code: 'sh0164',
					name: 'Регорафениб',
					info: '(регорафениб)',
					drugs: [21],
					rate: 1,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [4],
					pats: 0
				},
				{
					id: 1069,
					code: 'sh0636.1',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66],
					rate: 5,
					dose: [3000,600,100],
					ds: 'ds19.039',//>>
					st: 'st19.065',//>>
					cycles: [4,4,4],
					pats: 1
				},
				{
					id: 1070,
					code: 'sh0042.1',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16],
					rate: 5,
					dose: [3000,600,300],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 1
				},
				{
					id: 1071,
					code: 'sh0130',
					name: 'FOLFOX4',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 1,
					dose: [150,750,4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1072,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,40],
					rate: 1,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> II линия
		'st0_0_6':{
			group: 'II линия',
			meth: [
				{
					id: 1073,
					code: 'sh0165',
					name: 'Сорафениб',
					info: '(сорафениб)',
					drugs: [104],
					rate: 15,
					dose: [24000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [4],
					pats: 1
				},
				{
					id: 1074,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84,36],
					rate: 1,
					dose: [100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 1075,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 15,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4,4],
					pats: 1
				},
				{
					id: 1076,
					code: 'sh0164',
					name: 'Регорафениб',
					info: '(регорафениб)',
					drugs: [21],
					rate: 40,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [4],
					pats: 2
				},
				{
					id: 1077,
					code: 'sh0636.1',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66],
					rate: 15,
					dose: [3000,600,100],
					ds: 'ds19.039',//>>
					st: 'st19.065',//>>
					cycles: [4,4,4],
					pats: 1
				},
				{
					id: 1078,
					code: 'sh0042.1',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16],
					rate: 5,
					dose: [3000,600,300],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1079,
					code: 'sh0130',
					name: 'FOLFOX4',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 5,
					dose: [150,750,4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1080,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,40],
					rate: 4,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> III линия
		'st0_0_7':{
			group: 'III линия',
			meth: [
				{
					id: 1081,
					code: 'sh0165',
					name: 'Сорафениб',
					info: '(сорафениб)',
					drugs: [104],
					rate: 10,
					dose: [24000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [4],
					pats: 0
				},
				{
					id: 1082,
					code: 'sh0639',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84,36],
					rate: 1,
					dose: [100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 1083,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34,83],
					rate: 15,
					dose: [5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 1084,
					code: 'sh0164',
					name: 'Регорафениб',
					info: '(регорафениб)',
					drugs: [21],
					rate: 45,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [4],
					pats: 0
				},
				{
					id: 1085,
					code: 'sh0636.1',
					name: 'Гемцитабин + цисплатин',
					info: '(гемцитабин, цисплатин)',
					drugs: [34,83,66],
					rate: 10,
					dose: [3000,600,100],
					ds: 'ds19.039',//>>
					st: 'st19.065',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1086,
					code: 'sh0042.1',
					name: 'Гемцитабин + оксалиплатин',
					info: '(гемцитабин, оксалиплатин)',
					drugs: [34,83,16],
					rate: 9,
					dose: [3000,600,300],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1087,
					code: 'sh0130',
					name: 'FOLFOX4',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 5,
					dose: [150,750,4000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1088,
					code: 'sh0653',
					name: 'XELOX',
					info: '(оксалиплатин, капецитабин)',
					drugs: [16,24,40],
					rate: 5,
					dose: [150,100,50500],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4],
					pats: 0
				}
			],
			pats: 0
		}
	}

export default gcrData;	