
;"use strict";

import State from './state';
import drugData from './drugdata';
import methData from './methData';

class Printer {

	constructor() {

		this.clickCounter = 0;
        $('#mess-but').on(State.startEvent, (e)=>this.hideMessage(e));

	}

//******************************************

	render(path) {

		let cnt, currDrug, color = 0, colClass, lineTemplate, priceStr, totPriceStr, typeStr,
			nOfPacks, lim, result, tableCode = '', drugBuf, header, subTotal, ind, packDose,
			pathName = '', totalPrice, doseMg, doseStr, pathNum = -1;	
		const len = State.drugOrd.length;

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathName = State.pathList[cnt];
        		pathNum = cnt;
        		break;
        	}
        }
		if(pathNum>-1 && State.selPath[pathNum]==0) return;

        let insert = 
                	`<tr class="table-header">
                        <td width="250px">Препарат</td>
                        <td>Дозировка, мг в уп.</td>
                        <td>Кол-во уп.</td>
                        <td>Кол-во мг.</td>
                        <td>Затраты, руб.</td>
                    </tr>`;                

		State.calc.resetDataBufs();
        if(path.length>1) {        
			State.calc.calcOnePath(path);
		} else { // Total budget for all pathologies
			State.calc.calcFullBudget();
		}
        if(pathName=='') {
//        	pathName = 'Общий бюджет';
        	subTotal = '';
        } else {
        	subTotal = `<div class="print-subtotal">
        		Итого: <span id="prt-subtotal-${path}">${State.pathBudgets[path]}</span> руб.
        	</div>`;
        }
		drugBuf = State.drugBuf;
		tableCode = insert;
		totalPrice = 0;
//console.log(drugBuf);
		for(cnt=0 ; cnt<len ; cnt++) {
			ind = State.drugOrd[cnt];
			nOfPacks = State.calc.padDigits(drugBuf[ind].packs);	
			if(nOfPacks==0) continue;
			currDrug = drugData[ind];
 			result = drugBuf[ind].summ;
//console.log(result);
			totalPrice += result;
			doseMg = drugBuf[ind].mg;
			doseStr = State.calc.padDigits(Math.floor(doseMg));
			packDose = State.calc.padDigits(Math.floor(drugData[ind].dose * drugData[ind].set))
			result = Math.floor(result);
 			result = State.calc.padDigits(result);
			priceStr = State.calc.padFloat(currDrug.price);
			if(currDrug.type=='t') typeStr = '[Таргет.]'; 
			if(currDrug.type=='c') typeStr = '[Цитост.]'; 
			if(currDrug.type=='h') typeStr = '[Гормон.]';
			if(currDrug.type=='i') typeStr = '[Иммуно.]';
			if(currDrug.type=='o') typeStr = '[Другое]';
			lineTemplate = 
                	`<tr>
                        <td>
                        	${currDrug.name}
                        	<span class="prt-drug-type">${typeStr}</span>
                        </td>
                        <td>${packDose}</td>
                        <td>${nOfPacks}</td>
                        <td>${doseStr}</td>
                        <td>${result}</td>
                    </tr>`;
            tableCode += lineTemplate;    
		}
//		tableCode += holder;
		tableCode += '<br>'
		if(pathName=='') {
			$('#tbody-tot').html(tableCode);
			$('#prt-total').text(State.calc.padDigits(Math.floor(totalPrice)));		
		} else {
			$('#tbody'+pathNum).html(tableCode);
			$('#prt-subtotal'+pathNum).text(State.pathBudgets[path]);		
			$('.prt-pats'+pathNum).text(State.pathSumm[pathNum]);		
			$('#table'+pathNum).show();
			$('#prt-pats-line'+pathNum).show();
		}
	}

//******************************************

	renderTables(){

		let i, len;

		len = State.pathKeys.length;
		for(i=0 ; i<len ; i++) {
			$('#table'+i).hide();
			$('#prt-pats-line'+i).hide();
		}
		this.render(''); // Total budget
		this.render('krr');
		this.render('melanoma');
		this.render('rmz');
		this.render('rl');
		this.render('rya');
		this.render('rshm');
		this.render('rp');
		this.render('rz');
		this.render('rmp');
		this.render('rpsh');
		this.render('rpz');
		this.render('rtm');
		this.render('gcr');
		this.render('rpsz');
		this.render('neo');
	}

//******************************************

	hideMessage(e){

	    var type;

	    e.preventDefault();
        type = e.type;
        $('#print-message').css('display','none');
        State.flModal = 0;
    }

//******************************************

	    dataPrint(e){

	        var type, messTxt;

	        $('#print-cont').css('display','block');
	        this.renderTables();
	        $('.maincont').css('display','none');
	        $('#print-but').css('display','none');
	        $('body').css('overflow','visible');
	            State.flModal = 1;

	        setTimeout(function(){
	            window.focus();
	//            alert('Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.');
	            window.print();

	            if(State.flIpad) {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.';
	                messTxt += '<br>Если ваш планшет не нашел доступный принтер для печати, воспользуйтесь пиктограммой в виде прямоугольника в верхней строке браузера<br><br><img src="img/pict.png"><br><br>для отправки результатов по почте или преобразования в другой удобный формат';
	            } else {
	                messTxt = 'Для возвращения к калькулятору после печати, выполните двойной клик на экране с таблицей.';
	            }
	            $('#prt-mess-text').html(messTxt);
	            setTimeout(function(){
	                    $('#print-message').css('display','block');
	                }, 1000);
	        },1000);
	        this.clickCounter = 0;
	        $('body').bind(State.startEvent, (e)=>this.closePrintWindow(e));

	        return true;
	    }

//******************************************

	closePrintWindow(e){

	        var type;

	        if(State.flModal) return;
	        if(e.target.id=='mess-but') return;
	        type = e.type;
	        this.clickCounter++;
	        setTimeout(()=>{
	            this.clickCounter = 0;
	        },400);
	        if(this.clickCounter<2) return;
	        
	        $('body').unbind();
//	        $('body').css('overflow','hidden');
	        $('.maincont').css('display','block');
	        $('#distcont').css('display','none');
	        $('#print-cont').css('display','none');
	        e.preventDefault();
	    }
	}


//******************************************

export default Printer;