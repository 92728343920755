
"use strict";
let stData = {
	1: [7.1,28.3,23.7,40.1],
	2: [6.1,13.7,26.3,52.9],
	3: [8.7,17.7,31.7,39.5],
	4: [6.1,36.7,21.9,35.2],
	5: [14.9,19,17.9,45.9],
	6: [10,25.6,21.6,37.9],
	7: [14.6,18.8,20.5,45.1],
	8: [12.2,15,17.2,50.6],
	9: [13.1,15.3,37.3,34.3],
	10: [15.2,29.3,20.8,31.8],
	11: [19.4,27.2,20.7,32.5],
	12: [9.9,26,29.5,33.1],
	13: [10.9,17.9,14,49.3],
	14: [11,23.7,23.5,40.3],
	15: [9.7,24.3,25.7,40],
	16: [5,19.4,31.3,43.5],
	17: [8.4,19.9,18.9,52.7],
	18: [16.6,21.2,13.6,48.4],
	19: [33.3,0,11.1,55.6],
	20: [18.5,22.5,18.8,35.9],
	21: [10.9,20.6,26.5,41.3],
	22: [9.2,21.1,18.4,43.2],
	23: [15.4,22.8,26.6,32.3],
	24: [6.6,24.8,28.5,33.6],
	25: [19.7,19.7,14.6,33.8],
	26: [13.1,18.9,16.2,47.3],
	27: [4.4,18.6,27.5,49.5],
	28: [14.8,16.2,26.6,42.4],
	29: [19.1,20.7,17.6,42.6],
	30: [13.8,27.5,15.3,43.4],
	31: [7.2,14.9,17.6,58.1],
	32: [12.7,24.3,20.6,39],
	33: [5.1,22.7,33.6,36.5],
	34: [8.7,33.3,13,34.8],
	35: [7.1,23.8,35.7,33.3],
	36: [6.5,21.8,33.3,37.7],
	37: [10,21.1,20,36.7],
	38: [4.9,27.9,21.8,38.1],
	39: [17.6,29.4,23.5,29.4],
	40: [2.3,26.3,40.8,27.8],
	41: [3.7,23.4,29.9,37.4],
	42: [5.8,19.8,31.4,43],
	43: [2.1,34,23.4,36.2],
	44: [11.7,27.6,22.1,38.6],
	45: [14.2,22.7,27.4,35.7],
	46: [7.7,17.4,18.6,55.4],
	47: [17.7,24.4,14.9,39],
	48: [10.6,20,30.4,36.1],
	49: [14.1,26.1,18.9,40],
	50: [9.4,21.8,28,31.6],
	51: [10.4,23.4,25.2,41],
	52: [5.7,31.5,15.6,47.1],
	53: [10.1,18.9,29.8,41.2],
	54: [11.3,17,25.8,44],
	55: [11.9,20,33.6,34.5],
	56: [11.9,23.3,14.3,50.6],
	57: [12.5,21.4,19.3,46.8],
	58: [16.7,15.5,27.9,39.5],
	59: [19.5,16.3,15,47.6],
	60: [21.4,21.4,16.1,41.1],
	61: [11.4,14.2,26.5,47.9],
	62: [12.1,19,19.8,47.7],
	63: [16,18.9,23.6,41.5],
	64: [12.9,25.8,16,42.7],
	65: [11.7,32.2,17.8,36.5],
	66: [15.1,19.8,27,32.8],
	67: [14.8,13.3,20.7,50.8],
	68: [10.8,27.5,21.3,38.9],
	69: [12.8,20.6,25,39],
	70: [9.9,16.8,19.8,44.7],
	71: [26.4,24.1,12.4,32.1],
	74: [3.1,28.1,15.6,53.1],
	75: [14.6,25.6,32.9,25.6],
	76: [5.4,14.6,18.5,50],
	77: [13.5,23,15.7,44.9],
	78: [14.9,25.9,23,36.2],
	79: [11.4,12.6,15.4,49.1],
	80: [12.2,32.9,23.2,31.7],
	81: [12.5,15.6,25,43.8],
	82: [15.4,22.1,18.5,42.1],
	72: [20,24.6,16.2,39.2],
	83: [0,8.3,33.3,58.3],
	73: [8,22.9,32.9,30.9],
	84: [15.4,18.1,27.1,39.4],
	85: [16.3,4.7,16.3,55.8]
};
	
export default stData;