"use strict";
let stData = {
	1: [27.5,39,26.4,7.1],
	2: [24.4,44.5,21.7,9.4],
	3: [16.7,46.3,29.4,7.4],
	4: [27.7,38.5,22.6,11.1],
	5: [36.8,36.4,19,7.4],
	6: [20.9,48.1,22.8,7.5],
	7: [23.3,48,20.5,8.2],
	8: [25.8,40.8,22.9,10.5],
	9: [33.7,41.9,18,6.1],
	10: [26.4,41,24.4,7.8],
	11: [29.8,43.4,19,7.6],
	12: [23.8,46.6,20.3,8.3],
	13: [24,39.7,25.9,10.5],
	14: [22.6,49.5,22,5.9],
	15: [28.4,43.2,21.3,6.7],
	16: [15.8,64.1,6.3,12.4],
	17: [26.3,45.3,19.3,9.1],
	18: [28.8,42.8,19.6,8.7],
	19: [10,60,0,20],
	20: [28.4,40.3,22.6,7.1],
	21: [27.6,43.5,19.9,8.5],
	22: [20.3,47.6,23,7.5],
	23: [33.3,38.9,19.4,8],
	24: [24,41.9,22.4,9.1],
	25: [38.6,40.1,14.7,6.3],
	26: [29.9,37.5,22.5,9.3],
	27: [19.1,46.8,24.8,9.2],
	28: [32.6,41.6,20.3,5.5],
	29: [35.5,39.2,17,8.2],
	30: [26.3,45.7,20,8],
	31: [28.3,39.7,18.3,13.7],
	32: [24.8,49.7,19.2,6.2],
	33: [13.9,55.8,19.5,9.9],
	34: [13.7,47.7,20.3,10.7],
	35: [17.4,48.8,24.4,9.3],
	36: [17.9,52,20,8.6],
	37: [35.1,33.9,15.5,11.5],
	38: [23.1,41.5,26.9,8.1],
	39: [13.4,40.3,27.7,18.5],
	40: [11.9,54.2,28.3,4.8],
	41: [16.6,39.4,29.5,13.5],
	42: [12.3,53.3,24.9,9.5],
	43: [21.2,43.6,26.1,9.1],
	44: [14.4,43.5,31.2,10.8],
	45: [27.9,46.9,18.6,6.6],
	46: [34.8,36.5,23.8,4.8],
	47: [28.5,44.3,21.8,5.4],
	48: [26,42.8,19,11.8],
	49: [21.9,42.8,27.3,7.3],
	50: [27.1,45,19,7.1],
	51: [25.4,51.7,15.3,7.6],
	52: [26.8,39.7,25,8.5],
	53: [19.8,53.7,20,6.5],
	54: [22.5,50.2,23.2,3.1],
	55: [25.7,57.3,9.3,7.6],
	56: [28.9,41.8,22.1,7.2],
	57: [32.8,35.3,24.4,7.5],
	58: [26.9,47.5,21.5,4.1],
	59: [33.4,43.5,13.1,8.6],
	60: [31.6,39.2,19.6,8.2],
	61: [30.5,43.4,17.8,8.4],
	62: [23.6,49.2,20.3,7],
	63: [35.2,39.8,17.3,7.7],
	64: [26.2,41.8,24.7,7],
	65: [29.3,42.9,20.6,7],
	66: [31.2,44.5,17.4,6.6],
	67: [32.5,39.9,19.5,8.2],
	68: [30.6,44.5,16.6,7.8],
	69: [27.5,46.6,19.7,6.2],
	70: [25.5,41.4,24.4,7.1],
	71: [31.3,41.3,18.4,7.7],
	74: [23.2,52.2,11.6,13],
	75: [22.6,61.3,11.3,2.8],
	76: [24.6,43.8,24.6,3.9],
	77: [22.5,47.8,18.7,8.7],
	78: [25.7,42.9,24,7.5],
	79: [25.9,43.4,24.3,6.2],
	80: [21.8,44.9,25.2,8.2],
	81: [18.3,30,38.3,13.3],
	82: [34.6,37.3,20.8,7.3],
	72: [31.4,44.3,16.6,7.6],
	83: [6.7,66.7,20,6.7],
	73: [12.7,60.9,19.1,6.4],
	84: [31.9,41.8,19,7.3],
	85: [24.6,39.3,26.2,8.2]
};
	
export default stData;	
