
"use strict";
let stData = {
	1: [1.4,12.1,9.3,75.7],
	2: [2.2,5.4,7.6,79.9],
	3: [6.6,17.1,27,47.4],
	4: [2.7,14,27.9,55.4],
	5: [0.9,12.2,19.1,64.3],
	6: [5.4,15.1,32.2,43.9],
	7: [4.1,10.7,11.6,72.7],
	8: [1.5,10.4,7.5,80.6],
	9: [4.4,15.4,41.2,39],
	10: [0,28.6,13.5,49.2],
	11: [7.4,22,16,54.5],
	12: [5.4,19.8,27.5,46],
	13: [3.9,16.9,9.1,66.2],
	14: [1.5,18.7,19.4,59.7],
	15: [1.7,12.2,13,69.6],
	16: [2.7,9.9,26.1,60.4],
	17: [0.5,12.2,12.2,75.1],
	18: [3.4,11.7,11.2,72.7],
	19: [0,16.7,33.3,50],
	20: [2.6,15.5,15.5,54.3],
	21: [1,9,21,65],
	22: [0.9,11.5,8,74.3],
	23: [4.2,16.7,25.2,41.2],
	24: [6.8,18,21.1,43.5],
	25: [9.5,14.3,3.6,69],
	26: [6.4,9.6,12.8,69.1],
	27: [2.9,10.1,18.8,68.1],
	28: [6.3,16.7,10.4,66.7],
	29: [2.2,18,23.6,56.2],
	30: [7.6,23.6,13,55.7],
	31: [4.5,5.4,4.5,84.8],
	32: [6.8,14.7,10.7,59.9],
	33: [3.4,14.5,24.7,55.3],
	34: [2.2,20,6.7,57.8],
	35: [4,8,20,68],
	36: [2.7,18.2,25.9,49.1],
	37: [6.8,4.1,16.4,54.8],
	38: [2.2,14.3,17,54.5],
	39: [0,50,33.3,16.7],
	40: [4.3,12.9,36.2,45.7],
	41: [2.8,13.9,16.7,62.5],
	42: [2.1,6.3,12.5,79.2],
	43: [0,25.7,8.6,65.7],
	44: [7.9,22.2,14.3,55.6],
	45: [3.5,14.9,19.9,61.7],
	46: [3,3.5,11.1,78.3],
	47: [5.7,13.4,10.8,66.2],
	48: [1.7,12.1,20.4,65],
	49: [1,18.1,11.6,67.8],
	50: [2.6,15.6,38.1,35.5],
	51: [0.7,7.5,21.1,70.6],
	52: [2.8,16.5,9.2,71.6],
	53: [1.4,10.6,18.5,69.6],
	54: [7.7,15.4,10.3,64.1],
	55: [1.9,8.7,21.2,68.3],
	56: [5.5,21.2,8.9,64.3],
	57: [4.5,8,9.8,77.7],
	58: [4.2,28.4,12.6,54.7],
	59: [2.2,13.9,17.5,66.4],
	60: [3.2,22.6,16.1,58.1],
	61: [8.8,14,20.2,57],
	62: [3.6,15.4,18.2,58.5],
	63: [8,17.2,16,58.9],
	64: [6.7,16.7,13.7,59.6],
	65: [1.9,19.7,17.8,56.4],
	66: [3.1,8.1,24.8,54],
	67: [3.2,16,3.2,77.6],
	68: [0.4,8.6,29.1,57.2],
	69: [0.2,10.1,30,56.2],
	70: [6,16.5,9.7,60.3],
	71: [4.3,17.3,11.5,65.5],
	74: [0,23.8,33.3,42.9],
	75: [12,28,12,40],
	76: [0,19,9.5,50.8],
	77: [1.6,11.5,23.9,60.1],
	78: [4.8,20.6,16.4,58.2],
	79: [4.3,7.4,20.2,66],
	80: [4.3,10.6,2.1,63.8],
	81: [0,13.3,13.3,73.3],
	82: [2.3,17.4,16.3,64],
	72: [5.9,19.8,15.8,58.4],
	83: [0,40,0,40],
	73: [1.9,13.5,26,50],
	84: [6,13.1,23.8,57.1],
	85: [0,6.3,0,81.3]
};
	
export default stData;
