
;"use strict";

const methData = {

	'rmz': {
		'st1': {
			meth: [
			],
			pats: 0 
		},
		'st2': {
			meth: [
				{
					name: 'Эксеместан + трастузумаб',
					info: '(эксеместан, трастузумаб)',
					drugs: [],
					dose: [],
					price: [],
					rate: 50,
					cycles: [0],
					pats: 560
				}
			],
			pats: 560
		},	
		'st3': { 
			meth: [
				{
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [],
					dose: [],
					price: [],
					rate: 22.5,
					cycles: [0],
					pats: 110
				},
				{
					name: 'Циклофосфамид + Метотрексат',
					info: '(циклофосфамид, метотрексат)',
					drugs: [],
					dose: [],
					price: [],
					rate: 22.5,
					cycles: [0],
					pats: 90
				},
				{
					name: 'Винорелбин пероральный',
					info: '(винорелбин)',
					drugs: [],
					dose: [],
					price: [],
					rate: 50,
					cycles: [0],
					pats: 42
				},
				{
					name: 'Фулвестрант + трастузумаб',
					info: '(фулвестрант, трастузумаб)',
					drugs: [],
					dose: [],
					price: [],
					rate: 5,
					cycles: [0],
					pats: 30
				}
			],
			pats: 272
		}
	}, 
//////////////////////////////////// KRR ///////////////////////////////

	'krr': {
// ============================>krr
// ---------> Стадия 1
		'st1':{
			group: 'Стадия 1',
			meth: [
			],
			pats: 0
		},

// ---------> Стадия 2
		'st2':{
			group: 'Стадия 2',
			meth: [
				{
					id: 0,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 14,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.571414,8.571414,8.571414],
					pats: 7
				},
				{
					id: 1,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 6,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [10.71,10.71,10.71],
					pats: 3
				},
				{
					id: 2,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 60,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 29
				},
				{
					id: 3,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 20,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [7.143],
					pats: 10
				}
			],
			pats: 0
		},

// ---------> Стадия 3
		'st3':{
			group: 'Стадия 3',
			meth: [
				{
					id: 4,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 14,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.5714,8.5714,8.5714],
					pats: 31
				},
				{
					id: 5,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 6,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [10.71,10.71,10.71],
					pats: 13
				},
				{
					id: 6,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 60,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 132
				},
				{
					id: 7,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 20,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [7.14],
					pats: 44
				}
			],
			pats: 0
		},

// ---------> Исходно-резектабельные<br>операция проведена Линия 1
		'st4_1_1':{
			group: 'Исходно-резектабельные<br>операция проведена',
			meth: [
				{
					id: 8,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 27,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.71,10.71,10.71],
					pats: 4
				},
				{
					id: 9,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 13,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [10.71,10.71,10.71],
					pats: 2
				},
				{
					id: 10,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 40,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [7.14,7.14,7.14],
					pats: 6
				},
				{
					id: 11,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 20,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [8.5714],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные неоадъювантная терапия Линия 1
		'st4_1_2':{
			group: 'Потенциально-резектабельные неоадъювантная терапия',
			meth: [
				{
					id: 12,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.5714,8.5714,8.5714],
					pats: 2
				},
				{
					id: 13,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [8.5714,8.5714,8.5714],
					pats: 1
				},
				{
					id: 14,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 11,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5.714,5.714,5.714],
					pats: 3
				},
				{
					id: 15,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 6,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714],
					pats: 1
				},
				{
					id: 16,
					code: 'sh0206',
					name: 'FOLFOXIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин)',
					drugs: [13,61,9,16],
					rate: 6,
					dose: [350,6000,300,100],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714],
					pats: 1
				},
				{
					id: 17,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 12,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714],
					pats: 3
				},
				{
					id: 18,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 6,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714],
					pats: 1
				},
				{
					id: 19,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 14,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [5.714,5.714,5.714,5.714,5.714],
					pats: 2
				},
				{
					id: 20,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 4,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8.57141],
					pats: 1
				},
				{
					id: 21,
					code: 'sh0207',
					name: 'FOLFOXIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, бевацизумаб)',
					drugs: [13,61,9,16,2],
					rate: 4,
					dose: [750,6000,300,100,400],
					ds: 'ds19.043',//>>
					st: 'st19.067',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714,8.5714],
					pats: 1
				},
				{
					id: 22,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 8,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8.5714,8.5714,8.5714,1,16],
					pats: 2
				},
				{
					id: 23,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8.5714,8.5714,8.5714,1,16],
					pats: 1
				},
				{
					id: 24,
					code: 'sh0209.1',
					name: 'FOLFOXIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, цетуксимаб)',
					drugs: [13,61,9,16,23,23],
					rate: 4,
					dose: [750,6000,300,100,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714,1,16],
					pats: 1
				},
				{
					id: 25,
					code: 'sh0668',
					name: 'FOLFOX + панитумумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, панитумумаб)',
					drugs: [13,16,61,19],
					rate: 6,
					dose: [750,150,5000,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,8.5714,8],
					pats: 1
				},
				{
					id: 26,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 4,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714,8],
					pats: 1
				},
				{
					id: 27,
					code: 'sh0208',
					name: 'FOLFOXIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, панитумумаб)',
					drugs: [13,61,9,16,19],
					rate: 4,
					dose: [750,6000,300,100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714,8],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные после операции Линия 1
		'st4_1_3':{
			group: 'Потенциально-резектабельные после операции',
			meth: [
				{
					id: 28,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 34,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6.428,6.428,6.428],
					pats: 2
				},
				{
					id: 29,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 33,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6.428,6.428,6.428],
					pats: 2
				},
				{
					id: 30,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 33,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [4.28,6.428,6.428],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> Нерезектабельные Линия 1
		'st4_1_4':{
			group: 'Нерезектабельные',
			meth: [
				{
					id: 31,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 4,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.93,10.93,10.93],
					pats: 4
				},
				{
					id: 32,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 2,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [10.93,10.93,10.93],
					pats: 2
				},
				{
					id: 33,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 5,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [7,7,7],
					pats: 5
				},
				{
					id: 34,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 5,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [10.07,10.07,10.07,10.07],
					pats: 5
				},
				{
					id: 35,
					code: 'sh0206',
					name: 'FOLFOXIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин)',
					drugs: [13,61,9,16],
					rate: 2,
					dose: [350,6000,300,100],
					ds: 'ds19.041',//>>
					st: 'st19.065',//>>
					cycles: [10.71,10.71,10.71,10.71],
					pats: 2
				},
				{
					id: 36,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 5.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [12.857,12.857,12.857,12.857],
					pats: 6
				},
				{
					id: 37,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 2.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [12.857,12.857,12.857,12.857],
					pats: 3
				},
				{
					id: 38,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 8,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,8.5714,8.5714,8.5714],
					pats: 8
				},
				{
					id: 39,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 12,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.857,12.857,12.857,12.857,12.857],
					pats: 15
				},
				{
					id: 40,
					code: 'sh0207',
					name: 'FOLFOXIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, бевацизумаб)',
					drugs: [13,61,9,16,2],
					rate: 2,
					dose: [750,6000,300,100,400],
					ds: 'ds19.043',//>>
					st: 'st19.067',//>>
					cycles: [15,15,15,15,15],
					pats: 2
				},
				{
					id: 41,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 3,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [10.71,10.71,10.71,1,20],
					pats: 3
				},
				{
					id: 42,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 3,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.857,12.857,12.857,1,24],
					pats: 3
				},
				{
					id: 43,
					code: 'sh0209.1',
					name: 'FOLFOXIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, цетуксимаб)',
					drugs: [13,61,9,16,23,23],
					rate: 2,
					dose: [750,6000,300,100,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.857,12.857,12.857,12.857,1,24],
					pats: 2
				},
				{
					id: 44,
					code: 'sh0668',
					name: 'FOLFOX + панитумумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, панитумумаб)',
					drugs: [13,16,61,19],
					rate: 3,
					dose: [750,150,5000,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [10.93,10.93,10.93,10.2],
					pats: 3
				},
				{
					id: 45,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 3,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.857,12.857,12.857,12.857,12],
					pats: 3
				},
				{
					id: 46,
					code: 'sh0208',
					name: 'FOLFOXIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, оксалиплатин, панитумумаб)',
					drugs: [13,61,9,16,19],
					rate: 2,
					dose: [750,6000,300,100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.857,12.857,12.857,12.857,12],
					pats: 2
				},
				{
					id: 47,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 5,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [10.71,10.71],
					pats: 5
				},
				{
					id: 48,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 6,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [7.143],
					pats: 6
				},
				{
					id: 49,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 1,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 1
				},
				{
					id: 50,
					code: 'sh0426',
					name: 'LV5FU2 + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, панитумумаб)',
					drugs: [13,61,19],
					rate: 1,
					dose: [750,5000,500],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [12.857,12.857,12],
					pats: 1
				},
				{
					id: 51,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 2,
					dose: [600,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8,8],
					pats: 2
				},
				{
					id: 52,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 10,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [8.5714,8.5714,8.5714],
					pats: 10
				},
				{
					id: 53,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 5,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [12.857,12.857,12.857],
					pats: 5
				},
				{
					id: 54,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 2,
					dose: [600,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,5.714,5.714],
					pats: 2
				},
				{
					id: 55,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 3,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [8,8,1,16],
					pats: 3
				},
				{
					id: 56,
					code: 'sh0557.1',
					name: 'LV5FU2 + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, цетуксимаб)',
					drugs: [13,61,23,23],
					rate: 1,
					dose: [750,5000,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [12.857,12.857,1,24],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные, не ставшие резектабельными Линия 1
		'st4_1_5':{
			group: 'Потенциально-резектабельные, не ставшие резектабельными',
			meth: [
				{
					id: 57,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [2.357,2.357,2.357],
					pats: 0
				},
				{
					id: 58,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 6,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [1.5,1.5,1.5,1.5],
					pats: 1
				},
				{
					id: 59,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 6,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286,4.286,4.286],
					pats: 1
				},
				{
					id: 60,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 4,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4.286,4.286,4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 61,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4.286,4.286,4.286,1,8],
					pats: 0
				},
				{
					id: 62,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 4,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4.286,4.286,4.286,4.286,4],
					pats: 0
				},
				{
					id: 63,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 44,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [2.857],
					pats: 4
				},
				{
					id: 64,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 30,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [2.857,2.857,2.857],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Нерезектабельные Линия 2
		'st4_2_1':{
			group: 'Нерезектабельные',
			meth: [
				{
					id: 65,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 2.5,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 9
				},
				{
					id: 66,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 1.5,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 5
				},
				{
					id: 67,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 1,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 3
				},
				{
					id: 68,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 2,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 7
				},
				{
					id: 69,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 9,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 31
				},
				{
					id: 70,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 2,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 7
				},
				{
					id: 71,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 35
				},
				{
					id: 72,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 3.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 12
				},
				{
					id: 73,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 5
				},
				{
					id: 74,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 17
				},
				{
					id: 75,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 5,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 17
				},
				{
					id: 76,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 14,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 52
				},
				{
					id: 77,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 4,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 14
				},
				{
					id: 78,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 10,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,5.714,5.714],
					pats: 35
				},
				{
					id: 79,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 1,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 3
				},
				{
					id: 80,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 14
				},
				{
					id: 81,
					code: 'sh0557.1',
					name: 'LV5FU2 + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, цетуксимаб)',
					drugs: [13,61,23,23],
					rate: 1,
					dose: [750,5000,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,1,16],
					pats: 3
				},
				{
					id: 82,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [8,8,1,16],
					pats: 3
				},
				{
					id: 83,
					code: 'sh0668',
					name: 'FOLFOX + панитумумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, панитумумаб)',
					drugs: [13,16,61,19],
					rate: 1,
					dose: [750,150,5000,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [12.21,12.21,12.21,11.4],
					pats: 3
				},
				{
					id: 84,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 4,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.21,12.21,12.21,12.21,11.4],
					pats: 14
				},
				{
					id: 85,
					code: 'sh0426',
					name: 'LV5FU2 + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, панитумумаб)',
					drugs: [13,61,19],
					rate: 1,
					dose: [750,5000,500],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,8],
					pats: 3
				},
				{
					id: 86,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 3,
					dose: [600,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8,8],
					pats: 10
				},
				{
					id: 87,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 5,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 17
				},
				{
					id: 88,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 5,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 17
				},
				{
					id: 89,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 2
				},
				{
					id: 90,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8.5714],
					pats: 2
				},
				{
					id: 91,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 3
				},
				{
					id: 92,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Исходно резектабельные после операции Линия 2
		'st4_2_2':{
			group: 'Исходно резектабельные после операции',
			meth: [
				{
					id: 93,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3.5,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 94,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 1.5,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 95,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 0,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 96,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 2,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 97,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 9,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 1
				},
				{
					id: 98,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 2,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 99,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 1
				},
				{
					id: 100,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 3.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 101,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 102,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 103,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 5,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 104,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 14,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 4
				},
				{
					id: 105,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 4,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 106,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 10,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,5.714,5.714],
					pats: 1
				},
				{
					id: 107,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 1,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 108,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 109,
					code: 'sh0557.1',
					name: 'LV5FU2 + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, цетуксимаб)',
					drugs: [13,61,23,23],
					rate: 0,
					dose: [750,5000,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,1,16],
					pats: 0
				},
				{
					id: 110,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 3,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [8,8,1,16],
					pats: 0
				},
				{
					id: 111,
					code: 'sh0668',
					name: 'FOLFOX + панитумумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, панитумумаб)',
					drugs: [13,16,61,19],
					rate: 1,
					dose: [750,150,5000,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [12.21,12.21,12.21,11.4],
					pats: 0
				},
				{
					id: 112,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 4,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.21,12.21,12.21,12.21,11.4],
					pats: 0
				},
				{
					id: 113,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 3,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8,8],
					pats: 0
				},
				{
					id: 114,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 5,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 115,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 5,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 116,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 117,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8.5714],
					pats: 0
				},
				{
					id: 118,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 119,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально резектабельные после операции Линия 2
		'st4_2_3':{
			group: 'Потенциально резектабельные после операции',
			meth: [
				{
					id: 120,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 121,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 1.5,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 122,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 1,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 123,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 2,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 124,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 9,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 0
				},
				{
					id: 125,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 2,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 126,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 9,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 127,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 3,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 128,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 129,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 130,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 5,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 131,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 14,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 4
				},
				{
					id: 132,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 4,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 133,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 10,
					dose: [600,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,5.714,5.714],
					pats: 0
				},
				{
					id: 134,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 1,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 135,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 136,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 3,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [8,8,1,16],
					pats: 0
				},
				{
					id: 137,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 5,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.21,12.21,12.21,12.21,11.4],
					pats: 0
				},
				{
					id: 138,
					code: 'sh0426',
					name: 'LV5FU2 + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, панитумумаб)',
					drugs: [13,61,19],
					rate: 1,
					dose: [750,5000,500],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,8],
					pats: 0
				},
				{
					id: 139,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 3,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8,8],
					pats: 0
				},
				{
					id: 140,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 5,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 141,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 5,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 142,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 143,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [10],
					pats: 0
				},
				{
					id: 144,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 145,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные, но не ставшие ими Линия 2
		'st4_2_4':{
			group: 'Потенциально-резектабельные, но не ставшие ими',
			meth: [
				{
					id: 146,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 2.5,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 147,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 1.5,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 148,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 1,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 149,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 2,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 150,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 9,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 1
				},
				{
					id: 151,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 2,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 152,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 1
				},
				{
					id: 153,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 3.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 154,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 155,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 156,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 5,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 157,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 14,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 4
				},
				{
					id: 158,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 4,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 159,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 10,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,5.714,5.714],
					pats: 1
				},
				{
					id: 160,
					code: 'sh0670.1',
					name: 'FOLFOX + цетуксимаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, цетуксимаб)',
					drugs: [13,16,61,23,23],
					rate: 1,
					dose: [750,150,5000,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 161,
					code: 'sh0620.1',
					name: 'FOLFIRI + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, цетуксимаб)',
					drugs: [13,61,9,23,23],
					rate: 4,
					dose: [750,5000,300,700,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [12.21,12.21,12.21,1,22.8],
					pats: 0
				},
				{
					id: 162,
					code: 'sh0557.1',
					name: 'LV5FU2 + цетуксимаб',
					info: '(кальция фолинат, 5-фторурацил, цетуксимаб)',
					drugs: [13,61,23,23],
					rate: 1,
					dose: [750,5000,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,1,16],
					pats: 0
				},
				{
					id: 163,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [8,8,1,16],
					pats: 0
				},
				{
					id: 164,
					code: 'sh0668',
					name: 'FOLFOX + панитумумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, панитумумаб)',
					drugs: [13,16,61,19],
					rate: 1,
					dose: [750,150,5000,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [12.21,12.21,12.21,11.4],
					pats: 0
				},
				{
					id: 165,
					code: 'sh0648',
					name: 'FOLFIRI + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, панитумумаб)',
					drugs: [13,61,9,25,19],
					rate: 4,
					dose: [750,5000,300,40,500],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [12.21,12.21,12.21,12.21,11.4],
					pats: 0
				},
				{
					id: 166,
					code: 'sh0426',
					name: 'LV5FU2 + панитумумаб',
					info: '(кальция фолинат, 5-фторурацил, панитумумаб)',
					drugs: [13,61,19],
					rate: 1,
					dose: [750,5000,500],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [8.5714,8.5714,8],
					pats: 0
				},
				{
					id: 167,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 3,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [8,8,8],
					pats: 0
				},
				{
					id: 168,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 5,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 169,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 5,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 170,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 171,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [10],
					pats: 0
				},
				{
					id: 172,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 173,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Нерезектабельные Линия 3
		'st4_3_1':{
			group: 'Нерезектабельные',
			meth: [
				{
					id: 174,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.357,5.357,5.357],
					pats: 12
				},
				{
					id: 175,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [5.357,5.357,5.357],
					pats: 5
				},
				{
					id: 176,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 9,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [3.57,3.57,3.57],
					pats: 16
				},
				{
					id: 177,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 5,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [3.57],
					pats: 9
				},
				{
					id: 178,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 14,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [5.357,5.357,5.357,5.357],
					pats: 24
				},
				{
					id: 179,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 5,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4.286,4.286],
					pats: 9
				},
				{
					id: 180,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 9,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [2.857,2.857],
					pats: 16
				},
				{
					id: 181,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [6.428,6.428,6.428,6.428],
					pats: 2
				},
				{
					id: 182,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6.428,6.428,6.428,6.428],
					pats: 1
				},
				{
					id: 183,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [4.286,4.286,4.286,4.286,4.286],
					pats: 1
				},
				{
					id: 184,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 2,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [2.857,2.857,2.857],
					pats: 3
				},
				{
					id: 185,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 2,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [6.428,6.428,6.428,6.428,6.428],
					pats: 3
				},
				{
					id: 186,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 2,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4.286,4.286,4.286],
					pats: 3
				},
				{
					id: 187,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 2,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [6,6,4.286,4.286],
					pats: 3
				},
				{
					id: 188,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 2,
					dose: [100,80,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,8],
					pats: 3
				},
				{
					id: 189,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 2,
					dose: [600,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 190,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 30,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 53
				},
				{
					id: 191,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 1,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 2
				},
				{
					id: 192,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 1,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8.5714],
					pats: 2
				},
				{
					id: 193,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 2
				},
				{
					id: 194,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 0.5,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Исходно резектабельные после операции Линия 3
		'st4_3_2':{
			group: 'Исходно резектабельные после операции',
			meth: [
				{
					id: 195,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 196,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 197,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 11,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 1
				},
				{
					id: 198,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 5,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 199,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 11,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 1
				},
				{
					id: 200,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 5,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 201,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 1
				},
				{
					id: 202,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 203,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 204,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 205,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 2,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 206,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 2,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 207,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 2,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 208,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 2,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [6,6,5.714,5.714],
					pats: 0
				},
				{
					id: 209,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 2,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [5.714,5.714,1,16],
					pats: 0
				},
				{
					id: 210,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 2,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 211,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 0,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 212,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 30,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 2
				},
				{
					id: 213,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 1,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 214,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 1,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8.5714],
					pats: 0
				},
				{
					id: 215,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 216,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально резектабельные после операции Линия 3
		'st4_3_3':{
			group: 'Потенциально резектабельные после операции',
			meth: [
				{
					id: 217,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 218,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 219,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 9,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 220,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 10,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 221,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 9,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 0
				},
				{
					id: 222,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 4,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 223,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 0
				},
				{
					id: 224,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 225,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 226,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 2,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 227,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 2,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 228,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 2,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 229,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 2,
					dose: [600,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [6,6,5.714,5.714],
					pats: 0
				},
				{
					id: 230,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 2,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,16],
					pats: 0
				},
				{
					id: 231,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 2,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 232,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 0,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 233,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 30,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 3
				},
				{
					id: 234,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 1,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 235,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 1,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [10],
					pats: 0
				},
				{
					id: 236,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 237,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные, но не ставшие ими Линия 3
		'st4_3_4':{
			group: 'Потенциально-резектабельные, но не ставшие ими',
			meth: [
				{
					id: 238,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 239,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 240,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 9,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 241,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 5,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 0
				},
				{
					id: 242,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 14,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 1
				},
				{
					id: 243,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 5,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 244,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 9,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 0
				},
				{
					id: 245,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 246,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0.5,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 247,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 248,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 2,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 249,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 2,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 250,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 2,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 251,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 2,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [6,6,5.714,5.714],
					pats: 0
				},
				{
					id: 252,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 2,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,16],
					pats: 0
				},
				{
					id: 253,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 2,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 254,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 30,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 3
				},
				{
					id: 255,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 1,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 256,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 1,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [10],
					pats: 0
				},
				{
					id: 257,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [3,3,3,3],
					pats: 0
				},
				{
					id: 258,
					code: 'sh0924',
					name: 'Панитумумаб + дабрафениб + траметиниб',
					info: '(панитумумаб, дабрафениб, траметиниб)',
					drugs: [19,4,22],
					rate: 1,
					dose: [500,9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [3,3,3],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Нерезектабельные Линия 4
		'st4_4_1':{
			group: 'Нерезектабельные',
			meth: [
				{
					id: 259,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286,4.286],
					pats: 4
				},
				{
					id: 260,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [4.286,4.286,4.286],
					pats: 2
				},
				{
					id: 261,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 13,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [2.857,2.857,2.857],
					pats: 7
				},
				{
					id: 262,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 20,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [2.857],
					pats: 8
				},
				{
					id: 263,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 14,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286,4.286,4.286],
					pats: 7
				},
				{
					id: 264,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 10,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4.286,4.286],
					pats: 5
				},
				{
					id: 265,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [2.857,2.857],
					pats: 5
				},
				{
					id: 266,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4.286,4.286,4.286,4.286],
					pats: 1
				},
				{
					id: 267,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 268,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [4.286,4.286,4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 269,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 1,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [2.857,2.857,2.857],
					pats: 1
				},
				{
					id: 270,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 1,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4.286,4.286,4.286,4.286,4.286],
					pats: 1
				},
				{
					id: 271,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 0.5,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 272,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 1,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4,2.857,2.857],
					pats: 1
				},
				{
					id: 273,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,8],
					pats: 1
				},
				{
					id: 274,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 1,
					dose: [600,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,4],
					pats: 1
				},
				{
					id: 275,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 14,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.57],
					pats: 7
				},
				{
					id: 276,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [10,10],
					pats: 0
				},
				{
					id: 277,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [7.1429],
					pats: 0
				},
				{
					id: 278,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [2,2,2,2],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Исходно резектабельные после операции Линия 4
		'st4_4_2':{
			group: 'Исходно резектабельные после операции',
			meth: [
				{
					id: 279,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 280,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 281,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 10,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 282,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 23,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 3
				},
				{
					id: 283,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 13,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 0
				},
				{
					id: 284,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 10,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 285,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 0
				},
				{
					id: 286,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0.5,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 287,
					code: 'sh0204',
					name: 'FLOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 0,
					dose: [50,150,2000,400],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 288,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 289,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 1,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 290,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 1,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 291,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 1,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 292,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 1,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4,5.714,5.714],
					pats: 0
				},
				{
					id: 293,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,16],
					pats: 0
				},
				{
					id: 294,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 1,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 295,
					code: 'sh0647',
					name: 'FOLFIRI + афлиберцепт',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, афлиберцепт)',
					drugs: [13,61,9,25,1,27],
					rate: 5,
					dose: [750,5000,300,40,200,100],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141,8,8],
					pats: 0
				},
				{
					id: 296,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 15,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 297,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 298,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [7.1429],
					pats: 0
				},
				{
					id: 299,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [2,2,2,2],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально резектабельные после операции Линия 4
		'st4_4_3':{
			group: 'Потенциально резектабельные после операции',
			meth: [
				{
					id: 300,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 301,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 302,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 10,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 303,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 23,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 2
				},
				{
					id: 304,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 14,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 0
				},
				{
					id: 305,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 10,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 306,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 9,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 0
				},
				{
					id: 307,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 308,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 1,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 309,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 1,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 310,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 1,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 311,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 1,
					dose: [600,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4,5.714,5.714],
					pats: 0
				},
				{
					id: 312,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,16],
					pats: 0
				},
				{
					id: 313,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 1,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 314,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 15,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 315,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 316,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [7.1429],
					pats: 0
				},
				{
					id: 317,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [2,2,2,2],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Потенциально-резектабельные, но не ставшие ими Линия 4
		'st4_4_4':{
			group: 'Потенциально-резектабельные, но не ставшие ими',
			meth: [
				{
					id: 318,
					code: 'sh0130',
					name: 'FOLFOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 7,
					dose: [750,150,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 319,
					code: 'sh0202',
					name: 'FLOX',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил)',
					drugs: [13,16,61],
					rate: 3,
					dose: [50,150,2000],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [7.5,7.5,7.5],
					pats: 0
				},
				{
					id: 320,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 13,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 0
				},
				{
					id: 321,
					code: 'sh0650',
					name: 'Капецитабин моно',
					info: '(капецитабин)',
					drugs: [10],
					rate: 19,
					dose: [63000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [4.286],
					pats: 1
				},
				{
					id: 322,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(кальция фолинат, 5-фторурацил, иринотекан)',
					drugs: [13,61,9,25],
					rate: 14,
					dose: [750,5000,300,40],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8.57141,8.57141,8.57141,8.57141],
					pats: 0
				},
				{
					id: 323,
					code: 'sh0191',
					name: 'LV5FU2',
					info: '(кальция фолинат, 5-фторурацил)',
					drugs: [13,61],
					rate: 10,
					dose: [750,5000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6.428,6.428],
					pats: 0
				},
				{
					id: 324,
					code: 'sh0083',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [600,40],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4.286,4.286],
					pats: 0
				},
				{
					id: 325,
					code: 'sh0664',
					name: 'FOLFOX + бевацизумаб',
					info: '(кальция фолинат, оксалиплатин, 5-фторурацил, бевацизумаб)',
					drugs: [13,16,61,2],
					rate: 1,
					dose: [750,150,5000,400],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 326,
					code: 'sh0654',
					name: 'XELOX + бевацизумаб',
					info: '(капецитабин, оксалиплатин, бевацизумаб)',
					drugs: [10,16,24,2,26],
					rate: 0.5,
					dose: [50500,150,100,400,200],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6.43,6.43,6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 327,
					code: 'sh0691',
					name: 'Капецитабин + бевацизумаб',
					info: '(капецитабин, бевацизумаб)',
					drugs: [10,2,26],
					rate: 1,
					dose: [63000,400,200],
					ds: 'ds19.042',//>>
					st: 'st19.068',//>>
					cycles: [4.286,4.286,4.286],
					pats: 0
				},
				{
					id: 328,
					code: 'sh0648',
					name: 'FOLFIRI + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, иринотекан, бевацизумаб)',
					drugs: [13,61,9,25,2],
					rate: 1,
					dose: [750,5000,300,40,400],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9.64,9.64,9.64,9.64,9.64],
					pats: 0
				},
				{
					id: 329,
					code: 'sh0195',
					name: 'LV5FU2 + бевацизумаб',
					info: '(кальция фолинат, 5-фторурацил, бевацизумаб)',
					drugs: [13,61,2],
					rate: 0.5,
					dose: [750,5000,400],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6.43,6.43,6.43],
					pats: 0
				},
				{
					id: 330,
					code: 'sh0085',
					name: 'Иринотекан + бевацизумаб',
					info: '(иринотекан, бевацизумаб)',
					drugs: [9,25,2,26],
					rate: 1,
					dose: [300,40,400,200],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [4,4,5.714,5.714],
					pats: 0
				},
				{
					id: 331,
					code: 'sh0088.1',
					name: 'Иринотекан + цетуксимаб',
					info: '(иринотекан, цетуксимаб)',
					drugs: [9,25,23,23],
					rate: 1,
					dose: [300,40,700,500],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [4,4,1,16],
					pats: 0
				},
				{
					id: 332,
					code: 'sh0087',
					name: 'Иринотекан + панитумумаб',
					info: '(иринотекан, панитумумаб)',
					drugs: [9,25,19],
					rate: 1,
					dose: [300,40,500],
					ds: 'ds19.045',//>>
					st: 'st19.069',//>>
					cycles: [4,4,8],
					pats: 0
				},
				{
					id: 333,
					code: 'sh0164',
					name: 'Регорафениб моно',
					info: '(регорафениб)',
					drugs: [21],
					rate: 15,
					dose: [3360],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [2.857],
					pats: 0
				},
				{
					id: 334,
					code: 'sh0662',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 0.5,
					dose: [200,40],
					ds: 'ds19.049',//>>
					st: 'st19.073',//>>
					cycles: [12,12],
					pats: 0
				},
				{
					id: 335,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 0.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [7.1429],
					pats: 0
				},
				{
					id: 336,
					code: 'sh0942',
					name: 'Вемурафениб + цетуксимаб + иринотекан',
					info: '(вемурафениб, цетуксимаб, иринотекан)',
					drugs: [3,23,23,9],
					rate: 1,
					dose: [57600,800,500,400],
					ds: 'ds19.049',//>>
					st: '',
					cycles: [2,2,2,2],
					pats: 0
				}
			],
			pats: 0
		}
	}, 

//////////////////////////////////////////////////////////// KRR

	'rl': {
		'st1': {
			meth: [
			],
			pats: 0 
		},
		'st2': {
			meth: [
				{
					name: 'Эксеместан + трастузумаб',
					info: '(эксеместан, трастузумаб)',
					drugs: [],
					dose: [],
					price: [],
					rate: 50,
					cycles: [0],
					pats: 560
				}
			],
			pats: 560
		},	
		'st3': { 
			meth: [
				{
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [],
					dose: [],
					price: [],
					rate: 22.5,
					cycles: [0],
					pats: 200
				},
				{
					name: 'Циклофосфамид + Метотрексат',
					info: '(циклофосфамид, метотрексат)',
					drugs: [],
					dose: [],
					price: [],
					rate: 22.5,
					cycles: [0],
					pats: 100
				},
				{
					name: 'Винорелбин пероральный',
					info: '(винорелбин)',
					drugs: [],
					dose: [],
					price: [],
					rate: 50,
					cycles: [0],
					pats: 50
				},
				{
					name: 'Фулвестрант + трастузумаб',
					info: '(фулвестрант, трастузумаб)',
					drugs: [],
					dose: [],
					price: [],
					rate: 5,
					cycles: [0],
					pats: 57
				}
			],
			pats: 407
		}

	},

	'melanoma': {
// ============================>melanoma
// ---------> Стадия 1 
		'st1':{
			meth: [
			],
			pats: 0
		},

// ---------> Стадия 2 
		'st2':{
			meth: [
				{
					id: 337,
					code: 'sh9001',
					name: 'Интерферон',
					info: '(интерферон)',
					drugs: [7],
					rate: 50,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 25
				}
			],
			pats: 0
		},

// ---------> Стадия 3 
		'st3':{
			meth: [
				{
					id: 338,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 22.5,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [26,26],
					pats: 7
				},
				{
					id: 339,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 22.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [18],
					pats: 7
				},
				{
					id: 340,
					code: 'sh0049',
					name: 'Дабрафениб + траметиниб',
					info: '(дабрафениб, траметиниб)',
					drugs: [4,22],
					rate: 50,
					dose: [9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [12,12],
					pats: 16
				},
				{
					id: 341,
					code: 'sh9001',
					name: 'ИФН-альфа',
					info: 'ифн-альфа',
					drugs: [7],
					rate: 5,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 2
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 1
		'st4_1':{
			meth: [
				{
					id: 342,
					code: 'sh0049',
					name: 'Дабрафениб + траметиниб',
					info: '(дабрафениб, траметиниб)',
					drugs: [4,22],
					rate: 20,
					dose: [9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [12,12],
					pats: 7
				},
				{
					id: 343,
					code: 'sh0023',
					name: 'Вемурафениб + кобиментиниб',
					info: '(вемурафениб, кобиментиниб)',
					drugs: [3,12],
					rate: 15,
					dose: [53760,1260],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [13,13],
					pats: 5
				},
				{
					id: 344,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 22.5,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [12,12],
					pats: 8
				},
				{
					id: 345,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 22.5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8],
					pats: 7
				},
				{
					id: 346,
					code: 'sh0604;sh0661',
					name: 'Ниволумаб + ипилимумаб',
					info: '(ниволумаб, ипилимумаб)',
					drugs: [15,8,14,15],
					rate: 20,
					dose: [80,250,200,40],
					ds: 'ds19.049',//>>
					st: 'st19.074',//>>
					cycles: [4,4,20,20],
					custKsg: '604_661',//
					pats: 7
				},
				{
					id: 347,
					code: 'sh0022',
					name: 'Вемурафениб',
					info: '(вемурафениб)',
					drugs: [3],
					rate: 0,
					dose: [57600],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [5.6786],
					pats: 0
				},
				{
					id: 348,
					code: 'sh0048',
					name: 'Дабрафениб',
					info: '(дабрафениб)',
					drugs: [4],
					rate: 0,
					dose: [9000],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [5.1],
					pats: 0
				},
				{
					id: 349,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,18,11],
					rate: 0,
					dose: [300,30,900],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 350,
					code: 'sh0050',
					name: 'Дакарбазин',
					info: '(дакарбазин 200 мг)',
					drugs: [5],
					rate: 0,
					dose: [1800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1190,
					code: 'sh0051',
					name: 'Дакарбазин',
					info: '(дакарбазин 500 мг)',
					drugs: [145],
					rate: 0,
					dose: [2500],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1191,
					code: 'sh0081',
					name: 'Ипилимумаб',
					info: '(ипилимумаб)',
					drugs: [8],
					rate: 0,
					dose: [250],
					ds: 'ds19.049',//>>
					st: 'st19.074',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1192,
					code: 'sh0693',
					name: 'Темозоломид',
					info: '(темозоломид)',
					drugs: [146],
					rate: 0,
					dose: [360],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1193,
					code: 'sh9001',
					name: 'Арабинопиранозилметил нитрозомочевина',
					info: '(арабинопиранозилметил нитрозомочевина)',
					drugs: [147],
					rate: 0,
					dose: [3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1194,
					code: 'sh0807',
					name: 'Ломустин',
					info: '(ломустин)',
					drugs: [148],
					rate: 0,
					dose: [240],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1195,
					code: 'sh0226',
					name: 'Цисплатин + винбластин + дакарбазин',
					info: '(цисплатин, винбластин, дакарбазин)',
					drugs: [93,113,145],
					rate: 0,
					dose: [160,20,1500],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 0
				},
				{
					id: 1196,
					code: 'sh0077',
					name: 'Иматиниб',
					info: '(иматиниб)',
					drugs: [6],
					rate: 0,
					dose: [24000],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [10],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Стадия 4 Линия 2
		'st4_2':{
			meth: [
				{
					id: 351,
					code: 'sh0049',
					name: 'Дабрафениб + траметиниб',
					info: '(дабрафениб, траметиниб)',
					drugs: [4,22],
					rate: 18.46,
					dose: [9000,60],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [12,12],
					pats: 4
				},
				{
					id: 352,
					code: 'sh0023',
					name: 'Вемурафениб + кобиментиниб',
					info: '(вемурафениб, кобиментиниб)',
					drugs: [3,12],
					rate: 18.46,
					dose: [53760,1260],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [13,13],
					pats: 4
				},
				{
					id: 353,
					code: 'sh0661',
					name: 'Ниволумаб',
					info: '(ниволумаб)',
					drugs: [14,15],
					rate: 15.38,
					dose: [200,40],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [12,12],
					pats: 3
				},
				{
					id: 354,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 15.38,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8],
					pats: 3
				},
				{
					id: 355,
					code: 'sh0604;sh0661',
					name: 'Ниволумаб + ипилимумаб',
					info: '(ниволумаб, ипилимумаб)',
					drugs: [15,8,14,15],
					rate: 7.69,
					dose: [80,250,200,40],
					ds: 'ds19.049',//>>
					st: 'st19.074',//>>
					cycles: [4,4,20,20],
					custKsg: '604_661',//
					pats: 2
				},
				{
					id: 356,
					code: 'sh0022',
					name: 'Вемурафениб',
					info: '(вемурафениб)',
					drugs: [3],
					rate: 0,
					dose: [57600],
					ds: 'ds19.047',//>>
					st: '',
					cycles: [5.6786],
					pats: 0
				},
				{
					id: 357,
					code: 'sh0048',
					name: 'Дабрафениб',
					info: '(дабрафениб)',
					drugs: [4],
					rate: 0,
					dose: [9000],
					ds: 'ds19.048',//>>
					st: '',
					cycles: [5.1],
					pats: 0
				},
				{
					id: 358,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,18,11],
					rate: 7.69,
					dose: [300,30,900],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 359,
					code: 'sh0050',
					name: 'Дакарбазин',
					info: '(дакарбазин 200 мг)',
					drugs: [5],
					rate: 7.69,
					dose: [1800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 2
				},
				{
					id: 360,
					code: 'sh0081',
					name: 'Ипилимумаб',
					info: '(ипилимумаб)',
					drugs: [8],
					rate: 7.69,
					dose: [250],
					ds: 'ds19.049',//>>
					st: 'st19.074',//>>
					cycles: [4],
					pats: 2
				},
				{
					id: 361,
					code: 'sh0077',
					name: 'Иматиниб',
					info: '(иматиниб)',
					drugs: [6],
					rate: 1.54,
					dose: [24000],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [10],
					pats: 0
				},
				{
					id: 1197,
					code: 'sh0051',
					name: 'Дакарбазин',
					info: '(дакарбазин 500 мг)',
					drugs: [145],
					rate: 0,
					dose: [2500],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1198,
					code: 'sh0693',
					name: 'Темозоломид',
					info: '(темозоломид)',
					drugs: [146],
					rate: 0,
					dose: [360],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1199,
					code: 'sh9001',
					name: 'Арабинопиранозилметил нитрозомочевина',
					info: '(арабинопиранозилметил нитрозомочевина)',
					drugs: [147],
					rate: 0,
					dose: [3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1200,
					code: 'sh0807',
					name: 'Ломустин',
					info: '(ломустин)',
					drugs: [148],
					rate: 0,
					dose: [240],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 1201,
					code: 'sh0226',
					name: 'Цисплатин + винбластин + дакарбазин',
					info: '(цисплатин, винбластин, дакарбазин)',
					drugs: [93,113,145],
					rate: 0,
					dose: [160,20,1500],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 0
				}
			],
			pats: 0
		}
	}
};

export default methData;