
'use strict';
// Актуальность - 03.10.2021 
// !! Актуальны только проценты, но не постановления !!
var regList = {
	0:{
		'regName':'Центральный Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':0,
		'ind':0
	},

	1:{
		'regName':'Белгородская область',
		'zone':'',
		'regDescr':'',
		'inter':5,
		'lawdoc':'Постановление Губернатора Белгородской области от 19.02.2010 № 11',
		'num':1,
		'ind':1
	},

	2:{
		'regName':'Брянская область',
		'zone':'',
		'regDescr':'',
		'inter':7,
		'lawdoc':'Приказ Управления государственного регулирования тарифов Брянской области от 21.09.2021 № 24/1-л',
		'num':2,
		'ind':2
	},

	3:{
		'regName':'Владимирская область',
		'zone':'',
		'regDescr':'',
		'inter':11,
		'lawdoc':'Постановление Губернатора Владимирской области от 26.02.2010 №167, от 05.02.2015 № 61',
		'num':3,
		'ind':3
	},

	4:{
		'regName':'Воронежская область',
		'zone':'',
		'regDescr':'',
		'inter':7,
		'lawdoc':'Приказ Управления по государственному регулированию тарифов Воронежской области от 26.02.2010 № 6/2,в (редакции приказа от 24.03.2017 № 12/1)',
		'num':4,
		'ind':4
	},

	5:{
		'regName':'Ивановская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановление РСТ Ивановской области от 24.02.2010 № 259-л/1, постановление РСТ Ивановской обл. от 18.03.2010 № 265-л/1, Постановление Департамента энергетики и тарифов Ивановской обл. от 18.12.2015 № 60-л/1.',
		'num':5,
		'ind':5
	},

	6:{
		'regName':'Калужская область',
		'zone':'',
		'regDescr':'',
		'inter':8,
		'lawdoc':'Приказ Министерства  конкурентной политики Калужской области от 27.09.2021 № 49-РК',
		'num':6,
		'ind':7
	},

	7:{
		'regName':'Костромская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Департамента государственного регулирования цен и тарифов Костромской области от 27.09.2021 № 21/159',
		'num':7,
		'ind':8
	},

	8:{
		'regName':'Курская область',
		'zone':'',
		'regDescr':'',
		'inter':8,
		'lawdoc':'Постановление Администрации  Курской области от 27.08.2010 № 377-па',
		'num':8,
		'ind':9
	},

	9:{
		'regName':'Липецкая область',
		'zone':'',
		'regDescr':'',
		'inter':7.2,
		'lawdoc':'Приказ  Управления по ценам и контролю в сфере размещения государственных заказов Липецкой области от 25.02.2010 № 5',
		'num':9,
		'ind':10
	},

	10:{
		'regName':'г.Москва',
		'zone':'',
		'regDescr':'',
		'inter':7,
		'lawdoc':'Постановление Правительства г.Москвы от 24.02.2010 № 163-ПП (в ред. постановления  Правительства Москвы  от 29.09.2021 № 1545-ПП)',
		'num':10,
		'ind':11
	},

	11:{
		'regName':'Московская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Правительства Московской области от 26.02.2010 № 100/8 ',
		'num':11,
		'ind':12
	},

	12:{
		'regName':'Орловская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Приказ Управления по тарифам Орловской области от 18.02.2010 № 88-т, (01.02.2016 № 76-т; в редакция от 23.01.2017)',
		'num':12,
		'ind':13
	},

	13:{
		'regName':'Рязанская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Главного Управления "Региональная энергетическая комиссия" Рязанской области от 20.12.2011 № 488',
		'num':13,
		'ind':14
	},

	14:{
		'regName':'Смоленская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановление Администрации Смоленской области от 22.12.2010г. № 809 (в ред. от 21.02.2018 № 98)',
		'num':14,
		'ind':15
	},

	15:{
		'regName':'Тамбовская область',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Постановление Администрации Тамбовской области от 27.09.2021 № 734',
		'num':15,
		'ind':16
	},

	16:{
		'regName':'Тверская область',
		'zone':'',
		'regDescr':'',
		'inter':8.5,
		'lawdoc':'Приказ РЭК Тверской области от 29.11.2010 № 545-нп',
		'num':16,
		'ind':6
	},

	17:{
		'regName':'Тульская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Правительства Тульской области от 26.02.2010 № 179, от 01.07.2013  № 313',
		'num':17,
		'ind':17
	},

	18:{
		'regName':'Ярославская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Правительства Ярославской области  от 26.02.2010  № 111-п',
		'num':18,
		'ind':18
	},

	19:{
		'regName':'Северо-Западный Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':19,
		'ind':0
	},


	20:{
		'regName':'Республика Карелия',
		'zone':'',
		'regDescr':'',
		'inter':20,
		'lawdoc':'Постановление Правительства Республики Карелия от 14.09.2021 № 401-П',
		'num':20,
		'ind':28
	},

	21:{
		'regName':'Республика Коми',
 		'zone':'1 зона',
		'regDescr':'1 зона - МО ГО "Сыктывкар",  МО МР "Койгородский", МО МР "Прилузский", МО МР"Сысольский",  МО МР"Княжпогостский",  МО МР"Корткеросский",  МО МР "Усть-Куломский",  МО МР "Сыктывдинский",  МО МР "Усть-Вымский", МО МР "Троицко-Печорский",  МО МР "Удорский", МО ГО "Ухта", МО МР "Вуктыл", МО МР "Сосногорск"',
		'inter':12,
		'lawdoc':'Приказ Службы Республики Коми по тарифам от 04.10.2010 № 65/1, от 10.12.2010 № 94/4',
		'num':21,
		'ind':29
	},

	22:{
		'regName':'Республика Коми',
 		'zone':'2 зона',
		'regDescr':'2 зона - МО МР "Печора", МО МР "Ижемский", МО МР "Усть-Цилемский", МО ГО "Инта", МО ГО "Усинск", МО ГО "Воркута"',
		'inter':14,
		'lawdoc':'Приказ Службы Республики Коми по тарифам от 04.10.2010 № 65/1, от 10.12.2010 № 94/4',
		'num':22,
		'ind':29
	},

	23:{
		'regName':'Архангельская область',
 		'zone':'1 группа районов',
		'regDescr':'1 группа районов - районы с ограниченными сроками завоза грузов - Верхнетоемский, Ленский, Лешуконский, Мезенский, Пинежский, Приморский и Шенкурский и территория архипелага Новая Земля.',
		'inter':12,
		'lawdoc':'Постановление Агентства по тарифам и ценам Архангельской области 23.09.2021 № 51-п/5',
		'num':23,
		'ind':20
	},

	24:{
		'regName':'Архангельская область',
 		'zone':'2 группа районов',
		'regDescr':'2 группа районов - все остальные',
		'inter':12,
		'lawdoc':'Постановление Агентства по тарифам и ценам Архангельской области 23.09.2021 № 51-п/5',
		'num':24,
		'ind':20
	},

	25:{
		'regName':'Ненецкий автономный округ',
		'zone':'',
		'regDescr':'',
		'inter':8,
		'lawdoc':'Приказ Комитета по гос.регулированию  цен (тарифов) Ненецкого а.о. от 25.02.2010 № 14',
		'num':25,
		'ind':19
	},

	26:{
		'regName':'Вологодская область',
		'zone':'',
		'regDescr':'',
		'inter':11.5,
		'lawdoc':'Постановление Правительства Вологодской области  от 24.06.2015 № 216',
		'num':26,
		'ind':21
	},

	27:{
		'regName':'Калининградская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановление Правительства Калининградской области от 26.02.2010 № 68 (в ред. Постановлений Правительства Калининградской области от 10.05.2011 № 329, от 25.03.2015 № 147, от 08.12.2015 № 695)',
		'num':27,
		'ind':22
	},

	28:{
		'regName':'Ленинградская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Приказ Комитета по тарифам и ценовой политике Ленинградской области от 30.09.2021 № 111-п',
		'num':28,
		'ind':24
	},

	29:{
		'regName':'Мурманская область',
		'zone':'',
		'regDescr':'',
 		'inter':20,
		'lawdoc':'Постановление Правительства Мурманской области от 26.02.2010 № 76-ПП (в ред Постановления Правительства Мурманской обл. от 10.12.2015 № 568-ПП)',
		'num':29,
		'ind':25
	},

	30:{
		'regName':'Новгородская область',
		'zone':'',
		'regDescr':'',
		'inter':6,
		'lawdoc':'Постановление  Администрации Новгородской области от 25.02.2010 № 77, от 20.04.2010 № 174, от 02.10.2012 № 589, постановление Правительства Новгородской области от 21.01.2014 № 7',
		'num':30,
		'ind':26
	},

	31:{
		'regName':'Псковская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Администрации Псковской области от 10.12.2015 № 555',
		'num':31,
		'ind':27
	},

	32:{
		'regName':'г.Санкт-Петербург',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Распоряжение Комитета по тарифам Санкт-Петербурга от 29.09.2021 № 52-р',
		'num':32,
		'ind':23
	},

	33:{
		'regName':'Южный Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':33,
		'ind':0
	},


	34:{
		'regName':'Республика Адыгея',
		'zone':'',
		'regDescr':'',
		'inter':15,
		'lawdoc':'Приказ Управления государственного регулирования цен и тарифов Республики Адыгея  от 31.08.2010 № 26-п/2010 лек, от  23.09.2010 № 40п/2010-лек, от 27.12.2010 № 108-п/2010-лек, 17.09.2014 № 96-п, от 14.10.2014№ 108-п',
		'num':34,
		'ind':34
	},

	35:{
		'regName':'Астраханская область',
		'zone':'',
		'regDescr':'',
		'inter':11.75,
		'lawdoc':'Постановления Правительства Астраханской области  от 26.02.2010 № 67-п и от 27.02.2010 № 68-п,  от 03.08.2010 № 342-П',
		'num':35,
		'ind':31
	},

	36:{
		'regName':'Волгоградская область',
		'zone':'',
		'regDescr':'',
		'inter':10.36,
		'lawdoc':'Приказ комитета тарифного регулирования Волгоградской области от 25.11.2015г. № 50/2',
		'num':36,
		'ind':32
	},

	37:{
		'regName':'Республика Калмыкия',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Приказ РСТ Республики Калмыкия от 15.12.2015 № 93п/лп',
		'num':37,
		'ind':35
	},

	38:{
		'regName':'Краснодарский край',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Приказ РЭК-Департамента цен и тарифов Краснодарского края от 23.10.2019 № 5/2019-м (вступил в силу с 09.11.2019)',
		'num':38,
		'ind':30
	},

	39:{
		'regName':'Ростовская область',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Постановление Службы по тарифам Ростовской области от 28.09.2021 № 47/1',
		'num':39,
		'ind':33
	},

//!! Update
	40:{
		'regName':'Республика Крым',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Приказ Государственного Комитета по ценам и тарифам Республики Крым от 02.09.2021 № 33/4',
		'num':40,
		'ind':36
	},

	41:{
		'regName':'г. Севастополь',
		'zone':'',
		'regDescr':'',
		'inter':15,
		'lawdoc':'ППостановление Управления по тарифам Севастополя от 18.02.2015 № 15/8-м; Постановление Управления от 26.10.2015 №  15/47-м.',
		'num':41,
		'ind':37
	},

	42:{
		'regName':'Северо-Кавказский федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':42,
		'ind':0
	},


	43:{
		'regName':'Республика Дагестан',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Республиканской Службы по тарифам Республики Дагестан от 31.10.2014 № 43',
		'num':43,
		'ind':40
	},

	44:{
		'regName':'Чеченская Республика',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Решение Правления Государственного Комитета цен и тарифов Чеченской Республики от 31.10.2014 № 50-у. ',
		'num':44,
		'ind':44
	},

	45:{
		'regName':'Республика Ингушетия',
		'zone':'',
		'regDescr':'',
		'inter':14,
		'lawdoc':'Постановление  Правительства Республики Ингушетия от  19.01.2011 № 5',
		'num':45,
		'ind':39
	},

	46:{
		'regName':'Кабардино-Балкарская Республика',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Госуд.Комитета КБР по тарифам от 25.02.2010 № 7',
		'num':46,
		'ind':41
	},

	47:{
		'regName':'Карачаево-Черкесская  Республика',
		'zone':'',
		'regDescr':'',
 		'inter':11,
		'lawdoc':'Постановление Главного Управления КЧР по тарифам и ценам от 26.02.2010 № 9, от 13.09.2010 № 77, от 23.04.2015 № 61',
		'num':47,
		'ind':43
	},

	48:{
		'regName':'Республика Северная Осетия-Алания',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановление Правительства Республики Северная Осетия-Алания от 26.12.2014 №471',
		'num':48,
		'ind':42
	},

	49:{
		'regName':'Ставропольский край',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Постановление Региональной Тарифной Комиссии Ставропольского края от 23.09.2021 № 55/1',
		'num':49,
		'ind':38
	},

	50:{
		'regName':'Приволжский Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':50,
		'ind':0
	},


	51:{
		'regName':'Республика Башкортостан',
		'zone':'',
		'regDescr':'',
		'inter':8,
		'lawdoc':'Постановление Государственного Комитета Республики Башкортостан по тарифам от 13.09.2021 № 115',
		'num':51,
		'ind':53
	},

	52:{
		'regName':'Республика Марий-Эл',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Приказ Минздрава Республики Марий Эл от 19.02.2010 № 130',
		'num':52,
		'ind':54
	},

	53:{
		'regName':'Республика Мордовия',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'остановление Правительства Республики Мордовия от 10.09.2021 № 427',
		'num':53,
		'ind':55
	},

	54:{
		'regName':'Республика Татарстан',
		'zone':'',
		'regDescr':'',
		'inter':11,
		'lawdoc':'Постановление Комитета Татарстана по тарифам от 19.02.2010 № 8-1/соц, от 21.08.2010 № 8-3/соц',
		'num':54,
		'ind':56
	},

	55:{
		'regName':'Удмуртская Республика',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Постановление РЭК Удмуртской Республики от 09 ноября 2012 № 14/8',
		'num':55,
		'ind':57
	},

	56:{
		'regName':'Чувашская Республика',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Кабинета Министров Чувашской Республики от 9 декабря 2010 № 411',
		'num':56,
		'ind':58
	},

	57:{
		'regName':'Кировская область',
		'zone':'',
		'regDescr':'',
		'inter':9,
		'lawdoc':'Решение Правления РСТ Кировской области от 21.09.2021 № 32/1-НПС-2022',
		'num':57,
		'ind':46
	},

	58:{
		'regName':'Нижегородская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановление Правительства Нижегородской области от 22.03.2011 № 193',
		'num':58,
		'ind':45
	},

	59:{
		'regName':'Оренбургская область',
		'zone':'',
		'regDescr':'',
		'inter':11,
		'lawdoc':'Постановление Правительства Оренбургской области от 26.04.2011 № 258-п ( в редакции постановления Правительства Оренбургской области от 16.09.2021 № 843-п)',
		'num':59,
		'ind':48
	},

	60:{
		'regName':'Пензенская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Приказ Управления по регулированию тарифов, энергосбережению и размещению госзаказа Пензенской области от 25.02.2010 № 8, приказ Управления от 02.04.2010 № 15, приказ от 20.12.2010 № 41',
		'num':60,
		'ind':49
	},

	61:{
		'regName':'Пермский край',
		'zone':'',
		'regDescr':'',
		'inter':16,
		'lawdoc':'Постановление РЭК Пермского края от 26.02.2010 № 3, от 28.04.2011 № 9',
		'num':61,
		'ind':50
	},

	62:{
		'regName':'Самарская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Постановления Правительства Самарской области от 17.02.2010 № 40, от 21.04.2010 № 144',
		'num':62,
		'ind':47
	},

	63:{
		'regName':'Саратовская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Постановление Правительства Саратовской области от 27.02.2010 № 65-П',
		'num':63,
		'ind':51
	},

	64:{
		'regName':'Ульяновская область',
		'zone':'',
		'regDescr':'',
		'inter':10,
		'lawdoc':'Приказ Министерства экономики Ульяновской области от 27.02.2010 № 06-21',
		'num':64,
		'ind':52
	},

	65:{
		'regName':'Уральский Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':65,
		'ind':0
	},


	66:{
		'regName':'Курганская область',
		'zone':'',
		'regDescr':'',
		'inter':14,
		'lawdoc':'Постановление  Департамента государственного регулирования цен и тарифов Курганской области от 9 января 2010г № 1-1, постановление  Департамента государственного регулирования цен и тарифов Курганской области от 19 марта 2010 № 8-1, от 12.08.2010 № 25-3, 18.11.2010 № 40-31, от 07.07.2011 № 28-5, от 07.06.2012 № 22-1',
		'num':66,
		'ind':61
	},

	67:{
		'regName':'Свердловская область',
		'zone':'',
		'regDescr':'',
		'inter':14,
		'lawdoc':'Постановление РЭК Свердловской области от 01.12.2010 № 149-ПК',
		'num':67,
		'ind':62
	},

	68:{
		'regName':'Тюменская область',
		'zone':'',
		'regDescr':'',
		'inter':13,
		'lawdoc':'Распоряжение Департамента тарифной и ценовой политики Тюменской области от 27.09.2021 № 149/01-21',
		'num':68,
		'ind':63
	},

	69:{
		'regName':'Ханты-Мансийский автономный округ',
		'zone':'',
		'regDescr':'',
		'inter':13,
		'lawdoc':'Постановление Правительства Ханты-Мансийского а.о.- Югры от 24.02.2010 № 67-п (в ред. постановления Правительства Ханты-Мансийского а.о. -Югры от 24.09.2021 № 390-п)',
		'num':69,
		'ind':59
	},

	70:{
		'regName':'Ямало-Ненецкий автономный округ',
 		'zone':'1 зона',
		'regDescr':'1 зона - г.Салехард, г.Лабытнанги, г.Муравленко, г.Новый Уренгой, г. Ноябрьск, г.Губкинский, г.Надым, г.Тарко-Сале',
		'inter':12.1,
		'lawdoc':'Приказ Департамента тарифной политики, энергетики и жилищно-коммунального комплекса Ямало-Ненецкого автономного округа 29.12.2015 № 448-т',
		'num':70,
		'ind':60
	},

	71:{
		'regName':'Ямало-Ненецкий автономный округ',
 		'zone':'2 зона',
		'regDescr':'2 зона - прочие населенные пункты Ямало-Ненецкого автономного округа',
		'inter':12.1,
		'lawdoc':'Приказ Департамента тарифной политики, энергетики и жилищно-коммунального комплекса Ямало-Ненецкого автономного округа 29.12.2015 № 448-т',
		'num':71,
		'ind':60
	},

	72:{
		'regName':'Челябинская область',
		'zone':'',
		'regDescr':'',
		'inter':14,
		'lawdoc':'Постановление Министерства тарифного регулирования и энергетики Челябинской области от 17.09.2021 № 49/1',
		'num':72,
		'ind':64
	},

	73:{
		'regName':'Сибирский Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':73,
		'ind':0
	},


	74:{
		'regName':'Республика Алтай',
 		'zone':'1 зона',
		'regDescr':'1 зона - муниципальные образования, расположенные на территории районов, приравненных к районам Крайнего Севера (Кош-Агачский район, Улаганский район)',
		'inter':8,
		'lawdoc':'Постановление Комитета по тарифам  Республики Алтай от 27.02.2010 № 2, от 05.04.2010 № 4/1,  Приказ Комитета по тарифам Республики Алтай от 30.12.2013 N 33/6',
		'num':74,
		'ind':74
	},

	75:{
		'regName':'Республика Алтай',
 		'zone':'2 зона',
		'regDescr':'2 зона - прочие муниципальные образования',
		'inter':8,
		'lawdoc':'Постановление Комитета по тарифам  Республики Алтай от 27.02.2010 № 2, от 05.04.2010 № 4/1,  Приказ Комитета по тарифам Республики Алтай от 30.12.2013 N 33/6',
		'num':75,
		'ind':74
	},

	76:{
		'regName':'Республика Тыва',
 		'zone':'1 зона',
		'regDescr':'1 зона - г.Кызыл',
		'inter':15,
		'lawdoc':'Постановление Правительства Республика Тыва  от  09.03.2010  №56',
		'num':76,
		'ind':75
	},

	77:{
		'regName':'Республика Тыва',
 		'zone':'2 зона',
		'regDescr':'2 зона - г.Ак-Довурак, кожууны республики',
		'inter':15,
		'lawdoc':'Постановление Правительства Республика Тыва  от  09.03.2010  №56',
		'num':77,
		'ind':75
	},

	78:{
		'regName':'Республика Хакасия',
		'zone':'',
		'regDescr':'',
		'inter':15,
		'lawdoc':'Постановление Правительства Республики Хакасия от 24.02.2010 № 81, от 22.10.2010   № 536, от 23.10.2012 № 710',
		'num':78,
		'ind':76
	},

	79:{
		'regName':'Алтайский край',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Решение Управления Алтайского края по государственному регулированию цен и тарифов от 22.09.2021 № 86',
		'num':79,
		'ind':65
	},

	80:{
		'regName':'Красноярский край',
 		'zone':'1 зона',
		'regDescr':'1 зона - районы Крайнего Севера',
		'inter':11,
		'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п',
		'num':80,
		'ind':66
	},

	81:{
		'regName':'Красноярский край',
 		'zone':'2 зона',
		'regDescr':'2 зона - местности, приравненные к районам Крайнего Севера',
		'inter':11,
		'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п',
		'num':81,
		'ind':66
	},

	82:{
		'regName':'Красноярский край',
 		'zone':'3 зона',
		'regDescr':'3 зона - города и районы края, за исключением районов Крайнего Севера и приравненных к ним местностей.',
		'inter':11,
		'lawdoc':'Постановление Правительства Красноярского  края от  22.11.2011 № 705-п',
		'num':82,
		'ind':66
	},

	83:{
		'regName':'Иркутская область',
 		'zone':'1 зона',
		'regDescr':'1 зона - МО Иркутской области (за исключением вошедших во 2 и 3 зону)',
		'inter':13,
		'lawdoc':'Приказ Службы по тарифам Иркутской области от 23.10.2021 № 79-218-спр',
		'num':83,
		'ind':67
	},

	84:{
		'regName':'Иркутская область',
 		'zone':'2 зона',
		'regDescr':'2 зона - Усть-Кутское МО, МО "Нижнеилимский район",МО "Усть-Илимский район", МО "Братский район", МО "Жигаловский район", МО Иркутской области "Казачинско-Ленский район", МО "Качугский район",  МО города Братска,  МО город Усть-Илимск, Верхнегутарское МО, Нерхинское МО, Тофаларское МО, МО "Кырма"',
 		'inter':13,
		'lawdoc':'Приказ Службы по тарифам Иркутской области от 23.10.2021 № 79-218-спр',
		'num':84,
		'ind':67
	},

	85:{
		'regName':'Иркутская область',
 		'zone':'3 зона',
		'regDescr':'3 зона -  МО города Бодайбо и района, МО "Катангский район", МО "Киренский район", МО Мамско-Чуйского района',
		'inter':13,
		'lawdoc':'Приказ Службы по тарифам Иркутской области от 23.10.2021 № 79-218-спр',
		'num':85,
		'ind':67
	},

	86:{
		'regName':'Кемеровская область',
		'zone':'',
		'regDescr':'',
  		'inter':10,
		'lawdoc':'Постановление Департамента цен и тарифов Кемеровской области от 11.11.2010  № 38',
		'num':86,
		'ind':68
	},

	87:{
		'regName':'Новосибирская область',
		'zone':'',
		'regDescr':'',
		'inter':12,
		'lawdoc':'Приказ Департамента по тарифам Новосибирской области от 20.09.2021 № 165-ТС',
		'num':87,
		'ind':69
	},

	88:{
		'regName':'Омская область',
		'zone':'',
		'regDescr':'',
		'inter':13,
		'lawdoc':'Приказ РЭК Омской области от 27.02.2010 № 21/9',
		'num':88,
		'ind':70
	},

	89:{
		'regName':'Томская область',
 		'zone':'1 зона',
		'regDescr':'1 зона - муниципальные образования, расположенные на территории районов, приравненных к районам Крайнего Севера',
		'inter':11,
		'lawdoc':'Приказ Департамента тарифного регулирования Томской области от 28.01.2021 № 10-2 (в редакции приказа Департамета тарифного регулирования Томской обл. от 17.09.2021 № 10-65)',
		'num':89,
		'ind':71
	},

	90:{
		'regName':'Томская область',
 		'zone':'2 зона',
		'regDescr':'2 зона - прочие муниципальные образования',
		'inter':11,
		'lawdoc':'Приказ Департамента тарифного регулирования Томской области от 28.01.2021 № 10-2 (в редакции приказа Департамета тарифного регулирования Томской обл. от 17.09.2021 № 10-65)',
		'num':90,
		'ind':71
	},

	91:{
		'regName':'Дальневосточный Федеральный округ',
		'zone':'',
		'regDescr':'',
		'inter':0,
		'lawdoc':'',
		'num':91,
		'ind':0
	},


	92:{
		'regName':'Республика Бурятия',
 		'zone':'1 зона',
		'regDescr':'1 зона - городской округ "город Улан-Удэ"',
		'inter':13,
		'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4',
		'num':92,
		'ind':73
	},

	93:{
		'regName':'Республика Бурятия',
 		'zone':'4 зона',
		'regDescr':'2 зона - муниципальные районы, за исключением районов, приравненных к районам Крайнего Севера,  городского округа "город Северобайкальск"',
		'inter':13,
		'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4',
		'num':93,
		'ind':73
	},

	94:{
		'regName':'Республика Бурятия',
 		'zone':'3 зона',
		'regDescr':'3 зона - муниципальные районы, приравненные к районам  Крайнего Севера, городской округ "город Северобайкальск"',
		'inter':13,
		'lawdoc':'Приказ РСТ Республики Бурятия от 25.02.2010 № 4/10, от 25.03.2010 № 4/14, от 13.04.2010 № 4/18, от 16.06.2011 № 4/5, от 28.06.2012 № 4/4',
		'num':94,
		'ind':73
	},

	95:{
		'regName':'Забайкальский край',
 		'zone':'1 зона',
		'regDescr':'1 зона - город Чита и город Краснокаменск',
		'inter':20,
		'lawdoc':'Постановление Правительства Забайкальского края от 25.05.2018 № 304-НПА',
		'num':95,
		'ind':72
	},

	96:{
		'regName':'Забайкальский край',
 		'zone':'2 зона',
		'regDescr':'2 зона - территория Забайкальского края за исключением территорий города Читы и город Краснокаменска',
		'inter':20,
		'lawdoc':'Постановление Правительства Забайкальского края от 25.05.2018 № 304-НПА',
		'num':96,
		'ind':72
	},

	97:{
		'regName':'Республика Саха (Якутия)',
 		'zone':'1 зона',
		'regDescr':'1 зона - город Якутск, город Нерюнгри',
		'inter':16,
		'lawdoc':'Приказ Государственного Комитета по ценовой политике-РЭК Республики Саха (Якутия) от 27.02.2010 № 12, от 23.12.2014 № 96; Приказ Госкомцен Респ.Саха (Якутия) 07.09.2016 № 34',
		'num':97,
		'ind':84
	},

	98:{
		'regName':'Республика Саха (Якутия)',
 		'zone':'2 зона',
		'regDescr':'2 зона - территория Республики Саха (Якутия), не включенная в первую и третью зону',
		'inter':16,
		'lawdoc':'Приказ Государственного Комитета по ценовой политике-РЭК Республики Саха (Якутия) от 27.02.2010 № 12, от 23.12.2014 № 96; Приказ Госкомцен Респ.Саха (Якутия) 07.09.2016 № 34',
		'num':98,
		'ind':84
	},

	99:{
		'regName':'Республика Саха (Якутия)',
 		'zone':'3 зона',
		'regDescr':'3 зона - Абыйский, Аллаиховский, Анабарский, Булунский, Верхнеколымский, Верхоянский, Жиганский, Нижнеколымский,  Кобяйский, Момский, Оймяконский,  Оленекский, Среднеколымский, Усть-Янский, Эвено-Бытантайский районы Республики Саха (Якутия)',
		'inter':16,
		'lawdoc':'Приказ Государственного Комитета по ценовой политике-РЭК Республики Саха (Якутия) от 27.02.2010 № 12, от 23.12.2014 № 96; Приказ Госкомцен Респ.Саха (Якутия) 07.09.2016 № 34',
		'num':99,
		'ind':84
	},

	100:{
		'regName':'Приморский край',
 		'zone':'1 зона',
		'regDescr':'1 зона - местности за исключением местностей,  приравненных к районам Крайнего Севера',
		'inter':15,
		'lawdoc':'Постановление Администрации Приморского края от 27.02.2010 № 58-па',
		'num':100,
		'ind':77
	},

	101:{
		'regName':'Приморский край',
 		'zone':'2 зона',
		'regDescr':'2 зона - местности,  приравненные к районам Крайнего Севера',
		'inter':15,
		'lawdoc':'Постановление Администрации Приморского края от 27.02.2010 № 58-па',
		'num':101,
		'ind':77
	},

	102:{
		'regName':'Хабаровский край',
 		'zone':'1 зона',
		'regDescr':'1 зона - город Хабаровск, Бикинский, Вяземский, имени Лазо, Хабаровский районы',
		'inter':12,
		'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29',
		'num':102,
		'ind':78
	},

	103:{
		'regName':'Хабаровский край',
 		'zone':'2 зона',
		'regDescr':'2 зона - город Комсомольск-на-Амуре, Амурский, Ванинский, Верхнебуреинский, Комсомольский, Нанайский, Советско-Гаванский, Солнечный районы',
		'inter':12,
		'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29',
		'num':103,
		'ind':78
	},

	104:{
		'regName':'Хабаровский край',
 		'zone':'3 зона',
		'regDescr':'3 зона - Аяно-Майский, Николаевский, Охотский, имени Полины Осипенко, Тугуро-Чумиканский, Ульчский районы',
		'inter':18,
		'lawdoc':'Постановление Губернатора Хабаровского края  от 26.02.2010 № 32, от 11.08.2011 № 77, постановление Губернатора Хабаровского края от 15.04.2014 № 29 "О внесении изменений в постановление Губернатора Хабаровского края от 26 февраля 2010    № 32", постановление Губернатора Хабаровского края от 15.04.2014 № 29',
		'num':104,
		'ind':78
	},

	105:{
		'regName':'Амурская область',
 		'zone':'1 зона',
		'regDescr':'1 зона - муниципальные образования Амурской области (за исключением указанных ниже)',
		'inter':20,
		'lawdoc':'Приказ Управления государственного  регулирования цен и тарифов Амурской области от 27.02.2010 № 17-пр/м',
		'num':105,
		'ind':79
	},

	106:{
		'regName':'Амурская область',
 		'zone':'2 зона',
		'regDescr':'2 зона - город Зея, город Тында,  Зейский район, Селемджинский район, Тындинский район',
		'inter':25,
		'lawdoc':'Приказ Управления государственного  регулирования цен и тарифов Амурской области от 27.02.2010 № 17-пр/м',
		'num':106,
		'ind':79
	},

	107:{
		'regName':'Камчатский край',
 		'zone':'1 зона',
		'regDescr':'1 зона - Петропавловск-Камчатский городской округ, Вилючинский городской округ, Елизовский муниципальный район',
		'inter':23,
		'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п',
		'num':107,
		'ind':80
	},

	108:{
		'regName':'Камчатский край',
 		'zone':'2 зона',
		'regDescr':'2 зона - Корякский округ',
		'inter':23,
		'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п',
		'num':108,
		'ind':80
	},

	109:{
		'regName':'Камчатский край',
 		'zone':'3 зона',
		'regDescr':'3 зона - Мильковский, Усть-Большерецкий, Быстринский, Усть-Камчатский муниципальные районы',
		'inter':23,
		'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п',
		'num':109,
		'ind':80
	},

	110:{
		'regName':'Камчатский край',
 		'zone':'4 зона',
		'regDescr':'4 зона - Соболевский, Алеутский муниципальные районы',
		'inter':23,
		'lawdoc':'Постановление Правительства Камчатского края от 27.02.2010 № 98-п, Постановление Правительства Камчатского края от 24.06.2010 № 288-П "О внесении изменений в приложения № 1 и № 2 к постановлению Правительства Камчатского края от 27.02.2010 № 98-П, постановление Правительства Камчатского края от 08.10.2014 № 426-п',
		'num':110,
		'ind':80
	},

	111:{
		'regName':'Магаданская область',
 		'zone':'1 зона',
		'regDescr':'1 зона - город Магадан',
		'inter':10,
		'lawdoc':'Постановление Администрации Магаданской области от 25.02.2010 № 70-па (в редакции постановлений Администрации Магаданской области от 08.04.2010 № 176-па, от 03.06.2010 № 314-па, от 30.12.2010 №765-па, от 02.10.2014 № 812-пп)',
		'num':111,
		'ind':81
	},

	112:{
		'regName':'Магаданская область',
 		'zone':'2 зона',
		'regDescr':'2 зона - муниципальные образования области за исключением МО "город Магадан"',
		'inter':10,
		'lawdoc':'Постановление Администрации Магаданской области от 25.02.2010 № 70-па (в редакции постановлений Администрации Магаданской области от 08.04.2010 № 176-па, от 03.06.2010 № 314-па, от 30.12.2010 №765-па, от 02.10.2014 № 812-пп)',
		'num':112,
		'ind':81
	},

	113:{
		'regName':'Сахалинская область',
 		'zone':'1 зона',
		'regDescr':'1 зона - город Южно-Сахалинск',
		'inter':15,
		'lawdoc':'Приказ Региональной энергетической комиссии  Сахалинской области от 29.09.2021 № 29',
		'num':113,
		'ind':82
	},

	114:{
		'regName':'Сахалинская область',
 		'zone':'2 зона',
		'regDescr':'2 зона - Анивский городской округ, городской округ Долинский, Холмский городской округ, Корсаковский городской округ, Невельский муниципальный район, Томаринский городской округ, Углегорский муниципальный район, Макаровский городской округ, Александров-Сахалинский район,Тымовский городской округ, городской округ Поронайский,  городской округ Смирныховский, городской округ Ногликский, городской округ Охинский',
		'inter':15,
		'lawdoc':'Приказ Региональной энергетической комиссии  Сахалинской области от 29.09.2021 № 29',
		'num':114,
		'ind':82
	},

	115:{
		'regName':'Сахалинская область',
 		'zone':'3 зона',
		'regDescr':'3 зона - муниципальные образования, Северо-Курильский городской округ, Курильский городской округ, Южно-Курильский городской округ',
		'inter':15,
		'lawdoc':'Приказ Региональной энергетической комиссии  Сахалинской области от 29.09.2021 № 29',
		'num':115,
		'ind':82
	},

	116:{
		'zone':'',
		'regName':'Еврейская автономная область',
		'regDescr':'',
		'inter':14,
		'lawdoc':'Постановление Правительства Еврейской а.о. от 27.02.2010 № 64-пп  (в ред. Постановления правительства ЕАО от 16.07.2012 № 343-пп)',
		'num':116,
		'ind':85
	},

	117:{
		'regName':'Чукотский автономный округ',
 		'zone':'1 зона',
		'regDescr':'1 зона - городской округ Анадырь, муниципальные районы Билибинский, Иультинский, Провиденский, Чаунский, Чукотский муниципальный район, городское поселение Беринговский, сельские поселения Алькатваам, Мейныпильгино, Хатырка',
		'inter':15,
		'lawdoc':'Постановление Правительства Чукотского автономного округа от 15.09.2021 № 356',
		'num':117,
		'ind':83
	},

	118:{
		'regName':'Чукотский автономный округ',
 		'zone':'2 зона',
		'regDescr':'2 зона - Анадырский муниципальный район(кроме городского поселения Беринговский, сельских поселений Алькатваам, Мейныпильгино, Хатырка)',
		'inter':15,
		'lawdoc':'Постановление Правительства Чукотского автономного округа от 15.09.2021 № 356',
		'num':118,
		'ind':83
	}	
};

export default regList;

