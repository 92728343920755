;"use strict";

const rtmData = {

// ============================>rtm
// ---------> Адъювантная ХТ
		'st0_0_1':{
			group: 'Адъювантная ХТ',
			meth: [
				{
					id: 1034,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин, цисплатин)',
					drugs: [84,36,66,93],
					rate: 10,
					dose: [100,10,100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 3
				},
				{
					id: 1035,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин - AUC 5—6',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 55,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 16
				},
				{
					id: 1036,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел, цисплатин)',
					drugs: [17,66,93],
					rate: 35,
					dose: [400,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 11
				}
			],
			pats: 0
		},

// ---------> Рецидив, I линия
		'st0_0_2':{
			group: 'Рецидив, I линия',
			meth: [
				{
					id: 1037,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин, цисплатин)',
					drugs: [84,36,66,93],
					rate: 35,
					dose: [100,10,100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 4
				},
				{
					id: 1038,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 35,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 4
				},
				{
					id: 1039,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел, цисплатин)',
					drugs: [17,66,93],
					rate: 30,
					dose: [400,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 3
				}
			],
			pats: 0
		},

// ---------> Рецидив, II линия позднее 6 мес.
		'st0_0_3':{
			group: 'Рецидив, II линия позднее 6 мес.',
			meth: [
				{
					id: 1040,
					code: 'sh0641',
					name: 'Доксорубицин + цисплатин',
					info: '(доксорубицин, цисплатин)',
					drugs: [84,36,66,93],
					rate: 50,
					dose: [100,10,100,40],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6,6],
					pats: 3
				},
				{
					id: 1041,
					code: 'sh0672',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел, карбоплатин)',
					drugs: [17,41],
					rate: 25,
					dose: [400,750],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 1042,
					code: 'sh0675',
					name: 'Паклитаксел + цисплатин',
					info: '(паклитаксел, цисплатин)',
					drugs: [17,66,93],
					rate: 25,
					dose: [400,100,20],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6,6],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Эндометриоидная аденокарцинома с высокой ст. злокачественности,<br>I линия
		'st0_0_4':{
			group: 'Эндометриоидная аденокарцинома с высокой ст. злокачественности,<br>I линия',
			meth: [
				{
					id: 1043,
					code: 'sh0660',
					name: 'Медроксипрогестерон',
					info: '(медроксипрогестерона ацетат)',
					drugs: [118],
					rate: 40,
					dose: [12000],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [6],
					pats: 2
				},
				{
					id: 1044,
					code: 'sh9001',
					name: 'Мегестрол',
					info: '(мегестрола ацетат)',
					drugs: [89],
					rate: 30,
					dose: [4800],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 2
				},
				{
					id: 1045,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен)',
					drugs: [54],
					rate: 10,
					dose: [1200],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [6],
					pats: 1
				},
				{
					id: 1046,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол)',
					drugs: [29],
					rate: 10,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [6],
					pats: 1
				},
				{
					id: 1047,
					code: 'sh9001',
					name: 'Летрозол',
					info: '(летрозол)',
					drugs: [44],
					rate: 10,
					dose: [75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> II линия ранее 6 мес.
		'st0_0_5':{
			group: 'II линия ранее 6 мес.',
			meth: [
				{
					id: 1048,
					code: 'sh9001',
					name: 'Ифосфамид',
					info: '(ифосфамид)',
					drugs: [96],
					rate: 20,
					dose: [2000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 1049,
					code: 'sh9001',
					name: 'Топотекан',
					info: '(топотекан)',
					drugs: [95],
					rate: 15,
					dose: [12],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 0
				},
				{
					id: 1050,
					code: 'sh0128',
					name: 'Оксалиплатин',
					info: '(оксалиплатин)',
					drugs: [16,24],
					rate: 15,
					dose: [150,100],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 1051,
					code: 'sh0330',
					name: 'Доксорубицин пегилированный',
					info: '(пегилированный липосомальный доксорубицин)',
					drugs: [85,86],
					rate: 20,
					dose: [50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 0
				},
				{
					id: 1052,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 30,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> III линия ХТ
		'st0_0_8':{
			group: 'III линия ХТ',
			meth: [
				{
					id: 1053,
					code: 'sh0788',
					name: 'Ифосфамид + месна',
					info: '(ифосфамид, месна)',
					drugs: [96,98],
					rate: 38,
					dose: [2000,2400],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 1054,
					code: 'sh0128',
					name: 'Оксалиплатин',
					info: '(оксалиплатин)',
					drugs: [16,24],
					rate: 22,
					dose: [150,100],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 1055,
					code: 'sh0330',
					name: 'Доксорубицин пегилированный',
					info: '(пегилированный липосомальный доксорубицин)',
					drugs: [85,86],
					rate: 10,
					dose: [50,20],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 1056,
					code: 'sh0330',
					name: 'Доксорубицин',
					info: '(доксорубицин)',
					drugs: [84,36],
					rate: 30,
					dose: [100,10],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 2
				}
			],
			pats: 0
		}
	}

export default rtmData;	