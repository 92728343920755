;"use strict";

const rmzData = {

// ============================>rmz
// ---------> Нео/адъювантная химиотерапия, HER-2 негативный РМЖ
		'st0_0_1':{
			group: 'Нео/адъювантная химиотерапия, HER-2 негативный РМЖ',
			meth: [
				{
					id: 362,
					code: 'sh0058',
					name: 'AC×4',
					info: '(доксорубицин, циклофосфамид)',
					drugs: [36,64],
					rate: 9,
					dose: [110,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 25
				},
				{
					id: 363,
					code: 'sh0680',
					name: 'EC×4',
					info: '(эпирубицин,циклофосфамид)',
					drugs: [69,64],
					rate: 1,
					dose: [170,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4],
					pats: 3
				},
				{
					id: 364,
					code: 'sh0058;sh0705',
					name: 'AC×4→ D×4',
					info: '(доксорубицин,циклофосфамид,доцетаксел)',
					drugs: [36,64,37],
					rate: 20,
					dose: [110,1200,160],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					custKsg: 'ac4_d4',//
					pats: 56
				},
				{
					id: 365,
					code: 'sh0058;sh0700',
					name: 'AC×4→ Р×12',
					info: '(доксорубицин,циклофосфамид,паклитаксел)',
					drugs: [36,64,50],
					rate: 20,
					dose: [110,1200,150],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,12],
					custKsg: 'ac4_p12',//
					pats: 56
				},
				{
					id: 366,
					code: 'sh0058;sh0671',
					name: 'АС×4→ Р×4',
					info: '(доксорубицин,циклофосфамид,паклитаксел)',
					drugs: [36,64,50],
					rate: 20,
					dose: [110,1200,450],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4],
					custKsg: 'ac4_p4',//
					pats: 56
				},
				{
					id: 367,
					code: 'sh0072',
					name: 'DC×4',
					info: '(доцетаксел,циклофосфамид)',
					drugs: [37,64],
					rate: 30,
					dose: [160,1200],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [4,4],
					pats: 84
				}
			],
			pats: 0
		},

// ---------> Нео/адъювантная химиотерапия, HER-2 позитивный РМЖ
		'st0_0_2':{
			group: 'Нео/адъювантная химиотерапия, HER-2 позитивный РМЖ',
			meth: [
				{
					id: 368,
					code: 'sh0058;sh0069',
					name: 'AC×4→ (D + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,трастузумаб)',
					drugs: [36,64,37,57,56],
					rate: 20,
					dose: [110,1200,160,150,440],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4],
					custKsg: 'ac4_dtr4',//
					pats: 26
				},
				{
					id: 369,
					code: 'sh0058;sh0069',
					name: 'AC×4→ (Р + трастузумаб)×12',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,57,57],
					rate: 14,
					dose: [110,1200,150,150,150],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,12,1,12],
					custKsg: 'ac4_ptr12',//
					pats: 18
				},
				{
					id: 370,
					code: 'sh0058;sh0150',
					name: 'АС×4→ (Р + трастузумаб)×4',
					info: '(доксорубицин,циклофосфамид,паклитаксел,трастузумаб)',
					drugs: [36,64,50,57,56],
					rate: 5,
					dose: [110,1200,450,150,440],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4],
					custKsg: 'ac4_ptr4',//
					pats: 7
				},
				{
					id: 371,
					code: 'sh0179',
					name: '(DC + трастузумаб)×4',
					info: '(доцетаксел,циклофосфамид,трастузумаб)',
					drugs: [37,64,57,56],
					rate: 10,
					dose: [160,1200,150,440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [4,4,1,4],
					pats: 13
				},
				{
					id: 372,
					code: 'sh0066',
					name: 'DCН×6',
					info: '(доцетаксел,карбоплатин,трастузумаб)',
					drugs: [37,41,57,56],
					rate: 1,
					dose: [160,450,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [6,6,1,6],
					pats: 1
				},
				{
					id: 373,
					code: 'sh0058;sh0070',
					name: 'АС×4→ (D + трастузумаб + пертузумаб)x4',
					info: '(доксорубицин,циклофосфамид,доцетаксел,<br>трастузумаб,пертузумаб)',
					drugs: [36,64,37,57,56,52],
					rate: 20,
					dose: [110,1200,160,150,440,2100,0],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [4,4,4,1,4,1,3],
					custKsg: 'ac4_dtrp4',//
					pats: 26
				},
				{
					id: 374,
					code: 'sh0067',
					name: '(DCН + пертузумаб)×6',
					info: '(доцетаксел,карбоплатин,трастузумаб,пертузумаб)',
					drugs: [37,41,57,56,52],
					rate: 20,
					dose: [160,450,150,440,2940],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [6,6,1,6,1],
					pats: 27
				},
				{
					id: 375,
					code: 'sh0149',
					name: '(Р + трастузумаб)×12',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57],
					rate: 10,
					dose: [150,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [12,13],
					pats: 13
				}
			],
			pats: 0
		},

// ---------> Адъювантная таргетная терапия, HER-2 позитивный РМЖ
		'st0_0_3':{
			group: 'Адъювантная таргетная терапия, HER-2 позитивный РМЖ',
			meth: [
				{
					id: 376,
					code: 'sh0179',
					name: 'Трастузумаб',
					info: '(трастузумаб)',
					drugs: [56],
					rate: 95,
					dose: [440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [9],
					pats: 124
				},
				{
					id: 377,
					code: 'sh0533',
					name: 'Трастузумаб + Пертузумаб',
					info: '(трастузумаб,пертузумаб)',
					drugs: [56,52],
					rate: 5,
					dose: [440,420],
					ds: 'ds19.047',//>>
					st: 'st19.072',//>>
					cycles: [13,13],
					pats: 7
				}
			],
			pats: 0
		},

// ---------> Нео/адъювантная гормонотерапия, гормонпозитивный РМЖ
		'st0_0_4':{
			group: 'Нео/адъювантная гормонотерапия, гормонпозитивный РМЖ',
			meth: [
				{
					id: 378,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен)',
					drugs: [54],
					rate: 25,
					dose: [600],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [12],
					pats: 115
				},
				{
					id: 379,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол)',
					drugs: [29],
					rate: 35,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [12],
					pats: 161
				},
				{
					id: 380,
					code: '-',
					name: 'Летрозол',
					info: '(летрозол)',
					drugs: [44],
					rate: 35,
					dose: [30],
					ds: '',//>>
					st: '',
					cycles: [12],
					pats: 160
				},
				{
					id: 381,
					code: '-',
					name: 'Эксеместан',
					info: '(эксеместан)',
					drugs: [68],
					rate: 5,
					dose: [750],
					ds: '',//>>
					st: '',
					cycles: [12],
					pats: 23
				}
			],
			pats: 0
		},

// ---------> Нео/адъювантная гормонотерапия, Овариальная супрессия
		'st0_0_5':{
			group: 'Нео/адъювантная гормонотерапия, Овариальная супрессия',
			meth: [
				{
					id: 382,
					code: 'sh0047',
					name: 'Гозерелин',
					info: '(гозерелин)',
					drugs: [35],
					rate: 25,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 21
				},
				{
					id: 383,
					code: 'sh0182',
					name: 'Трипторелин',
					info: '(трипторелин)',
					drugs: [60],
					rate: 25,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [13],
					pats: 21
				},
				{
					id: 384,
					code: 'sh0019',
					name: 'Бусерелин',
					info: '(бусерелин)',
					drugs: [30],
					rate: 25,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 21
				},
				{
					id: 385,
					code: 'sh0113',
					name: 'Лейпрорелин',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 25,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 20
				}
			],
			pats: 0
		},

// ---------> Гормонотерапия при HER-2 негативном мРМЖ
		'st0_0_6':{
			group: 'Гормонотерапия при HER-2 негативном мРМЖ',
			meth: [
				{
					id: 386,
					code: 'sh0214',
					name: 'Фулвестрант',
					info: '(фулвестрант)',
					drugs: [62],
					rate: 15,
					dose: [500],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [13],
					pats: 49
				},
				{
					id: 387,
					code: '-',
					name: 'Рибоциклиб+летрозол',
					info: '(рибоциклиб,летрозол)',
					drugs: [53,44],
					rate: 17.5,
					dose: [12600,30],
					ds: '',//>>
					st: '',//>>
					cycles: [13,12],
					pats: 59
				},
				{
					id: 388,
					code: 'sh0214',
					name: 'Рибоциклиб+фулвестрант',
					info: '(рибоциклиб,фулвестрант)',
					drugs: [53,62],
					rate: 15,
					dose: [12600,500],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [13,13],
					pats: 49
				},
				{
					id: 389,
					code: '-',
					name: 'Эверолимус+эксеместан',
					info: '(эверолимус,эксеместан)',
					drugs: [67,68],
					rate: 10,
					dose: [300,750],
					ds: '',//>>
					st: '',
					cycles: [12,12],
					pats: 33
				},
				{
					id: 390,
					code: '-',
					name: 'Палбоциклиб+летрозол',
					info: '(палбоциклиб,летрозол)',
					drugs: [51,44],
					rate: 7.5,
					dose: [2625,30],
					ds: '',//>>
					st: '',//>>
					cycles: [13,12],
					pats: 24
				},
				{
					id: 391,
					code: 'sh0155',
					name: 'Палбоциклиб+фулвестрант',
					info: '(палбоциклиб,фулвестрант)',
					drugs: [51,62],
					rate: 5,
					dose: [2625,500],
					ds: 'ds19.046',//>>
					st: 'st19.071',//>>
					cycles: [13,13],
					pats: 16
				},
				{
					id: 392,
					code: 'sh0692',
					name: 'Тамоксифен',
					info: '(тамоксифен)',
					drugs: [54],
					rate: 5,
					dose: [600],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [12],
					pats: 16
				},
				{
					id: 393,
					code: '-',
					name: 'Торемифен',
					info: '(торемифен)',
					drugs: [55],
					rate: 0,
					dose: [3600],
					ds: '',//>>
					st: '',
					cycles: [6],
					pats: 0
				},
				{
					id: 394,
					code: 'sh0008',
					name: 'Анастрозол',
					info: '(анастрозол)',
					drugs: [29],
					rate: 15,
					dose: [30],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [12],
					pats: 49
				},
				{
					id: 395,
					code: '-',
					name: 'Летрозол',
					info: '(летрозол)',
					drugs: [44],
					rate: 15,
					dose: [30],
					ds: '',//>>
					st: '',
					cycles: [12],
					pats: 49
				},
				{
					id: 396,
					code: '-',
					name: 'Эксеместан',
					info: '(эксеместан)',
					drugs: [68],
					rate: 5,
					dose: [750],
					ds: '',//>>
					st: '',
					cycles: [12],
					pats: 16
				},
				{
					id: 397,
					code: 'sh0047',
					name: 'Гозерелин',
					info: '(гозерелин)',
					drugs: [35],
					rate: 3.75,
					dose: [3.6],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 398,
					code: 'sh0182',
					name: 'Трипторелин',
					info: '(трипторелин)',
					drugs: [60],
					rate: 3.75,
					dose: [3.75],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 399,
					code: 'sh0019',
					name: 'Бусерелин',
					info: '(бусерелин)',
					drugs: [30],
					rate: 3.75,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 400,
					code: 'sh0113',
					name: 'Лейпрорелин',
					info: '(лейпрорелин)',
					drugs: [43],
					rate: 3.75,
					dose: [3.75],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [13],
					pats: 12
				},
				{
					id: 401,
					code: 'sh0660',
					name: 'Медроксипрогестерон',
					info: '(медроксипрогестерон)',
					drugs: [46],
					rate: 0,
					dose: [15000],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [12],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Химиотерапии при HER-2 позитивном метастатическом РМЖ
		'st0_0_7':{
			group: 'Химиотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					id: 402,
					code: 'sh0145',
					name: 'паклитаксел + карбоплатин AUC2 + трастузумаб',
					info: '(паклитаксел,карбоплатин,трастузумаб)',
					drugs: [17,41,57,57],
					rate: 1,
					dose: [300.5,150,300,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [6,6,1,24],
					pats: 1
				},
				{
					id: 403,
					code: 'sh0069',
					name: 'D + трастузумаб',
					info: '(доцетаксел,трастузумаб)',
					drugs: [37,57,56],
					rate: 15,
					dose: [160,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [10,1,10],
					pats: 20
				},
				{
					id: 404,
					code: 'sh0149',
					name: 'Р + трастузумаб (еженедельный)',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57,57],
					rate: 1,
					dose: [150,300,150],
					ds: 'ds19.040',//>>
					st: 'st19.064',//>>
					cycles: [30,1,29],
					pats: 1
				},
				{
					id: 405,
					code: 'sh0577.1',
					name: 'Р + трастузумаб',
					info: '(паклитаксел,трастузумаб)',
					drugs: [50,57,56],
					rate: 19,
					dose: [150,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [30,1,10],
					pats: 25
				},
				{
					id: 406,
					code: 'sh0027.1',
					name: 'винорелбин + трастузумаб',
					info: '(винорелбин,трастузумаб)',
					drugs: [32,57,56],
					rate: 7,
					dose: [100,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [10,1,10],
					pats: 9
				},
				{
					id: 407,
					code: 'sh0104',
					name: 'капецитабин + трастузумаб',
					info: '(капецитабин,трастузумаб)',
					drugs: [40,57,56],
					rate: 7,
					dose: [48000,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [8,1,8],
					pats: 9
				},
				{
					id: 408,
					code: 'sh0390',
					name: 'циклофосфамид + метотрексат + трастузумаб',
					info: '(циклофосфамид,метотрексат,трастузумаб)',
					drugs: [63,48,57,56],
					rate: 0,
					dose: [350,10,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [24,24,1,8],
					pats: 0
				},
				{
					id: 409,
					code: 'sh0418.1',
					name: 'эрибулин + трастузумаб',
					info: '(эрибулин,трастузумаб)',
					drugs: [65,57,56],
					rate: 8,
					dose: [6,150,440],
					ds: 'ds19.047',//>>
					st: 'st19.071',//>>
					cycles: [10,1,10],
					pats: 10
				},
				{
					id: 410,
					code: 'sh0317.1',
					name: 'гемцитабин + трастузумаб',
					info: '(гемцитабин,трастузумаб)',
					drugs: [34,57,56],
					rate: 0,
					dose: [4000,150,440],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [8,1,8],
					pats: 0
				},
				{
					id: 411,
					code: 'sh0306.1',
					name: 'гемцитабин + цисплатин + трастузумаб',
					info: '(гемцитабин,цисплатин,трастузумаб)',
					drugs: [34,66,57,56],
					rate: 7,
					dose: [4000,300,150,440],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [8,8,1,8],
					pats: 9
				},
				{
					id: 412,
					code: 'sh0070',
					name: 'D + трастузумаб + пертузумаб',
					info: '(доцетаксел,трастузумаб,пертузумаб)',
					drugs: [37,57,56,52],
					rate: 15,
					dose: [160,150,440,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [12,1,12,13],
					pats: 20
				},
				{
					id: 413,
					code: 'sh0576.1',
					name: 'P + трастузумаб + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,57,56,52],
					rate: 14,
					dose: [150,150,440,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,1,12,13],
					pats: 18
				},
				{
					id: 414,
					code: 'sh0576.1',
					name: 'P + трастузумаб (еженед) + пертузумаб',
					info: '(паклитаксел,трастузумаб,пертузумаб)',
					drugs: [50,57,57,52],
					rate: 1,
					dose: [150,300,150,420],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [36,1,36,13],
					pats: 1
				},
				{
					id: 415,
					code: 'sh0181',
					name: 'трастузумаб эмтанзин',
					info: '(трастузумаб,эмтанзин)',
					drugs: [58,59],
					rate: 20,
					dose: [100,160],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [8,8],
					pats: 27
				},
				{
					id: 416,
					code: 'sh0110',
					name: 'лапатиниб + капецитабин',
					info: '(лапатиниб,капецитабин)',
					drugs: [42,40],
					rate: 10,
					dose: [26250,49000],
					ds: 'ds19.045',//>>
					st: 'st19.070',//>>
					cycles: [6,6],
					pats: 13
				}
			],
			pats: 0
		},

// ---------> Гормонотерапии при HER-2 позитивном метастатическом РМЖ
		'st0_0_8':{
			group: 'Гормонотерапии при HER-2 позитивном метастатическом РМЖ',
			meth: [
				{
					id: 417,
					code: 'sh0538',
					name: 'Фулвестрант + трастузумаб',
					info: '(фулвестрант,трастузумаб)',
					drugs: [62,57,56],
					rate: 20,
					dose: [500,150,440],
					ds: 'ds19.042',//>>
					st: 'st19.066',//>>
					cycles: [10,1,12],
					pats: 8
				},
				{
					id: 418,
					code: 'sh0293',
					name: 'Анастрозол + трастузумаб',
					info: '(анастрозол,трастузумаб)',
					drugs: [29,57,56],
					rate: 15,
					dose: [30,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [9,1,12],
					pats: 6
				},
				{
					id: 419,
					code: '-',
					name: 'Летрозол + трастузумаб',
					info: '(летрозол,трастузумаб)',
					drugs: [44,57,56],
					rate: 15,
					dose: [30,150,440],
					ds: '',//>>
					st: '',
					cycles: [9,1,12],
					pats: 6
				},
				{
					id: 420,
					code: '-',
					name: 'Эксеместан + трастузумаб',
					info: '(эксеместан,трастузумаб)',
					drugs: [68,57,56],
					rate: 10,
					dose: [750,150,440],
					ds: '',//>>
					st: '',
					cycles: [9,1,12],
					pats: 4
				},
				{
					id: 421,
					code: 'sh0372',
					name: 'Тамоксифен + трастузумаб',
					info: '(тамоксифен,трастузумаб)',
					drugs: [54,57,56],
					rate: 30,
					dose: [600,150,440],
					ds: 'ds19.042',//>>
					st: '',
					cycles: [7,1,10],
					pats: 12
				},
				{
					id: 422,
					code: 'sh0008',
					name: 'Анастрозол + лапатиниб',
					info: '(анастрозол,лапатиниб)',
					drugs: [29,42],
					rate: 5,
					dose: [30,42000],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [8,8],
					pats: 2
				},
				{
					id: 423,
					code: 'sh0008',
					name: 'Летрозол + лапатиниб',
					info: '(летрозол,лапатиниб)',
					drugs: [44,42],
					rate: 5,
					dose: [30,42000],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [8,8],
					pats: 2
				},
				{
					id: 424,
					code: '-',
					name: 'Эксеместан + лапатиниб',
					info: '(эксеместан,лапатиниб)',
					drugs: [68,42],
					rate: 2.2,
					dose: [750,42000],
					ds: '',//>>
					st: '',
					cycles: [8,8],
					pats: 1
				},
				{
					id: 425,
					code: 'sh0112',
					name: 'Анастрозол + трастузумаб + лапатиниб',
					info: '(анастрозол,трастузумаб,лапатиниб)',
					drugs: [29,57,56,42],
					rate: 5,
					dose: [30,150,440,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 2
				},
				{
					id: 426,
					code: '-',
					name: 'Летрозол + трастузумаб + лапатиниб',
					info: '(летрозол,трастузумаб,лапатиниб)',
					drugs: [44,57,56,42],
					rate: 5,
					dose: [30,150,440,42000],
					ds: '',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 2
				},
				{
					id: 427,
					code: 'sh0112',
					name: 'Эксеместан + трастузумаб + лапатиниб',
					info: '(эксеместан,трастузумаб,лапатиниб)',
					drugs: [68,57,56,42],
					rate: 1.1,
					dose: [750,150,440,42000],
					ds: 'ds19.046',//>>
					st: '',
					cycles: [7,1,10,7],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Химиотерапия при HER-2 негативном метастатическом РМЖ
		'st0_0_9':{
			group: 'Химиотерапия при HER-2 негативном метастатическом РМЖ',
			meth: [
				{
					id: 428,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [37],
					rate: 20,
					dose: [160],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [8],
					pats: 52
				},
				{
					id: 429,
					code: 'sh0700',
					name: 'Паклитаксел (еженедельный)',
					info: '(паклитаксел)',
					drugs: [50],
					rate: 26,
					dose: [150],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [24],
					pats: 66
				},
				{
					id: 430,
					code: 'sh0024.1',
					name: 'Винорелбин',
					info: '(винорелбин)',
					drugs: [32],
					rate: 12,
					dose: [100],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6],
					pats: 31
				},
				{
					id: 431,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [40],
					rate: 20,
					dose: [49000],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [8],
					pats: 52
				},
				{
					id: 432,
					code: 'sh0255.1',
					name: 'Эрибулин',
					info: '(эрибулин)',
					drugs: [65],
					rate: 15,
					dose: [6],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [8],
					pats: 39
				},
				{
					id: 433,
					code: 'sh0704',
					name: 'Гемцитабин+Цисплатин',
					info: '(гемцитабин,цисплатин)',
					drugs: [34,66],
					rate: 7,
					dose: [4000,300],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 18
				},
				{
					id: 434,
					code: 'sh1031',
					name: 'Альбумин-связанный паклитаксел',
					info: '(альбумин-связанный паклитаксел)',
					drugs: [28],
					rate: 1,
					dose: [500],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [8],
					pats: 3
				},
				{
					id: 435,
					code: 'sh0076',
					name: 'Иксабепилон',
					info: '(иксабепилон)',
					drugs: [38],
					rate: 2,
					dose: [75],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [6],
					pats: 5
				},
				{
					id: 436,
					code: 'sh0222',
					name: 'CMF',
					info: '(циклофосфамид,метотрексат,фторурацил)',
					drugs: [63,47,61],
					rate: 1,
					dose: [2500,140,2000],
					ds: 'ds19.038',//>>
					st: 'st19.065',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 437,
					code: 'sh0631.1',
					name: 'Гемцитабин',
					info: '(гемцитабин)',
					drugs: [34],
					rate: 1,
					dose: [6000],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [6],
					pats: 3
				},
				{
					id: 438,
					code: 'sh0189',
					name: 'FAC',
					info: '(фторурацил,доксорубицин,циклофосфамид)',
					drugs: [61,36,64],
					rate: 5,
					dose: [1000,90,1000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 13
				},
				{
					id: 439,
					code: 'sh0213',
					name: 'FEC',
					info: '(фторурацил,эпирубицин,циклофосфамид)',
					drugs: [61,69,64],
					rate: 1,
					dose: [1000,140,1000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6,6],
					pats: 3
				},
				{
					id: 440,
					code: 'sh0330',
					name: 'Липосомальный доксорубицин',
					info: '(липосомальный доксорубицин)',
					drugs: [86],
					rate: 1,
					dose: [100],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6],
					pats: 3
				},
				{
					id: 441,
					code: 'sh0058',
					name: 'АС',
					info: '(доксорубицин,циклофосфамид)',
					drugs: [36,64],
					rate: 10,
					dose: [110,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 26
				},
				{
					id: 442,
					code: 'sh0680',
					name: 'ЕС',
					info: '(эпирубицин,циклофосфамид)',
					drugs: [69,64],
					rate: 1,
					dose: [140,1200],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [6,6],
					pats: 3
				},
				{
					id: 443,
					code: 'sh0671',
					name: 'Паклитаксел (21 день)',
					info: '(паклитаксел)',
					drugs: [50],
					rate: 10,
					dose: [300],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [6],
					pats: 26
				},
				{
					id: 444,
					code: 'sh0144',
					name: 'Паклитаксел + карбоплатин',
					info: '(паклитаксел,карбоплатин)',
					drugs: [17,41],
					rate: 5,
					dose: [100.2,150],
					ds: 'ds19.038',//>>
					st: 'st19.063',//>>
					cycles: [18,18],
					pats: 13
				},
				{
					id: 445,
					code: 'sh0221',
					name: 'Циклофосфамид + Метотрексат',
					info: '(циклофосфамид,метотрексат)',
					drugs: [63,48],
					rate: 5,
					dose: [350,10],
					ds: 'ds19.039',//>>
					st: '',
					cycles: [18,18],
					pats: 13
				},
				{
					id: 446,
					code: 'sh0304.1',
					name: 'Винорелбин пероральный',
					info: '(винорелбин)',
					drugs: [31],
					rate: 3,
					dose: [120],
					ds: 'ds19.043',//>>
					st: 'st19.068',//>>
					cycles: [18],
					pats: 8
				}
			],
			pats: 0
		}
	};	

export default rmzData;