"use strict";

import methData from './methdata';
import rmzData from './rmz-meth';
import trastData0 from './trastdata0';
import trastData1 from './trastdata1';
import rlData from './rl-meth';
import ryaData from './rya-meth';
import rshmData from './rshm-meth';
import rpData from './rp-meth';
import rzData from './rz-meth';
import rmpData from './rmp-meth';
import rpshData from './rpsh-meth';
import rpzData from './rpz-meth';
import rtmData from './rtm-meth';
import gcrData from './gcr-meth';
import rpszData from './rpsz-meth';
import neoData from './neo-meth';
import State from './state';

class MethPanel {

	constructor() {

		this.currSt = 'st2';
		this.currPath = 'krr';
		this.pathData = methData[this.currPath];
		this.methSet = this.pathData[this.currSt].meth;
		this.allPats = this.pathData[this.currSt].pats;
		this.currFreePats = 3;
		this.currLineNum = 0;
		this.mode = 'count';
		this.methPack = {}; // Упакованный объект методов
		$('#dist-button').on(State.startEvent, this.showDistPanel);
	}

///////////////////////////////////

	showDistPanel() {

//		State.distPanel.render(State.currStad);
		State.distPanel.show();
	}

///////////////////////////////////

	setCurrentSt(st) {

		this.currSt = st;
	}


///////////////////////////////////

	render(st) {
		const path = State.currPath;

		if(st) this.currSt = st;
		if(State.currPath=='rmz') this.pathData = rmzData;
		else if(State.currPath=='rl') {
			this.pathData = rlData;
		}
		else if(State.currPath=='rya') {
			this.pathData = ryaData;
		}
		else if(State.currPath=='rshm') {
			this.pathData = rshmData;
		}
		else if(State.currPath=='rp') {
			this.pathData = rpData;
		}
		else if(State.currPath=='rz') {
			this.pathData = rzData;
		}
		else if(State.currPath=='rmp') {
			this.pathData = rmpData;
		}
		else if(State.currPath=='rpsh') {
			this.pathData = rpshData;
		}
		else if(State.currPath=='rpz') {
			this.pathData = rpzData;
		}
		else if(State.currPath=='rtm') {
			this.pathData = rtmData;
		}
		else if(State.currPath=='gcr') {
			this.pathData = gcrData;
		}
		else if(State.currPath=='rpsz') {
			this.pathData = rpszData;
		}
		else if(State.currPath=='neo') {
			this.pathData = neoData;
		}
		else this.pathData = methData[State.currPath];

		this.methSet = this.pathData[this.currSt].meth;

		if(path=='rmp') this.allPats = State.rmpFreePatsBuf[this.currSt];
		else if(path=='rya') this.allPats = State.ryaFreePatsBuf[this.currSt];
		else {
			if(this.pathData[this.currSt].result != -1) this.allPats = this.pathData[this.currSt].result;
			else this.allPats = this.pathData[this.currSt].pats;	
		}
		const methPanel = $('#methods-panel');

		const len = this.methSet.length;
		const view = `${ this.methSet.map( (meth, num)=>`
	            <div class="meth-line" id="meth-line${num}">
	                <div class="panel-pic">
	                    <div class="line-pic mid-line" id="line-pic${num}">
	                        <div class="dot-pic"></div>
	                    </div>
	                </div>
	                <div class="line-text">
	                    <div class="line-title">${meth.name}</div>
	                    <div class="line-info">${meth.info}</div>
	                </div>
	                <div class="line-counter">
	                    <div class="minus-but" id="meth-minus${num}"></div>
	                    <div class="pats-count" id="pcount${num}">
	                    	<div class="pats-edit" id="pats-edit${num}">
	                    		<input class="count-edit" id="count-edit${num}" type="text" value="${meth.pats}">
	                    	</div>
	                    	<div class="pats-text" id="pats-text${num}">${meth.pats}</div>
	                    </div>
	                    <div class="plus-but" id="meth-plus${num}"></div>
	                </div>
	            </div>
			`).join('')}	
		`;	

		methPanel.empty();
		methPanel.append(view);
		$('#line-pic0').addClass('top-line');
		$('#line-pic0').removeClass('mid-line');
		$('#line-pic'+(len-1)).addClass('bot-line');
		$('#line-pic'+(len-1)).removeClass('mid-line');
		$('.minus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.plus-but').on(State.startEvent, (e) => this.changeCounter(e));
		$('.pats-count').on(State.startEvent, (e) => this.showEdit(e));
		$('.count-edit').on('keypress focusout', (e) => this.editNumber(e));

		if(len==1) {
			$('#line-pic'+(len-1)).removeClass('bot-line');
			$('#line-pic'+(len-1)).removeClass('top-line');
		}

		let freeCnt = 0, cnt;
		for(cnt=0 ; cnt<len ; cnt++) {
			if(this.methSet[cnt].result) freeCnt += this.methSet[cnt].result;
			else freeCnt += this.methSet[cnt].pats;
		}
		freeCnt = this.allPats - freeCnt;	
		this.currFreePats = freeCnt;
		State.freePats[State.currPath][this.currSt] = freeCnt;
		$('#meth-free-pats').text(this.currFreePats);
		
		let currBudget;
		State.calc.resetDataBufs();
		currBudget = State.calc.calcOneGroup(State.currPath, this.currSt);
		currBudget = State.calc.padDigits(Math.floor(currBudget));
		$('.curr-budget').text(currBudget);
State.distPanel.render(this.currSt);		
	}

///////////////////////////////////

	completeEdit() {

		const num = this.currLineNum;
		let	pats = $('#count-edit'+num).val();
		let delta = pats - this.methSet[num].pats;
		let freePats = this.currFreePats - delta;	

		if(freePats<0) delta = this.currFreePats;
		this.methSet[num].pats += delta;
		this.currFreePats -= delta;
		pats = this.methSet[num].pats;
//console.log(pats);
		this.editMode = 'count';
		$('#count-edit'+num).val(pats);
		$('#pats-edit'+num).hide();
		$('#pats-text'+num).show();
		$('#pats-text'+num).text(pats);
		$('#meth-free-pats').text(this.currFreePats);
//		State.stPanel.markFreePats(State.currPath);
		if(delta!=0) {
			State.flDistChanged = 1;
		}
		this.displayResult();
this.saveMethData();
	}

///////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if(e.charCode<48 || e.charCode>57) {
				e.preventDefault();
				return;
			}
		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}


///////////////////////////////////

	showEdit(e) {

		let num = e.target.id,
			str = num.slice(0,9);

		if(str!='pats-text') return;
		num = num.slice(9);
		if(this.editMode=='edit') {
			if(this.currLineNum==num) return;
			this.completeEdit();
		}

		setTimeout(() => {
			this.editMode = 'edit';
			this.currLineNum = num;
			$('#pats-text'+num).hide();
			$('#pats-edit'+num).show();
			$('#count-edit'+num).val(this.methSet[num].pats);
		},50);
		setTimeout(() => {
			$('#count-edit'+num).focus();
			let len = $('#count-edit'+num).val().length;
//console.log(len);		
			let inp = document.getElementById('count-edit'+num);
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

	displayResult() {

		let result;

		State.stPanel.markFreePats(State.currPath);
		State.calc.resetDataBufs();
		result = State.calc.calcFullBudget();
		result = Math.round(result);
		$('.total-budget').text(State.calc.padDigits(result));

		$('#scr3-insert').html('');

		State.drugTable.render('');
		State.drugTable.render('melanoma');
		State.drugTable.render('krr');
		State.drugTable.render('rmz');
		State.drugTable.render('rl');
		State.drugTable.render('rya');
		State.drugTable.render('rshm');
		State.drugTable.render('rp');
		State.drugTable.render('rz');
		State.drugTable.render('rmp');
		State.drugTable.render('rpsh');
		State.drugTable.render('rpz');
		State.drugTable.render('rtm');
		State.drugTable.render('gcr');
		State.drugTable.render('rpsz');
		State.drugTable.render('neo');

		let currBudget;
		State.calc.resetDataBufs();
		currBudget = State.calc.calcOneGroup(State.currPath, this.currSt);
		currBudget = State.calc.padDigits(Math.floor(currBudget));
		$('.curr-budget').text(currBudget);
if(State.flDistChanged==1) State.distPanel.resetChanels();		
State.flDistChanged = 0;
	}

///////////////////////////////////

	changeCounter(e) {

		let str = e.target.id, num,
			methSet = this.methSet;

		if(this.editMode=='edit') {
			this.completeEdit();
		}	
		num = str.slice(0,8);
		setTimeout(() => {
			if(num=='meth-min') {
				num = str.slice(10);
				if(methSet[num].pats < 1) return;
				methSet[num].pats--;
				this.currFreePats++;
				$('#pats-text'+num).text(''+methSet[num].pats);
				$('#meth-free-pats').text(this.currFreePats);
			}
			else if(num=='meth-plu') {
				if(this.currFreePats<1) return;
				num = str.slice(9);
				methSet[num].pats++;
				this.currFreePats--;
				$('#pats-text'+num).text(''+methSet[num].pats);
				$('#meth-free-pats').text(this.currFreePats);
			}	
State.flDistChanged = 1;
			this.displayResult();
this.saveMethData();
//			State.stPanel.markFreePats(State.currPath);
//			State.calc.calcOneGroup(State.currPath, State.currStad);
//			State.calc.calcOnePath('krr');
//			State.calc.calcFullBudget();

		}, 100);

	}

///////////////////////////////////
// Загружаем всю БД методов в локальный буфер, но не перегружаем в State
// Используется для частичной перезаписи БД методов 

	loadMethPack() {

		let dataStr, key = 'mn_meth';

		dataStr = localStorage.getItem(key);	
		if(null==dataStr) this.methPack = {};
		else this.methPack = JSON.parse(dataStr);
	}	

///////////////////////////////////
// Упаковка структуры данных, описывающей раскладку по методам 
// для одной патологии. Используется перед сохранением распределения между сеансами  
// !!! В текущей реализации не может вызываться напрямую, т.к. для выборочной
// перезаписи нужно вызывать loadMethPack, а он вызывается только в saveMethData() 

	compactPathMethData(pathName) {

		let pathData, group, len, meth, methPack;

		if(pathName=='rl') pathData = rlData;
		else if(pathName=='rmz') pathData = rmzData;
		else if(pathName=='rya') pathData = ryaData;
		else if(pathName=='rshm') pathData = rshmData;
		else if(pathName=='rp') pathData = rpData;
		else if(pathName=='rz') pathData = rzData;
		else if(pathName=='rmp') pathData = rmpData;
		else if(pathName=='rpsh') pathData = rpshData;
		else if(pathName=='rpz') pathData = rpzData;
		else if(pathName=='rtm') pathData = rtmData;
		else if(pathName=='gcr') pathData = gcrData;
		else if(pathName=='rpsz') pathData = rpszData;
		else if(pathName=='neo') pathData = neoData;
		else pathData = methData[pathName];
		methPack = this.methPack;
		if(!methPack[pathName]) methPack[pathName] = {};
		for(group in pathData) {
			
			if(!methPack[pathName][group]) methPack[pathName][group] = {};
			methPack[pathName][group].p = pathData[group].pats;
			methPack[pathName][group].r = pathData[group].result;
			methPack[pathName][group].m = [];
			len = pathData[group].meth.length;
			for(meth=0 ; meth<len ; meth++) {
				methPack[pathName][group].m.push(pathData[group].meth[meth].pats);
			}
			methPack[pathName][group].om = [];
			methPack[pathName][group].omd = [];
			methPack[pathName][group].rl = [];
			methPack[pathName][group].on = [];
			for(meth=0 ; meth<len ; meth++) {
				methPack[pathName][group].om.push(pathData[group].meth[meth].oms);
				methPack[pathName][group].omd.push(pathData[group].meth[meth].omsd);
				methPack[pathName][group].rl.push(pathData[group].meth[meth].rlo);
				methPack[pathName][group].on.push(pathData[group].meth[meth].onls);
			}
		}
	}

///////////////////////////////////
// Упаковываем и сохраняем в LS все данные распределения пациентов 

	saveMethData() {

		let cnt, path, len, dataStr, key = 'mn_meth';

		this.loadMethPack();
		len = State.pathKeys.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			path = State.pathKeys[cnt];
			this.compactPathMethData(path);
		}
		this.methPack.rmpFP = State.rmpFreePatsBuf;
		this.methPack.stPats = State.stPats;
		this.methPack.rmzMults = State.rmzMultBuf;
		this.methPack.rmzPats = State.rmzPatsBuf;
		this.methPack.rzMults = State.rzMultBuf;
		this.methPack.rzPats = State.rzPatsBuf;
		this.methPack.ryaFP = State.ryaFreePatsBuf;
		this.methPack.ryaMults = State.ryaMultBuf;
		this.methPack.ryaPats = State.ryaPatsBuf;
		this.methPack.rpszMults = State.rpszMultBuf;
		this.methPack.rpszPats = State.rpszPatsBuf;
		this.methPack.rpshMults = State.rpshMultBuf;
		this.methPack.rpshPats = State.rpshPatsBuf;
		this.methPack.rlMults = State.rlMultBuf;
		this.methPack.rlPats = State.rlPatsBuf;
		dataStr = JSON.stringify(this.methPack);
		localStorage.setItem(key,dataStr);
		State.pathPanel.savePathData();

	}

///////////////////////////////////
// Загрузка из this.methPack структуры данных, описывающей раскладку по методам 
// для одной патологии.  

	restorePathMethData(pathName) {

		let pathData, group, len, meth, methPack;

		if(pathName=='rl') pathData = rlData;
		else if(pathName=='rmz') pathData = rmzData;
		else if(pathName=='rya') pathData = ryaData;
		else if(pathName=='rshm') pathData = rshmData;
		else if(pathName=='rp') pathData = rpData;
		else if(pathName=='rz') pathData = rzData;
		else if(pathName=='rmp') pathData = rmpData;
		else if(pathName=='rpsh') pathData = rpshData;
		else if(pathName=='rpz') pathData = rpzData;
		else if(pathName=='rtm') pathData = rtmData;
		else if(pathName=='gcr') pathData = gcrData;
		else if(pathName=='rpsz') pathData = rpszData;
		else if(pathName=='neo') pathData = neoData;
		else pathData = methData[pathName];
		methPack = this.methPack;
		for(group in pathData) {
			pathData[group].pats = methPack[pathName][group].p;
			pathData[group].result = methPack[pathName][group].r;
			len = pathData[group].meth.length;
			for(meth=0 ; meth<len ; meth++) {
				pathData[group].meth[meth].pats = methPack[pathName][group].m[meth];
			}
			for(meth=0 ; meth<len ; meth++) {
				if(methPack[pathName][group].om) {
					pathData[group].meth[meth].oms = methPack[pathName][group].om[meth];
					pathData[group].meth[meth].omsd = methPack[pathName][group].omd[meth];
					pathData[group].meth[meth].rlo = methPack[pathName][group].rl[meth];
					pathData[group].meth[meth].onls = methPack[pathName][group].on[meth];
				} else {
					pathData[group].meth[meth].oms = 0;
					pathData[group].meth[meth].omsd = 0;
					pathData[group].meth[meth].rlo = 0;
					pathData[group].meth[meth].onls = 0;
				}
			}
		}
	}

///////////////////////////////////
// Загружаем из LS в this.methPack все данные распределения пациентов 

	loadMethData() {

		let cnt, path, len, group, dataStr, key = 'mn_meth';

		dataStr = localStorage.getItem(key);	
		this.methPack = JSON.parse(dataStr);
		State.rmpFreePatsBuf = this.methPack.rmpFP;
		State.stPats = this.methPack.stPats;
		State.rmzMultBuf = this.methPack.rmzMults;
		State.rmzPatsBuf = this.methPack.rmzPats;
		State.rzMultBuf = this.methPack.rzMults;
		State.rzPatsBuf = this.methPack.rzPats;
		State.ryaFreePatsBuf = this.methPack.ryaFP;
		State.ryaMultBuf = this.methPack.ryaMults;
		State.ryaPatsBuf = this.methPack.ryaPats;
		State.rpszMultBuf = this.methPack.rpszMults;
		State.rpszPatsBuf = this.methPack.rpszPats;
		State.rpshMultBuf = this.methPack.rpshMults;
		State.rpshPatsBuf = this.methPack.rpshPats;
		State.rlMultBuf = this.methPack.rlMults;
		State.rlPatsBuf = this.methPack.rlPats;
		len = State.pathKeys.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			path = State.pathKeys[cnt];
			this.restorePathMethData(path);
		}
		State.pathPanel.loadPathData();
		for(group in rlData) State.rlPatsBuf[group] = rlData[group].pats;
		for(group in rmpData) State.rmpPatsBuf[group] = rmpData[group].pats;
	}

///////////////////////////////////
// Сбрасываем записанные в LS данные по распределению пациентов

	resetMethData() {

		const lsName = 'mn_meth';

		localStorage.removeItem(lsName);
	} 


///////////////////////////////////
// Загружаем в целевой набор методов патч, корректирующий часть методов

	patchMethSet(target, patch) {

		let currGroup, currSet, targetSet, len, i;

		for(currGroup in patch) {
			currSet = patch[currGroup].meth;
			targetSet = target[currGroup].meth;
			len = currSet.length;
			for(i=0 ; i<len ; i++) {
				if(currSet[i] != -1) {
					targetSet[i].drugs = currSet[i].drugs;
					targetSet[i].dose = currSet[i].dose;
					targetSet[i].cycles = currSet[i].cycles;
				}
			}
		}
	}


///////////////////////////////////
// Приводим набор методов в соответствии с пресетами в настройках

	loadOptions() {

		if(State.flTrastMode==1) { // Выбрана подкожная форма трастузумаба
			this.patchMethSet(rmzData, trastData1);
		} else {
			this.patchMethSet(rmzData, trastData0);
		}
	}

}

export default MethPanel;