
"use strict";
let stData = {
	1: [12.7,17.5,28.2,41.4],
	2: [12.1,9,26,52],
	3: [11,10.4,42.6,35.2],
	4: [20.9,18.4,27.9,32.8],
	5: [14.8,16.9,27.4,37.2],
	6: [10.4,17.6,35.8,36],
	7: [12.3,10.1,26.7,50.4],
	8: [10.1,11.7,20.2,57.3],
	9: [15.6,14.8,37.4,32.1],
	10: [24,13.1,20.9,39.9],
	11: [19.1,14.2,24.9,41.8],
	12: [11.4,20.8,29.7,35.5],
	13: [13.1,8.4,23.9,53.1],
	14: [19.6,13.1,23,43.4],
	15: [12,15.7,24.9,47.1],
	16: [9.7,21.2,31.1,37.5],
	17: [3.7,16.1,14.1,65.9],
	18: [18,7.8,25,48.7],
	19: [12.5,6.3,43.8,37.5],
	20: [16,7.7,29.9,37.8],
	21: [15.9,17.2,24.7,37.3],
	22: [14.4,10.5,24.3,46],
	23: [16.9,17.8,27.3,32.9],
	24: [7,20.7,29.2,36.4],
	25: [22.9,8.7,24.8,41],
	26: [11.1,17.9,23.4,44.4],
	27: [11.3,8.1,33.9,46.6],
	28: [11.2,13.1,30.2,45.5],
	29: [14.5,15.4,31.2,38.6],
	30: [11.1,13.1,21.6,54],
	31: [9.2,6.6,22.2,60.7],
	32: [12.6,21,26.1,38.4],
	33: [9.7,12.9,38.5,37.9],
	34: [4.8,18.5,11.9,61.3],
	35: [7.5,15,35.5,42.1],
	36: [7.9,18.4,33.6,38.7],
	37: [12.8,4.8,12,52],
	38: [12.8,10.6,34.4,37.6],
	39: [10,32.5,30,27.5],
	40: [2.6,16.8,43.7,36],
	41: [8.9,9.2,25.7,55.1],
	42: [5.1,10.9,29.5,54.5],
	43: [11.6,10.7,28.1,45.5],
	44: [10.9,13.2,22.3,53.6],
	45: [24.1,17.3,26.9,31.7],
	46: [16.7,13.3,22.8,46.3],
	47: [18.5,14.8,26.5,39],
	48: [9.6,23.6,26.8,37.6],
	49: [16.3,15.5,28.4,37.8],
	50: [14.4,19.4,25.8,33.7],
	51: [17.4,16.4,25.1,41.1],
	52: [16.7,12.5,27.3,43.6],
	53: [14.8,13,30,42.2],
	54: [11.5,12.4,25.4,47.8],
	55: [14.1,16.2,31.6,38.2],
	56: [16.4,22.7,20,40.9],
	57: [13.8,14,24.1,48.1],
	58: [15,15.5,34.2,34.4],
	59: [16.2,12.2,26.3,44],
	60: [23,13.5,26.2,37.3],
	61: [15.2,14.6,26.5,43.3],
	62: [11.7,19.8,26.5,41.5],
	63: [28.1,13,27.4,31.3],
	64: [14.3,10.1,27.1,47],
	65: [15.3,17.7,27.4,38.5],
	66: [9.3,22.8,30.3,33.4],
	67: [12.3,10.7,22.4,54.6],
	68: [13,25.2,22.4,38.1],
	69: [13.7,21.5,25.9,36.9],
	70: [15.4,10.7,33.4,38.8],
	71: [18.6,12.9,25,41.6],
	74: [8.9,20.3,30.4,32.9],
	75: [6.7,8,29.3,49.3],
	76: [9.7,14.6,20.2,45.7],
	77: [15.9,20.6,22.3,39.4],
	78: [15.9,21.1,27.8,35.2],
	79: [13.3,15.7,32,36.2],
	80: [15.1,17.8,21.1,46.1],
	81: [8.8,13.2,27.9,50],
	82: [9.9,7.3,30.6,51.9],
	72: [18,13.8,30.9,37.1],
	83: [3.8,23.1,19.2,53.8],
	73: [5.9,21.1,41.9,25.8],
	84: [8.7,6.5,38.7,45.8],
	85: [11.4,17,22.7,44.3]
};
	
export default stData;
