;"use strict";

import State from './state';

class PathSelector {

	constructor() {
		this.editMode = 'count';
		$('.path-select-line').on(State.startEvent, (e) => this.flipPath(e));
		$('.path-select-line2').on(State.startEvent, (e) => this.flipPath(e));
		$('.path-select-line3').on(State.startEvent, (e) => this.flipPath(e));
		$('#scr0-pats-txt').on(State.startEvent, (e) => this.showEdit(e));
		$('#scr0-pats-inp').on('keypress focusout', (e) => this.editNumber(e));
		$('#scr0-recalc').on(State.startEvent, (e) => this.resetDistribution(e));
	}


///////////////////////////////////////////////
// Сбрасываем все данные о пользовательском распределении
// и перераспределяем все автоматически

	resetDistribution() {

		localStorage.removeItem('mn_path');
		localStorage.removeItem('mn_meth');
		State.methPanel.resetMethData();
		// Стираем ручные количества по патологиям
		// и буфер количеств по группам для РМЖ
		State.calc.resetData();
		State.calc.initDataSet();            
		State.priceTable.render();
		State.flDistChanged = 1;		
		State.methPanel.displayResult();
		State.calc.updateTotalPatientViews();
		State.pathPanel.render();
	}


///////////////////////////////////////////////

	flipPath(e) {

		let num = e.currentTarget.id, i, len, 
			summ = 0;

		num = num.slice(9);
		// Считаем оставшиеся активные патологии
		len = State.selPath.length;
		for(i=0 ; i<len ; i++) if(State.selPath[i]==1) summ++;
		// Последний не убираем	
		if(State.selPath[num]==1 && summ<2) return;	
		if(State.selPath[num]==1) {
			State.selPath[num] = 0;
			$('#path-select-caption'+num).addClass('grey-text');
			$('#path-select-caption'+num).removeClass('blue-text');
			$('#path-bullet'+num).addClass('path-bullet-inactive');
			$('#path-bullet'+num).removeClass('path-bullet-active');
		} else {
			State.selPath[num] = 1;
			$('#path-select-caption'+num).addClass('blue-text');
			$('#path-select-caption'+num).removeClass('grey-text');
			$('#path-bullet'+num).addClass('path-bullet-active');
			$('#path-bullet'+num).removeClass('path-bullet-inactive');
		}
		State.selPathKeys = [];
		for(i=0 ; i<len ; i++) if(State.selPath[i]==1) State.selPathKeys.push(State.pathKeys[i]);
		State.calc.initDataSet();
//!!D		State.methPanel.displayResult();
	}

///////////////////////////////////

	getPathNumber(pathName) {

		let i, len = State.pathKeys.length, name;

		if(pathName=='mel') name = 'melanoma';
		else name = pathName;
		for(i=0 ; i<len ; i++) if(State.pathKeys[i]==name) return i;
		return -1;
	}

///////////////////////////////////////////////

	completeEdit() {


		let val, len, i, pats, allPatsInitial, allPatsNew, probBuf = [],
			patBuf = [], currPathNum;

		if(this.editMode!='edit') return;
		let	num = $('#scr0-pats-inp').val();
		this.editMode = 'count';
		val = parseInt(num);
		$('#scr0-pats-txt').show();
		$('#scr0-pats-div').hide();
		if(val>0)
			$('#scr0-pats-txt').text(val);
		// Расчитываем вероятности разбиения по патологиям для текущего региона
		allPatsNew = val;
		pats = State.currPats; allPatsInitial = 0;
		for(i in pats) {
			allPatsInitial += pats[i]*State.selPath[this.getPathNumber(i)];
		}
		for(i in pats) {
			if(State.selPath[this.getPathNumber(i)]) { 
				probBuf.push(pats[i]/allPatsInitial*100);
				patBuf.push(0);
			}
		}
		State.calc.disributePats(allPatsNew, probBuf, patBuf);
		let ind = 0;
		for(i in pats) {
			currPathNum = this.getPathNumber(i);
			if(State.selPath[currPathNum]) { 
				State.customPats[i] = patBuf[ind];
				ind++; 
			} else {
				State.customPats[i] = 0;
			}
		}

		localStorage.removeItem('mn_path');
		localStorage.removeItem('mn_meth');
		State.methPanel.resetMethData();
		State.calc.initDataSet();            
State.distPanel.resetAllChanels();
		State.priceTable.render();
		State.methPanel.displayResult();
State.calc.updateTotalPatientViews();
		State.pathPanel.render();
		State.methPanel.saveMethData();	
	}


///////////////////////////////////////////////

	editNumber(e) {

		if(e.type=='keypress') {
			if(e.charCode==13) {
				this.completeEdit();
				return;
			}
			if(e.charCode<48 || e.charCode>57) { 
				e.preventDefault();
				return;
			}
		}
		if(e.type=='focusout') {
				this.completeEdit();
		}	
	}

///////////////////////////////////////////////

	showEdit(e) {

		let str = e.currentTarget.id;

		if(str!='scr0-pats-txt') return;
		setTimeout(() => {
			this.editMode = 'edit';
			$('#scr0-pats-txt').hide();
			$('#scr0-pats-div').show();
//!!ToDo			$('#scr0-pats-inp').val(drugData[num].price);
		},50);
		setTimeout(() => {
			$('#scr0-pats-inp').focus();
			let len = $('#scr0-pats-inp').val().length;
			let inp = document.getElementById('scr0-pats-inp');
			inp.setSelectionRange(0,len);
		},100);

	}

///////////////////////////////////

} 

export default PathSelector;