;"use strict";

const rzData = {

// ============================>rz
// ---------> Периоперационная ХТ
		'st0_0_1':{
			group: 'Периоперационная ХТ',
			meth: [
				{
					id: 887,
					code: 'sh0537',
					name: 'CF',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 20,
					dose: [100,20,9000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 12
				},
				{
					id: 888,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 20,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [6,6,6],
					pats: 12
				},
				{
					id: 889,
					code: 'sh0588',
					name: 'FLOT',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [87,16,13,61],
					rate: 60,
					dose: [100,150,400,5000],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [6,6,6,6],
					pats: 38
				}
			],
			pats: 0
		},

// ---------> Адъювантная ХТ
		'st0_0_2':{
			group: 'Адъювантная ХТ',
			meth: [
				{
					id: 890,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 80,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [6,6,6],
					pats: 69
				},
				{
					id: 891,
					code: 'sh0650',
					name: 'Капецитабин монотерапия',
					info: '(капецитабин)',
					drugs: [10],
					rate: 20,
					dose: [50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 17
				}
			],
			pats: 0
		},

// ---------> Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения Линия 1
		'st0_1_1':{
			group: 'Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения',
			meth: [
				{
					id: 892,
					code: 'sh0537',
					name: 'CF',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 6,
					dose: [100,20,9000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 12
				},
				{
					id: 893,
					code: 'sh0180',
					name: 'СХ + трастузумаб',
					info: '(капецитабин, цисплатин, трастузумаб)',
					drugs: [10,66,93,56,56,57],
					rate: 5,
					dose: [50500,100,40,440,440,150],
					ds: 'ds19.043',//>>
					st: 'st19.069',//>>
					cycles: [6,6,6,6,1,1],
					pats: 10
				},
				{
					id: 894,
					code: 'sh0685',
					name: 'СХ',
					info: '(капецитабин, цисплатин)',
					drugs: [10,66,93],
					rate: 5,
					dose: [50500,100,40],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [5,5,5],
					pats: 10
				},
				{
					id: 895,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 10,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 20
				},
				{
					id: 896,
					code: 'sh0658',
					name: 'XELOX + трастузумаб',
					info: '(капецитабин, оксалиплатин, трастузумаб)',
					drugs: [10,16,24,56,56,57],
					rate: 5,
					dose: [50500,150,100,440,440,150],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7,7,7,7,1,1],
					pats: 10
				},
				{
					id: 897,
					code: 'sh0663',
					name: 'FOLFOX',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 6,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8,8,8],
					pats: 12
				},
				{
					id: 898,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [9,25,13,61],
					rate: 5,
					dose: [300,40,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8,8,8,8],
					pats: 10
				},
				{
					id: 899,
					code: 'sh0615.1',
					name: 'FLOT + трастузумаб',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил, трастузумаб)',
					drugs: [87,16,13,61,56,57],
					rate: 4,
					dose: [100,150,400,5000,440,150],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [10,10,10,10,10,1],
					pats: 8
				},
				{
					id: 900,
					code: 'sh0588',
					name: 'FLOT',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [87,16,13,61],
					rate: 8,
					dose: [100,150,400,5000],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [8,8,8,8],
					pats: 16
				},
				{
					id: 901,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 15,
					dose: [50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 25
				},
				{
					id: 902,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 20
				},
				{
					id: 903,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 6,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 12
				},
				{
					id: 904,
					code: 'sh0139.1',
					name: 'Паклитаксел',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 10,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 20
				},
				{
					id: 905,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 5,
					dose: [600,200,1000],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 10
				}
			],
			pats: 0
		},

// ---------> IIA - IIIB без оперативного лечения Линия 1
		'st0_1_2':{
			group: 'IIA - IIIB без оперативного лечения',
			meth: [
				{
					id: 906,
					code: 'sh0537',
					name: 'CF',
					info: '(цисплатин, 5-фторурацил)',
					drugs: [66,93,61],
					rate: 6,
					dose: [100,20,9000],
					ds: 'ds19.037',//>>
					st: 'st19.063',//>>
					cycles: [4,4,4],
					pats: 1
				},
				{
					id: 907,
					code: 'sh0653',
					name: 'XELOX',
					info: '(капецитабин, оксалиплатин)',
					drugs: [10,16,24],
					rate: 10,
					dose: [50500,150,100],
					ds: 'ds19.041',//>>
					st: 'st19.067',//>>
					cycles: [5,5,5],
					pats: 2
				},
				{
					id: 908,
					code: 'sh0658',
					name: 'XELOX + трастузумаб',
					info: '(капецитабин, оксалиплатин, трастузумаб)',
					drugs: [10,16,24,56,56,57],
					rate: 5,
					dose: [50500,150,100,440,440,150],
					ds: 'ds19.044',//>>
					st: 'st19.069',//>>
					cycles: [7,7,7,7,1,1],
					pats: 1
				},
				{
					id: 909,
					code: 'sh0663',
					name: 'FOLFOX',
					info: '(оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [16,13,61],
					rate: 6,
					dose: [150,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8,8,8],
					pats: 1
				},
				{
					id: 910,
					code: 'sh0646',
					name: 'FOLFIRI',
					info: '(иринотекан, кальция фолинат, 5-фторурацил)',
					drugs: [9,25,13,61],
					rate: 5,
					dose: [300,40,750,5000],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [8,8,8,8],
					pats: 1
				},
				{
					id: 911,
					code: 'sh0615.1',
					name: 'FLOT + трастузумаб',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил, трастузумаб)',
					drugs: [87,16,13,61,56,57],
					rate: 4,
					dose: [100,150,400,5000,440,150],
					ds: 'ds19.044',//>>
					st: 'st19.068',//>>
					cycles: [10,10,10,10,10,1],
					pats: 1
				},
				{
					id: 912,
					code: 'sh0588',
					name: 'FLOT',
					info: '(доцетаксел, оксалиплатин, кальция фолинат, 5-фторурацил)',
					drugs: [87,16,13,61],
					rate: 8,
					dose: [100,150,400,5000],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [8,8,8,8],
					pats: 1
				},
				{
					id: 913,
					code: 'sh0650',
					name: 'Капецитабин',
					info: '(капецитабин)',
					drugs: [10],
					rate: 15,
					dose: [50500],
					ds: 'ds19.039',//>>
					st: 'st19.066',//>>
					cycles: [6],
					pats: 4
				},
				{
					id: 914,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 10,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 2
				},
				{
					id: 915,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 6,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 916,
					code: 'sh0139.1',
					name: 'Паклитаксел',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 10,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [4],
					pats: 2
				},
				{
					id: 917,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 5,
					dose: [600,200,1000],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения Линия 2
		'st0_2_3':{
			group: 'Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения',
			meth: [
				{
					id: 918,
					code: 'sh0139.1',
					name: 'Паклитаксел 80 мг монотерапия',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 30,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3],
					pats: 28
				},
				{
					id: 919,
					code: 'sh0671',
					name: 'Паклитаксел 135 мг монотерапия',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 5,
					dose: [900],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4],
					pats: 5
				},
				{
					id: 920,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 15,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 15
				},
				{
					id: 921,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 10,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 10
				},
				{
					id: 922,
					code: 'sh0506',
					name: 'Рамуцирумаб',
					info: '(рамуцирумаб)',
					drugs: [109,108],
					rate: 5,
					dose: [100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4,4],
					pats: 5
				},
				{
					id: 923,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [3],
					pats: 5
				},
				{
					id: 924,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 20,
					dose: [600,100,500],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 19
				},
				{
					id: 925,
					code: 'sh0706',
					name: 'XELIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 5,
					dose: [300,80,43000],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 5
				},
				{
					id: 926,
					code: 'sh0912',
					name: 'FOLFIRI + трастузумаб',
					info: '(иринотекан, кальция фолинат, 5-фторурацил, трастузумаб)',
					drugs: [9,25,13,61,57,56],
					rate: 5,
					dose: [300,40,750,5000,300,440],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4,4,3,1],
					pats: 5
				}
			],
			pats: 0
		},

// ---------> IIA - IIIB без оперативного лечения Линия 2
		'st0_2_4':{
			group: 'IIA - IIIB без оперативного лечения',
			meth: [
				{
					id: 927,
					code: 'sh0139.1',
					name: 'Паклитаксел 80 мг монотерапия',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 30,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [3],
					pats: 5
				},
				{
					id: 928,
					code: 'sh0671',
					name: 'Паклитаксел 135 мг монотерапия',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 5,
					dose: [900],
					ds: 'ds19.039',//>>
					st: 'st19.064',//>>
					cycles: [4],
					pats: 0
				},
				{
					id: 929,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 15,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [6,6],
					pats: 1
				},
				{
					id: 930,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 10,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 931,
					code: 'sh0506',
					name: 'Рамуцирумаб',
					info: '(рамуцирумаб)',
					drugs: [109,108],
					rate: 5,
					dose: [100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 932,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 5,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [3],
					pats: 0
				},
				{
					id: 933,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 20,
					dose: [600,100,500],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 934,
					code: 'sh0706',
					name: 'XELIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 5,
					dose: [300,80,43000],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [3,3,3],
					pats: 0
				},
				{
					id: 935,
					code: 'sh0912',
					name: 'FOLFIRI + трастузумаб',
					info: '(иринотекан, кальция фолинат, 5-фторурацил, трастузумаб)',
					drugs: [9,25,13,61,57,56],
					rate: 5,
					dose: [300,40,750,5000,300,440],
					ds: 'ds19.042',//>>
					st: 'st19.067',//>>
					cycles: [4,4,4,4,3,1],
					pats: 0
				}
			],
			pats: 0
		},

// ---------> Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения Линия 3
		'st0_3_5':{
			group: 'Местнораспрос- трнанённая и диссеминированная карцинома после оперативного лечения',
			meth: [
				{
					id: 936,
					code: 'sh0139.1',
					name: 'Паклитаксел',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 10,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 2
				},
				{
					id: 937,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 16,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 3
				},
				{
					id: 938,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 5,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [2,2],
					pats: 1
				},
				{
					id: 939,
					code: 'sh0506',
					name: 'Рамуцирумаб',
					info: '(рамуцирумаб)',
					drugs: [109,108],
					rate: 20,
					dose: [100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [3,3],
					pats: 4
				},
				{
					id: 940,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 15,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [3],
					pats: 3
				},
				{
					id: 941,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 20,
					dose: [600,100,500],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 3
				},
				{
					id: 942,
					code: 'sh9001',
					name: 'ELF',
					info: '(этопозид, кальция фолинат, фторурацил)',
					drugs: [110,13,61],
					rate: 5,
					dose: [650,300,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1,2,2],
					pats: 1
				},
				{
					id: 943,
					code: 'sh0706',
					name: 'XELIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 5,
					dose: [300,80,43000],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [2,2,2],
					pats: 1
				},
				{
					id: 944,
					code: '-',
					name: 'Иринтрекан + трастузумаб',
					info: '(иринотекан, трастузумаб)',
					drugs: [9,25,57,56],
					rate: 4,
					dose: [400,40,300,440],
					ds: '',//>>
					st: '',//>>
					cycles: [4,4,3,1],
					pats: 1
				}
			],
			pats: 0
		},

// ---------> IIA - IIIB без оперативного лечения Линия 3
		'st0_3_6':{
			group: 'IIA - IIIB без оперативного лечения',
			meth: [
				{
					id: 945,
					code: 'sh0139.1',
					name: 'Паклитаксел',
					info: '(паклитаксел)',
					drugs: [91],
					rate: 10,
					dose: [600],
					ds: 'ds19.040',//>>
					st: 'st19.065',//>>
					cycles: [2],
					pats: 0
				},
				{
					id: 946,
					code: 'sh0698',
					name: 'Иринотекан',
					info: '(иринотекан)',
					drugs: [9,25],
					rate: 16,
					dose: [200,80],
					ds: 'ds19.039',//>>
					st: 'st19.063',//>>
					cycles: [4,4],
					pats: 0
				},
				{
					id: 947,
					code: 'sh0705',
					name: 'Доцетаксел',
					info: '(доцетаксел)',
					drugs: [88,87],
					rate: 5,
					dose: [120,20],
					ds: 'ds19.041',//>>
					st: 'st19.066',//>>
					cycles: [2,2],
					pats: 0
				},
				{
					id: 948,
					code: 'sh0506',
					name: 'Рамуцирумаб',
					info: '(рамуцирумаб)',
					drugs: [109,108],
					rate: 20,
					dose: [100,500],
					ds: 'ds19.046',//>>
					st: 'st19.070',//>>
					cycles: [3,3],
					pats: 1
				},
				{
					id: 949,
					code: 'sh0504',
					name: 'Пембролизумаб',
					info: '(пембролизумаб)',
					drugs: [20],
					rate: 15,
					dose: [200],
					ds: 'ds19.048',//>>
					st: 'st19.072',//>>
					cycles: [3],
					pats: 0
				},
				{
					id: 950,
					code: 'sh0575.1',
					name: 'Паклитаксел + рамуцирумаб',
					info: '(паклитаксел, рамуцирумаб)',
					drugs: [91,109,108],
					rate: 20,
					dose: [600,100,500],
					ds: 'ds19.048',//>>
					st: 'st19.073',//>>
					cycles: [4,4,4],
					pats: 2
				},
				{
					id: 951,
					code: 'sh9001',
					name: 'ELF',
					info: '(этопозид, кальция фолинат, фторурацил)',
					drugs: [110,13,61],
					rate: 5,
					dose: [650,300,3000],
					ds: 'ds19.037',//>>
					st: 'st19.062',//>>
					cycles: [1,2,2],
					pats: 0
				},
				{
					id: 952,
					code: 'sh0706',
					name: 'XELIRI',
					info: '(иринотекан, капецитабин)',
					drugs: [9,25,10],
					rate: 5,
					dose: [300,80,43000],
					ds: 'ds19.040',//>>
					st: 'st19.067',//>>
					cycles: [2,2,2],
					pats: 0
				},
				{
					id: 953,
					code: '-',
					name: 'Иринтрекан + трастузумаб',
					info: '(иринотекан, трастузумаб)',
					drugs: [9,25,57,56],
					rate: 4,
					dose: [400,40,300,440],
					ds: '',//>>
					st: '',//>>
					cycles: [4,4,3,1],
					pats: 0
				}
			],
			pats: 0
		}
	}

export default rzData;	