

"use strict" ;

class Slider {

  constructor(params) {

    var self = this;
    var sliderElem = null;
    var shiftY = null;
    var sliderCoords = null;
    var initTop;

    var divName = params.sliderDivName || 'slider';
    var callback = params.callback;
    var initVal = params.initVal || 0;

    self.flTouch = 0;
    self.gain = params.gain || 310;
    self.thumbElem = null;
    sliderElem = document.getElementById(divName);
    self.thumbElem = sliderElem.children[0];
    self.thumbElem.prnt = sliderElem ;
    self.thumbElem.onmousedown = clickObj;
    self.thumbElem.ontouchstart = clickObj;
    initTop = Math.round(self.gain - self.gain/100*initVal); 
    self.thumbElem.style.top = initTop + 'px';


  // Методы - приватные члены класса
    function fixEvent(e){  

      e = e || window.event;  

      if (!e.target) e.target = e.srcElement;  

      if (e.pageX == null && e.clientX != null ) { // если нет pageX..
        var html = document.documentElement;
        var body = document.body;  

        e.pageX = e.clientX + (html.scrollLeft || body && body.scrollLeft || 0);
        e.pageX -= html.clientLeft || 0;
        e.pageY = e.clientY + (html.scrollTop || body && body.scrollTop || 0);
        e.pageY -= html.clientTop || 0;
      }  

      if (!e.which && e.button) {
        e.which = e.button & 1 ? 1 : ( e.button & 2 ? 3 : ( e.button & 4 ? 2 : 0 ) )
      }
      return e;
    }   

    //----------------------------------------------  

    function getCoords(elem){  

      var box = elem.getBoundingClientRect(),
       body = document.body,
       docElem = document.documentElement,
       scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop,
       clientTop = docElem.clientTop || body.clientTop || 0,
       top  = box.top +  scrollTop - clientTop;
      
      return { top: Math.round(top) };
    }   

    //----------------------------------------------  

    function moveObj(e){  
      var persent;
      
      e = fixEvent(e);
      var newTop = e.pageY - self.shiftY - self.sliderCoords.top; // Новая позиция верхнего края рукоятки относительно
                                                        // верхнего края движка
      if (newTop < 0) {
        newTop = 0;
      }
      
      var NewDown = self.sliderElem.offsetHeight - self.thumbElem.offsetHeight; // Какова максимально низкая позиция
      // верхнего края рукоятки относительно вернего края движка, когда рукоятка упирается в нижний срез движка
      // !! Это не нужно считать каждый раз. Должно быть вынесено в конструктор !!
      if (newTop > NewDown) {
        newTop = NewDown;
      }
      
      persent = self.gain - newTop;
      persent = Math.round(persent/self.gain*100);
      callback(persent,divName);  
  //    console.log(persent);
      self.thumbElem.style.top = newTop + 'px';
      e.preventDefault();
    }  

    //----------------------------------------------  

    function clickObj(e){  

      e = fixEvent(e);
      if(e.type=='touchstart') self.flTouch = 1;
      if(self.flTouch==1 && e.type=='mousedown') return;

      self.thumbElem = this;
      self.sliderElem = this.prnt;
      var thumbCoords = getCoords(self.thumbElem);
      self.shiftY = e.pageY - thumbCoords.top; // Сдвиг точки захвата по вертикали относительно верхнего
                                              // среза рукоятки
      self.sliderCoords = getCoords(self.sliderElem);  

      if(self.flTouch==0){
        document.onmousemove = moveObj;
        document.onmouseup = function() {
          document.onmousemove = function(){};
          document.onmouseup = function(){};
        }
      }
      document.ontouchmove = moveObj;
      document.ontouchend = function() {
        document.ontouchmove = function(){};
        document.ontouchend = function(){};
      }
    }

  }

//////////////////////////////////////

  setVal(value){

    var position = Math.round(this.gain - this.gain/100*value); 

    this.thumbElem.style.top = position + 'px';
  }

}

//----------------------------------------------

export default Slider;