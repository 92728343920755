; "use strict";

import State from './state';
import drugData from './drugdata';
import methData from './methdata';
import rmzData from './rmz-meth';
import rlData from './rl-meth';
import ryaData from './rya-meth';
import rshmData from './rshm-meth';
import rpData from './rp-meth';
import rzData from './rz-meth';
import rmpData from './rmp-meth';
import rpshData from './rpsh-meth';
import rpzData from './rpz-meth';
import rtmData from './rtm-meth';
import gcrData from './gcr-meth';
import rpszData from './rpsz-meth';
import neoData from './neo-meth';
import melStData from './melanoma_st';
import rmzStData from './rmz-st';
import rlStData from './rl-st';

class Calc {

	constructor() {

		let group;

		for(group in rmzData) State.rmzPatsBuf[group] = -1;
		for(group in rlData) State.rlPatsBuf[group] = -1;
	}

/////////////////////////////

	calcOneGroup(path, st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

		if(chan) {
			if(path=='rmz') return this.calcOneRmzGroup(st, chan);	
			if(path=='rl') return this.calcOneRlGroup(st, chan);	
			if(path=='rya') return State.ryaModel.calcOneGroup(st, chan);	
			if(path=='rshm') return State.rshmModel.calcOneGroup(st, chan);	
			if(path=='rp') return State.rpModel.calcOneGroup(st, chan);	
			if(path=='rz') return State.rzModel.calcOneGroup(st, chan);	
			if(path=='rmp') return State.rmpModel.calcOneGroup(st, chan);	
			if(path=='rpsh') return State.rpshModel.calcOneGroup(st, chan);	
			if(path=='rpz') return State.rpzModel.calcOneGroup(st, chan);	
			if(path=='rtm') return State.rtmModel.calcOneGroup(st, chan);	
			if(path=='gcr') return State.gcrModel.calcOneGroup(st, chan);	
			if(path=='rpsz') return State.rpszModel.calcOneGroup(st, chan);	
			if(path=='neo') return State.neoModel.calcOneGroup(st, chan);	
		} else {
			if(path=='rmz') return this.calcOneRmzGroup(st);	
			if(path=='rl') return this.calcOneRlGroup(st);	
			if(path=='rya') return State.ryaModel.calcOneGroup(st);	
			if(path=='rshm') return State.rshmModel.calcOneGroup(st);	
			if(path=='rp') return State.rpModel.calcOneGroup(st);	
			if(path=='rz') return State.rzModel.calcOneGroup(st);	
			if(path=='rmp') return State.rmpModel.calcOneGroup(st);	
			if(path=='rpsh') return State.rpshModel.calcOneGroup(st);	
			if(path=='rpz') return State.rpzModel.calcOneGroup(st);	
			if(path=='rtm') return State.rtmModel.calcOneGroup(st);	
			if(path=='gcr') return State.gcrModel.calcOneGroup(st);	
			if(path=='rpsz') return State.rpszModel.calcOneGroup(st);	
			if(path=='neo') return State.neoModel.calcOneGroup(st);	
		}

		const methSet = methData[path][st].meth;

		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==24) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

				// Year budget for one drug for group
//				mgTotal = cycleDose*nOfCycles[currDrugN]*nOfPatients
// if(mgTotal>0)
// if(nOfPatients>0)
// if(drugIndex==13 && mgTotal>0)
//  	 console.log(currDrug.name+' cycleDose='+cycleDose+'nOfCycles='+nOfCycles+' mg='+mgTotal);
// 				nOfPacks = cycleDose/drugDose*nOfCycles*nOfPatients/drugPack;
// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

// if(nOfPatients>0) console.log(drugDose+'>>'+drugPack);
				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
// console.log('Drug: '+ currDrug.name + ':' +drugBudget);
//console.log(drugIndex);
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
//console.log('Method:'+methodBudget);
			groupBudget += methodBudget;

			if(chan=='oms') {		
				if(methSet[cnt].custKsg) {
					let custSet = this.getCustomKSGSet(methSet[cnt].custKsg, 'st');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.stIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc; // Округление не делаем, т.к. в этой модели нет неценых циклов
						State.nOfCasesKs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.stIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesKs += nOfPatients*cyclesRound;
					if(methSet[cnt].st && methSet[cnt].st!='') {
						State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
					}
				}
			}
			if(chan=='omsd') {		
				if(methSet[cnt].custKsg) {
					let custSet = this.getCustomKSGSet(methSet[cnt].custKsg, 'ds');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.dsIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc;
						State.nOfCasesDs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.dsIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesDs += nOfPatients*cyclesRound;
					if(methSet[cnt].ds && methSet[cnt].ds!='') {
						State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
					}
				}
			}
		}
//console.log('Group:'+groupBudget);
//console.log(State.drugBuf);
	return groupBudget;
	}


/////////////////////////////

	getCustomKSGSet(custCode, stType) {

		const ksgSet = {
			'ac4_d4': {// sh058 - 4 цикла ->  (sh642) sh0705 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.041',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.066',
						cyc: 4
					}
				]
			},
			'ac4_p12': {// sh058 - 4 цикла ->  (sh502) sh0700 - 12 циклов
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.037',
						cyc: 12
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.062',
						cyc: 12
					}
				]
			},
			'ac4_p12t': {// sh058 - 4 цикла ->  sh149 - 12 циклов
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.040',
						cyc: 12
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.064',
						cyc: 12
					}
				]
			},
			'ac4_p4': {//sh058 - 4 цикла ->  sh671 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.039',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.064',
						cyc: 4
					}
				]
			},
			'ac4_p4t': {// sh058 - 4 цикла -> sh150 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.043',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.067',
						cyc: 4
					}
				]
			},
			'ac4_dtr4': {//sh058 - 4 цикла ->  sh069 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.044',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.069',
						cyc: 4
					}
				]
			}, 
			'ac4_ptr12': {// sh058 - 4 цикла ->  sh149 - 12 циклов
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.040',
						cyc: 12
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.064',
						cyc: 12
					}
				]
			}, 
			'ac4_ptr4': {// sh058 - 4 цикла -> sh150 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.043',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.067',
						cyc: 4
					}
				]
			}, 
			'ac4_dtrp4': {// sh058 - 4 цикла ->  sh070 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.048',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.072',
						cyc: 4
					}
				]
			},
			'058_577': {// sh058 - 4 цикла ->  sh577.1 - 4 цикла
				ds: [
					{
						code: 'ds19.037',
						cyc: 4
					},
					{
						code: 'ds19.043',
						cyc: 4
					}
				],
				st: [
					{
						code: 'st19.062',
						cyc: 4
					},
					{
						code: 'st19.068',
						cyc: 4
					}
				]
			},
			'583_661': {//
				ds: [
					{
						code: 'ds19.047',
						cyc: 4
					},
					{
						code: 'ds19.047',
						cyc: 11
					}
				],
				st: [
					{
						code: 'st19.071',
						cyc: 4
					},
					{
						code: 'st19.071',
						cyc: 11
					}
				]
			},
			'593_594': {//
				ds: [
					{
						code: 'ds19.048',
						cyc: 4
					},
					{
						code: 'ds19.048',
						cyc: 8
					}
				],
				st: [
					{
						code: 'st19.072',
						cyc: 4
					},
					{
						code: 'st19.072',
						cyc: 8
					}
				]
			},
			'595_597': {//
				ds: [
					{
						code: 'ds19.032',
						cyc: 4
					},
					{
						code: 'ds19.032',
						cyc: 6
					}
				],
				st: [
					{
						code: 'st19.058',
						cyc: 4
					},
					{
						code: 'st19.058',
						cyc: 6
					}
				]
			},
			'596_597': {//
				ds: [
					{
						code: 'ds19.049',
						cyc: 4
					},
					{
						code: 'ds19.049',
						cyc: 6
					}
				],
				st: [
					{
						code: 'st19.073',
						cyc: 4
					},
					{
						code: 'st19.073',
						cyc: 6
					}
				]
			},
			'497_011': {//
				ds: [
					{
						code: 'ds19.044',
						cyc: 4
					},
					{
						code: 'ds19.043',
						cyc: 5
					}
				],
				st: [
					{
						code: 'st19.068',
						cyc: 4
					},
					{
						code: 'st19.067',
						cyc: 5
					}
				]
			},
			'604_661': {//
				ds: [
					{
						code: 'ds19.049',
						cyc: 4
					},
					{
						code: 'ds19.047',
						cyc: 20
					}
				],
				st: [
					{
						code: 'st19.074',
						cyc: 4
					},
					{
						code: 'st19.071',
						cyc: 20
					}
				]
			},
			'954_504': {//
				ds: [
					{
						code: 'ds19.048',
						cyc: 4
					},
					{
						code: 'ds19.048',
						cyc: 6
					}
				],
				st: [
					{
						code: 'st19.073',
						cyc: 4
					},
					{
						code: 'st19.072',
						cyc: 6
					}
				]
			},
			'962_661': {//
				ds: [
					{
						code: 'ds19.048',
						cyc: 4
					},
					{
						code: 'ds19.047',
						cyc: 20
					}
				],
				st: [
					{
						code: 'st19.072',
						cyc: 4
					},
					{
						code: 'st19.071',
						cyc: 20
					}
				]
			}
		};

		let data = ksgSet[custCode][stType];
		return data;
	}

/////////////////////////////

	calcOneRmzGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice, cyclesRound, 
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

		const methSet = rmzData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==24) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

				// Year budget for one drug for group
//				mgTotal = cycleDose*nOfCycles[currDrugN]*nOfPatients; // !!!!UPDATE
// if(mgTotal>0)
// if(nOfPatients>0)
// 	 console.log(currDrug.name+' cycleDose='+cycleDose+' nOfCycles='+nOfCycles+' mg='+mgTotal);
// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

// if(nOfPatients>0) console.log(drugDose+'>>'+drugPack);
				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
// console.log('Drug: '+ currDrug.name + ':' +drugBudget);
//console.log(drugIndex);
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
//console.log('Method:'+methodBudget);
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				if(methSet[cnt].custKsg) {
					let custSet = this.getCustomKSGSet(methSet[cnt].custKsg, 'st');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.stIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc; // Округление не делаем, т.к. в этой модели нет неценых циклов
						State.nOfCasesKs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.stIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesKs += nOfPatients*cyclesRound;
					if(methSet[cnt].st && methSet[cnt].st!='') {
						State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
					}
				}
			}
			if(chan=='omsd') {		
				if(methSet[cnt].custKsg) {
					let custSet = this.getCustomKSGSet(methSet[cnt].custKsg, 'ds');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.dsIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc;
						State.nOfCasesDs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.dsIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesDs += nOfPatients*cyclesRound;
					if(methSet[cnt].ds && methSet[cnt].ds!='') {
						State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
					}
				}
			}
		}
//console.log('Group:'+groupBudget);
//console.log(State.drugBuf);
	return groupBudget;
	}


/////////////////////////////

	calcOneRlGroup(st, chan) {

		let cnt, len, drugSet, doseSet, nOfDrugs, currDrugN, nOfPatients, mgTotal,
			cycleDose, currDrug, drugIndex, nOfCycles, currPrice,  cyclesRound,
			drugDose, drugBudget, methodBudget, groupBudget, drugPack, nOfPacks;

		const methSet = rlData[st].meth;
		groupBudget = 0;
		len = methSet.length;
		for(cnt=0 ; cnt<len ; cnt++) {
			drugSet = methSet[cnt].drugs;
			doseSet = methSet[cnt].dose;
			nOfDrugs = drugSet.length;
			nOfCycles = methSet[cnt].cycles; // N of infusions for year 
										// for current method of therapy
if(chan=='oms') nOfPatients = methSet[cnt].oms;
else if(chan=='omsd') nOfPatients = methSet[cnt].omsd;
else if(chan=='rlo') nOfPatients = methSet[cnt].rlo;
else if(chan=='onls') nOfPatients = methSet[cnt].onls;
else nOfPatients = methSet[cnt].pats;
			methodBudget = 0;							
//console.log(State.drugBuf);
			for(currDrugN=0 ; currDrugN<nOfDrugs ; currDrugN++) {
				drugIndex = drugSet[currDrugN];
				cycleDose = doseSet[currDrugN];
// console.log(drugIndex);
// if(drugIndex==24) console.log(drugData);
				currDrug = drugData[drugIndex];

				currPrice = currDrug.price; // Price of one pack
				drugDose = currDrug.dose;	// Dose of one tablet or flask
				drugPack = currDrug.set;		// N of items in pack

				// Year budget for one drug for group
//!!TODO Временная заглушка для маленьких групп распределения, когда
//		nOfPatients некорректно расчитывается
				if(isNaN(nOfPatients)) continue; 
//				mgTotal = cycleDose*nOfCycles[currDrugN]*nOfPatients;
// if(mgTotal>0)
// if(nOfPatients>0)
// 	 console.log(currDrug.name+' cycleDose='+cycleDose+' nOfCycles='+nOfCycles+' mg='+mgTotal);
// Вариант округляющий упаковки до курса для таблеток и до цикла для флаконов
if(currDrug.tab) { // Таблетированная форма
	nOfPacks = Math.ceil(cycleDose/drugDose); // Таблеток на цикл
	nOfPacks = Math.ceil(nOfPacks*nOfCycles[currDrugN]/drugPack); // Пачек на курс
	nOfPacks = nOfPacks*nOfPatients // Пачек на курс на всех
}
else {
	nOfPacks = Math.ceil(cycleDose/drugDose/drugPack); // Упаковок на цикл
	nOfPacks = nOfPatients*Math.ceil(nOfPacks*nOfCycles[currDrugN]);
}
	mgTotal = Math.round(nOfPacks*drugDose*drugPack); 

// if(nOfPatients>0) console.log(drugDose+'>>'+drugPack);
				nOfPacks = Math.ceil(nOfPacks);
				drugBudget = nOfPacks*currPrice;
// console.log('Drug: '+ currDrug.name + ':' +drugBudget);
//console.log(drugIndex);
				State.drugBuf[drugIndex].summ += drugBudget;
				State.drugBuf[drugIndex].packs += nOfPacks;
				State.drugBuf[drugIndex].mg += mgTotal;
				methodBudget += drugBudget;
				if(chan=='oms') {		
					State.omsBuf[drugIndex].summ += drugBudget;
					State.omsBuf[drugIndex].packs += nOfPacks;
					State.omsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='omsd') {		
					State.omsdBuf[drugIndex].summ += drugBudget;
					State.omsdBuf[drugIndex].packs += nOfPacks;
					State.omsdBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='rlo') {
					State.rloBuf[drugIndex].summ += drugBudget;
					State.rloBuf[drugIndex].packs += nOfPacks;
					State.rloBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}
				if(chan=='onls') {
					State.onlsBuf[drugIndex].summ += drugBudget;
					State.onlsBuf[drugIndex].packs += nOfPacks;
					State.onlsBuf[drugIndex].mg += mgTotal;
					State.chanBudgets[chan] += drugBudget;
				}	
			}
//console.log('Method:'+methodBudget);
			groupBudget += methodBudget;	
			if(chan=='oms') {		
				if(methSet[cnt].custKsg) {
					let custSet = State.calc.getCustomKSGSet(methSet[cnt].custKsg, 'st');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.stIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc; // Округление не делаем, т.к. в этой модели нет неценых циклов
						State.nOfCasesKs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.stIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].st, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesKs += nOfPatients*cyclesRound;
					if(methSet[cnt].st && methSet[cnt].st!='') {
						State.ksgBuf[methSet[cnt].st] += nOfPatients*cyclesRound;
					}
				}
//console.log(State.stIncome+'->'+methSet[cnt].st+methSet[cnt].name)
			}
			if(chan=='omsd') {		
				if(methSet[cnt].custKsg) {
					let custSet = State.calc.getCustomKSGSet(methSet[cnt].custKsg, 'ds');
					let custCnt, custLen = custSet.length;
					for(custCnt=0 ; custCnt<custLen ; custCnt++) {
						State.dsIncome += nOfPatients*
							State.distPanel.calcMethodIncome(custSet[custCnt].code, custSet[custCnt].cyc); 	
						cyclesRound = custSet[custCnt].cyc;
						State.nOfCasesDs += nOfPatients*cyclesRound;
						State.ksgBuf[custSet[custCnt].code] += nOfPatients*cyclesRound;
					}
				} else {
					State.dsIncome += nOfPatients*
						State.distPanel.calcMethodIncome(methSet[cnt].ds, methSet[cnt].cycles[0]); 	
					cyclesRound = Math.ceil(methSet[cnt].cycles[0]);
					State.nOfCasesDs += nOfPatients*cyclesRound;
					if(methSet[cnt].ds && methSet[cnt].ds!='') {
						State.ksgBuf[methSet[cnt].ds] += nOfPatients*cyclesRound;
					}
				}
			}
		}
//console.log('Group:'+groupBudget);
//console.log(State.drugBuf);
	return groupBudget;
	}


/////////////////////////////

	resetKsgBufDs() {

		State.ksgBuf['ds19.037'] = 0;
		State.ksgBuf['ds19.038'] = 0;
		State.ksgBuf['ds19.039'] = 0;
		State.ksgBuf['ds19.040'] = 0;
		State.ksgBuf['ds19.041'] = 0;
		State.ksgBuf['ds19.042'] = 0;
		State.ksgBuf['ds19.043'] = 0;
		State.ksgBuf['ds19.044'] = 0;
		State.ksgBuf['ds19.045'] = 0;
		State.ksgBuf['ds19.046'] = 0;
		State.ksgBuf['ds19.047'] = 0;
		State.ksgBuf['ds19.048'] = 0;
		State.ksgBuf['ds19.049'] = 0;

	}

/////////////////////////////

	resetKsgBufKs() {

		State.ksgBuf['st19.062'] = 0;
		State.ksgBuf['st19.063'] = 0;
		State.ksgBuf['st19.064'] = 0;
		State.ksgBuf['st19.065'] = 0;
		State.ksgBuf['st19.066'] = 0;
		State.ksgBuf['st19.067'] = 0;
		State.ksgBuf['st19.068'] = 0;
		State.ksgBuf['st19.069'] = 0;
		State.ksgBuf['st19.070'] = 0;
		State.ksgBuf['st19.071'] = 0;
		State.ksgBuf['st19.072'] = 0;
		State.ksgBuf['st19.073'] = 0;
		State.ksgBuf['st19.074'] = 0;
	}

/////////////////////////////

	resetDataBufs() {

		const len = drugData.length;

		State.nOfCasesKs = 0;
		State.nOfCasesDs = 0;
		State.drugBuf = [];
		State.omsBuf = [];
		State.omsdBuf = [];
		State.rloBuf = [];
		State.onlsBuf = [];
		State.chanBudgets['oms'] = 0;
		State.chanBudgets['omsd'] = 0;
		State.chanBudgets['rlo'] = 0;
		State.chanBudgets['onls'] = 0;

		for(let cnt=0 ; cnt<len ; cnt++) {
			State.drugBuf[cnt] = {};
			State.drugBuf[cnt].summ = 0;
			State.drugBuf[cnt].packs = 0;
			State.drugBuf[cnt].mg = 0;

			State.omsBuf[cnt] = {};
			State.omsBuf[cnt].summ = 0;
			State.omsBuf[cnt].packs = 0;
			State.omsBuf[cnt].mg = 0;

			State.omsdBuf[cnt] = {};
			State.omsdBuf[cnt].summ = 0;
			State.omsdBuf[cnt].packs = 0;
			State.omsdBuf[cnt].mg = 0;

			State.rloBuf[cnt] = {};
			State.rloBuf[cnt].summ = 0;
			State.rloBuf[cnt].packs = 0;
			State.rloBuf[cnt].mg = 0;

			State.onlsBuf[cnt] = {};
			State.onlsBuf[cnt].summ = 0;
			State.onlsBuf[cnt].packs = 0;
			State.onlsBuf[cnt].mg = 0;
		}
	}


/////////////////////////////


	calcOnePath(path, chan) {

		let pathBudget = 0,
			pathData, currGroup, cnt, lim, pathNum=-1;

        for(cnt=0, lim=State.pathKeys.length ; cnt<lim ; cnt++) {
        	if(path==State.pathKeys[cnt]) {
        		pathNum = cnt;
        		break;
        	}
        }
        if(State.selPath[pathNum] != 1) return 0

		if(path=='rmz') pathData = rmzData;
		else if(path=='rl') pathData = rlData;
		else if(path=='rya') pathData = ryaData;
		else if(path=='rshm') pathData = rshmData;
		else if(path=='rp') pathData = rpData;
		else if(path=='rz') pathData = rzData;
		else if(path=='rmp') pathData = rmpData;
		else if(path=='rpsh') pathData = rpshData;
		else if(path=='rpz') pathData = rpzData;
		else if(path=='rtm') pathData = rtmData;
		else if(path=='gcr') pathData = gcrData;
		else if(path=='rpsz') pathData = rpszData;
		else if(path=='neo') pathData = neoData;
		else pathData = methData[path];

		for(currGroup in pathData) {
 			if(chan) pathBudget += this.calcOneGroup(path, currGroup, chan);
			else pathBudget += this.calcOneGroup(path, currGroup);
		}	
//console.log('Path:'+path+'>'+pathBudget);
		if(chan) ;
		else State.pathBudgets[path] = this.padDigits(Math.floor(pathBudget));			
// if(chan=='oms')
// 	console.log(path+'->'+State.stIncome);
		return pathBudget;
	}

/////////////////////////////


	calcFullBudget(chan) {

		let fullBadget = 0,
			currPath;
//console.log(State.drugBuf);
// 
		if(chan=='oms') {
			State.stIncome = 0; // Reset income accumulator
			State.nOfCasesKs = 0;
			this.resetKsgBufKs();
		} 
		if(chan=='omsd') {
			State.dsIncome = 0; // Reset income accumulator
			State.nOfCasesDs = 0;
			this.resetKsgBufDs();
		} 
//		for(let i=0, len=State.pathKeys.length ; i<len ; i++) {
		for(let i=0, len=State.selPathKeys.length ; i<len ; i++) {
			currPath = State.selPathKeys[i];
			if(chan) fullBadget += this.calcOnePath(currPath, chan);
			else fullBadget += this.calcOnePath(currPath);
		}	
//console.log('FullBudget:'+fullBadget);
		return fullBadget;
	}

/////////////////////////////

	findMax(buf) {

		let len = buf.length, i, max=buf[0], maxInd=0;

		for(i=0 ; i<len ; i++) {
			if(buf[i]>max) {
				max = buf[i];
				maxInd = i;
			}
		}
		return maxInd;
	}

	findMin(buf) {

		let len = buf.length, i, min=buf[0], minInd=0;

		for(i=0 ; i<len ; i++) {
			if(buf[i]<min) {
				min = buf[i];
				minInd = i;
			}
		}
		return minInd;
	}

/////////////////////////////
// input - число пациентов, которое необходимо распределить
// probBuf - ссылка на массив процентов распределения
// patByf - ссылка на массив распределенных пациентов

// Версия с обратным проходом от маленьких вероятностей к большим
// из модуля РП

	disributePats(input, probBuf, patBuf) {

		let len = probBuf.length, i, minInd, rest, pats, data;
		let probList = []; // Массив индексов элементов из probBuf, отсортированных
							// по возрастанию
		let buf = [];					
		for(i=0 ; i<len ; i++) {
			buf.push(probBuf[i]);
		}
		for(i=0 ; i<len ; i++) {
			minInd = State.calc.findMin(buf);
			probList.push(minInd);
			buf[minInd] = 200;
		}

// В probList теперь указатели на элементы массива вероятностей probBuf в возрастающем
// порядке
// Отдаем приоритет позициям с меньшей вероятностью!!
		rest = input ; // Нераспределенный остаток
		for(i=0 ; i<len ; i++) {
			data = input*probBuf[probList[i]]/100;
			pats = Math.round(data);
			if(pats>rest) pats = rest;
			patBuf[probList[i]] = pats;
			rest -= pats;
			if(rest<0) rest = 0;
		}
		if(rest>0) patBuf[probList[len-1]] += rest;
	}
 


// 	disributePats(input, probBuf, patBuf) {

// 		let len = probBuf.length, i, maxInd, rest, pats, data;
// 		let probList = []; // Массив индексов элементов из probBuf, отсортированных
// 							// по убыванию
// 		let buf = [];					
// 		for(i=0 ; i<len ; i++) {
// 			buf.push(probBuf[i]);
// 		}
// 		for(i=0 ; i<len ; i++) {
// 			maxInd = this.findMax(buf);
// 			probList.push(maxInd);
// 			buf[maxInd] = -1;
// 		}

// // В probList теперь указатели на элементы массива вероятностей probBuf в убывающем
// // порядке
// // Отдаем приоритет позициям с большей вероятностью!!
// 		rest = input ; // Нераспределенный остаток
// //console.log('==============');
// //!! EXPERIMENTAL calculation of bias constant
// 		let bias = 0, delta, bias2 = 0;
// 		for(i=0 ; i<len ; i++) {
// 			data = input*probBuf[probList[i]]/100;
// 			pats = Math.round(data);
// 			delta = (pats - data);
// 			if(delta > 0) delta = 0.5 - delta;
// 			else delta = -0.5 + delta;
// 			bias += delta;
// 		}

// 		bias = bias/len;


// 		for(i=0 ; i<len ; i++) {
// //	V1		pats = Math.ceil(input*probBuf[probList[i]]/100);
// //	V2		pats = Math.round(input*probBuf[probList[i]]/100);
// //!! EXPERIMENTAL distribution (simple mode with bias constant of 0.1):
// //			data = input*probBuf[probList[i]]/100 - 0.1;
// 			data = input*probBuf[probList[i]]/100 - bias;
// 			pats = Math.round(data);

// 			if(pats>rest) pats = rest;
// 			patBuf[probList[i]] = pats;
// 			rest -= pats;
// 			if(rest<0) rest = 0;
// 		}
// 		if(rest>0) patBuf[probList[0]] += rest;
// 	}

 
/////////////////////////////
// Распределяем пациентов патологий по стадиям и заносим результат 
// в массив State.stPats
// Если в списке стадий есть чистые, без подгрупп - рендерим их в панель


	distributeSt() {

		const probs = State.stProbs;
		let pats = State.stPats;
		let currPath, i, str, probSet, patSet, currSt, len, totPats;

		len = probs.length;

		for(currPath=0 ; currPath<len ; currPath++) {
			probSet = probs[currPath];
			patSet = pats[currPath];
			totPats = State.pathTotals[currPath];
			this.disributePats(totPats, probSet, patSet);
		}

// Заполняем чистые стадии в дереве методов

		for(currPath=0 ; currPath<len ; currPath++) {
			patSet = pats[currPath];
			currSt = methData[State.pathKeys[currPath]];
			for(i=2 ; i<=4 ; i++) {
				str = 'st'+i;
				if(currSt[str]) currSt[str].pats = patSet[i-1];
			}
		}

	}


/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам

	distributeMeth(path, group) {

		let pathData;

		if(State.flNoDist) return; // Отладочный режим
		if(path=='rmz') {
			this.distributeRmzMeth(path, group);
			return;
		} else if(path=='rl') {
			this.distributeRlMeth(path, group);
			return;
		}
		else pathData = methData[path];
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			probBuf.push(methSet[i].rate);
			patBuf.push(0);
		}

		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}


	}


/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам для РМЖ

	distributeRmzMeth(path, group) {

		let pathData, prob;

		pathData = rmzData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			prob = methSet[i].pats/total*100;
			probBuf.push(prob);
			patBuf.push(0);

		}
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}


	}


/////////////////////////////
// Распределяем КРР 4ст между резектабельными, потенциальными и нерезектабельными 

	distribResect() {

		let afterResection, nonResectable1, nonReverted1, i, data, progressResect2,
			line2nonresect, line2Summ,
			line3nonresect, line3Summ, progressResect3,
			line4nonresect, line4Summ, progressResect4,
			progressPot2, progressPot3, progressPot4,
			resectFail2, resectFail3, resectFail4,
			stData;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 

		let krrData = methData['krr'], currGroup;

		let prob = State.resectProb;
		let pats = State.resectPats;
		let total = State.stPats[0][3]; // Всего в 4-й стадии
//		let stPats = [15, 20, 40, 25];
		let stPats = State.stPats[0];
		let recidProb = [10, 20, 35, 100]; // Вероятность наступления рецидива по стадиям
		let recidPat = [];
		let initProb = [15,20,40,25]; // Общее распределение по стадиям
//	К этому моменту в массиве stPats уже содержатся количества пациентов
// в стадиях, требующих химиотерапии. Для получения массива рецидивов
// необходимо получить распеределение по стадиям общего кол-ва пациентов 



//		let chemioProb = [0,40,90,100]; // Какой процент в стадии потребует химиотерапии
// Из начального распределения по стадиям получаем массив рецидивов
// и массив распределений, потребовавших химиотерапию
		let initPats = [], krrTotal = State.pathTotals[0];;
		this.disributePats(krrTotal, initProb, initPats);

		for(i=0 ; i<4 ; i++) {
			recidPat[i] = initPats[i]*recidProb[i]/100.0;
		}

		this.disributePats(total, prob, pats);

		methData['krr']['st4_1_1'].pats = pats[0];
		methData['krr']['st4_1_2'].pats = pats[1];
// Коррекция по строкам 50-52 таблицы эпидемиология
		afterResection = Math.ceil(pats[1]*0.25*0.9);
		methData['krr']['st4_1_3'].pats = afterResection;
// Считаем нерезектабельных, попавших в 1-ю линию
// (коррекция по С58 эпидемиологии)
		nonResectable1 = Math.ceil(pats[2]*0.9);				
		methData['krr']['st4_1_4'].pats = nonResectable1;
		nonReverted1 = Math.ceil(pats[1]*0.75*0.5); // Потенциально-резектабельные, но не ставшие 
		 								// и нуждающиеся с ХТ
		methData['krr']['st4_1_5'].pats = nonReverted1;
// НЕ Собираем число пациентов в объединенных группах (алгоритм отменен!!!)
		line2nonresect = nonResectable1*0.7; 
		for(i=0 ; i<4 ; i++) line2nonresect += recidPat[i];
		line3nonresect = line2nonresect*0.5;
		line4nonresect = line3nonresect*0.3;
		methData['krr']['st4_2_1'].pats = Math.ceil(line2nonresect);
		methData['krr']['st4_3_1'].pats = Math.ceil(line3nonresect);
		methData['krr']['st4_4_1'].pats = Math.ceil(line4nonresect);
// Отказываемся от варианта с объединением групп
		// line2Summ = line2nonresect;
		// line3Summ = line3nonresect;
		// line4Summ = line4nonresect;
// Добавляем прогресирование б-ых с исходно резект после операции		
		progressResect2 = pats[0]*0.5*0.9;
		progressResect3 = progressResect2*0.7;
		progressResect4 = progressResect3*0.5;
		methData['krr']['st4_2_2'].pats = Math.ceil(progressResect2);
		methData['krr']['st4_3_2'].pats = Math.ceil(progressResect3);
		methData['krr']['st4_4_2'].pats = Math.ceil(progressResect4);
		// line2Summ += progressResect2;
		// line3Summ += progressResect3;
		// line4Summ += progressResect4;
// Добавляем прогресирование б-ых с потенц. резект. после операции
		
		let operated = pats[1]*0.25; // Сколько потенциальных прооперировано
		progressPot2 = operated*0.7*0.9; // Из них попало в 1-ю линию
		progressPot3 = progressPot2*0.7;
		progressPot4 = progressPot3*0.5;
		methData['krr']['st4_2_3'].pats = Math.ceil(progressPot2);
		methData['krr']['st4_3_3'].pats = Math.ceil(progressPot3);
		methData['krr']['st4_4_3'].pats = Math.ceil(progressPot4);
		// line2Summ += progressPot2;
		// line3Summ += progressPot3;
		// line4Summ += progressPot4;

// Добавляем потенциально-резектабельные, получавшие ХТ, но не ставших резектабельными

		resectFail2 = pats[1]*0.75*0.5*0.7;
		resectFail3 = resectFail2*0.5;
		resectFail4 = resectFail3*0.3;
		methData['krr']['st4_2_4'].pats = Math.ceil(resectFail2);
		methData['krr']['st4_3_4'].pats = Math.ceil(resectFail3);
		methData['krr']['st4_4_4'].pats = Math.ceil(resectFail4);
		// line2Summ += resectFail2;
		// line3Summ += resectFail3;
		// line4Summ += resectFail4;

		// line2Summ = Math.round(line2Summ);
		// line3Summ = Math.round(line3Summ);
		// line4Summ = Math.round(line4Summ);

		// methData['krr']['st4_2_1'].pats = line2Summ;
		// methData['krr']['st4_3_1'].pats = line3Summ;
		// methData['krr']['st4_4_1'].pats = line4Summ;

		pathSumm = methData['krr']['st1'].pats +
				   methData['krr']['st2'].pats +	
				   methData['krr']['st3'].pats +	
				   methData['krr']['st4_1_1'].pats +	
				   methData['krr']['st4_1_2'].pats +	
				   methData['krr']['st4_1_3'].pats +	
				   methData['krr']['st4_1_4'].pats +	
				   methData['krr']['st4_1_5'].pats +	
				   methData['krr']['st4_2_1'].pats +
				   methData['krr']['st4_2_2'].pats +
				   methData['krr']['st4_2_3'].pats +
				   methData['krr']['st4_2_4'].pats +
				   methData['krr']['st4_3_1'].pats +
				   methData['krr']['st4_3_2'].pats +
				   methData['krr']['st4_3_3'].pats +
				   methData['krr']['st4_3_4'].pats +
				   methData['krr']['st4_4_1'].pats +
				   methData['krr']['st4_4_2'].pats +
				   methData['krr']['st4_4_3'].pats +
				   methData['krr']['st4_4_4'].pats;
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[0] = pathSumm;

		for(currGroup in krrData) krrData[currGroup].result = -1;
	}

/////////////////////////////
// Распределяем общее число пациентов КРР по методам для всех групп
//

	distributeMethKRR() {

		this.distributeMeth('krr','st2');
		this.distributeMeth('krr','st3');
//		this.distribResect();
		this.distributeMeth('krr','st4_1_1');
		this.distributeMeth('krr','st4_1_2');
		this.distributeMeth('krr','st4_1_3');
		this.distributeMeth('krr','st4_1_4');
		this.distributeMeth('krr','st4_1_5');
		this.distributeMeth('krr','st4_2_1');
		this.distributeMeth('krr','st4_2_2');
		this.distributeMeth('krr','st4_2_3');
		this.distributeMeth('krr','st4_2_4');
		this.distributeMeth('krr','st4_3_1');
		this.distributeMeth('krr','st4_3_2');
		this.distributeMeth('krr','st4_3_3');
		this.distributeMeth('krr','st4_3_4');
		this.distributeMeth('krr','st4_4_1');
		this.distributeMeth('krr','st4_4_2');
		this.distributeMeth('krr','st4_4_3');
		this.distributeMeth('krr','st4_4_4');
	}
		
/////////////////////////////
// Суммируем для меланомы всех пациентов для данной стадии и группы
// Стадия вычисляется из входящей строки группы

	summMelGroupPats(group) {

		let st = group.slice(2,3); st = parseInt(st);
		let total = State.stPats[1][st-1];
		let methSet = methData['melanoma'][group].meth;
		let summ = 0, len, i, pats;

		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			pats = total*methSet[i].rate/100.0;
			summ += pats;
		}
		return summ;
	}

/////////////////////////////
// Распределяем общее число пациентов МЕЛ по методам для всех групп
//

	distributeMethMel() {

// Для каждой группы пробегаемся по списку методов и считаем сумму пациентов
		let pathData = methData['melanoma'];
		let currGroup, currSumm,i,customVal;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in pathData) {
			currSumm = this.summMelGroupPats(currGroup);
// Костыль, восстанавливающий коррекцию, введенную для группы st4_2, чтобы вывести ее на 100%
if(currGroup=='st4_1') {
	currSumm /= 0.65;
}
			currSumm = Math.ceil(currSumm);
// Заносим сумму пациентов в данные группы
			if(!State.flNoDist)	pathData[currGroup].pats = currSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth('melanoma',currGroup);
		}
		for(currGroup in pathData) {
			if(pathData[currGroup].result==-1) pathSumm += pathData[currGroup].pats;
			else pathSumm += pathData[currGroup].result;
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[1] = pathSumm;
	}



/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// для меланомы

	distributeStMel() {

		let stProbs = [34.9, 46, 9.3, 7.4];
		let melData = methData['melanoma'], currGroup;
		let stPats = State.stPats[1], total = State.pathTotals[1],
			st3Mult = 0.5,//1-летняя ВБП меланома, стадия 2
			st4Mult = 0.5,//1-летняя ОВ меланома, стадия 4
			dead = State.currLeth['mel'], i;
			stProbs = melStData[State.currRegNum];
			for(i=0 ; i<4 ; i++) {
				stPats[i] = total*stProbs[i]/100.0;
			}

			stPats[2] = stPats[2] + stPats[1]*st3Mult;
			stPats[3] = dead + stPats[3]*st4Mult;
			// Берем только химиотерапию: (вероятности тоже скорректированы на 65%, 
			// т.е. приведены к 100%)
// Коррекция для группы st4_2, чтобы вывести ее на 100%
			stPats[3] *= 0.65;
			for(i=0 ; i<4 ; i++) {
				stPats[i] = Math.ceil(stPats[i]);
			}
		for(currGroup in melData) melData[currGroup].result = -1;
		this.distributeMethMel();
	}


/////////////////////////////
// Распределяем общее число пациентов РМЖ по методам для всех групп
//

	distributeMethRMZ() {

		let i, currGroup, pats;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in rmzData) {
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры
			if(State.rmzPatsBuf[currGroup]!=-1) {
				rmzData[currGroup].pats = State.rmzPatsBuf[currGroup]; 
			}

// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам больше 100%
// Суммируем пациентов внутри каждой группы

			let currGrData = rmzData[currGroup].meth;
			let rmzGrSumm = 0, len = currGrData.length, num;
			if(rmzData[currGroup].result!=-1 && State.rmzMultBuf[currGroup]) {
				pats = Math.round(rmzData[currGroup].result/State.rmzMultBuf[currGroup]);
			}
			else {
				pats = rmzData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num; 
				rmzGrSumm += num;
			}
			rmzGrSumm = Math.ceil(rmzGrSumm);
// Перезаписываем новое значение в группу
			rmzData[currGroup].pats = rmzGrSumm;
			pathSumm += rmzGrSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeMeth('rmz',currGroup);
// Сохраняем множитель распределения в буфере
			State.rmzMultBuf[currGroup] = rmzGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.rmzPatsBuf[currGroup] = pats;			
		}
// 		for(currGroup in rmzData) {
// 			if(rmzData[currGroup].result==-1) pathSumm += rmzData[currGroup].pats;
// 			else pathSumm += rmzData[currGroup].result/State.rmzMultBuf[currGroup];
// //			else pathSumm += rmzData[currGroup].result;
//		}	
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[2] = pathSumm;
	}

/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// для РМЖ

	distributeStRMZ() {

		let i, currGroup;

		let stProbs = [20, 45, 25, 10],
			  recid = [10, 20, 50];
		let stPats = State.stPats[2], total = State.pathTotals[2], 
			allRecid = 0;
		
			stProbs = rmzStData[State.currRegNum];
			for(i=0 ; i<4 ; i++) {
				stPats[i] = total*stProbs[i]/100.0;
			} 

			for(i=0 ; i<3 ; i++) {
				allRecid += stPats[i]*recid[i]/100.0;
			}

			stPats[3] = (stPats[3] + allRecid)*3

			for(i=0 ; i<4 ; i++) {
				stPats[i] = Math.ceil(stPats[i]);
			}

// Считаем распределения по классам терапии
		const neoAdXtProb =  [25,60,100];
		const neoAdHorProb = [70,70,70];
		const neoAdTarProb = [20,20,20];
		let neoAdXtPats = [], neoAdHorPats = [], neoAdTarPats = [];
// Общее кол-во пациентов в каждом классе терапии
		let neoAdXtTotal = 0, neoAdHorTotal = 0, neoAdTarTotal = 0;

		for(i=0 ; i<3 ; i++) {
			neoAdXtPats[i] = neoAdXtProb[i]*stPats[i]/100;
			neoAdHorPats[i] = neoAdHorProb[i]*stPats[i]/100;
			neoAdTarPats[i] = neoAdTarProb[i]*stPats[i]/100;
			neoAdXtTotal += neoAdXtPats[i];
			neoAdHorTotal += neoAdHorPats[i];
			neoAdTarTotal += neoAdTarPats[i];
		}

//нео/адъювантная химиотерапия, HER-2 негативный РМЖ
		rmzData['st0_0_1'].pats = Math.round(0.7*neoAdXtTotal);

//нео/адъювантная химиотерапия, HER-2 позитивный РМЖ
		rmzData['st0_0_2'].pats = Math.round(neoAdTarTotal);

//адъювантная таргетная терапия, HER-2 позитивный РМЖ
		rmzData['st0_0_3'].pats = Math.round(neoAdTarTotal);

//нео/адъювантная гормонотерапия, гормонпозитивный РМЖ
		rmzData['st0_0_4'].pats = Math.round(1.0*neoAdHorTotal);

//нео/адъювантная гормонотерапия, Овариальная супрессия
		rmzData['st0_0_5'].pats = Math.round(
			(stPats[0]+stPats[1]+stPats[2])*0.3*0.6*0.7
			);

//гормонотерапия при HER-2 негативном мРМЖ
		rmzData['st0_0_6'].pats = Math.round(0.5*stPats[3]);
		
//химиотерапии при HER-2 позитивном метастатическом РМЖ
		rmzData['st0_0_7'].pats = Math.round(0.2*stPats[3]);

//гормонотерапии при HER-2 позитивном метастатическом РМЖ
		rmzData['st0_0_8'].pats = Math.round(0.06*stPats[3]);

//химиотерапия при HER-2 негативном метастатическом РМЖ
		rmzData['st0_0_9'].pats = Math.round(0.4*stPats[3]);

		for(currGroup in rmzData) {
			rmzData[currGroup].result = -1;
			State.rmzPatsBuf[currGroup] = -1;
		}

		this.distributeMethRMZ();

	}

/////////////////////////////
// Распределяем кол-ва пациентов в группе по методам для РЛ, учитывая в параметре ratio, 
// насколько реальная сумма всех методов 
// меньше общего кол-ва в группе

	distributeRlMeth(group) {

		let pathData, prob;

 		if(State.flNoDist)	return;

// 		pathData = rlData;
// 		let methSet = pathData[group]['meth'];
// 		let len, i, res, total, probBuf=[], patBuf=[];
// // Формируем массив вероятностей
// 		total = pathData[group].pats;
// 		total = Math.round(total);
// 		len = methSet.length;
// 		for(i=0 ; i<len ; i++) {
// 			prob = methSet[i].rate;
// 			probBuf.push(prob*ratio);
// 			patBuf.push(0);
// 		}
// 		this.disributePats(total, probBuf, patBuf);
// 		for(i=0 ; i<len ; i++) {
// 			res = patBuf.splice(0,1);
// 			methSet[i].pats = res[0]; 
// 		}
		pathData = rlData;
		let methSet = pathData[group]['meth'];
		let len, i, res, total, probBuf=[], patBuf=[];
// Формируем массив вероятностей
		if(pathData[group].result!=-1) total = pathData[group].result;
		else total = pathData[group].pats;
		len = methSet.length;
		for(i=0 ; i<len ; i++) {
			prob = methSet[i].pats/total*100;
			probBuf.push(prob);
			patBuf.push(0);
		}
		this.disributePats(total, probBuf, patBuf);
		for(i=0 ; i<len ; i++) {
			res = patBuf.splice(0,1);
			methSet[i].pats = res[0]; 
		}
	}


/////////////////////////////
// Распределяем общее число пациентов РЛ по методам для всех групп
//

	distributeMethRL() {

// Модель с недостатком процентов
// 		let i, currGroup, pats;
// 		let stPats = State.stPats[3];
// 		for(currGroup in rlData) {
// // Для каждой группы нужно пересчитать общее количество пациентов,
// // исходя из того, что сумма процентов по методам не равна 100%
// // Суммируем пациентов внутри каждой группы
// 			let currGrData = rlData[currGroup].meth;
// 			let rlGrSumm = 0, len = currGrData.length, num, ratio;
// 			if(rlData[currGroup].result != -1) pats = rlData[currGroup].result;
// 			else pats = rlData[currGroup].pats;
// //if(currGroup=='st3_0_4') console.log(currGrData);
// 			for(i=0 ; i<len ; i++) {
// 				num = currGrData[i].rate*pats/100.0;
// 				if(!State.flNoDist)	currGrData[i].pats = num;
// 				rlGrSumm += num;
// 			}
// 			rlGrSumm = Math.floor(rlGrSumm);			
// 			if(rlGrSumm==0) ratio = 1;
// 			else ratio = pats/rlGrSumm;			
// // Перезаписываем новое значение в группу
// //!!D			
// 			rlGrSumm = Math.ceil(rlGrSumm);

// 			rlData[currGroup].pats = rlGrSumm;

// // Для каждой группы перераспределяем пациентов по методам
// 			this.distributeRlMeth(currGroup, ratio);
// // Сохраняем реальное кол-во пациентов для расчета нераспределенных 
// 			State.rlFreePatsBuf[currGroup] = rlData[currGroup].pats;
// // Восстанавливаем затертое начальное распределение 
// if(State.rlPatsBuf[currGroup])			
// 			rlData[currGroup].pats = State.rlPatsBuf[currGroup];
// 		}

// Модель с избытком процентов
		let i, currGroup, pats;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
		for(currGroup in rlData) {
// Восстанавливаем исходное распределение пациентов, нарушенное при предыдущем
// проходе процедуры
			if(State.rlPatsBuf[currGroup]!=-1) {
				rlData[currGroup].pats = State.rlPatsBuf[currGroup]; 
			}

// Для каждой группы нужно пересчитать общее количество пациентов,
// исходя из того, что сумма процентов по методам больше 100%
// Суммируем пациентов внутри каждой группы

			let currGrData = rlData[currGroup].meth;
			let rlGrSumm = 0, len = currGrData.length, num;
			if(rlData[currGroup].result!=-1 && State.rlMultBuf[currGroup]) {
				pats = Math.round(rlData[currGroup].result/State.rlMultBuf[currGroup]);
			}
			else {
				pats = rlData[currGroup].pats;
			} 
			for(i=0 ; i<len ; i++) {
				num = currGrData[i].rate*pats/100.0;
				if(!State.flNoDist)	currGrData[i].pats = num; 
				rlGrSumm += num;
			}
			rlGrSumm = Math.ceil(rlGrSumm);
// Перезаписываем новое значение в группу
			rlData[currGroup].pats = rlGrSumm;
			pathSumm += rlGrSumm;
// Для каждой группы перераспределяем пациентов по методам
			this.distributeRlMeth(currGroup);
// Сохраняем множитель распределения в буфере
			State.rlMultBuf[currGroup] = rlGrSumm/pats;
// Сохраняем начальное распределение в буфере
			State.rlPatsBuf[currGroup] = pats;		

		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[3] = pathSumm;
		}
	}


/////////////////////////////
// Распределяем общее число пациентов текущего региона по стадиям
// для РЛ

	distributeStRL() {

		let stProbs = [10, 21, 35, 34],
			  recid = [10, 40, 85];
		let stPats = State.stPats[3], total = State.pathTotals[3], 
			allRecid = 0, i, currGroup;
		let pathSumm = 0; // Накопитель для фактической суммы пациентов проходящих
							// терапию в течение года 
//total = 676;
			stProbs = rlStData[State.currRegNum];
			for(i=0 ; i<4 ; i++) {
				stPats[i] = total*stProbs[i]/100.0;
			} 

			for(i=0 ; i<3 ; i++) {
				allRecid += stPats[i]*recid[i]/100.0;
			}

			stPats[3] = (stPats[3] + allRecid); //????

			for(i=0 ; i<4 ; i++) {
				stPats[i] = Math.ceil(stPats[i]);
			}
		let st1MRL = stPats[0]*15/100.0;
		let st2MRL = stPats[1]*0.15*0.8;
		let st2NMRL = stPats[1]*0.85*(0.7*0.7+0.15);
		let st3MRL = stPats[2]*0.15*0.75;
		let st3NMRL = stPats[2]*0.85;// Оставить только 0.85?
		let st3NMRLneo = stPats[2]*0.85*0.24*0.40;
		let st3aduv = stPats[2]*0.85*0.24*0.60;
		let st3hlt = stPats[2]*0.85*0.56*0.65;

//		let incure = stPats[3]*35/100.0;
//		let st4line1 = stPats[3] - incure;
		let st4line1 = stPats[3];
		let st4line1MRL = st4line1*0.15*0.65;

		let st4line1NMRL = st4line1*0.85*0.70;
		let EGFRmut = st4line1NMRL*0.50*0.15;
		let ALKmut = st4line1NMRL*0.50*0.05;
		let ROSmut = st4line1NMRL*0.01;
		let BRAF = st4line1NMRL*0.01;

		let PKlet = st4line1NMRL - EGFRmut - ALKmut - ROSmut - BRAF; 
		let PDL1 = PKlet*0.30*0.30 + st3NMRL*0.10*0.56*0.35;
		let ITXT = PKlet*(0.30*0.70 + 0.70*0.30);
		let PKXT = PKlet*0.70*0.70 + st3NMRL*0.70*0.56*0.35;
		let st4line2 = st4line1*50/100.0; 
		let st4line2MRL = st4line1MRL*40/100.0;
//		let st4line2NMRL = st4line2 - st4line2*0.2;

		let ITXT2l = EGFRmut*0.80*0.15;
		let ITaftXT = PKXT*0.50;

		let XT2l_1 = EGFRmut*0.50*0.80*0.85 + ALKmut*0.80*0.80 + (ROSmut+BRAF)*0.80;
		let XT2l_2 = PDL1*0.50*0.80;
		let XT2l_3 = ITXT*0.80*0.50*0.80;
		let XT2l = XT2l_1 + XT2l_2 + XT2l_3;
//		let st4line2NMRL = EGFRmut*0.50*0.80*0.85 + ALKmut*0.80*0.20 + ITXT2l + ITaftXT + XT2l;
		let st4line2NMRL = EGFRmut*0.50*0.80*0.85 + ALKmut*0.80*0.20;

// 1-стадия МРЛ//
		rlData['st1_0_1'].pats = Math.round(st1MRL);
// 2-стадия МРЛ//
		rlData['st2_0_1'].pats = Math.round(st2MRL);
// 2-стадия НМРЛ//
		rlData['st2_0_2'].pats = Math.round(st2NMRL);
// 3-стадия МРЛ//
		rlData['st3_0_1'].pats = Math.round(st3MRL);
// НМРЛ неоадъювантная терапия//
		rlData['st3_0_2'].pats = Math.round(st3NMRLneo);
// НМРЛ адъювантная терапия//
		rlData['st3_0_3'].pats = Math.round(st3aduv);
// НМРЛ ХЛТ//
		rlData['st3_0_4'].pats = Math.round(st3hlt);
// 4-стадия 1 линия МРЛ//
		rlData['st4_1_1'].pats = Math.round(st4line1MRL);
// 4-стадия 1 линия НМРЛ
//		rlData['st4_1_2'].pats = Math.round(EGFRmut+ALKmut+ROSmut+BRAF+PDL1+ITXT+PKXT);

// НМРЛ AK + EGFRmut//
		rlData['st4_1_2'].pats = Math.round(EGFRmut);
// НМРЛ AK + ALKmut//
		rlData['st4_1_3'].pats = Math.round(ALKmut);
// НМРЛ ROS mut//
		rlData['st4_1_4'].pats = Math.round(ROSmut);
//НМРЛ BRAF//
		rlData['st4_1_5'].pats = Math.round(BRAF);

// Плоская клетка + АК без мутаций, PDL-1 >50%	//
		rlData['st4_1_6'].pats = Math.round(PDL1);

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> НМРЛ
// Плоская клетка + АК без мутаций, ИТ+ХТ //
		rlData['st4_1_7'].pats = Math.round(ITXT+PKXT);
// Плоская клетка + АК без мутаций, ХТ //
//		rlData['st4_1_8'].pats = Math.round(PKXT);

// 4-стадия 2 линия МРЛ //
		rlData['st4_2_1'].pats = Math.round(st4line2MRL);

// 4-стадия 2 линия НМРЛ 
//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> НМРЛ c драйверными мутациями

		rlData['st4_2_2'].pats = Math.round(st4line2NMRL);

//>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> НМРЛ
// 4-стадия 2 линия НМРЛ ИТ+ХТ
		rlData['st4_2_3'].pats = Math.round(ITXT2l+ITaftXT+XT2l);
// 4-стадия 2 линия НМРЛ ИТ (после ХТ)		
//		rlData['st4_2_4'].pats = Math.round(ITaftXT);
// 4-стадия 2 линия НМРЛ ХТ
//		rlData['st4_2_5'].pats = Math.round(XT2l);

		for(currGroup in rlData) {
// Для каждой группы нужно расчитать и сохранить в State.rlPatsBuf общее количество пациентов,
// т.к. после ручного ввода и перераспределения, исходные значения буду перезаписаны

			State.rlPatsBuf[currGroup] = rlData[currGroup].pats;
			rlData[currGroup].result = -1;
			pathSumm += rlData[currGroup].pats;
		}
		// Сохраняем полное фактическое кол-во пациентов в хранилище
		State.pathSumm[3] = pathSumm;
		this.distributeMethRL();
//console.log(rlData);
	}

/////////////////////////////
// Рендерим все индикаторы общего числа пациентов

	updateTotalPatientViews() {

		let i, len, patSumm = 0, totPatSumm = 0;

		len = State.pathTotals.length;

		for(i=0 ; i<len ; i++) {
			patSumm += State.pathTotals[i]*State.selPath[i];
			totPatSumm += State.pathSumm[i]*State.selPath[i];
		}
		State.totPatSumm = totPatSumm;
		if(patSumm<0) {
			$('#scr0-pats-inp').val(0);	
			$('#scr0-pats-txt').text(0);
		} else {
			$('#scr0-pats-inp').val(patSumm);	
			$('#scr0-pats-txt').text(patSumm);
		}
		if(totPatSumm<0) {
			$('.total-patients').text('---');
		} else {
			$('.total-patients').text(this.padDigits(totPatSumm));
		}
	}


/////////////////////////////

	distributePath(path) {

		if(path=='krr') {
			this.distributeSt();
			this.distribResect();
			this.distributeMethKRR();
		} 
		if(path=='melanoma') this.distributeStMel(); 
		if(path=='rmz') this.distributeStRMZ(); 
		if(path=='rl') this.distributeStRL(); 
		if(path=='rya') State.ryaModel.distributeSt(); 
		if(path=='rshm') State.rshmModel.distributeSt(); 
		if(path=='rp') State.rpModel.distributeSt(); 
		if(path=='rz') State.rzModel.distributeSt(); 
		if(path=='rmp') State.rmpModel.distributeSt(); 
		if(path=='rpsh') State.rpshModel.distributeSt(); 
		if(path=='rpz') State.rpzModel.distributeSt(); 
		if(path=='rtm') State.rtmModel.distributeSt(); 
		if(path=='gcr') State.gcrModel.distributeSt(); 
		if(path=='rpsz') State.rpszModel.distributeSt(); 
		if(path=='neo') State.neoModel.distributeSt(); 
	}


/////////////////////////////

	getDefaultSt() {

		return State.defaultSt[State.currPath];
	}

/////////////////////////////

	resetData() {

		let i, pats;

		pats = State.customPats;
		for(i in pats) pats[i] = -1; 
		for(i in State.rmzPatsBuf) State.rmzPatsBuf[i] = -1;	
		for(i in State.rlPatsBuf) State.rlPatsBuf[i] = -1;	
		for(i in State.rzPatsBuf) State.rzPatsBuf[i] = -1;	
		for(i in State.ryaPatsBuf) State.ryaPatsBuf[i] = -1;	
	}


/////////////////////////////
// Проверяем, нет ли сохраненных данных по распределению пациентов в LS

	checkDataSaved() {

		const key = 'mn_meth';

		let dataSet = localStorage.getItem(key);
		return (null != dataSet)
	}

/////////////////////////////

	restoreAllData() {

		State.clinData.loadClinData();
		State.methPanel.loadMethData();
	}

/////////////////////////////

	distributeAllPaths() {

		this.distributeSt();
		this.distribResect();
		this.distributeMethKRR();
		this.distributeStMel();
		this.distributeStRMZ();
		this.distributeStRL();
		State.ryaModel.distributeSt();
		State.rshmModel.distributeSt();
		State.rpModel.distributeSt();
		State.rzModel.distributeSt();
		State.rmpModel.distributeSt();
		State.rpshModel.distributeSt();
		State.rpzModel.distributeSt();
		State.rtmModel.distributeSt();
		State.gcrModel.distributeSt();
		State.rpszModel.distributeSt();
		State.neoModel.distributeSt();
	}

/////////////////////////////

	initDataSet() {

		let patSumm = 0, i, len, flCustomData = 0;

		if(this.checkDataSaved()) {
			this.restoreAllData(); // Если пользователь уже заходил
			// в программу - все распределения восстановить из Local Storage
		}
		$('.curr-region').text(State.currRegname);
		len = State.pathKeys.length;
		if(State.customPats['krr']>-1)  // Общее количество пациентов для этой патологии вводилось вручную
			State.pathTotals[0] = State.customPats['krr'];
		else State.pathTotals[0] = State.currPats['krr'];  // Работаем с исходной статистикой по региону

		if(State.customPats['mel']>-1) State.pathTotals[1] = State.customPats['mel'];
		else State.pathTotals[1] = State.currPats['mel'];

		if(State.customPats['rmz']>-1) State.pathTotals[2] = State.customPats['rmz'];
		else State.pathTotals[2] = State.currPats['rmz'];

		if(State.customPats['rl']>-1) State.pathTotals[3] = State.customPats['rl'];
		else State.pathTotals[3] = State.currPats['rl'];

		if(State.customPats['rya']>-1) State.pathTotals[4] = State.customPats['rya'];
		else State.pathTotals[4] = State.currPats['rya'];

		if(State.customPats['rshm']>-1) State.pathTotals[5] = State.customPats['rshm'];
		else State.pathTotals[5] = State.currPats['rshm'];

		if(State.customPats['rp']>-1) State.pathTotals[6] = State.customPats['rp'];
		else State.pathTotals[6] = State.currPats['rp'];

		if(State.customPats['rz']>-1) State.pathTotals[7] = State.customPats['rz'];
		else State.pathTotals[7] = State.currPats['rz'];

		if(State.customPats['rmp']>-1) State.pathTotals[8] = State.customPats['rmp'];
		else State.pathTotals[8] = State.currPats['rmp'];

		if(State.customPats['rpsh']>-1) State.pathTotals[9] = State.customPats['rpsh'];
		else State.pathTotals[9] = State.currPats['rpsh'];

		if(State.customPats['rpz']>-1) State.pathTotals[10] = State.customPats['rpz'];
		else State.pathTotals[10] = State.currPats['rpz'];

		if(State.customPats['rtm']>-1) State.pathTotals[11] = State.customPats['rtm'];
		else State.pathTotals[11] = State.currPats['rtm'];

		if(State.customPats['gcr']>-1) State.pathTotals[12] = State.customPats['gcr'];
		else State.pathTotals[12] = State.currPats['gcr'];

		if(State.customPats['rpsz']>-1) State.pathTotals[13] = State.customPats['rpsz'];
		else State.pathTotals[13] = State.currPats['rpsz'];

		if(State.customPats['neo']>-1) State.pathTotals[14] = State.customPats['neo'];
		else State.pathTotals[14] = State.currPats['neo'];

		this.updateTotalPatientViews()

		State.pathPanel.render(); // Fetch all*2
		if(!this.checkDataSaved()) {
			this.distributeAllPaths();
			State.distPanel.resetAllChanels();
		} 
		State.stPanel.setCurrentSt('st2'); // Fetch current
		State.stPanel.render(State.currPath); // Fetch all
//!!TODO Зачем фетчится все??
// Это приходится делать для маркировки свободных пациентов на панели патологий
// Более корректно иметь метод апдейта только той строки патологий, для которой
// могли перераспределиться пациенты
		State.methPanel.render(this.getDefaultSt(State.currPath));
		State.methPanel.displayResult(); // Fetch all
	}


/////////////////////////////

    padDigits(str){

        var res='',s='',i,cnt,n;

        str = '' + str;
        n = str.length;

        for(i=0,cnt=0;i<n;i++){
            s = s + str.slice(-1);
            str = str.slice(0,str.length-1);
            cnt++;
            if(cnt>=3){
                cnt = 0;
                s = s + ' ';
            }
        }
        n = s.length;
        for(i=0;i<n;i++){
            res = res + s.slice(-1);
            s = s.slice(0,s.length-1);
        }
	    return(res);
    }

/////////////////////////////

    padFloat(str){

        var res='',s='',i,cnt,n, ext, point;

        ext = '';
        str = '' + str;
        point = str.indexOf('.');
        if(point==-1) { str = str + '.00'};
        point = str.indexOf('.');
        if(point>-1) {
            ext = str.slice(point+1, str.length);
            str = str.slice(0, point);
            if(ext.length > 2) ext = ext.slice(0, 1);
            if(ext.length == 1) ext = ext + '0';
            if(ext.length == 0) ext = ext + '00';
        }
        n = str.length;
        for(i=0,cnt=0;i<n;i++){
            s = s + str.slice(-1);
            str = str.slice(0,str.length-1);
            cnt++;
            if(cnt>=3){
                cnt = 0;
                s = s + ' ';
            }
        }
        n = s.length;
        for(i=0;i<n;i++){
            res = res + s.slice(-1);
            s = s.slice(0,s.length-1);
        }
        if(ext!='') {
            res += ',';
            res += ext;
        }
    return(res);

    }

}

export default Calc;