;"use strict";

import regList from './regdata';
import regStat from './region_stat';
import regLeth from './region_leth';
import State from './state';
import DivSlider from './divslider';
import Slider from './vslider';


class RegSelector {

	constructor() {
		this.flRegSelect = 0; // Ready to select subjects first
		this.currSubj = 0;
		this.mapCoords = [
			[48,197],
			[26,60],
			[11,272],
			[37,308],
			[88,212],
			[167,147],
			[238,74],
			[390,4]
			[124,326],
		];

		this.regSubjects = {};
		this.selectedRegs = {};
		this.nOfSubjects = 0;
		this.nOfRegions = 0; 
		this.netError = 0;

		this.regsLoaded = {};
		this.subjList = {};
		this.regionSlider = null;
		this.regSlidePos = 0;
		this.regListSize = 0;
		this.regWinSize = 0;
		this.lastRegion = 0;
		this.currRegInd = 0; // Индекс текущего региона в бд регионов
		this.divPosition = 0;

		this.initRegDivSlider();
		this.initRegData();
        $('#regokbut').on(State.startEvent, ()=>this.hideRegs());


		const lsName = 'mn_regdata';
		let dataStr, dataSet = localStorage.getItem(lsName);
		
		if(null == dataSet 
		|| dataSet == undefined
		|| dataSet == 'undefined') State.regionDataInit = 1; // Необходимо при входе вызвать
		else {
			dataStr = localStorage.getItem(lsName);	
			this.currRegInd = JSON.parse(dataStr);
		}									
	}

////////////////////////////////////////////////

    showRegs(e){

        let speed = 600,  type;

	    this.flRegSelect = 0;
        this.renderSubjList();
        this.restoreSubj(this.currSubj);
        this.regDivSlider.setVal(this.divPosition);
		State.flSelection = 1;
	    State.flAnimate = 1;
		$('#regselect').show();
$('#section'+State.currPage).animate({'opacity':0}, speed, function(){
	$('#section'+State.currPage).hide();
    State.flAnimate = 0;
});
        $('#regselect').animate({top: 72}, speed, function(){
            $('#proginfo').css('display','none');
        });
    }

///////////////////////////////////////////////

	initRegData() {

		var initPos = 100,
			sliderGain = 418;

	//	getRegData(); // Перенесено в remoteDataLoad
this.netError = 1; // Убрать после завершения работы с внутренним массивом данных
		this.getSubjects();
		this.renderSubjList();

	    this.regionSlider = new Slider({
	        sliderDivName:'regscroller',
	        callback:(value, divname) => this.setRegDivPosition(value, divname),
	        initVal:initPos,
	        gain:sliderGain
	    });
	}

//////////////////////////////////////////////

	renderSubjList(){

		var i, str;

		$('#regscroll').empty();
		$('#regscroll').append('<div id="mainmap"><img src="./map-wind.png" style="position: absolute; z-index:200" usemap="#subjects"></div>');
		for(i=0;i<=this.nOfSubjects;i++){
			$('#mainmap').append('<div class="regmap hidmap" id="regmap' + i + '">'+
				'</div>');
			$('#subject'+i).bind('mousedown touchstart', (e) => this.subjClick(e));
			$('#subject'+i).hover((e)=>this.subjIn(e),(e)=>this.subjOut(e));
		}
	//	$('#scrolldiv').removeClass('bordered');
	//	$('#regselect').removeClass('bordered');
	}

///////////////////////////////////////////////

	countRegions(){

		if(this.netError==0){
			return this.regsLoaded.length;
		} else {
			return this.countElements(regList);
		}
	}

	countSubjects(){
		return this.countElements(this.regSubjects);
	}

	countElements(data){
		var i, cnt;

		cnt = 0;
		for(i in data){
			cnt++;
		}
		return cnt;
	}

///////////////////////////////////////////////

	getSubjects(){

		var i, currReg, cnt, subjLimit, currSubj;

		this.nOfRegions = this.countRegions();
		if(this.netError==0){
			subjLimit = this.subjList.length;
			for(i=0;i<subjLimit;i++){
					currReg = {};
					currSubj = this.subjList[i];
					currReg['regName'] = currSubj['name'];
					currReg['zone'] = '';
					currReg['regDescr'] = '';
					currReg['inter'] = 0;
					currReg['lawdoc'] = '';
					this.regSubjects[i] = currReg;
				}
		} else {
			cnt = 0;
			for(i=0;i<this.nOfRegions;i++){
				currReg = regList[i];
				if(currReg['inter']==0){
					this.regSubjects[cnt] = currReg;
					cnt++;
				}
			}
		}
		this.nOfSubjects = this.countSubjects();	
	}

///////////////////////////////////////////////

	subjClick(e){

		var str, subjNum, type, target;

	    type = e.type;
		str = e.target.id;
		target = str.slice(0,4);
		if(target!='subj') {
			return;
		}
		str = str.slice(-1);
		subjNum = parseInt(str);
		if(subjNum==8) subjNum = 2;
		this.updateSelected(subjNum);
		return;

//	if(subjNum!=lastRegLine){
//		$('#regmap'+subjNum).removeClass('hidmap');
//		$('#regmap'+lastRegLine).addClass('hidmap');
//	    lastRegLine = subjNum;
//	}
//  currSubj = subjNum;
//    lastSubj = subjNum;
//    if(lastRegLine!=subjNum) lastRegion = 0;
}

///////////////////////////////////////////////


	subjIn(e){

		var str,subjNum;

		str = e.target.id;
		str = str.slice(-1);
		subjNum = parseInt(str);
		$('#regmap'+subjNum).removeClass('hidmap');
		if(subjNum==8)
			$('#regmap'+2).removeClass('hidmap');
		if(subjNum==8 && this.lastRegLine==2) return;
		if(subjNum!=this.lastRegLine){
			$('#regmap'+this.lastRegLine).addClass('hidmap');
			if(this.lastRegLine==2 && subjNum != 8)
				$('#regmap'+8).addClass('hidmap');
		}
	}

///////////////////////////////////////////////

	subjOut(e){

		var str,subjNum;

		str = e.target.id;
		str = str.slice(-1);
		subjNum = parseInt(str);
		$('#regmap'+subjNum).addClass('hidmap');
		if(subjNum==8)
			$('#regmap'+2).addClass('hidmap');
		$('#regmap'+this.lastRegLine).removeClass('hidmap');
			if(this.lastRegLine==2)
				$('#regmap'+8).removeClass('hidmap');
	}

///////////////////////////////////////////////

	updateSelected(lineNum){

	        var str, currReg, title, name;

	        if(this.flRegSelect==1){ // Флаг режима выбора 1 = выбираем регион
	        // Режим апдейта выбранного элемента при работе с регионами
	            if(lineNum!=this.lastRegLine) {
	                $('#regline'+this.lastRegLine).removeClass('selline');
	                $('#regline'+lineNum).addClass('selline');
	            }
	            currReg = this.selectedRegs[lineNum];
	            title = 'Выбранный регион: ';
	            this.lastRegion = lineNum;
	            this.currRegion = currReg.n;
	// console.log(currReg.n);            
	// console.log(currReg);            
	        } else {
	        // Режим апдейта выбранного элемента при работе с субъектами
	            if(lineNum!=this.lastRegLine) {
	                $('#regmap'+lineNum).removeClass('hidmap');
	                $('#regmap'+this.lastRegLine).addClass('hidmap');
	            }
	            currReg = this.regSubjects[lineNum];
	            title = 'Выбранный субъект: ';
	            this.currSubj = lineNum;
	            this.lastSubj = lineNum;
	            if(this.lastRegLine!=lineNum) this.lastRegion = 0;
	        }
	        this.lastRegLine = lineNum;
	        name = currReg['regName'];
	        if(currReg['zone']!='') name = name + ', ' + currReg['zone']; 
	        str = title + name;
	        $('#selectedreg').text(str);
	        $('#print-region').text(name);
	        if(this.flRegSelect==1){
	            $('#regionname').text(name);
	        }
	    }

///////////////////////////////////////////////

    restoreSubj(lineNum){

        var str, currReg, title, name;

        $('#regmap'+this.lastRegLine).addClass('hidmap');
        $('#regmap'+lineNum).removeClass('hidmap');
        this.lastRegLine = lineNum;
        currReg = this.regSubjects[lineNum];
        title = 'Выбранный субъект: ';
        this.currSubj = lineNum;
        this.lastSubj = this.lastRegLine;
        name = currReg['regName'];
        str = title + name;
        $('#selectedreg').text(str);
    }

///////////////////////////////////////////////

	saveRegData(regNum) {

		const lsName = 'mn_regdata';
		let dataStr = JSON.stringify(regNum);
		
		localStorage.setItem(lsName, dataStr);
		State.regionDataInit = 0;
	}

///////////////////////////////////////////////
// По номеру региона в this.currRegInd выполняем тот же набор действий
// что и в hideRegs при интерактивном выборе региона

	restoreRegData() { 

		let currReg, currPats, currIndex, currStat, lethStat, currLeth;

			currReg = regList[this.currRegInd];

            State.currRegAdd = currReg['inter'];
            State.currLawDoc = currReg['lawdoc'];
            currPats = State.currPats;
            currLeth = State.currLeth;
            currIndex = currReg['ind'];
            currStat = regStat[currIndex];
            lethStat =  regLeth[currIndex];

            currPats['mel'] = currStat['mel'];
            currPats['krr'] = currStat['krr'];
            currPats['rmz'] = currStat['rmz'];
            currPats['rl'] = currStat['rl'];
            currPats['rya'] = currStat['rya'];
            currPats['rshm'] = currStat['rshm'];
            currPats['rp'] = currStat['rp'];
            currPats['rz'] = currStat['rz'];
            currPats['rmp'] = currStat['rmp'];
            currPats['rpsh'] = currStat['rpsh'];
            currPats['rpz'] = currStat['rpz'];
            currPats['rtm'] = currStat['rtm'];
            currPats['gcr'] = currStat['gcr'];
            currPats['rpsz'] = currStat['rpsz'];
            currPats['neo'] = Math.round(currStat['neo']*0.0000525);
            currLeth['mel'] = lethStat['mel'];
            State.currRegname = currStat['reg'];
            State.currRegNum = currIndex;
            this.lastRegLine = 0;
		// Стираем ручные количества по патологиям
		// и буфер количеств по группам для РМЖ
			State.calc.resetData();
			State.calc.initDataSet();
//State.calc.distributeAllPaths();            
			State.drugStore.fillDrugData();
			State.priceTable.render();
			State.methPanel.displayResult();
			State.pathPanel.render();
			State.calc.updateTotalPatientViews();
console.log(State.pathSumm);
	}

///////////////////////////////////////////////

    hideRegs(){
    
        var speed = 600, str, name, currReg, title, 
        	currPats, currIndex, currStat, lethStat, currLeth;

        if(this.flRegSelect==0){
            this.flRegSelect = 1;
            $('#regback').css('display','block');
            $('#regselect').animate({opacity: 0}, speed, ()=>{
                this.renderRegList();
                $('#regscroller').css('display','block');
				this.regDivPosition = 0;
                this.regDivSlider.setVal(this.regDivPosition);
                $('#regselect').animate({opacity: 1}, speed, function(){
//                    $('#regback').css('display','none');
                });
                this.restoreReg(this.lastRegion);
                currReg = this.selectedRegs[this.lastRegion];
//                currRegion = currReg.n;
//                reactivateSession();
                title = 'Выбранный регион: ';
                name = currReg['regName'];
                if(currReg['zone']!='') name = name + ', ' + currReg['zone']; 
                str = title + name;
                $('#selectedreg').text(str);
                $('#regionname').text(name);
            });
        } else { // Текущий регион выбран. Инициируем все связанные с ним данные 
            currReg = this.selectedRegs[this.lastRegion];
            this.currRegion = currReg.n;
            this.currRegInd = currReg.num; // Индекс в общей БД регионов
            this.saveRegData(this.currRegInd);
//!!D            reactivateSession();
//            currMult = currReg['inter'];
            State.currRegAdd = currReg['inter'];
            State.currLawDoc = currReg['lawdoc'];
            currPats = State.currPats;
            currLeth = State.currLeth;
            currIndex = currReg['ind'];
            currStat = regStat[currIndex];
            lethStat =  regLeth[currIndex];

            currPats['mel'] = currStat['mel'];
            currPats['krr'] = currStat['krr'];
            currPats['rmz'] = currStat['rmz'];
            currPats['rl'] = currStat['rl'];
            currPats['rya'] = currStat['rya'];
            currPats['rshm'] = currStat['rshm'];
            currPats['rp'] = currStat['rp'];
            currPats['rz'] = currStat['rz'];
            currPats['rmp'] = currStat['rmp'];
            currPats['rpsh'] = currStat['rpsh'];
            currPats['rpz'] = currStat['rpz'];
            currPats['rtm'] = currStat['rtm'];
            currPats['gcr'] = currStat['gcr'];
            currPats['rpsz'] = currStat['rpsz'];
            currPats['neo'] = Math.round(currStat['neo']*0.0000525);
            currLeth['mel'] = lethStat['mel'];
            State.currRegname = currStat['reg'];
            State.currRegNum = currIndex;
            this.lastRegLine = 0;
State.methPanel.resetMethData();
State.calc.resetData();
State.calc.initDataSet();            
State.drugStore.fillDrugData();
State.priceTable.render();
State.methPanel.displayResult();
State.pathPanel.render();
State.calc.updateTotalPatientViews();

            $('#regselect').animate({top: -574}, speed);
            $('#regscroller').css('display','none');
$('#regback').css('display','none');
			$('#reg-interest').text(State.currRegAdd+'%');
$('#section'+State.currPage).show();
$('#section'+State.currPage).animate({'opacity':1}, 700, function(){
    State.flAnimate = 0;
});
setTimeout(function(){
    $('#print-butt').show();
    $('.menustrip').show();
}, 500);
			State.flSelection = 0;

        }

     }

///////////////////////////////////////////////

	renderRegList(){

		var i, str, info, size, frameSize, scrollSize, divSize;

		this.loadRegList();
		size = this.countElements(this.selectedRegs);
		$('#regscroll').empty();
		for(i=0;i<size;i++){
			str = this.selectedRegs[i].regName;
			info = this.selectedRegs[i].regDescr;
			$('#regscroll').append('<div class="regline" id="regline' + i + '">'+
				'<div id="regname'+ i +'" class="regname">' + str + '</div>'+
				'<div id="reginfo'+ i +'" class="reginfo">' + info + '</div>'+
				'</div>');
		}
	    $('#regscroller').css('display','block');

	//	$('#scrolldiv').addClass('bordered');
	//	$('#scrolldiv').removeClass('bordered');
	//	$('#regselect').addClass('bordered');
		frameSize = $('#scrolldiv').height();
		scrollSize = $('#regscroll').height();
		this.regListSize = scrollSize;
		this.regWinSize = frameSize;
		i = frameSize - scrollSize;
		if(i>0) {
			divSize = $('#regline'+(size-1)).height();
			divSize = divSize + i;
			$('#regline'+(size-1)).height(divSize);
		}
	}

///////////////////////////////////////////////

	loadRegList(){
		var i, j, cnt, currReg, regCnt, tmpReg, dataCnt, rg;

		this.selectedRegs = {};
		regCnt = 0;
		if(this.netError==0){
			for(i=0;i<this.nOfRegions;i++){
				currReg = this.regsLoaded[i];
				if(currReg['subject']==this.currSubj+1){
					tmpReg = {};
					tmpReg['n'] = i;
					tmpReg['regName'] = currReg['name'];
					tmpReg['zone'] = currReg['zone'];
					tmpReg['regDescr'] = currReg['description'];
					tmpReg['inter'] = currReg['inter'];
					tmpReg['lawdoc'] = currReg['lawdoc'];
					tmpReg['ind'] = currReg['ind'];
					this.selectedRegs[regCnt] = tmpReg;
					regCnt++;
				}
			}
		} else {
			cnt = 0, dataCnt = 0;
			for(i=0 ; i<this.nOfRegions ; i++){
				currReg = regList[i];
				if(currReg['inter']==0){
					dataCnt--;
					if(cnt==this.currSubj){
						j = i + 1;
						dataCnt++;
						currReg = regList[j];
						while((currReg['inter']!=0)&&(j<this.nOfRegions)){
							tmpReg = {};
							tmpReg['regName'] = currReg['regName'];
							tmpReg['zone'] = currReg['zone'];
							tmpReg['regDescr'] = currReg['regDescr'];
							tmpReg['inter'] = currReg['inter'];
							tmpReg['lawdoc'] = currReg['lawdoc'];
							tmpReg['ind'] = currReg['ind'];
							tmpReg['num'] = currReg['num'];
							tmpReg['n'] = dataCnt; 
							this.selectedRegs[regCnt] = {};
							for(rg in tmpReg) {
								if(!tmpReg.hasOwnProperty(rg)) continue;
								this.selectedRegs[regCnt][rg] = tmpReg[rg];
							}
							j++; regCnt++; dataCnt++;
							if(j>=this.nOfRegions) break;
							currReg = regList[j];
						}
						return;	
					}
					cnt++;
				}
				dataCnt++;
			}
		}
	}

///////////////////////////////////////////////

    restoreReg(lineNum){

        var str, currReg, title, name;

        $('#regline'+this.lastRegLine).removeClass('selline');
        $('#regline'+lineNum).addClass('selline');
        this.lastRegLine = lineNum;
        currReg = this.selectedRegs[lineNum];
        title = 'Выбранный регион: ';
        this.lastRegion = this.lastRegLine;
        name = currReg['regName'];
        if(currReg['zone']!='') name = name + ', ' + currReg['zone']; 
        str = title + name;
        $('#selectedreg').text(str);
    }

///////////////////////////////////////////////

    initRegDivSlider(){

        this.regDivPosition = 100;
        this.regDivSlider = new DivSlider({
            sliderDivName:'scrolldiv',
            callback:(data)=>this.updateRegs(data),
            postrace:(data)=>this.updateRegScroller(data),
            initVal:this.regDivPosition
        });

    }

///////////////////////////////////////////////

    updateRegScroller(position) {

        var currPos, hidden;

        hidden = this.regListSize - this.regWinSize;
        if(hidden<0) hidden = 0;
        currPos = 0 - position;
        currPos =  hidden - currPos;
        currPos = currPos/hidden*100;
        if(currPos>100) currPos = 100;
        if(currPos<0) currPos = 0;
        this.regionSlider.setVal(currPos);
    }

///////////////////////////////////////////////

    updateRegs(data){

        var lineNum, str, lineHeigth = 113 ;

        if(!this.flRegSelect) return;
        str = data.slice(7);

        lineNum = Math.floor(str);
        this.updateSelected(lineNum);
    }

///////////////////////////////////////////////

	setRegDivPosition(value, divName){

		var currPos, hidden, maxMove;

		hidden = this.regListSize - this.regWinSize;
		if(hidden<0) hidden = 0;
	// 	maxMove = hidden/regWinSize*100;
	// 	currPos = regDivPosition - maxMove/100.0*(100 - value)*1.02;
		currPos = this.regDivPosition - hidden/100*(100 - value)*1.02; 
		this.regDivSlider.setVal(currPos);
}

///////////////////////////////////////////////


}

///////////////////////////////////////////////
///////////////////////////////////////////////

export default RegSelector;


//////////////////////////


function getRegData(){

    var apiUrl = '../data/api/',
    	regionUrl,
    	subjectUrl, regNum, nOfRegs, currReg, oldReg, param,
    	subjNum, nOfSubjs, currSubj, oldSubj, numVar,
    	regsBufer = {},
    	subjBufer = {};
//D
return;
    if(deploy == 'production') apiUrl = '../data/api/';
    if(deploy == 'testing') apiUrl = '../test/data/api/';
    if(deploy == 'local') apiUrl = '../test/data/api/';
//    if(deploy == 'local') apiUrl = '../test/data/api_badaddress/';// Bad address for testing of using constants

    regionUrl = apiUrl + 'regions';
    subjectUrl = apiUrl + 'subjects';

    $.ajax({
           url: regionUrl,
           type: "get",
           async: false,
           data: {},
           dataType: "json",
           contentType: "application/json",
           success: function(data){ 
//              regsLoaded = data.rows;
              regsBufer = data.rows;
              },
           error: function(data){ 
//           		console.log('No connection to region DB');
//           		console.log(data); 
           		this.netError = 1;
           	}
          });             

    if(this.netError==0){
    	nOfRegs = regsBufer.length;
    	for(regNum=0 ; regNum<nOfRegs ; regNum++){
    		if(regsLoaded[0]===undefined) {
    			flRegDataOld = true;
    			break;
    		}
    		currReg = regsBufer[regNum];
    		oldReg = regsLoaded[regNum];
    		if(oldReg['description']!=currReg['description']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['id']!=currReg['number']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['inter']!=currReg['inter']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['lawdoc']!=currReg['lawdoc']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['name']!=currReg['name']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['subject']!=currReg['subject']) {
   				flRegDataOld = true;
    		}
    		if(oldReg['zone']!=currReg['zone']) {
   				flRegDataOld = true;
    		}
    	}
   		if(flRegDataOld) regsLoaded = regsBufer; 
    }	
    $.ajax({
           url: subjectUrl,
           type: "get",
           async: false,
           data: {},
           dataType: "json",
           contentType: "application/json",
           success: function(data){ 
//              subjList = data.rows;
              subjBufer = data.rows;
              },
           error: function(data){ 
//           		console.log('No connection to subject DB');
//           		console.log(data);  netError = 1;
           	}
          });
    if(this.netError==0){
    	nOfSubjs = subjBufer.length;
    	for(subjNum=0 ; subjNum<nOfSubjs ; subjNum++){
    		if(subjList[0]===undefined) {
    			flSubjDataOld = true;
    			break;
    		}
    		currSubj = subjBufer[subjNum];
    		oldSubj = subjList[subjNum];
    		if(oldSubj['id']!=currSubj['number']) {
   				flSubjDataOld = true;
    		}
    		if(oldSubj['name']!=currSubj['name']) {
   				flSubjDataOld = true;
    		}
    	}
    	if(flSubjDataOld) subjList = subjBufer;
    }

//	if(netError==0) console.log('Good connection to remote DB. Overloading region data.');                     
}       





